import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Check, X, AlertCircle } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import imageCompression from 'browser-image-compression';
import { Capacitor } from '@capacitor/core';

const PaymentSuccessNotification = ({ onClose }) => {
  return (
    <div className="bg-white border border-green-100 rounded-lg shadow-lg p-4 max-w-2xl w-full mx-auto mt-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="bg-green-100 rounded-full p-2">
            <Check className="text-green-500 w-6 h-6" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">Payment Successful</h2>
            <p className="text-gray-600 mt-1">
              Your payment has been successfully received.
              <br />
              You have unlocked premium service now.
            </p>
          </div>
        </div>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const ConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-gray-900">Confirm Upload</h2>
        <p className="text-gray-600 mb-6">
          Are you sure you want to upload these images and start the color analysis process?
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const GeneratingAnalysis = () => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-xl shadow-2xl max-w-md w-full text-center">
      <div className="relative z-10">
        <div className="flex flex-col items-center mb-6">
          <img src="/logo.png" alt="Logo" className="w-16 h-16 mb-4" />
        </div>

        <h2 className="text-2xl font-bold mb-4 text-gray-900">
          Creating Your Color Analysis
        </h2>

        <p className="text-gray-600 mb-6">
          Our AI is analyzing your photos and generating your personalized color palette...
        </p>

        <div className="space-y-3 text-left">
          <div className="flex items-center text-sm text-gray-600">
            <div className="w-4 h-4 rounded-full bg-green-500 mr-3 flex items-center justify-center">
              <Check className="w-3 h-3 text-white" />
            </div>
            <span>Photos uploaded successfully</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <div className="w-4 h-4 rounded-full bg-green-500 mr-3 flex items-center justify-center">
              <Check className="w-3 h-3 text-white" />
            </div>
            <span>Processing images</span>
          </div>
          <div className="flex items-center text-sm text-gray-600">
            <ClipLoader color="#2563eb" size={16} className="mr-3" />
            <span>Generating color analysis</span>
          </div>
        </div>

        <div className="mt-8 text-sm text-gray-600 bg-gray-50 p-4 rounded-lg">
          <p className="font-bold text-gray-900 mb-2">While you wait...</p>
          <p>
            We're using advanced AI to analyze your photos and create a personalized
            color palette that will enhance your natural features.
          </p>
        </div>
      </div>
    </div>
  </div>
);

const ImageUpload = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const [compressedImages, setCompressedImages] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const API_ENDPOINT = process.env.REACT_APP_API_GATEWAY_ENDPOINT;

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const verifySession = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');

      if (sessionId) {
        try {
          const response = await axios.get(`${API_ENDPOINT}/verify-success`, {
            params: { session_id: sessionId }
          });
          if (response.data.success) {
            localStorage.setItem('token', response.data.token);
            setPaymentSuccess(true);
            window.history.replaceState({}, document.title, "/upload");
          }
        } catch (error) {
          console.error('Payment verification error:', error);
        }
      }

      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, redirecting to login');
        navigate('/login');
      } else {
        try {
          console.log('Verifying token');
          const response = await axios.post(`${API_ENDPOINT}/verify-token`, { token });
          if (!response.data.valid) {
            console.log('Token invalid, redirecting to login');
            navigate('/login');
          } else {
            setUserPlan(response.data.plan);
          }
        } catch (error) {
          console.error('Token verification failed:', error);
          navigate('/login');
        }
      }
      setIsLoading(false);
    };

    verifySession();
  }, [navigate, location, API_ENDPOINT]);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.85,  // Reduced from 0.8 to 0.5
      useWebWorker: true,
      fileType: 'image/jpeg',
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log(`Compressed size: ${compressedFile.size / 1024 / 1024} MB`);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const handleImageSelection = async (event) => {
    const files = Array.from(event.target.files);
    const maxImages = userPlan === 'basic' ? 3 : 10;
    const minImages = userPlan === 'basic' ? 1 : 6;
    const newImages = [];
    const newCompressedImages = [];

    for (let file of files) {
      if (selectedImages.length + newImages.length >= maxImages) {
        setError(`You can only upload up to ${maxImages} images with your ${userPlan} plan.`);
        break;
      }
      newImages.push(file);
      const compressedFile = await compressImage(file);
      newCompressedImages.push(compressedFile);
    }

    setSelectedImages([...selectedImages, ...newImages]);
    setCompressedImages([...compressedImages, ...newCompressedImages]);
    setError(null);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const minImages = userPlan === 'basic' ? 1 : 6;
    if (selectedImages.length < minImages) {
      alert(`Please select at least ${minImages} image${minImages > 1 ? 's' : ''}.`);
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirmUpload = async () => {
    setIsUploading(true);
    setShowConfirmation(false);

    try {
      const formData = new FormData();
      for (let i = 0; i < compressedImages.length; i++) {
        const file = compressedImages[i];
        const fileName = `${uuidv4()}-${file.name}`;
        formData.append('images', file, fileName);
      }

      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_ENDPOINT}/start-training`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      });

      if (response.data.success) {
        if (userPlan === 'basic') {
          setIsGenerating(true);
          let retryCount = 0;
          const maxRetries = 60; // 5 minutes max
          const pollInterval = setInterval(async () => {
            try {
              const pollResponse = await axios.get(
                `${API_ENDPOINT}/create-playbook`,
                {
                  params: { 
                    token,
                    uuid: response.data.uuid
                  }
                }
              );
              
              if (pollResponse.data.playbook && pollResponse.data.playbook.style_analysis) {
                clearInterval(pollInterval);
                setIsGenerating(false);
                navigate('/color-analysis');
              } else if (pollResponse.data.error) {
                clearInterval(pollInterval);
                setError(pollResponse.data.error);
                setIsGenerating(false);
              }
              
              retryCount++;
              if (retryCount >= maxRetries) {
                clearInterval(pollInterval);
                setError('Analysis generation timed out. Please try again.');
                setIsGenerating(false);
              }
            } catch (error) {
              console.error('Polling error:', error);
              clearInterval(pollInterval);
              setError('Error generating analysis. Please try again.');
              setIsGenerating(false);
            }
          }, 5000);
        } else {
          navigate('/waiting');
        }
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('An error occurred during upload. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const getInstructions = () => {
    const basicInstructions = [
      "Upload 1-3 photos of yourself.",
      "Make sure you are the only person in the photo.",
      "Selfies work great - ensure your face is clearly visible.",
      "Use photos with good lighting conditions.",
      "Try to ensure the image quality is as good as possible."
    ];

    const proInstructions = [
      "Upload 8-10 photos of yourself.",
      "Make sure you are the only person in each photo.",
      "Selfies work great, so feel free to use a lot of them.",
      "Ensure your face is clearly visible.",
      "Include both indoor and outdoor photos if possible.",
      "Try to ensure the image quality is as good as possible."
    ];

    return userPlan === 'basic' ? basicInstructions : proInstructions;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <img src="/logo.png" alt="Loading" className="w-24 h-24 animate-pulse" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {!isNativeMobile && (
        <nav className="bg-white border-b border-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-center h-24">
              <Link to="/" className="flex items-center">
                <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
              </Link>
            </div>
          </div>
        </nav>
      )}
      
      {paymentSuccess && (
        <PaymentSuccessNotification onClose={() => setPaymentSuccess(false)} />
      )}

      <main className="flex-grow container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
          Upload Your Photos
        </h1>

        <div className={`max-w-7xl mx-auto ${isMobile ? '' : 'md:flex'} gap-12 mb-12`}>
          {isMobile ? (
            <>
              <div className="w-full mb-12">
                <h2 className="text-2xl font-bold text-gray-900 mb-6">Instructions:</h2>
                <ol className="space-y-4">
                  {getInstructions().map((instruction, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <span className="flex-shrink-0 w-6 h-6 bg-blue-600 text-white rounded-full flex items-center justify-center text-sm font-medium">
                        {index + 1}
                      </span>
                      <span className="text-base text-gray-900 font-medium">{instruction}</span>
                    </li>
                  ))}
                </ol>
                <div className="mt-8 p-6 bg-gray-50 rounded-xl">
                  <p className="text-lg text-gray-900 italic">
                    We'll analyze your photos and email you when it is ready! It takes about one hour.
                  </p>
                </div>
              </div>

              <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64 bg-white rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-50">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-600"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500">PNG, JPG or WEBP (MAX. 10 images, 10 MB in total)</p>
                      </div>
                      <input 
                        id="dropzone-file" 
                        type="file" 
                        className="hidden" 
                        onChange={handleImageSelection}
                        accept="image/*"
                        multiple
                      />
                    </label>
                  </div>

                  {error && (
                    <div className="text-red-500 text-center p-4 bg-red-50 rounded-lg">
                      {error}
                    </div>
                  )}

                  {selectedImages.length > 0 && (
                    <div>
                      <h3 className="text-xl font-semibold mb-4 text-gray-900">
                        Selected Images ({selectedImages.length}/10)
                      </h3>
                      <div className="grid grid-cols-3 gap-4">
                        {selectedImages.map((image, index) => (
                          <div key={index} className="relative group">
                            <img 
                              src={URL.createObjectURL(image)} 
                              alt={`Selected ${index + 1}`} 
                              className="w-full h-24 object-cover rounded-lg shadow-md"
                            />
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                              <button
                                onClick={() => handleRemoveImage(index)}
                                className="text-white bg-red-500 rounded-full p-1 hover:bg-red-600 transition-colors"
                              >
                                <X size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <button
                    type="submit"
                    className={`w-full bg-blue-600 text-white font-semibold py-3 px-6 rounded-xl hover:bg-blue-700 transition duration-300 ${
                      selectedImages.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={selectedImages.length === 0 || isUploading}
                  >
                    {isUploading ? (
                      <ClipLoader color="#ffffff" loading={isUploading} size={24} />
                    ) : (
                      'Upload and Start Analysis'
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="w-full md:w-1/2 order-2 md:order-1">
                <h2 className="text-3xl font-bold mb-6 text-gray-900">Instructions:</h2>
                <ol className="list-decimal list-inside space-y-4">
                  {getInstructions().map((instruction, index) => (
                    <li key={index} className="text-lg text-gray-900 font-medium hover:text-blue-600 transition-all duration-300">
                      {instruction}
                    </li>
                  ))}
                </ol>
                <p className="mt-8 text-lg text-gray-900 italic border-l-4 border-blue-600 pl-4">
                  We'll analyze your photos and email you your personalized color palette within an hour!
                </p>
              </div>
              <div className="w-full md:w-1/2 order-1 md:order-2">
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64 bg-white rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-50">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-600"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500">PNG, JPG or WEBP (MAX. 10 images, 10 MB in total)</p>
                      </div>
                      <input id="dropzone-file" type="file" className="hidden" onChange={handleImageSelection} multiple accept="image/*" disabled={isUploading || selectedImages.length >= 10} />
                    </label>
                  </div>
                  <div className="text-right text-sm text-gray-600">
                    You can see below your uploaded pictures. Maximum 10 pics allowed.
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                  )}
                  <button 
                    type="submit" 
                    className="w-full bg-blue-600 text-white font-bold py-3 px-6 rounded-xl hover:bg-blue-700 transition duration-300"
                    disabled={isUploading || selectedImages.length === 0}
                  >
                    {isUploading ? "Uploading..." : "Analyze My Colors"}
                  </button>
                </form>
              </div>
            </>
          )}
        </div>

        {!isMobile && selectedImages.length > 0 && (
          <div className="w-full">
            <h3 className="text-2xl font-semibold mb-4">Selected Images ({selectedImages.length}/10)</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {selectedImages.map((image, index) => (
                <div key={index} className="relative group">
                  <img src={URL.createObjectURL(image)} alt={`Selected ${index + 1}`} className="w-full h-40 object-cover rounded-lg" />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                    <span className="text-white text-sm">Image {index + 1}</span>
                  </div>
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>

      {showConfirmation && (
        <ConfirmationPopup
          onConfirm={handleConfirmUpload}
          onCancel={() => setShowConfirmation(false)}
        />
      )}

      {isGenerating && <GeneratingAnalysis />}

      {!isNativeMobile && <Footer />}
    </div>
  );
};

export default ImageUpload;
