import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { FaDownload, FaPalette, FaShare, FaPlay, FaHome, FaStar } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import Playground from './Playground';
import { Share } from '@capacitor/share';
import { InAppReview } from '@capacitor-community/in-app-review';
import Footer from './Footer';

const ColorBox = ({ color, name }) => (
  <div className="flex flex-col items-center">  
    <div
      className="w-20 h-20 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-110 relative"
      style={{ backgroundColor: color }}
    >
      <span className="absolute bottom-1 right-1 text-white text-xs font-mono bg-black bg-opacity-50 px-1 rounded">
        {color}
      </span>
    </div>
    <span className="mt-2 text-sm font-medium">{name}</span>
  </div>
);

const ColorBoxMobile = ({ color, name }) => (
  <div className="flex flex-col items-center m-2">
    <div
      className="w-20 h-20 rounded-md shadow-lg"
      style={{ backgroundColor: color }}
    ></div>
    <span className="mt-2 text-sm font-bold">{name}</span>
    <span className="text-xs font-bold">{color}</span>
  </div>
);

const ImageWithDownload = ({ src, alt }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = alt.replace(/\s+/g, '_').toLowerCase() + '.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div 
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={src}
        alt={alt}
        className="w-full h-auto rounded-lg shadow-lg"
      />
      {isHovered && (
        <button
          onClick={handleDownload}
          className="absolute top-2 right-2 bg-primary text-white p-2 rounded-full hover:bg-secondary transition-colors duration-200"
        >
          <FaDownload />
        </button>
      )}
    </div>
  );
};

const ColorSection = ({ title, description, colors, pics, isReversed, isMobile }) => {
  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${colors[0]?.color_code}, ${colors[2]?.color_code})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  const content = (
    <>
      <motion.h3
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-5xl font-bold mb-4"
        style={gradientStyle}
      >
        {title}
      </motion.h3>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
        className="text-gray-300 text-lg leading-relaxed mb-8"
      >
        {description.split('. ').map((sentence, index, array) => (
          <p key={index} className="mb-2">
            {sentence.trim() + (index < array.length - 1 ? '.' : '')}
          </p>
        ))}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.6 }}
        className="flex justify-around mb-8"
      >
        {Array.isArray(colors) && colors.map((color, index) => (
          <ColorBox key={index} color={color.color_code} name={color.color_name} />
        ))}
      </motion.div>
    </>
  );

  const images = (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6, delay: 0.4 }}
      className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-4 h-full`}
    >
      {pics && Object.values(pics).map((pic, index) => (
        <ImageWithDownload
          key={index}
          src={pic}
          alt={`${title} example ${index + 1}`}
          className={`w-full ${isMobile ? 'h-[300px]' : 'h-full'} object-cover`}
        />
      ))}
    </motion.div>
  );

  if (isMobile) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-12"
      >
        {content}
        <div className="mt-8">{images}</div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className={`flex flex-col lg:flex-row items-start mb-24 ${
        isReversed ? "lg:flex-row-reverse" : ""
      }`}
    >
      <div className={`lg:w-1/3 mb-8 lg:mb-0 ${isReversed ? 'lg:pl-12' : 'lg:pr-12'}`}>
        {content}
      </div>
      <div className="lg:w-2/3 mb-8 lg:mb-0 h-[600px]">
        {images}
      </div>
    </motion.div>
  );
};

const JewelrySection = ({ jewelColor, explanation, pics, isMobile }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="mt-12 text-center"
    >
      <h3 className="text-4xl font-bold mb-4 bg-gradient-to-r from-yellow-400 to-yellow-200 bg-clip-text text-transparent">
        {jewelColor === 'gold' ? 'Gold Jewelry' : 'Silver Jewelry'}
      </h3>
      <div className="text-gray-300 text-lg leading-relaxed mb-8 max-w-3xl mx-auto">
        {explanation.split('. ').map((sentence, index, array) => (
          <p key={index} className="mb-2">
            {sentence.trim() + (index < array.length - 1 ? '.' : '')}
          </p>
        ))}
      </div>
      <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-4 max-w-4xl mx-auto ${isMobile ? '' : 'h-[600px]'}`}>
        {pics && Object.values(pics).map((pic, index) => (
          <ImageWithDownload
            key={index}
            src={pic}
            alt={`${jewelColor} jewelry example ${index + 1}`}
            className={`w-full ${isMobile ? 'h-[300px]' : 'h-full'} object-cover`}
          />
        ))}
      </div>
    </motion.div>
  );
};

export const Separator = () => (
  <div className="w-full flex justify-center my-8">
    <div className="w-1/3 h-px bg-gradient-to-r from-transparent via-gray-400 to-transparent"></div>
  </div>
);

const ColorAnalysisTest = () => {
  const [scrollY, setScrollY] = useState(0);
  const [playbook, setPlaybook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { publicId } = useParams();
  const [generationStatus, setGenerationStatus] = useState('idle');
  const [isPublicView, setIsPublicView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const slideTimerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [copied, setCopied] = useState(false);
  const [isTouching, setIsTouching] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchPlaybook = async () => {
      setLoading(true);
      try {
        let response;
        if (publicId) {
          // Fetch public playbook
          response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
            { params: { public_id: publicId } }
          );
          setIsPublicView(true);
        } else {
          // Fetch private playbook
          const token = localStorage.getItem('token');
          if (!token) {
            navigate('/login');
            return;
          }

          // First, verify the token
          const verifyResponse = await axios.post(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`,
            { 
              token,
              appleToken: isNativeMobile ? localStorage.getItem('appleToken') : undefined
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );

          if (!verifyResponse.data.valid) {
            localStorage.removeItem('token');
            navigate('/login');
            return;
          }
          if (!verifyResponse.data.hasPaid) {
            navigate('/payment');
            return;
          }
          if (verifyResponse.data.nextPage === '/waiting') {
            navigate('/waiting');
            return;
          }
          if (verifyResponse.data.nextPage === '/upload') {
            navigate('/upload');
            return;
          }

          const uuid = verifyResponse.data.uuid;

          // If token is valid, fetch the playbook
          response = await axios.get(
            `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
            {
              params: { token, uuid }
            }
          );

          if (response.data.message === 'Playbook generation started') {
            setGenerationStatus('generating');
            pollPlaybookStatus(uuid);
          }
        }

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
          setGenerationStatus('completed');
        } else {
          setError('Playbook not found');
        }
      } catch (error) {
        // console.error('Error:', error);
        // setError('An error occurred. Please try again.');
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    fetchPlaybook();
  }, [navigate, publicId, isNativeMobile]);

  const pollPlaybookStatus = async (uuid) => {
    const pollInterval = setInterval(async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
          {
            params: { token: localStorage.getItem('token'), uuid }
          }
        );

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
          setGenerationStatus('completed');
          clearInterval(pollInterval);
        }
      } catch (error) {
        console.error('Error polling playbook status:', error);
      }
    }, 10000); // Poll every 10 seconds

    // Clear interval after 10 minutes (60 * 10 * 1000 ms)
    setTimeout(() => {
      clearInterval(pollInterval);
      if (generationStatus !== 'completed') {
        setError('Playbook generation timed out. Please try again later.');
      }
    }, 600000);
  };

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  const ShareButton = () => {
    const [copied, setCopied] = useState(false);

    const handleShare = () => {
      if (playbook && playbook.public_id) {
        const shareUrl = `https://palettehunt.com/shared/${playbook.public_id}`;
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }
    };

    return (
      <button
        onClick={handleShare}
        className="bg-white text-gray-900 font-semibold py-3 px-8 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg flex items-center justify-center space-x-2"
      >
        <FaShare className="mr-2" />
        <span>{copied ? 'Copied!' : 'Share Your Color Analysis'}</span>
      </button>
    );
  };

  const handleShare = () => {
    if (playbook && playbook.public_id) {
      const shareUrl = `https://palettehunt.com/shared/${playbook.public_id}`;
      if (isNativeMobile) {
        // For mobile, we'll use the Capacitor Share API
        Share.share({
          title: 'My Color Analysis',
          text: 'Check out my color analysis!',
          url: shareUrl,
        }).then(() => {
          console.log('Shared successfully');
        }).catch((error) => {
          console.error('Sharing failed', error);
        });
      } else {
        // For desktop, we'll use the clipboard
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }
    }
  };

  const slides = useMemo(() => {
    if (!playbook) return [];
    return [
      { type: 'intro', title: 'Your Color Analysis Results ✨', content: playbook.style_analysis.introduction },
      ...playbook.style_analysis.color_analysis.flatMap(section => [
        { type: 'description', ...section },
        ...(section.pics && typeof section.pics === 'object' 
          ? Object.entries(section.pics).map(([index, image], i) => ({
              type: 'single-image',
              title: section.shades,
              image: image,
              color: section.colors_and_codes[i],
              colors_and_codes: section.colors_and_codes
            }))
          : []
        )
      ]),
      ...(playbook.style_analysis.silver_or_gold ? [
        { 
          type: 'jewelry', 
          title: 'Silver or Gold?', 
          description: playbook.style_analysis.silver_or_gold.explanation,
          jewel_color: playbook.style_analysis.silver_or_gold.jewel_color
        },
        ...(playbook.style_analysis.silver_or_gold.pics && typeof playbook.style_analysis.silver_or_gold.pics === 'object'
          ? Object.entries(playbook.style_analysis.silver_or_gold.pics).map(([index, image]) => ({
              type: 'single-image',
              title: 'Silver or Gold?',
              image: image,
              jewel_color: playbook.style_analysis.silver_or_gold.jewel_color
            }))
          : []
        )
      ] : []),
      { type: 'final', title: 'Explore More' }
    ];
  }, [playbook]);

  // console.log('Created slides:', slides);

  useEffect(() => {
    if (isNativeMobile && playbook && !isTouching) {
      resetTimer();
    }
    return () => clearInterval(timerRef.current);
  }, [currentSlide, isNativeMobile, playbook, isTouching]);

  const resetTimer = () => {
    setProgress(0);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(timerRef.current);
          if (currentSlide < slides.length) {
            setCurrentSlide(prev => prev + 1);
          }
          return 0;
        }
        return oldProgress + 1;
      });
    }, 100);
  };


  const getGradientStyle = (colors) => {
    if (!colors || colors.length < 3) return {};
    const gradient = `linear-gradient(to right, ${colors[0].color_code}, ${colors[2].color_code})`;
    return {
      backgroundImage: gradient,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      display: 'inline-block', // This ensures the gradient applies correctly
    };
  };

  const handleReviewRequest = async () => {
    if (Capacitor.isNativePlatform()) {
      const { isAvailable } = await InAppReview.isAvailable();
      if (isAvailable) {
        await InAppReview.requestReview();
      }
    }
  };

  const getSeasonEmoji = (title) => {
    if (!title || typeof title !== 'string') return '';
    const lowerTitle = title.toLowerCase();
    if (lowerTitle.includes('spring')) return '🌸';
    if (lowerTitle.includes('summer')) return '🌴';
    if (lowerTitle.includes('autumn')) return '🍂';
    if (lowerTitle.includes('winter')) return '❄️';
    return '';
  };

  const renderMobileSlides = () => {
    if (!playbook) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-pink-50">
          <img src="/logo.png" alt="Loading" className="w-24 h-24 animate-pulse" />
        </div>
      );
    }

    const introContent = playbook.style_analysis.introduction;
    const sentences = introContent.split('. ').filter(sentence => sentence.trim() !== '');
    const midpoint = Math.ceil(sentences.length / 2);

    const allSlides = [
      { 
        type: 'intro', 
        title: 'Your Color Analysis Results ✨', 
        content: sentences.slice(0, midpoint).join('. ') + '.',
        footer: <div className="text-3xl">→</div> // Right arrow
      },
      { 
        type: 'intro', 
        title: 'Your Color Analysis Results ✨', 
        content: sentences.slice(midpoint).join('. '),
        footer: <p className="text-center mt-4"></p>
      },
      ...playbook.style_analysis.color_analysis.flatMap(section => [
        { type: 'description', ...section },
        ...(section.pics && typeof section.pics === 'object' 
          ? Object.entries(section.pics).map(([index, image], i) => ({
              type: 'single-image',
              title: section.shades,
              image: image,
              color: section.colors_and_codes[i],
              colors_and_codes: section.colors_and_codes
            }))
          : []
        )
      ]),
      ...(playbook.style_analysis.silver_or_gold ? [
        { 
          type: 'jewelry', 
          title: 'Silver or Gold?', 
          description: playbook.style_analysis.silver_or_gold.explanation,
          jewel_color: playbook.style_analysis.silver_or_gold.jewel_color
        },
        ...(playbook.style_analysis.silver_or_gold.pics && typeof playbook.style_analysis.silver_or_gold.pics === 'object'
          ? Object.entries(playbook.style_analysis.silver_or_gold.pics).map(([index, image]) => ({
              type: 'single-image',
              title: 'Silver or Gold?',
              image: image,
              jewel_color: playbook.style_analysis.silver_or_gold.jewel_color
            }))
          : []
        )
      ] : []),
      { type: 'final', title: 'Explore More' }
    ];

    const handleSwipe = (event, info) => {
      let newSlide = currentSlide;
      if (info.offset.x < -50 && currentSlide < allSlides.length - 1) {
        newSlide = currentSlide + 1;
      } else if (info.offset.x > 50 && currentSlide > 0) {
        newSlide = currentSlide - 1;
      }

      if (newSlide !== currentSlide) {
        setCurrentSlide(newSlide);
        resetTimer(); // Reset the timer whenever the slide changes
      }
    };

    const renderSlide = (slide) => {
      const gradientStyle = getGradientStyle(slide.colors_and_codes);
      const seasonEmoji = getSeasonEmoji(slide.title);
      
      const getJewelryTitleStyle = (jewelColor) => {
        return {
          color: jewelColor.toLowerCase() === 'gold' ? '#FFD700' : '#C0C0C0'
        };
      };

      switch (slide.type) {
        case 'intro':
          return (
            <div className="flex flex-col h-full items-center">
              <img src="/logo.png" alt="Logo" className="w-12 h-12 mb-4" />
              <h2 className="text-3xl font-semibold mb-6 text-center">
                {slide.title} {seasonEmoji}
              </h2>
              <p className="text-lg text-gray-700 mb-4 text-left w-full">
                {slide.content.split('. ').map((sentence, index) => (
                  <React.Fragment key={index}>
                    <strong>{sentence}{index !== slide.content.split('. ').length - 1 ? '.' : ''}</strong>
                    <br /><br />
                  </React.Fragment>
                ))}
              </p>
              {slide.footer && (
                <div className="mt-auto">{slide.footer}</div>
              )}
            </div>
          );
        case 'description':
          return (
            <div className="flex flex-col h-full items-center">
              <img src="/logo.png" alt="Logo" className="w-12 h-12 mb-4" />
              <h2 className="text-3xl font-semibold mb-6 text-center" style={gradientStyle}>{slide.shades}</h2>
              <p className="text-lg text-gray-700 mb-6">
                {slide.explanation.split('. ').map((sentence, index) => (
                  <React.Fragment key={index}>
                    <strong>{sentence}{index !== slide.explanation.split('. ').length - 1 ? '.' : ''}</strong>
                    <br /><br />
                  </React.Fragment>
                ))}
              </p>
              {/* <div className="flex justify-between mt-4 w-full max-w-xs mx-auto">
                {Array.isArray(slide.colors_and_codes) && slide.colors_and_codes.slice(0, 3).map((colorObj, index) => (
                  <div key={index} className={`flex-1 text-center`}>
                    <ColorBoxMobile color={colorObj.color_code} name={colorObj.color_name} />
                  </div>
                ))}
              </div> */}
            </div>
          );
        case 'images':
        case 'jewelry-images':
          return (
            <div className="flex flex-col h-full items-center">
              <img src="/logo.png" alt="Logo" className="w-12 h-12 mb-4" />
              <h2 className="text-3xl font-semibold mb-6 text-center" style={gradientStyle}>{slide.title}</h2>
              {slide.images && typeof slide.images === 'object' ? (
                <div className="flex flex-col gap-4 overflow-y-auto">
                  {Object.values(slide.images).map((image, i) => (
                    <img key={i} src={image} alt={`${slide.title} ${i + 1}`} className="w-full h-64 object-cover rounded-lg" />
                  ))}
                </div>
              ) : (
                <p className="text-xl text-gray-700">No images available</p>
              )}
            </div>
          );
        case 'jewelry':
          return (
            <div className="flex flex-col h-full items-center">
              <img src="/logo.png" alt="Logo" className="w-12 h-12 mb-4" />
              <h2 className="text-3xl font-semibold mb-6 text-center" style={getJewelryTitleStyle(slide.jewel_color)}>
                {slide.title}
              </h2>
              <p className="text-lg text-gray-700">
                {slide.description.split('. ').map((sentence, index) => (
                  <React.Fragment key={index}>
                    <strong>{sentence}{index !== slide.description.split('. ').length - 1 ? '.' : ''}</strong>
                    <br /><br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          );
        case 'final':
          return (
            <div className="flex flex-col items-center justify-center h-full">
              <div className="space-y-32 flex flex-col items-center justify-center flex-grow">
                <div className="flex flex-col items-center">
                  <p className="text-lg mb-4 font-bold">Share your color analysis</p>
                  <button onClick={handleShare} className="bg-blue-500 text-white px-8 py-4 rounded-full flex items-center text-xl w-64">
                    <FaShare className="mr-3" /> <span className="font-bold">Share Results</span>
                  </button>
                </div>

                <div className="flex flex-col items-center">
                  <p className="text-lg mb-4 font-bold">Share some love ❤️</p>
                  <button onClick={handleReviewRequest} className="bg-yellow-500 text-white px-8 py-4 rounded-full flex items-center text-xl w-64">
                    <FaStar className="mr-3" /> <span className="font-bold">Leave a Review</span>
                  </button>
                </div>

                <div className="flex flex-col items-center">
                  <p className="text-lg mb-4 font-bold">Try on new styles and colors</p>
                  <button onClick={() => navigate('/playground')} className="bg-green-500 text-white px-8 py-4 rounded-full flex items-center text-xl w-64">
                    <span className="mr-3" role="img" aria-label="canvas">🎨</span> <span className="font-bold">Playground</span>
                  </button>
                </div>
              </div>
            </div>
          );
        case 'single-image':
          return (
            <div className="flex flex-col h-full items-center">
              <h2 className="text-3xl font-semibold mb-6 text-center">
                <span>{slide.title}</span> <span className="text-black">{seasonEmoji}</span>
              </h2>
              <img src={slide.image} alt={slide.title} className="w-full h-auto object-cover rounded-lg mb-4" />
              {slide.colors_and_codes && (
                <div className="flex justify-between items-start mt-2 w-full max-w-xs mx-auto">
                  {slide.colors_and_codes.slice(0, 3).map((colorObj, index) => (
                    <div key={index} className="flex flex-col items-center w-1/3">
                      <div 
                        className="w-14 h-14 rounded-md mb-2" 
                        style={{ backgroundColor: colorObj.color_code }}
                      ></div>
                      <div className="w-full text-center">
                        <span className="font-bold text-center break-words">{colorObj.color_name}</span>
                        <span className="text-sm block">{colorObj.color_code}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div className="bg-pink-50 min-h-screen flex flex-col"
           onTouchStart={() => setIsTouching(true)}
           onTouchEnd={() => {
             setIsTouching(false);
             resetTimer();
           }}>
        <div className="flex justify-center space-x-2 p-4 mb-4 mt-8">
          {allSlides.map((_, index) => (
            <div key={index} className="h-1 w-full bg-gray-300 rounded-full overflow-hidden">
              <motion.div
                className="h-full bg-gray-600"
                initial={{ width: "0%" }}
                animate={{ width: index === currentSlide ? `${progress}%` : index < currentSlide ? "100%" : "0%" }}
              />
            </div>
          ))}
        </div>
        <motion.div
          className="flex-grow p-6 flex flex-col"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={handleSwipe}
        >
          {renderSlide(allSlides[currentSlide])}
        </motion.div>
      </div>
    );
  };

  useEffect(() => {
    fetchColorAnalysis();
  }, []);

  const fetchColorAnalysis = async () => {
    // ... (existing fetchColorAnalysis logic)
    setIsLoading(false);
  };

  if (isNativeMobile) {
    return renderMobileSlides();
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#ffffff" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">{error}</h1>
      </div>
    );
  }

  if (generationStatus === 'generating') {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Generating your color analysis... This may take a few minutes.</h1>
      </div>
    );
  }

  if (!playbook || !playbook.style_analysis) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Error: Playbook data is missing or invalid.</h1>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={`bg-white text-black min-h-screen ${isNativeMobile ? 'pt-16 pb-32' : ''}`}
    >
      <div>
        {!isNativeMobile && (
          <header
            className="w-full p-6 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg"
            style={{
              boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${Math.min(
                scrollY / 1000,
                0.1
              )})`,
            }}
          >
            <div className="max-w-7xl mx-auto flex justify-center items-center">
              <Link to="/" className="flex items-center">
                <img
                  src="/logo.png"
                  alt="Palette Hunt Logo"
                  className="h-16 w-auto"
                />
              </Link>
            </div>
          </header>
        )}
        <main className={`p-8 ${isNativeMobile ? 'mt-8 mb-16' : ''}`}>
          <div className="max-w-7xl mx-auto">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-6xl font-bold mb-8 text-center text-black"
            >
              {isPublicView ? "Color Analysis Results" : "Your Color Analysis Results"}
            </motion.h1>

            {/* Add the introduction section */}
            {playbook && playbook.style_analysis && playbook.style_analysis.introduction && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="text-xl text-gray-700 text-center mb-18 max-w-3xl mx-auto"
                >
                  {playbook.style_analysis.introduction.split('. ').map((sentence, index, array) => (
                    <p key={index} className="mb-4">
                      {sentence.trim() + (index < array.length - 1 ? '.' : '')}
                    </p>
                  ))}
                </motion.div>
                <Separator />
              </>
            )}

            {/* Check if any section has pics */}
            {(() => {
              const hasPics = playbook.style_analysis.color_analysis.some(section => 
                section.pics && typeof section.pics === 'object' && Object.keys(section.pics).length > 0
              );

              if (!hasPics) {
                // No pics layout - centered, text-focused
                return (
                  <div className="mt-12">
                    {playbook.style_analysis.color_analysis.map((section, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                        className="mb-16 text-center max-w-3xl mx-auto"
                      >
                        <h3 className="text-4xl font-bold mb-6 text-black">
                          {section.shades}
                        </h3>
                        <div className="text-xl text-gray-700 mb-8">
                          {section.explanation.split('. ').map((sentence, idx, array) => (
                            <p key={idx} className="mb-4">
                              {sentence.trim() + (idx < array.length - 1 ? '.' : '')}
                            </p>
                          ))}
                        </div>
                        <div className="flex justify-center gap-8 mb-12">
                          {section.colors_and_codes.map((color, colorIndex) => (
                            <div key={colorIndex} className="flex flex-col items-center">
                              <div
                                className="w-20 h-20 rounded-lg shadow-lg mb-3"
                                style={{ backgroundColor: color.color_code }}
                              />
                              <span className="font-medium text-gray-700">{color.color_name}</span>
                              <span className="text-sm text-gray-500">{color.color_code}</span>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    ))}

                    {playbook.style_analysis.silver_or_gold && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="mt-16 text-center max-w-3xl mx-auto"
                      >
                        <h3 className="text-4xl font-bold mb-6 text-black">
                          Silver or Gold?
                        </h3>
                        <div className="text-xl text-gray-700">
                          {playbook.style_analysis.silver_or_gold.explanation.split('. ').map((sentence, idx, array) => (
                            <p key={idx} className="mb-4">
                              {sentence.trim() + (idx < array.length - 1 ? '.' : '')}
                            </p>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </div>
                );
              }

              // Original layout with pics
              return (
                <div className="mt-24">
                  {Array.isArray(playbook.style_analysis.color_analysis) && playbook.style_analysis.color_analysis.map((section, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && <div className="mb-16" />}
                      <ColorSection
                        title={section.shades}
                        description={section.explanation}
                        colors={section.colors_and_codes}
                        pics={section.pics}
                        isReversed={index % 2 !== 0}
                        isMobile={isMobile}
                      />
                      {index < playbook.style_analysis.color_analysis.length - 1 && (
                        <>
                          <div className="mt-4" />
                          <Separator />
                          <div className="mb-24" />
                        </>
                      )}
                    </React.Fragment>
                  ))}

                  {playbook.style_analysis.silver_or_gold && (
                    <>
                      <div className="mt-8" />
                      <Separator />
                      <div className="mb-16" />
                      <motion.h2
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-5xl font-bold mb-6 text-center"
                        style={{
                          background: 'linear-gradient(to right, #C0C0C0, #FFD700)',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          backgroundClip: 'text',
                          textFillColor: 'transparent'
                        }}
                      >
                        Silver or Gold?
                      </motion.h2>
                      <JewelrySection
                        jewelColor={playbook.style_analysis.silver_or_gold.jewel_color}
                        explanation={playbook.style_analysis.silver_or_gold.explanation}
                        pics={playbook.style_analysis.silver_or_gold.pics}
                        isMobile={isMobile}
                      />
                    </>
                  )}
                </div>
              );
            })()}

            {/* Only show sharing and exploration sections if there are pics */}
            {playbook.style_analysis.color_analysis.some(section => 
              section.pics && typeof section.pics === 'object' && Object.keys(section.pics).length > 0
            ) && (
              <>
                <div className="mt-24 text-center">
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-4xl font-bold mb-8 text-black"
                  >
                    Want to share your color analysis with friends?
                  </motion.h2>
                  <ShareButton />
                </div>

                <div className="mt-24 text-center">
                  <motion.h2
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="text-4xl font-bold mb-8 text-black"
                  >
                    Ready to explore your new style?
                  </motion.h2>
                  <Link
                    to="/playground"
                    className="inline-flex items-center bg-primary text-white font-semibold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                  >
                    <FaPlay className="mr-2" /> Try the Color Playground
                  </Link>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
      {!isNativeMobile && <Footer />}
    </motion.div>
  );
};

export { ColorSection, JewelrySection, ImageWithDownload };

export default ColorAnalysisTest;