export const locations = [
  {
    "name": "United States",
    "slug": "united-states",
    "type": "country",
    "pricingRange": "$100 - $500",
    "businesses": [],
    "description": "The United States offers a wide range of color analysis services, from high-end fashion consultants to more affordable options. The diverse culture and fashion-forward cities make it a popular choice for color analysis."
  },
  {
    "name": "Canada",
    "slug": "canada",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Canada's color analysis services reflect its diverse culture and climate. Analysts often specialize in palettes suitable for both urban and natural settings."
  },
  {
    "name": "United Kingdom",
    "slug": "united-kingdom",
    "type": "country",
    "pricingRange": "$150 - $450",
    "businesses": [],
    "description": "The United Kingdom offers a variety of color analysis services, from traditional methods to modern techniques. The country's rich history and fashion scene make it a unique destination for color analysis."
  },
  {
    "name": "France",
    "slug": "france",
    "type": "country",
    "pricingRange": "$200 - $500",
    "businesses": [],
    "description": "France is known for its high-end fashion and sophisticated color analysis services. Analysts often focus on chic and elegant palettes that reflect the country's style."
  },
  {
    "name": "Germany",
    "slug": "germany",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Germany's color analysis services are known for their precision and attention to detail. Analysts often specialize in practical and versatile palettes suitable for various occasions."
  },
  {
    "name": "Italy",
    "slug": "italy",
    "type": "country",
    "pricingRange": "$150 - $450",
    "businesses": [],
    "description": "Italy offers a range of color analysis services that reflect its rich cultural heritage and fashion-forward cities. Analysts often focus on vibrant and stylish palettes."
  },
  {
    "name": "Spain",
    "slug": "spain",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Spain's color analysis services are influenced by its sunny climate and vibrant culture. Analysts often specialize in bright and bold palettes suitable for the lively fashion scene."
  },
  {
    "name": "Netherlands",
    "slug": "netherlands",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Color identity",
        "description": "Consultant",
        "address": "Baan 74, Rotterdam",
        "phone": "+31 6 53311278",
        "website": "http://coloridentity.nl/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20identity&query_place_id=ChIJddRMTkAzxEcRl6ZHm4NfXF4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipODRmC1Vt3zpMY_lKauWHs5sEMdjOjveZi5LwQf=w614-h240-k-no"
      },
      {
        "name": "Studio True Colours",
        "description": "Beautician. Rating: 4.9/5 from 12 reviews",
        "address": "Van Veldekestraat 14, Nuenen",
        "phone": "+31 6 15436421",
        "website": "http://www.studiotruecolours.nl/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20True%20Colours&query_place_id=ChIJcf8obEzZxkcRF3kmP1X3TiA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=fT1g5iE1m-yG7Y93CQjLiQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=313.40488&pitch=0&thumbfov=100"
      },
      {
        "name": "Armochromeow | Image Consultant and Personal Stylist specialised in Colour Analysis, Yin and Yang and Fragrances",
        "description": "Image consultant. Rating: 5/5 from 19 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.armochromeow.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Armochromeow%20%7C%20Image%20Consultant%20and%20Personal%20Stylist%20specialised%20in%20Colour%20Analysis%2C%20Yin%20and%20Yang%20and%20Fragrances&query_place_id=ChIJmaSBq_al-WYRXUOGlb77DVE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOezCc3OuKkZM2D6Qr5ZRVTUNcilo9ARPwkE6mw=w408-h650-k-no"
      },
      {
        "name": "KLEURKARAKTER - kleuranalyse en online minerale make-up",
        "description": "Consultant. Rating: 5/5 from 4 reviews",
        "address": "Aragonstraat 11, Alkmaar",
        "phone": "+31 6 53657088",
        "website": "http://www.kleurkarakter.nl/",
        "url": "https://www.google.com/maps/search/?api=1&query=KLEURKARAKTER%20-%20kleuranalyse%20en%20online%20minerale%20make-up&query_place_id=ChIJyenVADJOz0cRRDnx25vLNcc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjhSfwEViYuYuYH1TX8thKWaKtl418oox3NGw6=w408-h352-k-no"
      },
      {
        "name": "ColourBusiness",
        "description": "Image consultant. Rating: 4.9/5 from 7 reviews",
        "address": "Peppelkade 48, Houten",
        "phone": "+31 6 12591727",
        "website": "https://colourbusiness.nl/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourBusiness&query_place_id=ChIJA6tQMF9mxkcRNtFFidadT3A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPEujTzRvEeh0ycqzoClS2oYgCfxPHagZXSRUc5=w408-h306-k-no"
      }
    ],
    "description": "The Netherlands offers color analysis services that reflect its modern and innovative approach to fashion. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Sweden",
    "slug": "sweden",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "The Colour Insight",
        "description": "Stylist",
        "address": "Halmstadsvägen 53, Johanneshov",
        "phone": "+46 76 627 72 77",
        "website": "http://www.thecolourinsight.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Colour%20Insight&query_place_id=ChIJteXM3mkBVI4RogOBiKbJO6A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOj4-E5nakZbGb0IIbUPHtVNqfLEMqOk2nj5yXE=w408-h291-k-no"
      },
      {
        "name": "Colour and the Shape",
        "description": "Hairdresser. Rating: 5/5 from 7 reviews",
        "address": "Höganäsgatan 4A, Uppsala",
        "phone": "+46 18 10 01 21",
        "website": "http://colourandtheshape.se/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20and%20the%20Shape&query_place_id=ChIJFY890AnMX0YROyx3aTBSltc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPfdj8WcX_8gQgVckPjIFilGsYQ8h68W8ZWK2m4=w426-h240-k-no"
      },
      {
        "name": "aMELIa uRSACHe Fashion & Style",
        "description": "Fashion designer. Rating: 4/5 from 2 reviews",
        "address": "Bäckaskiftsvägen 33, Enskede",
        "phone": "+46 73 682 46 77",
        "website": "http://www.ameliaursache.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=aMELIa%20uRSACHe%20Fashion%20%26%20Style&query_place_id=ChIJO16MSF2dX0YR1S-5e0CHc5o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOcQmw6uu67qVwMbMJ-EKYlj0PCTmFfzc0ACPqP=w408-h612-k-no"
      },
      {
        "name": "Make Up Institute Stockholm",
        "description": "Make-up artist. Rating: 4.4/5 from 79 reviews",
        "address": "Sergels Torg 11, Stockholm",
        "phone": "+46 8 30 06 40",
        "website": "http://www.makeupinstitute.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Make%20Up%20Institute%20Stockholm&query_place_id=ChIJp-hwz3GdX0YR-odWRtTRKHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPF7ZmeNusrY5FV7i-3emJM7mpdCg5zqPw2epEf=w408-h654-k-no"
      },
      {
        "name": "Colour & Harmony",
        "description": "Beauty Salon. Rating: 5/5 from 8 reviews",
        "address": "Kneippgatan 34, Norrköping",
        "phone": "+46 72 212 05 00",
        "website": "http://www.colourandharmony.se/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20%26%20Harmony&query_place_id=ChIJj--__dE7WUYRUm9u5h87k7o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN0b8Fl0bReXoFP3iFw8bWZRkSYxH1qc-rHtdgN=w408-h408-k-no"
      }
    ],
    "description": "Sweden's color analysis services are known for their minimalist and functional approach. Analysts often specialize in neutral and understated palettes."
  },
  {
    "name": "Norway",
    "slug": "norway",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colour Me Beautiful",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "Henerala Almazova St, 18/7, Kyiv",
        "phone": "+380 67 645 1945",
        "website": "http://colourmebeautiful.com.ua/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful&query_place_id=ChIJ9R-_NKjP1EARwjLlxQ6qwnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPapXvVN5JZXUDY7wU6zUznK9I4buXRyyd9Hggp=w408-h271-k-no"
      },
      {
        "name": "Image Stylist, Sirkus Shopping",
        "description": "Stylist. Rating: 5/5 from 104 reviews",
        "address": "3 etasje gjennom storstua/kantinen, Falkenborgvegen 9, Trondheim",
        "phone": "+47 48 26 24 30",
        "website": "https://imagestylisten.onlinebooq.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=Image%20Stylist%2C%20Sirkus%20Shopping&query_place_id=ChIJdRglI8UxbUYRioKd_gWK2nI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPzBhc4tZn6ruv2Y1k1xhwtLskykvEQN3pwsj1r=w408-h299-k-no"
      },
      {
        "name": "Colors In Style By Kari",
        "description": "Stylist. Rating: 5/5 from 12 reviews",
        "address": "Nedre Stiaberget 53B, Godvik",
        "phone": "+47 90 91 69 07",
        "website": "https://colorsinstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20In%20Style%20By%20Kari&query_place_id=ChIJxQ3LJ8z7PEYR47K6-VzfD0w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMKJcX0hITgRjnyxR4LqVQfLw-TErD1cIg9PHjA=w1602-h240-k-no"
      },
      {
        "name": "Fargeporten Personlig Fargeanalyse",
        "description": "Image consultant",
        "address": "Ilderfaret, Kråko, Fitjar",
        "phone": "+47 41 27 03 21",
        "website": "http://fargeporten.no/",
        "url": "https://www.google.com/maps/search/?api=1&query=Fargeporten%20Personlig%20Fargeanalyse&query_place_id=ChIJP3sJ2ayHPEYRhv_7uNTSFjk",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=asHcM-N742c24Zm_gZTChA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=260.68573&pitch=0&thumbfov=100"
      }
    ],
    "description": "Norway offers color analysis services that reflect its natural beauty and serene landscapes. Analysts often focus on palettes inspired by nature."
  },
  {
    "name": "Denmark",
    "slug": "denmark",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "My Best Colours by Anette Henriksen",
        "description": "Image consultant",
        "address": "Bøgely 29, Bramming",
        "phone": "+45 29 39 31 16",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Best%20Colours%20by%20Anette%20Henriksen&query_place_id=ChIJ2T1fPGU9S0YRFVrRINGIkpU",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=3KgXyjumsiKYdGaTjgHzeA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=139.43408&pitch=0&thumbfov=100"
      },
      {
        "name": "yourcolors.byjoana",
        "description": "Image consultant. Rating: 4.9/5 from 54 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.instagram.com/yourcolors.byjoana?igsh=MXV1MHpvcjhna2p2bw==&utm_source=qr",
        "url": "https://www.google.com/maps/search/?api=1&query=yourcolors.byjoana&query_place_id=ChIJGbTIpSxH_4ERLZOMASYVyV8",
        "imageUrl": ""
      },
      {
        "name": "Colours By Cph ApS",
        "description": "undefined. Rating: 5/5 from 1 reviews",
        "address": "Falkoner Alle 1, 3. sal, Frederiksberg",
        "phone": "",
        "website": "http://www.coloursbycph.dk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20By%20Cph%20ApS&query_place_id=ChIJAyvZbaBTUkYRwmgvwfNr4Cg",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=rpMSn3uaLq9ywYrQEokKXQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=289.9915&pitch=0&thumbfov=100"
      }
    ],
    "description": "Denmark's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  {
    "name": "Finland",
    "slug": "finland",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Shania Kauneushoitola",
        "description": "Beauty Salon. Rating: 5/5 from 2 reviews",
        "address": "Sirkkalankatu 29, Turku",
        "phone": "+358 2 4316155",
        "website": "http://www.shania.fi/",
        "url": "https://www.google.com/maps/search/?api=1&query=Shania%20Kauneushoitola&query_place_id=ChIJA_bGION2jEYR6ZwqYqa3tlQ",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=ZYY8fKL8hHf4NYWj_YcTdw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=339.21033&pitch=0&thumbfov=100"
      }
    ],
    "description": "Finland offers color analysis services that reflect its unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  {
    "name": "Switzerland",
    "slug": "switzerland",
    "type": "country",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Personal Color Analysis by Smart Color",
        "description": "Image consultant. Rating: 5/5 from 14 reviews",
        "address": "Badenerstrasse 97, Zürich",
        "phone": "",
        "website": "https://www.smartcolor.ch/",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Color%20Analysis%20by%20Smart%20Color&query_place_id=ChIJecmYU7ILkEcR4kRa0ICiaeU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN-QTmpyqwLW2UB6PPuDun4x4_BQmjTb_jO0Lqd=w408-h423-k-no"
      },
      {
        "name": "Charlotte @ Chic Couleurs - International Personal Stylist & Colour Consultant",
        "description": "Image consultant. Rating: 5/5 from 42 reviews",
        "address": "Avenue Du Cimetière, 10 PETIT-LANCY, GENEVA",
        "phone": "+41 79 273 95 46",
        "website": "http://www.chiccouleurs.ch/",
        "url": "https://www.google.com/maps/search/?api=1&query=Charlotte%20%40%20Chic%20Couleurs%20-%20International%20Personal%20Stylist%20%26%20Colour%20Consultant&query_place_id=ChIJlbBktoB7jEcRnCXvQT9Ut3g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNHiQQqA-z3aDulhLnuZvbFni4gn7i_NY982IL6=w408-h306-k-no"
      },
      {
        "name": "Navyblue Image- & Stilberatung in Bern",
        "description": "Image Consultant. Rating: 5/5 from 38 reviews",
        "address": "Bondelistrasse 4, Wabern bei Bern",
        "phone": "+41 77 431 54 38",
        "website": "https://navy-blue.ch/",
        "url": "https://www.google.com/maps/search/?api=1&query=Navyblue%20Image-%20%26%20Stilberatung%20in%20Bern&query_place_id=ChIJN9Xrz905jkcReLqumRfh75s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOYSekfnRC52sZxAHT44FKowCIs0AtjXc-0z5qs=w426-h240-k-no"
      },
      {
        "name": "Individuel Cosmetique",
        "description": "Cosmetics store. Rating: 4.3/5 from 48 reviews",
        "address": "Rue de la Fontaine 43, Geneva",
        "phone": "+41 22 810 83 67",
        "website": "https://individuelcosmetics.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Individuel%20Cosmetique&query_place_id=ChIJxeFOHSVljEcRkWErFXRxLzU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP4NfTMjMU81krprYz0MTvU0Oy1xZt4iKCQU7_X=w408-h306-k-no"
      },
      {
        "name": "Chromatologie",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "Kasernenstrasse 77A, Zürich",
        "phone": "+41 79 886 83 00",
        "website": "http://www.chromatologie.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=1719969468228396437",
        "url": "https://www.google.com/maps/search/?api=1&query=Chromatologie&query_place_id=ChIJ_-Cs9WxljEcRNV_qBKJOAVg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOS9UPQbwWc6gpX-uYtlOmOfukD5gBy5NyhCNW7=w408-h725-k-no"
      }
    ],
    "description": "Switzerland's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  {
    "name": "Austria",
    "slug": "austria",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colorline Consulting",
        "description": "Consultant",
        "address": "Moosstraße 72, Salzburg",
        "phone": "+43 664 3076000",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorline%20Consulting&query_place_id=ChIJmQJB7YiRdkcRt-AxPj9M2wA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=s7CXTLhqa4m2Cl7ztVO00w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=294.38434&pitch=0&thumbfov=100"
      },
      {
        "name": "Farb & Stil COACHING Alexandra Diregger",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "Burgholzerweg 16, Sankt Ulrich bei Steyr",
        "phone": "+43 681 81316493",
        "website": "http://www.farbundstilcoaching.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=Farb%20%26%20Stil%20COACHING%20Alexandra%20Diregger&query_place_id=ChIJCVxEdhLPc0cRed09RNPVYRY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMlO-6dE0tOTH5yw6vjQrfG5Z7SUMQlbq2FVx0h=w408-h612-k-no"
      },
      {
        "name": "Stilvoll bei Brigitte Zöhrer",
        "description": "Stylist. Rating: 5/5 from 6 reviews",
        "address": "Hauptpl. 50, Frohnleiten",
        "phone": "+43 676 5700992",
        "website": "https://www.stilvoll-brigitte.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=Stilvoll%20bei%20Brigitte%20Z%C3%B6hrer&query_place_id=ChIJBRBVJsglbkcRZwfHcz-ilm8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMoeE0m7slWTPXTnIBQ-9Kpbus9LVcJmbd3IlxH=w408-h271-k-no"
      },
      {
        "name": "byKristina",
        "description": "Consultant. Rating: 4.9/5 from 37 reviews",
        "address": "Estepl. 8/7, Vienna",
        "phone": "+43 676 3167209",
        "website": "http://www.bykristina.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=byKristina&query_place_id=ChIJk-6D5NcAbUcRJlL32PZacY0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM22IpwVW1s-PsxoyqrgBqvq_0MPBhFafRdsXbk=w648-h240-k-no"
      }
    ],
    "description": "Austria offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Belgium",
    "slug": "belgium",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "My Color Passport",
        "description": "Consultant. Rating: 4.7/5 from 3 reviews",
        "address": "Bouwensstraat 21, Antwerp",
        "phone": "+32 3 432 00 02",
        "website": "http://www.mycolorpassport.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Color%20Passport&query_place_id=ChIJIwL6sNPlw0cRogfzuomTWmU",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=V9rXqnS1VgiX2fmO0BjoWQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=149.4611&pitch=0&thumbfov=100"
      },
      {
        "name": "Truus Yperman - peaches and pink",
        "description": "Image consultant. Rating: 5/5 from 53 reviews",
        "address": "Rue Jean-Baptiste Colyns 82, Brussels",
        "phone": "+32 479 07 99 53",
        "website": "https://www.peachesandpinkbrussels.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Truus%20Yperman%20-%20peaches%20and%20pink&query_place_id=ChIJTUkJeK7Fw0cRSi6b01sPdO0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipML_k6YPmR3Lr6uBaC-SMgL6m5f7YgjATBxXASA=w646-h240-k-no"
      },
      {
        "name": "Couleur Couleur",
        "description": "Hairdresser. Rating: 4.4/5 from 57 reviews",
        "address": "Pl. du Châtelain 4, Ixelles",
        "phone": "+32 2 649 01 44",
        "website": "http://www.couleurcouleur.be/",
        "url": "https://www.google.com/maps/search/?api=1&query=Couleur%20Couleur&query_place_id=ChIJU53emPTEw0cRdNjl0y2zuKk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNieEw1gfXG1fVFNBmHj-pIZnGJmWmB1TStgtqZ=w408-h544-k-no"
      },
      {
        "name": "Your Colors by Carole Mawet",
        "description": "Wellness program. Rating: 4.8/5 from 8 reviews",
        "address": "Rue du Ctre 117 boîte 2, Yvoir",
        "phone": "+32 497 22 44 45",
        "website": "https://yourcolors.be/",
        "url": "https://www.google.com/maps/search/?api=1&query=Your%20Colors%20by%20Carole%20Mawet&query_place_id=ChIJD-A1tguXwUcRGkdCXoI9BCc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO9Siuk0BQPkKWLcAvlmLKPpCDD2Cq7Ev3vvvHv=w408-h491-k-no"
      },
      {
        "name": "Harmony Color & Style",
        "description": "Image Consultant",
        "address": "Driehoekstraat 28, Wijnegem",
        "phone": "+32 485 61 33 41",
        "website": "http://www.harmonycolorandstyle.be/",
        "url": "https://www.google.com/maps/search/?api=1&query=Harmony%20Color%20%26%20Style&query_place_id=ChIJSwgt5Ff4w0cR9q0vAvqVPa0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHlHW1rRFHPMwsz3FoDJFhrFLyBp96PjhoA0-s=w408-h360-k-no"
      }
    ],
    "description": "Belgium's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Portugal",
    "slug": "portugal",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Raissa Tavares Consultoria de Imagem e Estilo",
        "description": "Image Consultant. Rating: 5/5 from 48 reviews",
        "address": "R. Sarmento Beires 30, Matosinhos",
        "phone": "+351 912 505 700",
        "website": "https://www.instagram.com/raissatavares.consultoria/",
        "url": "https://www.google.com/maps/search/?api=1&query=Raissa%20Tavares%20Consultoria%20de%20Imagem%20e%20Estilo&query_place_id=ChIJObZTqAlpJA0RMmeOLb_DrGg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMyeG2H8-4rWh81JICSpWvxyIf1iP2fOtB0Gc1l=w408-h272-k-no"
      },
      {
        "name": "Juliana Vieira Hairstyle & Cosmetologista",
        "description": "Beauty salon. Rating: 5/5 from 54 reviews",
        "address": "R. Serpa Pinto 39, Horta",
        "phone": "+351 935 810 759",
        "website": "https://julianavieirahairstylecosmetologista.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Juliana%20Vieira%20Hairstyle%20%26%20Cosmetologista&query_place_id=ChIJ8wGNz4KLRwsRAkbBiuFRIdk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOqiSuK7zk1yGEeoeC_mOfI63bcOJxQzj9jlEKK=w426-h240-k-no"
      }
    ],
    "description": "Portugal offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Greece",
    "slug": "greece",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Magda Kazoli | Certified Image Consultant & Personal Stylist",
        "description": "Image Consultant. Rating: 5/5 from 54 reviews",
        "address": "",
        "phone": "+30 694 502 6026",
        "website": "https://www.magdakazoli.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Magda%20Kazoli%20%7C%20Certified%20Image%20Consultant%20%26%20Personal%20Stylist&query_place_id=ChIJc84L4ii9oRQR45ULZmca890",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP0vUXp30Vd2h2MtHKPnqXeJy4AtikcnhEjHhpZ=w426-h240-k-no"
      },
      {
        "name": "Aristea Korkovelou Image Consultant • Aforcolour • Greece",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "28is Oktovriou 39, Thessaloniki",
        "phone": "+30 231 405 9501",
        "website": "https://aforcolour.gr/",
        "url": "https://www.google.com/maps/search/?api=1&query=Aristea%20Korkovelou%20Image%20Consultant%20%E2%80%A2%20Aforcolour%20%E2%80%A2%20Greece&query_place_id=ChIJTZSPCBY5qBQRnyJFxYkpQGE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNE6QfFDImMrx5fCICocpuc4UEKkqAoKbrchQcy=w408-h544-k-no"
      },
      {
        "name": "Style Art",
        "description": "Stylist. Rating: 5/5 from 22 reviews",
        "address": "Solonos 31Α, Chalandri",
        "phone": "+30 693 240 2000",
        "website": "https://www.styleart.gr/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Art&query_place_id=ChIJ00hf6smZoRQRkxbjRUR_Juc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=43aeXGNu9S8-kVuwVQZQtg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=26.265299&pitch=0&thumbfov=100"
      },
      {
        "name": "Athina Balasi My Colours Online Colour Analysis",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "+30 694 508 4382",
        "website": "http://www.mycolours.gr/",
        "url": "https://www.google.com/maps/search/?api=1&query=Athina%20Balasi%20My%20Colours%20Online%20Colour%20Analysis&query_place_id=ChIJV29o_38FfkwRY1LyUwxE2A8",
        "imageUrl": ""
      }
    ],
    "description": "Greece's color analysis services are influenced by its rich history and beautiful landscapes. Analysts often specialize in palettes inspired by the Mediterranean."
  },
  {
    "name": "Poland",
    "slug": "poland",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Milka Lewandowska Osobista Stylistka | MILKA STUDIO | Analiza kolorystyczna",
        "description": "Stylist. Rating: 5/5 from 54 reviews",
        "address": "Śniadeckich 4, Gdańsk",
        "phone": "+48 577 170 586",
        "website": "http://www.milkastudio.pl/",
        "url": "https://www.google.com/maps/search/?api=1&query=Milka%20Lewandowska%20Osobista%20Stylistka%20%7C%20MILKA%20STUDIO%20%7C%20Analiza%20kolorystyczna&query_place_id=ChIJ2SZMSeB1_UYREZEalynqle4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMaQKAQtoc302XddJ8zOZHrR9pLeMLiuguwZl9R=w408-h544-k-no"
      },
      {
        "name": "Lip Lab",
        "description": "Beauty salon. Rating: 4.9/5 from 170 reviews",
        "address": "Juliana Bartoszewicza 11, Warsaw",
        "phone": "+48 730 500 114",
        "website": "http://www.liplab.pl/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=11052125436972897277",
        "url": "https://www.google.com/maps/search/?api=1&query=Lip%20Lab&query_place_id=ChIJz_x0uL_NHkcRvfK6poOrekc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDR7XB41UTy9gwlf72JHC20pzTuMBbiLBbW0jl=w408-h261-k-no"
      },
      {
        "name": "COLOUR and STYLE",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "Rynek 7, Radom",
        "phone": "+48 508 142 387",
        "website": "http://colourandstyle.pl/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLOUR%20and%20STYLE&query_place_id=ChIJx1JJrzNZGEcRywBvzIsoICo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP5D6ENhcZ-llGPaiOMS5USnS2NOAZhqIGDbVgF=w408-h306-k-no"
      },
      {
        "name": "Style Coach Paulina Łotecka | Osobista stylistka",
        "description": "Image consultant. Rating: 5/5 from 62 reviews",
        "address": "",
        "phone": "+48 519 552 719",
        "website": "https://www.paulinalotecka.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Coach%20Paulina%20%C5%81otecka%20%7C%20Osobista%20stylistka&query_place_id=ChIJm9ZC4-9BggARlbeDinh2zkE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOLxiiGQCejikm5e-pd_VWVMjSljTlRVJ-BYYgx=w408-h544-k-no"
      },
      {
        "name": "KM STYLE KARINA MROZICKA Stylistka Wrocław",
        "description": "Stylist. Rating: 5/5 from 102 reviews",
        "address": "",
        "phone": "+48 609 845 076",
        "website": "https://kmstyle.pl/",
        "url": "https://www.google.com/maps/search/?api=1&query=KM%20STYLE%20KARINA%20MROZICKA%20Stylistka%20Wroc%C5%82aw&query_place_id=ChIJIaVeEmbFD0cRgDYNnoBAifs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNzW-15aW5vmf2D0Bds9JKgIKiT5nG4z3hulyJH=w408-h272-k-no"
      }
    ],
    "description": "Poland offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Czech Republic",
    "slug": "czech-republic",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "ColourMe barevná typologie",
        "description": "Stylist",
        "address": "Záhumenice 556/34, Brno-South-Horní Heršpice",
        "phone": "+420 774 511 565",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourMe%20barevn%C3%A1%20typologie&query_place_id=ChIJ3ZlBxFuVEkcR_sKRKKvlFVg",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=HBpcED-PjJsroV_DdBgM0A&cb_client=search.gws-prod.gps&w=408&h=240&yaw=161.16887&pitch=0&thumbfov=100"
      },
      {
        "name": "Redamakeup Artist",
        "description": "Beauty salon. Rating: 5/5 from 13 reviews",
        "address": "Beauty Coworking Time, Voctářova 18, Prague 8-Palmovka",
        "phone": "+420 732 710 422",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Redamakeup%20Artist&query_place_id=ChIJv1qBa1eVC0cR3wEkNXDZnDQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPEQMpXiTAq2Uf-ArN41JTvq4VPsyA821_aBS6t=w427-h240-k-no"
      }
    ],
    "description": "The Czech Republic's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Hungary",
    "slug": "hungary",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "ColourMyStyle",
        "description": "Stylist. Rating: 5/5 from 8 reviews",
        "address": "Márvány u. 33-2.em, Budapest",
        "phone": "+36 30 597 9988",
        "website": "http://www.colourmystyle.hu/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourMyStyle&query_place_id=ChIJExact0XcQUcRtQ1uWMCDvHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNIOWbocNGKKiOQrb06qGrbdSBkF9KKuabDcWl_=w408-h272-k-no"
      },
      {
        "name": "Agi Huszka Style",
        "description": "Stylist. Rating: 4.6/5 from 10 reviews",
        "address": "Honvéd tér 10, Budapest",
        "phone": "+36 30 650 6944",
        "website": "http://agihuszka.hu/",
        "url": "https://www.google.com/maps/search/?api=1&query=Agi%20Huszka%20Style&query_place_id=ChIJnxQntE7dQUcRk2oQwIFkqEg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMIkFmi4Oi55zwSsLyg8QQgTRBeYoaumlA9-ROs=w408-h272-k-no"
      },
      {
        "name": "True Colours Szín- és Stílustanácsadás",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "+36 30 947 5875",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=True%20Colours%20Sz%C3%ADn-%20%C3%A9s%20St%C3%ADlustan%C3%A1csad%C3%A1s&query_place_id=ChIJf8VqZVjdQUcROSGPKr4un3s",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=LXh4dwB1_OWxQtb4AHKvwg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=164.8432&pitch=0&thumbfov=100"
      }
    ],
    "description": "Hungary offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Russia",
    "slug": "russia",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Russia's color analysis services are known for their bold and dramatic approach. Analysts often specialize in vibrant and striking palettes."
  },
  {
    "name": "Mexico",
    "slug": "mexico",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "BEYOUTIFUL BY SG",
        "description": "Image consultant",
        "address": "P.º del Pedregal 1051-5, Tijuana, Baja California",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=BEYOUTIFUL%20BY%20SG&query_place_id=ChIJSad64qdL2YARLYpYjDUfJ8s",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=WtzvPCr32hMLq4KGu4rqkQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=218.85806&pitch=0&thumbfov=100"
      }
    ],
    "description": "Mexico offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Brazil",
    "slug": "brazil",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Carina Almeida - Consultora de Cores",
        "description": "Beautician. Rating: 5/5 from 92 reviews",
        "address": "R. Carmem Miranda, 140, Poços de Caldas, State of Minas Gerais",
        "phone": "+55 35 99132-6354",
        "website": "http://www.carinaalmeida.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Carina%20Almeida%20-%20Consultora%20de%20Cores&query_place_id=ChIJZzVUR33DtpQRV1i7cow9IEU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO7H5R87U6sfr1WS6H89L5fKlQm2zEdfC4uyuaM=w408-h612-k-no"
      },
      {
        "name": "Ray Andrade • Coloração Pessoal Brasília",
        "description": "Image consultant. Rating: 5/5 from 44 reviews",
        "address": "",
        "phone": "+55 61 98128-7371",
        "website": "http://linktr.ee/sourayandrade",
        "url": "https://www.google.com/maps/search/?api=1&query=Ray%20Andrade%20%E2%80%A2%20Colora%C3%A7%C3%A3o%20Pessoal%20Bras%C3%ADlia&query_place_id=ChIJ98TSHr5mcQkRB3l5exMQfLM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMKMm3UW38sgZCdXum9oAEmrrGZNH4gqBmf5ZQk=w408-h612-k-no"
      }
    ],
    "description": "Brazil's color analysis services are influenced by its lively culture and beautiful landscapes. Analysts often specialize in bold and vibrant palettes."
  },
  {
    "name": "Argentina",
    "slug": "argentina",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Vernucci Estilistas",
        "description": "Beauty salon. Rating: 4.6/5 from 662 reviews",
        "address": "Av. Independencia 2099, AAC, Buenos Aires",
        "phone": "+54 11 4943-2299",
        "website": "http://www.vernucci.com.ar/",
        "url": "https://www.google.com/maps/search/?api=1&query=Vernucci%20Estilistas&query_place_id=ChIJOaVG7eDKvJURewrNZUQHjgs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPZlvwfm82c28p4eahe0M66vnYD9Uu_PfeQulK5=w408-h306-k-no"
      }
    ],
    "description": "Argentina offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Peru",
    "slug": "peru",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Peru offers color analysis services that reflect its rich cultural heritage and beautiful landscapes. Analysts often focus on palettes inspired by nature."
  },
  {
    "name": "Chile",
    "slug": "chile",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Chile's color analysis services are influenced by its diverse climate and vibrant culture. Analysts often specialize in practical and versatile palettes."
  },
  {
    "name": "Venezuela",
    "slug": "venezuela",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Venezuela offers color analysis services that reflect its lively culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Ecuador",
    "slug": "ecuador",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Ecuador's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Bolivia",
    "slug": "bolivia",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Patty Hernández. Asesoría de Imagen, Especialista en Color",
        "description": "Image consultant. Rating: 5/5 from 16 reviews",
        "address": "Calle, Santa Cruz de la Sierra",
        "phone": "",
        "website": "http://www.pattyhernandez.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Patty%20Hern%C3%A1ndez.%20Asesor%C3%ADa%20de%20Imagen%2C%20Especialista%20en%20Color&query_place_id=ChIJ-8VpM6_n8ZMRMurECm8aHHc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=pJmosLjsmItSzYPQdOC8Gw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=33.508297&pitch=0&thumbfov=100"
      }
    ],
    "description": "Bolivia offers color analysis services that reflect its rich cultural heritage and beautiful landscapes. Analysts often focus on palettes inspired by nature."
  },
  {
    "name": "Paraguay",
    "slug": "paraguay",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Paraguay's color analysis services are influenced by its diverse culture and modern fashion scene. Analysts often specialize in practical and versatile palettes."
  },
  {
    "name": "Uruguay",
    "slug": "uruguay",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Uruguay offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Costa Rica",
    "slug": "costa-rica",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Costa Rica's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Panama",
    "slug": "panama",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Panama offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Cuba",
    "slug": "cuba",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Cuba's color analysis services are influenced by its rich history and beautiful landscapes. Analysts often specialize in palettes inspired by the Caribbean."
  },
  {
    "name": "China",
    "slug": "china",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Skyi3",
        "description": "Image consultant. Rating: 5/5 from 33 reviews",
        "address": "805, Hua Fu Commercial Building, 111 Queen's Rd W, Sheung Wan",
        "phone": "+852 6166 8873",
        "website": "https://www.skyi3.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skyi3&query_place_id=ChIJ69FfpqeUnCcRA4PEYyQHfbI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPLu1aW64jZsgKqMW-L2kKp195u6l2tsnma31cC=w408-h272-k-no"
      },
      {
        "name": "Colour Me Beautiful HK",
        "description": "Image consultant",
        "address": "Bldg Nos, Chit Lee Commercial Building, Office 06, 15/F, 30-46 Shau Kei Wan Rd, Sai Wan Ho",
        "phone": "+852 6685 7399",
        "website": "http://www.colourmebeautiful.hk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful%20HK&query_place_id=ChIJT2_mxnoABDQRubqjxJz4sdE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOqxGrhRSS1ISoGfW7EIzKU2D962Hk-920yPKPs=w427-h240-k-no"
      },
      {
        "name": "My Image Consultancy 睿雅形象顧問公司",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "Unit 2107 21/F Workingview Commercial Building, 21 Yiu Wah Street, Causeway Bay, Causeway Bay",
        "phone": "+852 3705 9153",
        "website": "http://www.myimage.com.hk/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Image%20Consultancy%20%E7%9D%BF%E9%9B%85%E5%BD%A2%E8%B1%A1%E9%A1%A7%E5%95%8F%E5%85%AC%E5%8F%B8&query_place_id=ChIJuROo51AABDQREoHZWS_2iuQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO2oYN75KAYowMr1dEvTL4dM85nIWp9eiV0IlgS=w408-h306-k-no"
      },
      {
        "name": "THE STYLUS personal styling consultancy",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "+852 6764 2732",
        "website": "https://the-stylus.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=THE%20STYLUS%20personal%20styling%20consultancy&query_place_id=ChIJxUNiA-8Tr2sR9i2DS8e0BOY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP-BZS77eRADK0C7leVkbHOMp27tXU41VfK0Etz=w408-h408-k-no"
      },
      {
        "name": "Insightful Styles",
        "description": "Stylist. Rating: 5/5 from 6 reviews",
        "address": "17R, Emperor Hollywood Centre, 151 Hollywood Rd, Sheung Wan",
        "phone": "",
        "website": "https://www.instagram.com/mya.insightfulstyles/",
        "url": "https://www.google.com/maps/search/?api=1&query=Insightful%20Styles&query_place_id=ChIJExSnOSwBBDQRU6iFsfQ4z5Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPUga5O6AGUwItwPjRiRIZl68E6EaMvEGLzGsA=w408-h544-k-no"
      }
    ],
    "description": "China offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Japan",
    "slug": "japan",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "パーソナルカラー診断サロン【Lily Beaute/リリーボーテ】渋谷・恵比寿店(Color analysis)",
        "description": "Image consultant. Rating: 4.9/5 from 138 reviews",
        "address": "Ebisunishi, 1 Chome−15−2 706 アパルトマンイトウ 7F 706号室 7th floor, Shibuya, Tokyo",
        "phone": "+81 70-1215-1031",
        "website": "https://www.instagram.com/yurina_color/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%E8%A8%BA%E6%96%AD%E3%82%B5%E3%83%AD%E3%83%B3%E3%80%90Lily%20Beaute%2F%E3%83%AA%E3%83%AA%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%86%E3%80%91%E6%B8%8B%E8%B0%B7%E3%83%BB%E6%81%B5%E6%AF%94%E5%AF%BF%E5%BA%97(Color%20analysis)&query_place_id=ChIJWRlXOfCLGGARE4k8MFTcHUI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipML3YYMcellMpR3qFrRvE-SuU8UvQLNSjnp_pqU=w408-h408-k-no"
      },
      {
        "name": "【Ciccy】パーソナルカラー診断・骨格診断・顔タイプ診断(Personal color analysis)",
        "description": "Image consultant. Rating: 5/5 from 18 reviews",
        "address": "3 Chome-17-11 Shibaura, Minato City, Tokyo",
        "phone": "+81 80-3522-7785",
        "website": "https://ciccy-image.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E3%80%90Ciccy%E3%80%91%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%E8%A8%BA%E6%96%AD%E3%83%BB%E9%AA%A8%E6%A0%BC%E8%A8%BA%E6%96%AD%E3%83%BB%E9%A1%94%E3%82%BF%E3%82%A4%E3%83%97%E8%A8%BA%E6%96%AD(Personal%20color%20analysis)&query_place_id=ChIJranZR7KLGGARSOKunxa5zB0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO-6UlCy2JImu-ULwVFbRC9cOOOuiKUB7wEyC0k=w408-h288-k-no"
      },
      {
        "name": "Redigo",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "Jingumae, 1 Chome−10−11 パークアクシス明治神宮前, Shibuya, Tokyo",
        "phone": "",
        "website": "http://readygojapan.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Redigo&query_place_id=ChIJLS-bzKSMGGARisyMTmD-0A4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=GHBx4o0lBAsFAYJH7CoDyA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=250.99608&pitch=0&thumbfov=100"
      },
      {
        "name": "パーソナルカラーサロンAlice",
        "description": "Image consultant. Rating: 4.4/5 from 9 reviews",
        "address": "Honchodori, 11 Bancho, 1835番地, Niigata, Niigata",
        "phone": "+81 25-369-5026",
        "website": "http://alice-personalcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%E3%82%B5%E3%83%AD%E3%83%B3Alice&query_place_id=ChIJ9UeN7MrJ9F8R80JaFMwIXgQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmZG6YvqOL-XgtE5UYX2U719FQnUg8UbFfzHf7=w408-h261-k-no"
      },
      {
        "name": "make up salon AGIRL",
        "description": "Make-up artist. Rating: 4.8/5 from 100 reviews",
        "address": "Minamiaoyama, 2 Chome−22−15 クリヤマビル 2F, Minato City, Tokyo",
        "phone": "+81 3-6206-1159",
        "website": "https://agirlbeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=make%20up%20salon%20AGIRL&query_place_id=ChIJT7wVXe6LGGARzIFtHsKssOs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNd4-UNjQ0FZvQTHRN8RWxHANm5okoPbKRh4zFj=w408-h408-k-no"
      }
    ],
    "description": "Japan's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  {
    "name": "South Korea",
    "slug": "south-korea",
    "type": "country",
    "pricingRange": "₩60,000 - ₩400,000",
    "businesses": [
      {
        "name": "Vic's Lab",
        "description": "Located in Hongdae, offers English-speaking services, known for detailed analysis and product recommendations.",
        "address": "",
        "phone": "",
        "website": "http://vicslab.kr/",
        "url": "",
        "imageUrl": ""
      },
      {
        "name": "Mood Collect",
        "description": "Located in Gangnam, offers packages including makeup tutorials. Note: Strict cancellation policy.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": "",
        "instagram": "mood__collect"
      },
      {
        "name": "Color Blossom",
        "description": "Located in Seongbuk-gu, offers group sessions at a lower price (₩60,000 per person). English-speaking consultant available.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      },
      {
        "name": "Colorsociety",
        "description": "Located in Hongdae, known for kind and patient English-speaking consultants.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "South Korea, particularly Seoul, is known for its advanced and detailed personal color analysis services. Many studios offer English-speaking consultants and combine color analysis with makeup tutorials and product recommendations."
  },
  {
    "name": "Korea",
    "slug": "korea",
    "type": "country",
    "pricingRange": "₩60,000 - ₩400,000",
    "businesses": [
      {
        "name": "Mood Collect",
        "description": "Located in Gangnam, offers packages including makeup tutorials. Note: Strict cancellation policy.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": "",
        "instagram": "mood__collect"
      },
      {
        "name": "Color Blossom",
        "description": "Located in Seongbuk-gu, offers group sessions at a lower price (₩60,000 per person). English-speaking consultant available.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      },
      {
        "name": "Colorsociety",
        "description": "Located in Hongdae, known for kind and patient English-speaking consultants.",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "South Korea, particularly Seoul, is known for its advanced and detailed personal color analysis services. Many studios offer English-speaking consultants and combine color analysis with makeup tutorials and product recommendations."
  },
  {
    "name": "India",
    "slug": "india",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Intended Image Consulting LLP",
        "description": "Image consultant. Rating: 4.9/5 from 27 reviews",
        "address": "beside Maruthi Suzuki Service Center, Hyderabad, Telangana",
        "phone": "+91 63022 31059",
        "website": "http://www.intendedimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Intended%20Image%20Consulting%20LLP&query_place_id=ChIJIQLIW6CWyzsRdd7jJwBE5hs",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=4wmYfF9p6pVILKBHUe2Cfg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=316.9898&pitch=0&thumbfov=100"
      },
      {
        "name": "International Image Consulting Institute",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "632, New Delhi, Delhi, Delhi",
        "phone": "+91 98998 72862",
        "website": "http://www.iici.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=International%20Image%20Consulting%20Institute&query_place_id=ChIJ_y0Dw1kFDTkRceCZUsoNs3I",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=UY3nBzXQWon8KkoKgxpISw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=145.73494&pitch=0&thumbfov=100"
      },
      {
        "name": "Elsewhere Professional Unisex Salon",
        "description": "Beauty salon. Rating: 4.8/5 from 309 reviews",
        "address": "KD Complex 100, 60 Feet Road, opp. Baldev Petrol Pump, Bathinda, Punjab",
        "phone": "+91 164 509 0245",
        "website": "https://www.facebook.com/elsewheresalonnspa/",
        "url": "https://www.google.com/maps/search/?api=1&query=Elsewhere%20Professional%20Unisex%20Salon&query_place_id=ChIJMU44uLoyFzkR55eSlgrJF-U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPv_29-7d_-61kbhU05hKwPlOPdYGtJGnNYQJyT=w408-h408-k-no"
      },
      {
        "name": "Scissorscut Makeover Studio ( Smsludhiana )",
        "description": "Hair salon. Rating: 4.8/5 from 485 reviews",
        "address": "197 R, near Monte Carlo, Ludhiana, Punjab",
        "phone": "+91 98152 54494",
        "website": "http://www.smsludhiana.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Scissorscut%20Makeover%20Studio%20(%20Smsludhiana%20)&query_place_id=ChIJzfyVSU2CGjkRVzIITonEqGM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPVnemWU9zbB06ezv54jK1Uxkx8RNXro-cdBII=w408-h408-k-no"
      },
      {
        "name": "Black Heaven Unisex Salon & Academy",
        "description": "Beauty salon. Rating: 4.8/5 from 206 reviews",
        "address": "black heaven Salon & Academy, Club Chowk Rd, Malerkotla, Punjab",
        "phone": "+91 99158 58300",
        "website": "https://blackheaven.co.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=Black%20Heaven%20Unisex%20Salon%20%26%20Academy&query_place_id=ChIJ8VQRBzhnEDkRV6Z_THxPK_Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPUj1kX9fPdtslIU7YyIl4E2tPMAKIlEQRx4TFN=w408-h544-k-no"
      }
    ],
    "description": "India offers color analysis services that reflect its rich cultural heritage and vibrant fashion scene. Analysts often focus on bold and colorful palettes."
  },
  {
    "name": "Indonesia",
    "slug": "indonesia",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Palette Me Studio | Personal Color Analysis Jogja | 16 Seasons Color Analysis",
        "description": "Image consultant. Rating: 5/5 from 16 reviews",
        "address": "Jl. Titibumi Barat No.45, Yogyakarta, Special Region of Yogyakarta",
        "phone": "+62 823-2467-4986",
        "website": "https://paletteme.framer.ai/",
        "url": "https://www.google.com/maps/search/?api=1&query=Palette%20Me%20Studio%20%7C%20Personal%20Color%20Analysis%20Jogja%20%7C%2016%20Seasons%20Color%20Analysis&query_place_id=ChIJa3JYnI1Zei4R3ToWdfbWAgg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO9OnX6S4EKiP-txSs0kZTzkQofm-MkaC3B07M5=w408-h612-k-no"
      },
      {
        "name": "Colourist",
        "description": "Beauty salon. Rating: 5/5 from 113 reviews",
        "address": "Kawasan District, Treasury Tower, Jl. Jend. Sudirman kav 52-53 No.8, South Jakarta City, Jakarta",
        "phone": "+62 887-0322-2119",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colourist&query_place_id=ChIJXXtiJTrxaS4R6RwXbK8MtXo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsP9cp_aEHl2A1GtLMNfkUOA6_ZwV4x-cRMURW=w408-h544-k-no"
      },
      {
        "name": "myCOLOR ID studio",
        "description": "Consultant. Rating: 5/5 from 10 reviews",
        "address": "Jl. Pantai Mutiara No.1 blok G, RT.7/RW.16, North Jakarta City, Jakarta",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=myCOLOR%20ID%20studio&query_place_id=ChIJOXUwPQAdai4RAS30HDufKKg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3CE95I2i6z_Wn85PBhQlkYEmHmfwUl0ON49fg=w408-h306-k-no"
      },
      {
        "name": "Colors of Soul, Bandung",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "4MC9+6C7, Villa Sutra Indah Jl. Sutera Indah No.8, Bandung Regency, West Java",
        "phone": "+62 858-8591-7413",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20of%20Soul%2C%20Bandung&query_place_id=ChIJUSeWCADnaC4RNlMECOVtzb8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPDbZM-WlpY8_GgAdQ1iLQKmHKcGrbf10-pBh7V=w408-h723-k-no"
      },
      {
        "name": "Kagayaku Studio | Personal Color Analysis & Image Consulting",
        "description": "Beauty salon. Rating: 5/5 from 17 reviews",
        "address": "Urban Heights Residences, Jl. Ciater Raya No.8 Lantai 5, South Tangerang City, Banten",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Kagayaku%20Studio%20%7C%20Personal%20Color%20Analysis%20%26%20Image%20Consulting&query_place_id=ChIJ3UQ7QADlaS4R9TYtKGTmYSo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPT2t7BvIUDJ854D_Rw51j3kFvSGWx7LYEjJfVs=w408-h544-k-no"
      }
    ],
    "description": "Indonesia's color analysis services are influenced by its diverse culture and beautiful landscapes. Analysts often specialize in palettes inspired by nature."
  },
  {
    "name": "Thailand",
    "slug": "thailand",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Spiral Color Salon /Personal color analysis",
        "description": "Education center. Rating: 5/5 from 20 reviews",
        "address": "RSU tower, 10F, Sukhumvit soi31, Khlong Toei, Bangkok",
        "phone": "+66 80 938 7817",
        "website": "https://www.spiralcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Spiral%20Color%20Salon%20%2FPersonal%20color%20analysis&query_place_id=ChIJHxsVGAWf4jARu3r-yLmmv1o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPF1ssEVzdN73p6FleKIrpZ5Y77FCHbLl_GnpdP=w408-h306-k-no"
      },
      {
        "name": "Personal Color",
        "description": "Image consultant",
        "address": "Ratchathewi, Bangkok",
        "phone": "+66 82 580 1929",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Color&query_place_id=ChIJwzbxYbmf4jAR3Pr6Gr8dGUo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPWq4O7c60WkisCd87b5REi8isJVYj96uh8cWSg=w408-h408-k-no"
      },
      {
        "name": "Chroma Club",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "44/14 Soi Sukhumvit 62, Phra Khanong, Bangkok",
        "phone": "+66 85 566 5653",
        "website": "http://lin.ee/p61ogPp",
        "url": "https://www.google.com/maps/search/?api=1&query=Chroma%20Club&query_place_id=ChIJu4gGQ-af4jARfpqjVkF8Quc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMigTjc_FgoP6zkgxzTvzwOXVLx-okw0k9Hl6MK=w408-h306-k-no"
      },
      {
        "name": "Thailand Image Academy",
        "description": "Image consultant",
        "address": "Promphan 3 Building, Unit, 606 Lat Phrao 3 Alley, Chatuchak, Bangkok",
        "phone": "+66 95 682 6539",
        "website": "https://www.thailandimageacademy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Thailand%20Image%20Academy&query_place_id=ChIJm5UTSQmd4jAR0XukgPmMQJI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPAPLCFnyg3REmWSasrmFwQ8HBLdV8ydd6ZfJpM=w408-h408-k-no"
      }
    ],
    "description": "Thailand offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Vietnam",
    "slug": "vietnam",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Color Visual Studio - Color Analysis & Styling in Viet Nam",
        "description": "Stylist. Rating: 5/5 from 28 reviews",
        "address": "81 Trương Định, Ho Chi Minh City, District 3, Ho Chi Minh City",
        "phone": "+84 785 338 299",
        "website": "https://colorvisual.vn/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Visual%20Studio%20-%20Color%20Analysis%20%26%20Styling%20in%20Viet%20Nam&query_place_id=ChIJRTnw18dfJocRejTtgqufkv4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPyPXZBcDRNo9fHnSx9srDxIiC41yElfHUrhOYO=w408-h408-k-no"
      },
      {
        "name": "U:Color - Personal Color Studio",
        "description": "Image consultant",
        "address": "12 Đ. Quốc Hương, Ho Chi Minh City, Quan 2, Ho Chi Minh City",
        "phone": "+84 909 323 680",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=U%3AColor%20-%20Personal%20Color%20Studio&query_place_id=ChIJk5VKicIndTERhIIlYcTzvHo",
        "imageUrl": ""
      },
      {
        "name": "Carico Beauty Salon - Personal Color Analysis",
        "description": "Beauty salon. Rating: 4.8/5 from 4 reviews",
        "address": "7 Thạch Thị Thanh, Ho Chi Minh City, District 1, Ho Chi Minh City",
        "phone": "+84 909 373 321",
        "website": "https://mausaccanhan.vn/",
        "url": "https://www.google.com/maps/search/?api=1&query=Carico%20Beauty%20Salon%20-%20Personal%20Color%20Analysis&query_place_id=ChIJz9lLRe0pdTERhKnQXc0KbN4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHu4cdNCz5ICnWSvjDwrBnxRmnWgDzq0ZYTXQn=w408-h544-k-no"
      },
      {
        "name": "Dvore Hair Ngoại Giao Đoàn",
        "description": "Hair salon. Rating: 5/5 from 102 reviews",
        "address": "LK.C 40(Embassy Garden, P. Lưu Cơ, Hanoi, Bac Tu Liem, Hanoi",
        "phone": "+84 962 145 711",
        "website": "https://www.facebook.com/share/CuVjg38NWCh9NrR2/",
        "url": "https://www.google.com/maps/search/?api=1&query=Dvore%20Hair%20Ngo%E1%BA%A1i%20Giao%20%C4%90o%C3%A0n&query_place_id=ChIJBRAZY4pVNDERmuuxC9tsaBQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNl1Tg3OjKQyHF76SCPtVo5OTlU6ohveOw0-iW7=w408-h408-k-no"
      }
    ],
    "description": "Vietnam's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Malaysia",
    "slug": "malaysia",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colour Me Beautiful Malaysia Colour Consultant",
        "description": "Image Consultant. Rating: 4.9/5 from 32 reviews",
        "address": "Blk F-2-02, Dataran Glomac, Jalan SS 6/5b, Petaling Jaya, Selangor",
        "phone": "+60 10-771 1376",
        "website": "http://www.colourmebeautiful.com.my/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful%20Malaysia%20Colour%20Consultant&query_place_id=ChIJQxrkLxpNzDER5zR8Gnz6e4Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOn_KlPUQ5eKP-G1-Va8DHRLAPXF58h4Nenvjp5=w408-h567-k-no"
      },
      {
        "name": "Advent Image Management",
        "description": "Image Consultant. Rating: 4.8/5 from 6 reviews",
        "address": "Arcoris Business Suites, 10, Jalan Kiara, Kuala Lumpur, Federal Territory of Kuala Lumpur",
        "phone": "+60 17-687 1132",
        "website": "https://adventimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Advent%20Image%20Management&query_place_id=ChIJkXjxMfJIzDEREyRCmT_q4Ls",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNJbhK7YB0EP6HHAO0nkChLlSWG7Cngi9OfY_6t=w408-h295-k-no"
      },
      {
        "name": "style by osh",
        "description": "Image Consultant. Rating: 5/5 from 45 reviews",
        "address": "Colony Coworking Space - KLCC, 6, Jalan Kia Peng, Kuala Lumpur, Federal Territory of Kuala Lumpur",
        "phone": "+60 17-666 4088",
        "website": "http://www.stylebyosh.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=style%20by%20osh&query_place_id=ChIJBdi7rFRIzDERWeuGw7AtqN4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP42hZW9xK3voc-RfdNYPuN18wubrQ5N5K96Zry=w408-h271-k-no"
      },
      {
        "name": "Sheryl New - Strategic Brand Image Trainer",
        "description": "Image Consultant. Rating: 4.9/5 from 36 reviews",
        "address": "B-07-29, EMPIRE SUBANG SOHO, EMPIRE SUBANG SHOPPING GALLERY, Jalan SS 16/1, Subang Jaya, Selangor",
        "phone": "+60 10-220 6925",
        "website": "http://www.tibconsultancy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Sheryl%20New%20-%20Strategic%20Brand%20Image%20Trainer&query_place_id=ChIJMdR5dDlNzDERjHgnSF6xJF0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOp_XgwTYRZczfDqOpJ80LDlzvvuiwYLdWy2uqJ=w408-h544-k-no"
      },
      {
        "name": "Moddish Craft to Style",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "11G & 11-1, Jalan 3/109E, Jln Klang Lama, Kuala Lumpur, Federal Territory of Kuala Lumpur",
        "phone": "+60 17-242 9559",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Moddish%20Craft%20to%20Style&query_place_id=ChIJGSI_hMNLzDER15hzZVPcqIM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipME59PmfALPn1riEgPA6ka7GeHdAVGMQmpVKl9d=w459-h240-k-no"
      }
    ],
    "description": "Malaysia offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Singapore",
    "slug": "singapore",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Carmens Colours - Personal colour analysis & styling by Carmen",
        "description": "Image consultant. Rating: 5/5 from 94 reviews",
        "address": "23 Chatsworth Rd, Singapore",
        "phone": "",
        "website": "https://www.carmenscolours.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Carmens%20Colours%20-%20Personal%20colour%20analysis%20%26%20styling%20by%20Carmen&query_place_id=ChIJSwv4d1Yb2jERGgtk78IhwMQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjweqMw5b2LRsadGi0WtGJALawPBwP83IqBDAf=w408-h584-k-no"
      },
      {
        "name": "Colour Ultimate Studio",
        "description": "Image consultant. Rating: 5/5 from 26 reviews",
        "address": "1 Tanglin Rd, #05-07 Rendezvous Hotel, Singapore",
        "phone": "+65 8375 0077",
        "website": "https://colourultimatestudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Ultimate%20Studio&query_place_id=ChIJd5c6Ir4Z2jERw2u3WsgBASA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMvrKvVdGsmzMkIg8Z4hY5uEgvxpVyn0fQn7cLk=w408-h544-k-no"
      },
      {
        "name": "Something About Colour",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "28 Canberra Dr, Singapore",
        "phone": "+65 8200 3396",
        "website": "https://www.somethingaboutcolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Something%20About%20Colour&query_place_id=ChIJy1rA7YsV2jERgOGr00VyQwo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNWnMutuQ9fFPQaH-zlFfJHhmRvLB_M4FD6KJlb=w408-h544-k-no"
      },
      {
        "name": "Style Forth",
        "description": "Image consultant. Rating: 5/5 from 24 reviews",
        "address": "30 Purvis St, #02-01, Singapore",
        "phone": "",
        "website": "https://www.styleforth.sg/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Forth&query_place_id=ChIJKR7nGjgZ2jERAiYLyzGneGs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMgsWtMFmLcxErhcKmtdh64S6s2ac-VWAJ7yJzT=w408-h272-k-no"
      },
      {
        "name": "Palette Pro by Studio FUSE",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "22 Sin Ming Ln, #06-82, Singapore",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Palette%20Pro%20by%20Studio%20FUSE&query_place_id=ChIJWet9rHoR2jER_pXxr1gk_3o",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=4j2rUI4ZXpAaxJ8FSLUD7w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=21.36231&pitch=0&thumbfov=100"
      }
    ],
    "description": "Singapore's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  {
    "name": "Philippines",
    "slug": "philippines",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Flair Image Consultancy",
        "description": "Image consultant. Rating: 4.3/5 from 12 reviews",
        "address": "Unit 2005 Astoria Plaza Ortigas Business Center, Pasig, Metro Manila",
        "phone": "+63 917 530 1771",
        "website": "http://www.imagebyflair.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Flair%20Image%20Consultancy&query_place_id=ChIJzailiVTJlzMRfLEnt12pvYk",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=rbflwpytCVyUmTE2cte4Wg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=157.84239&pitch=0&thumbfov=100"
      },
      {
        "name": "Jasmine Mclean Styling",
        "description": "Image consultant",
        "address": "",
        "phone": "+63 977 481 5220",
        "website": "http://www.facebook.com/jasminemcleanstyling",
        "url": "https://www.google.com/maps/search/?api=1&query=Jasmine%20Mclean%20Styling&query_place_id=ChIJQ3Vk5YEgvm8RiEBQDAB23NA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP-6COFuqijzVPoYPbM1Y_a45fFYuSmfbhk0Xhr=w408-h544-k-no"
      },
      {
        "name": "My Image Consultant Philippines",
        "description": "Image consultant",
        "address": "26th Street, corner 11th Ave, Taguig, Metro Manila",
        "phone": "+63 917 882 1100",
        "website": "https://www.imageconsultantphilippines.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Image%20Consultant%20Philippines&query_place_id=ChIJe7Fg09DJlzMRX3AUNxRWyYY",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=SlLBO-yA19o5dvSJ04Agjw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=117.86652&pitch=0&thumbfov=100"
      },
      {
        "name": "Color It",
        "description": "Hairdresser",
        "address": "118 santan st, Paseo De Animales, Pasig, Metro Manila",
        "phone": "+63 919 870 1058",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20It&query_place_id=ChIJF0oj6c25lzMRt6DPlXYNv0M",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=TDXYWXABqQHzjDG9ISKulg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=2.1595843&pitch=0&thumbfov=100"
      }
    ],
    "description": "The Philippines offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Australia",
    "slug": "australia",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "The PS Colour - Personal Colour Analysis in Brisbane City",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "8 Adelaide St, Brisbane City, Queensland",
        "phone": "+61 448 299 014",
        "website": "https://www.thepscolour.com/%20?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=15866840746342700966",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20PS%20Colour%20-%20Personal%20Colour%20Analysis%20in%20Brisbane%20City&query_place_id=ChIJ43tUXhxbkWsRwkIhFc8nnlM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=wecCoQ4wdbPwVFRbkFrn7A&cb_client=search.gws-prod.gps&w=408&h=240&yaw=50.7948&pitch=0&thumbfov=100"
      },
      {
        "name": "Your Colour Analysis",
        "description": "Image consultant. Rating: 5/5 from 91 reviews",
        "address": "",
        "phone": "+61 478 839 464",
        "website": "https://www.yourcolouranalysis.com.au/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=1853852312938958531",
        "url": "https://www.google.com/maps/search/?api=1&query=Your%20Colour%20Analysis&query_place_id=ChIJRfgG-imT3KQRC_5jpM-DD0w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPBpbYylLeTAIqfyD9BZaImOzCmqNBZBAVA3ExT=w408-h544-k-no"
      },
      {
        "name": "Colour Babe",
        "description": "Image consultant. Rating: 5/5 from 36 reviews",
        "address": "unit 53/164/172 Wellington St, Ormiston, Queensland",
        "phone": "+61 422 588 763",
        "website": "http://www.colourbabe.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Babe&query_place_id=ChIJbYt504VhkWsRIDxX2m3EbR8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOECfbbU2-QUcT6empEsJHdnHbPCG2qKKGYfXzp=w408-h544-k-no"
      },
      {
        "name": "Colour You",
        "description": "Image consultant. Rating: 5/5 from 52 reviews",
        "address": "503/343 Little Collins St, Melbourne, Victoria",
        "phone": "+61 426 870 929",
        "website": "https://colouryouau.as.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20You&query_place_id=ChIJyVXtRjRD1moRq7Edy92YnaY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNuhpBEWS_IyxcHAWpvZXzPwYUHPSLVeGOiYcdI=w439-h240-k-no"
      },
      {
        "name": "Your Colours",
        "description": "Image Consultant. Rating: 4.6/5 from 47 reviews",
        "address": "28/350 Cambridge St, Wembley, Western Australia",
        "phone": "+61 8 9325 2865",
        "website": "http://www.yourcolours.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Your%20Colours&query_place_id=ChIJW_LVIzClMioR-WKbm1xl0BY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPYPYy6Nuh63jhmzy9xJnPwm1X03-yekzBkGcIP=w408-h268-k-no"
      }
    ],
    "description": "Australia's color analysis services are influenced by its diverse climate and vibrant culture. Analysts often specialize in practical and versatile palettes."
  },
  {
    "name": "New Zealand",
    "slug": "new-zealand",
    "type": "country",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colours to Keep",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "15 McInnes Pl, Hamilton",
        "phone": "+64 27 752 3335",
        "website": "http://www.colourstokeep.co.nz/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20to%20Keep&query_place_id=ChIJKfvdi-cjbW0RTEUk6S8OHI0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNzPB83eU-p-Q_VWyyo-IpWHeFpXolvlm03_2IA=w408-h544-k-no"
      },
      {
        "name": "Charlotte Porter Colours",
        "description": "Beautician. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "+64 27 282 6059",
        "website": "https://www.charlotteportercolours.co.nz/",
        "url": "https://www.google.com/maps/search/?api=1&query=Charlotte%20Porter%20Colours&query_place_id=ChIJdVc22Da8XGwRsesg-twm-V0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMJ8hzNup3OIR8K_yZqQOEQ0tsB1ZS2qWTuFrg6=w408-h612-k-no"
      },
      {
        "name": "Style Gorgeous - The Executive Stylist",
        "description": "Stylist. Rating: 5/5 from 24 reviews",
        "address": "4/69 Pirie St, Wellington",
        "phone": "+64 22 016 4410",
        "website": "https://www.stylegorgeous.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Gorgeous%20-%20The%20Executive%20Stylist&query_place_id=ChIJkZKhhMSvOG0Rksw5_2bcDSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipObjNY5A93nOhwsFgcH14_qAOKY4nfm3L1Yfe_y=w408-h612-k-no"
      },
      {
        "name": "Colours",
        "description": "Cosmetics store",
        "address": "7001 Mumford Rd, Halifax, Nova Scotia",
        "phone": "+1 902-422-3111",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours&query_place_id=ChIJT8kCppkhWksRZtbe_2u9Zew",
        "imageUrl": ""
      },
      {
        "name": "Styled By Ashleigh",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "B7/101 Caledonian Rd, Christchurch",
        "phone": "+64 22 098 0939",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Styled%20By%20Ashleigh&query_place_id=ChIJcRqJdtiLMW0RR-DYDXO3veM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Larf0pvhng-Vh4omRZHBWA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=290.6231&pitch=0&thumbfov=100"
      }
    ],
    "description": "New Zealand offers color analysis services that reflect its natural beauty and serene landscapes. Analysts often focus on palettes inspired by nature."
  },
  {
    "name": "California",
    "slug": "california",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Diana Olson & Company | Diana's Color Collage",
        "description": "Consultant. Rating: 5/5 from 2 reviews",
        "address": "465 Union St, Pasadena, California",
        "phone": "(626) 584-9761",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Diana%20Olson%20%26%20Company%20%7C%20Diana's%20Color%20Collage&query_place_id=ChIJHU5lKGnDwoARZS8j9IV70Zc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=JMlUYp0xD18WHxY9Z-BP2A&cb_client=search.gws-prod.gps&w=408&h=240&yaw=356.66663&pitch=0&thumbfov=100"
      },
      {
        "name": "An Eye for Color",
        "description": "Stylist. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "(408) 781-7412",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=An%20Eye%20for%20Color&query_place_id=ChIJKWTuf4c1joARQyPH9JLuCZY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMTHFtdDRz88q7Sa2Sos0KQBdWO3cG_BowJ2vDe=w408-h544-k-no"
      },
      {
        "name": "Personal Color Lab",
        "description": "Image consultant. Rating: 5/5 from 26 reviews",
        "address": "10161 Bolsa Ave #208a, Westminster, California",
        "phone": "(714) 713-2032",
        "website": "http://personalcolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Color%20Lab&query_place_id=ChIJXxR4B_cn3YAROK2trsQ21OY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPfI_--Kx-kxl3qPxPUR9vsOxS14ty0EMhOgbGO=w408-h544-k-no"
      },
      {
        "name": "Best of Hue Color Analysis",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "609 N Pacific Coast Hwy Suite 114, Redondo Beach, California",
        "phone": "(508) 335-8729",
        "website": "http://www.bestofhue.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Best%20of%20Hue%20Color%20Analysis&query_place_id=ChIJG11F1n-1woAR_Mq8TlMh3G8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM_BmoxxWeVyXJMXk5QMwV-g_s1numbfpmMnzw5=w408-h502-k-no"
      }
    ]
  },
  {
    "name": "New York",
    "slug": "new-york-state",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Seklab Color Analysis Studio",
        "description": "Image consultant. Rating: 5/5 from 81 reviews",
        "address": "202 W 40th St Suite 400, New York, New York",
        "phone": "(332) 999-4179",
        "website": "https://www.seklabnyc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Seklab%20Color%20Analysis%20Studio&query_place_id=ChIJwxeSMoRZwokR2BBukhq4lSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlfS2fOpii7qpV5M8BXyKf45AA6DJvTiYJ3GQr=w408-h544-k-no"
      },
      {
        "name": "The Outfit Curator",
        "description": "Stylist. Rating: 4.8/5 from 17 reviews",
        "address": "123 W 81st St, New York, New York",
        "phone": "",
        "website": "http://www.theoutfitcurator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Outfit%20Curator&query_place_id=ChIJjUVyL1BZwokRE7p_2_X0RMI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQjkI8r7-F7JkalGMJ0YXHyz8VSArJYQ_0AG0T=w408-h612-k-no"
      },
      {
        "name": "Lily's Color Lab",
        "description": "Stylist. Rating: 4.9/5 from 71 reviews",
        "address": "1239 Broadway, New York, New York",
        "phone": "(202) 813-9687",
        "website": "https://lilyscolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lily's%20Color%20Lab&query_place_id=ChIJqxVpZRFbwokRPYp0fw-xRUI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7oWbYDctv5kuqOqA8g16iw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=91.2156&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour NYC - Downtown Brooklyn",
        "description": "Stylist. Rating: 5/5 from 11 reviews",
        "address": "325 Gold St Suite 703A, Brooklyn, New York",
        "phone": "(929) 266-7228",
        "website": "https://houseofcolour.com/fernandavazquez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NYC%20-%20Downtown%20Brooklyn&query_place_id=ChIJPRb8F6tbwokRbe-woVjHwO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOUh0ZE6PWUcygcamfo11_uH1HiI4QGjPSEEqSC=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "Texas",
    "slug": "texas",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour | Austin | Lindsay Duncan",
        "description": "Stylist. Rating: 5/5 from 19 reviews",
        "address": "",
        "phone": "(972) 900-2662",
        "website": "https://max.houseofcolour.co.uk/book/18125-LindsayDuncan",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20%7C%20Austin%20%7C%20Lindsay%20Duncan&query_place_id=ChIJOZrSR_Y5W4YR6y9ybHJV4eU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN-7LMkixGzqVCEDKd3T63JHaXHX35KzOlOqdUP=w408-h612-k-no"
      },
      {
        "name": "Color Me Confident - Personal Color Analysis",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "",
        "phone": "",
        "website": "https://colormeconfidentpca.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Me%20Confident%20-%20Personal%20Color%20Analysis&query_place_id=ChIJhxfyEi4ntEMR8W5YyM3EIrU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP-sWRVTmJGYzoazEM9u-5VORpdv8e7mhp9u519=w408-h510-k-no"
      },
      {
        "name": "Color Composure - TCI personal color analysis",
        "description": "Image consultant. Rating: 5/5 from 46 reviews",
        "address": "5601 Edmond Ave, Waco, Texas",
        "phone": "",
        "website": "http://www.colorcomposure.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Composure%20-%20TCI%20personal%20color%20analysis&query_place_id=ChIJbaX1XfZbdSURoWFSGZw-K8U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMmr3a0O5ljtt7HNI4g3kGso-uV0FQec9GH32aJ=w408-h544-k-no"
      },
      {
        "name": "Color Match Studio",
        "description": "Consultant. Rating: 5/5 from 28 reviews",
        "address": "814 East Ave suite d, Katy, Texas",
        "phone": "",
        "website": "http://colormatchstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Match%20Studio&query_place_id=ChIJ3wg9F8UlQYYRbp2rpSe7VAw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOngOpKShfVmw7C_CV-Ar81bEWw3c1WZj-z3kY=w408-h272-k-no"
      },
      {
        "name": "Colorier | Korean Color Analysis in Austin",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "1170-1304 Wells Branch Pkwy, Pflugerville, Texas",
        "phone": "",
        "website": "https://thecolorier.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorier%20%7C%20Korean%20Color%20Analysis%20in%20Austin&query_place_id=ChIJCUy_0tFSFWURIttA130NqNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_sNy6l8Mj143Fp6sY45Kfeh9d7wC7GF22o1g1=w408-h611-k-no"
      }
    ]
  },
  {
    "name": "Florida",
    "slug": "florida",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Kim Armga",
        "description": "Image consultant. Rating: 5/5 from 26 reviews",
        "address": "3919 Hill Terrace Dr, Jacksonville, Florida",
        "phone": "(904) 891-4643",
        "website": "https://www.jaxcolorandstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Kim%20Armga&query_place_id=ChIJS23JiH6x5YgRYkcChv1V9RQ",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=9PiM2EKxLR_2B_Av2jQy6Q&cb_client=search.gws-prod.gps&w=408&h=240&yaw=87.85406&pitch=0&thumbfov=100"
      },
      {
        "name": "The Color Analyst",
        "description": "Stylist. Rating: 4.9/5 from 54 reviews",
        "address": "5041 Wiles Rd, Coconut Creek, Florida",
        "phone": "(754) 276-4662",
        "website": "http://www.thecoloranalyst.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Analyst&query_place_id=ChIJtS6Nxy-soKIR1x_AIgXAKt4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO0Wj7Dzr_rIAILNEldedMgt-9EphRPynYyVsod=w408-h414-k-no"
      },
      {
        "name": "House of Colour Ocoee & Orlando",
        "description": "Image consultant. Rating: 5/5 from 40 reviews",
        "address": "",
        "phone": "(407) 504-0327",
        "website": "https://houseofcolourocoee.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Ocoee%20%26%20Orlando&query_place_id=ChIJwxyWlx-fsQwRE2sP8rKAV1A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPT6o8JxB6TWGxlHcsCwxK_0jf24_HqXZpT19pI=w408-h612-k-no"
      },
      {
        "name": "Color Your Couture",
        "description": "Image consultant. Rating: 5/5 from 33 reviews",
        "address": "117th St E, Bradenton, Florida",
        "phone": "",
        "website": "https://coloryourcouture.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Your%20Couture&query_place_id=ChIJbZVOzBpFciIRtrfY85T-wfU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOzF5-VZslakwUvsjXgTHfehKAf9Pn_PkFMVMGJ=w408-h272-k-no"
      }
    ]
  },
  {
    "name": "Illinois",
    "slug": "illinois",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Normal",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(309) 267-0104",
        "website": "https://max.houseofcolour.co.uk/book/22150-Krista_Gooris",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Normal&query_place_id=ChIJgZgog5ahA2ERgQYXDHWZowY",
        "imageUrl": ""
      },
      {
        "name": "Pretty Your World",
        "description": "Image consultant",
        "address": "106 N Metcalf Ave, Amboy, Illinois",
        "phone": "(779) 770-4716",
        "website": "https://www.prettyyourworld.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Pretty%20Your%20World&query_place_id=ChIJA16DfCsJCYgROgxp0_AE7IQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPcsmXHwAr7LJExAE2nD3KbNfatWtTcARKo4-db=w408-h259-k-no"
      },
      {
        "name": "Colour Palette Corporation",
        "description": "Beauty salon. Rating: 5/5 from 2 reviews",
        "address": "213 Catalpa Ave, Itasca, Illinois",
        "phone": "(224) 688-5154",
        "website": "https://www.colourpalettecorporation.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Palette%20Corporation&query_place_id=ChIJ4-GR6qutD4gRa04OfaGnt1Y",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=003X0zpT1Lt9_4TETjATPw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=101.70035&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour North Naperville",
        "description": "Image consultant",
        "address": "619 E Ogden Ave, Naperville, Illinois",
        "phone": "(312) 882-3631",
        "website": "https://www.houseofcolour.com/stylists/lindsey-kapala-north-naperville-illinois",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20North%20Naperville&query_place_id=ChIJswcS85RXDogRRE9VOdFTFsM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPXFsEeaog2n4M3xbgQFItV-Tq6d-IrfvLhzcK4=w408-h408-k-no"
      },
      {
        "name": "Styled by Soyol",
        "description": "Image consultant. Rating: 5/5 from 56 reviews",
        "address": "1701 Golf Rd, Rolling Meadows, Illinois",
        "phone": "(312) 532-5668",
        "website": "https://www.styledbysoyol.com/home",
        "url": "https://www.google.com/maps/search/?api=1&query=Styled%20by%20Soyol&query_place_id=ChIJKTNVNQnBD4gRVAjltDxTnWY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOM6FfyPoBPfxiyGaG_PHJotD5atwIbN7d2gUJ-=w408-h612-k-no"
      }
    ]
  },
  {
    "name": "Pennsylvania",
    "slug": "pennsylvania",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Vivid Color",
        "description": "Beauty Salon. Rating: 5/5 from 3 reviews",
        "address": "1748 S Queen St #4612, York, Pennsylvania",
        "phone": "+1 717-495-9100",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Vivid%20Color&query_place_id=ChIJg0cCRjGJyIkRphvZG5Anh_Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMt--H10VfMbHLdH2kO1VL15d3vJa4MCViYnSh9=w408-h544-k-no"
      },
      {
        "name": "House of Colour - Jill Rafter",
        "description": "Image consultant",
        "address": "",
        "phone": "(215) 840-3414",
        "website": "https://max.houseofcolour.co.uk/book/27275-Jill_Rafter",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Jill%20Rafter&query_place_id=ChIJK_heji1j60cRSlNbjfSO7QU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf2l4QO5Mr2tNPTD8ZwypYaH3hcWqgSgJx4amQ=w408-h408-k-no"
      },
      {
        "name": "Mayfield Color Consulting",
        "description": "Consultant",
        "address": "",
        "phone": "(505) 231-4774",
        "website": "http://choosingpaintcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Mayfield%20Color%20Consulting&query_place_id=ChIJh0RN1_JwhoUR8ccS4KEn5Ao",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNWXru7vzjp2EcdYLt8T5r_A2UYvSnwJ2wudb4T=w408-h306-k-no"
      }
    ]
  },
  {
    "name": "Ohio",
    "slug": "ohio",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Created for Color",
        "description": "Image consultant. Rating: 5/5 from 24 reviews",
        "address": "4015 Executive Park Dr Suite 405, Cincinnati, Ohio",
        "phone": "(513) 399-7305",
        "website": "http://createdforcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Created%20for%20Color&query_place_id=ChIJ51CmIYhVQIgROMYgqpPeVtI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMruxKcNlaQowqlRdpz_hgvc7Q-2ncqri_iSWJF=w408-h408-k-no"
      },
      {
        "name": "House of Colour Bath Ohio",
        "description": "Image consultant. Rating: 5/5 from 40 reviews",
        "address": "",
        "phone": "(234) 247-2022",
        "website": "https://www.houseofcolour.com/stylists/kendra-hunter-bath-ohio",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Bath%20Ohio&query_place_id=ChIJKYwaLb3bMIgRMPcLJU2KTLE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0ETI3jd9xV9kLeTSnAyJj6M8SkIgl4Zk7Pwk-=w408-h271-k-no"
      },
      {
        "name": "Splat Hair Design",
        "description": "Beauty salon. Rating: 4.9/5 from 169 reviews",
        "address": "105 W Main St Ste C, Ashland, Ohio",
        "phone": "(419) 908-9337",
        "website": "https://splathd.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Splat%20Hair%20Design&query_place_id=ChIJeRUgJkv7OYgRVtJUEHM0AW8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPWyp4g5oQ-fpreyE5zfOEZFmqV6z7okblfKe4O=w408-h401-k-no"
      },
      {
        "name": "Kelly Does Color",
        "description": "Image consultant",
        "address": "4400 N High St, Columbus, Ohio",
        "phone": "",
        "website": "https://kellydoescolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Kelly%20Does%20Color&query_place_id=ChIJp8ec7KWNOIgRPenYCvJFhBc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=y6iAavhzY48L8QHTcmRCNQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=266.46585&pitch=0&thumbfov=100"
      },
      {
        "name": "Color Sanctuary Salon & Spa",
        "description": "Beauty salon. Rating: 4.8/5 from 19 reviews",
        "address": "1006 E Main St, Ashland, Ohio",
        "phone": "(419) 606-3454",
        "website": "https://m.facebook.com/CSPMSalon/?ref=content_filter&mt_nav=0",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Sanctuary%20Salon%20%26%20Spa&query_place_id=ChIJ77Tn_579OYgReVrMrIcrzwc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOnSK0jyC_9mTkPU6iXx29FjOdQyLAozcMrUC9v=w408-h247-k-no"
      }
    ]
  },
  {
    "name": "Georgia",
    "slug": "georgia",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Discerning Palette Personal Color Analysis",
        "description": "Stylist",
        "address": "",
        "phone": "(404) 375-5479",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Discerning%20Palette%20Personal%20Color%20Analysis&query_place_id=ChIJE93lWP_MdQMRt1xQNdy9PwU",
        "imageUrl": ""
      },
      {
        "name": "House of Colour West Marietta",
        "description": "Image consultant",
        "address": "Unity Trail NW, Marietta, Georgia",
        "phone": "(470) 233-5371",
        "website": "https://max.houseofcolour.co.uk/book/24325-Amanda_Dusseault",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20West%20Marietta&query_place_id=ChIJSy2oKto_9YgRSsLvNbGpKbg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNh_tA-KigvDFuDnRTOTppFd4M8LTDIaqYRZw_8=w408-h544-k-no"
      },
      {
        "name": "Yummy Colour by Indya Nicole",
        "description": "Beauty salon. Rating: 5/5 from 11 reviews",
        "address": "11215 Abercorn St STE 6, Savannah, Georgia",
        "phone": "+1 912-349-5731",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Yummy%20Colour%20by%20Indya%20Nicole&query_place_id=ChIJFzNjAfd1-4gRTM9WRdy6IF4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=B7vO6Ej88ikbgKOSDf5ikA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=117.15954&pitch=0&thumbfov=100"
      },
      {
        "name": "Colorena",
        "description": "Image consultant. Rating: 5/5 from 10 reviews",
        "address": "Carrington Pointe, Tucker, Georgia",
        "phone": "(470) 781-4550",
        "website": "https://www.colorenastudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorena&query_place_id=ChIJS-RTKy6p9YgRdqupO6P0P-o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMM6NrOZwPOLeC4aCESQTkd7gTLRGxo0ODHbCDU=w408-h603-k-no"
      },
      {
        "name": "House of Colour Canton",
        "description": "Image Consultant. Rating: 5/5 from 5 reviews",
        "address": "198 North St, Canton, Georgia",
        "phone": "",
        "website": "http://hoccanton.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Canton&query_place_id=ChIJi9S0b-1l9YgROHg2xajYpVI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Ekp-Unmft35P7NQ3GgVCrA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=4.3288155&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "North Carolina",
    "slug": "north-carolina",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Color Me Beautiful Raleigh",
        "description": "Image consultant. Rating: 5/5 from 67 reviews",
        "address": "7486 Creedmoor Rd, Raleigh, North Carolina",
        "phone": "",
        "website": "http://colormebeautifulraleigh.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Me%20Beautiful%20Raleigh&query_place_id=ChIJ-8iVJjT3rIkRKcE9Oe4_dAk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDS6tIHXnkwFbW6gzLh9LztaanqSH8oF8CK6I=w408-h714-k-no"
      },
      {
        "name": "House of Colour Holly Springs",
        "description": "Image consultant. Rating: 5/5 from 27 reviews",
        "address": "125 Canford Wy, Holly Springs, North Carolina",
        "phone": "(651) 238-4870",
        "website": "https://www.houseofcolour.com/stylists/nicole-sveen-holly-springs-north-carolina",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Holly%20Springs&query_place_id=ChIJH9l4B3WNrIkR2WnZoRxu8ss",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPk8UDeEiRMvva4ZgwPIGB500_EgKuSyItZDvyV=w408-h408-k-no"
      },
      {
        "name": "Claire Roberts Style (image / wardrobe consultant - Raleigh area)",
        "description": "Consultant. Rating: 5/5 from 25 reviews",
        "address": "",
        "phone": "(919) 601-6299",
        "website": "http://www.clairerobertsstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Claire%20Roberts%20Style%20(image%20%2F%20wardrobe%20consultant%20-%20Raleigh%20area)&query_place_id=ChIJs4KVKUNfrIkRoccJfSMQF7E",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMmt9kBtcHGLrV-qbPMlJONq18teXZVuR9vVCKm=w408-h408-k-no"
      },
      {
        "name": "Glow Philosophy",
        "description": "Stylist. Rating: 5/5 from 13 reviews",
        "address": "",
        "phone": "",
        "website": "https://glowphilosophy.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=Glow%20Philosophy&query_place_id=ChIJj4VMkAOLlKQRH66WIXGyrC4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNEjut_TizEgq6hKtV_3UTzfb0V0vZ171ujA1-2=w408-h272-k-no"
      }
    ]
  },
  {
    "name": "Michigan",
    "slug": "michigan",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Arabella Color",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.arabellacolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Arabella%20Color&query_place_id=ChIJKe5nNNwyU6QR5M9SS0QRexM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNzg2TfyexWlZeBEFtM8bTkjMc53-r475RIZsqj=w408-h528-k-no"
      },
      {
        "name": "Colorfully Styled with House of Colour",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "",
        "phone": "(269) 208-6509",
        "website": "https://linktr.ee/houseofcolour_colorfullystyled",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorfully%20Styled%20with%20House%20of%20Colour&query_place_id=ChIJw8lMKbbpd28RqiJ1UhWW5oo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNfcN1qyCI0bw1kY6FmBVH4U2HhG48mVn31eQ5e=w408-h612-k-no"
      },
      {
        "name": "The Message of Color",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(616) 780-0373",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Message%20of%20Color&query_place_id=ChIJIQOPdWSyGYgRDWKFem86VU8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPrsOKsTyFW0oWjfez9kGhPwBP0P3TEjqT4qtmK=w426-h240-k-no"
      },
      {
        "name": "Polychromatic Color Analysis | Grand Rapids",
        "description": "Consultant. Rating: 5/5 from 4 reviews",
        "address": "1243 Northlawn St NE, Grand Rapids, Michigan",
        "phone": "(616) 481-8339",
        "website": "https://polychromaticcolorstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Polychromatic%20Color%20Analysis%20%7C%20Grand%20Rapids&query_place_id=ChIJT6GLHLitGYgRIn50RYevXwo",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=J3bciRAt7tSPvr73TW5ROw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=7.039258&pitch=0&thumbfov=100"
      },
      {
        "name": "Personal Palette Inc",
        "description": "Image consultant",
        "address": "493 E 8th St, Holland, Michigan",
        "phone": "+1 616-396-7226",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Palette%20Inc&query_place_id=ChIJn7s3n1HtGYgRMrEjoUo1Aao",
        "imageUrl": ""
      }
    ]
  },
  {
    "name": "New Jersey",
    "slug": "new-jersey",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Rachel Nachmias Image & Color Consulting",
        "description": "Consultant. Rating: 4.9/5 from 46 reviews",
        "address": "200 Chester Ave Suite 1 Unit 692, Moorestown, New Jersey",
        "phone": "(856) 437-0240",
        "website": "https://rachelnachmias.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Rachel%20Nachmias%20Image%20%26%20Color%20Consulting&query_place_id=ChIJfeocQ44riE8R4PMqa06AZZ4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOXa6QuBXGQS8FHBI54zfIHENMIMemt0iBmIwmV=w408-h272-k-no"
      },
      {
        "name": "Color Synergy - Personal Style Consultant",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "294 West End Ave, Ridgewood, New Jersey",
        "phone": "(201) 970-7016",
        "website": "https://colorsynergy.net/#navigation",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Synergy%20-%20Personal%20Style%20Consultant&query_place_id=ChIJITh3Ui3jwokRDpduuPz_d6Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOzfgsUCOhlyzF4xElePz5frLpWtYtmL8G5u1Al=w408-h544-k-no"
      },
      {
        "name": "An Eye For Detail, Inc",
        "description": "Image consultant. Rating: 5/5 from 65 reviews",
        "address": "53 Raven Dr, Morristown, New Jersey",
        "phone": "(201) 575-2626",
        "website": "http://www.eyefordetailnj.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=An%20Eye%20For%20Detail%2C%20Inc&query_place_id=ChIJi0Cj7fqlw4kRkGwsVQP-TcI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOSdilHJ7aRQfcUVtIfEKL2mE0cHa39VUcm2iRq=w408-h412-k-no"
      }
    ]
  },
  {
    "name": "Virginia",
    "slug": "virginia",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Styled In Worth: Color Analysis & Personal Styling",
        "description": "Image consultant. Rating: 5/5 from 29 reviews",
        "address": "",
        "phone": "(804) 543-7224",
        "website": "https://styledinworth.myflodesk.com/subscribe",
        "url": "https://www.google.com/maps/search/?api=1&query=Styled%20In%20Worth%3A%20Color%20Analysis%20%26%20Personal%20Styling&query_place_id=ChIJzzBX206Ls4kRZxrDmxt9Gvc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMNHqZXkOYaQSTmMDPT5ax0DOv9nLHdWa-UfmyB=w408-h612-k-no"
      },
      {
        "name": "Lily's Color Lab",
        "description": "Stylist. Rating: 4.9/5 from 71 reviews",
        "address": "1239 Broadway, New York, New York",
        "phone": "(202) 813-9687",
        "website": "https://lilyscolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lily's%20Color%20Lab&query_place_id=ChIJqxVpZRFbwokRPYp0fw-xRUI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7oWbYDctv5kuqOqA8g16iw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=91.2156&pitch=0&thumbfov=100"
      },
      {
        "name": "Chroma Color Analysis",
        "description": "Stylist",
        "address": "",
        "phone": "(541) 243-2711",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Chroma%20Color%20Analysis&query_place_id=ChIJLTnS1050hQURxxdQFsRTAwo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOOO58QM6LWFL7Md7yN0LZQT_HRJB4MGCAz5VEQ=w408-h272-k-no"
      },
      {
        "name": "Beauty For All Seasons",
        "description": "Consultant. Rating: 5/5 from 2 reviews",
        "address": "7525 Sage St, Manassas, Virginia",
        "phone": "(800) 770-0125",
        "website": "http://beautyforallseasons.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20For%20All%20Seasons&query_place_id=ChIJQ6udpB9dtokRDENnRTS8Ed4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=scWKi9mxnXQjqv_e3DdIhA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=87.11998&pitch=0&thumbfov=100"
      },
      {
        "name": "Color My Image Inc",
        "description": "Cosmetics store",
        "address": "5025 Backlick Rd # B, Annandale, Virginia",
        "phone": "(703) 354-9797",
        "website": "https://colormyimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20My%20Image%20Inc&query_place_id=ChIJI6op0tCyt4kRqPuxvlHt30A",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=_gL94fbqO4WSxJAk9OodYg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=355.18832&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Washington",
    "slug": "washington-state",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "Color Lab Salon by TSR",
        "description": "Hair salon. Rating: 4.8/5 from 585 reviews",
        "address": "1607 Connecticut Ave NW, Washington, District of Columbia",
        "phone": "(202) 800-8816",
        "website": "http://www.besthairdc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Lab%20Salon%20by%20TSR&query_place_id=ChIJScAJymq3t4kRoc8Am0vhOkg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOGCB_nE9NWahtieDv-btRv8av0W3NXDtqm3soc=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "Massachusetts",
    "slug": "massachusetts",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Studio St. John Personal Color Consulting",
        "description": "Image consultant",
        "address": "121 Berkshire Dr, Williamstown, Massachusetts",
        "phone": "(413) 884-3649",
        "website": "https://www.courtenaystjohncolors.com/contact-1/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20St.%20John%20Personal%20Color%20Consulting&query_place_id=ChIJmUes5hu64IkRLlpOHrlw8w4",
        "imageUrl": ""
      },
      {
        "name": "Boston Color Analysis",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "(716) 259-2128",
        "website": "https://bostoncoloranalysis.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Boston%20Color%20Analysis&query_place_id=ChIJG_R-6cujewMRX4Cl_Qe9u7Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPRaI5_NqKbD38IMSWFk6DVl-2IGE0OtJK60ium=w480-h240-k-no"
      },
      {
        "name": "Ana Harris Personal Stylist & Color Analysis in Massachusetts / Rhode Island",
        "description": "Image consultant. Rating: 5/5 from 55 reviews",
        "address": "2 Bank St, Fall River, Massachusetts",
        "phone": "",
        "website": "http://www.stylebyanaharris.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ana%20Harris%20Personal%20Stylist%20%26%20Color%20Analysis%20in%20Massachusetts%20%2F%20Rhode%20Island&query_place_id=ChIJYVuK1lxx44kRlkm93LMjuEc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN3VZe7qNeXS9v46WhWNmwtGp4qUMBkULPp9OP_=w426-h240-k-no"
      },
      {
        "name": "Splash of Color ACK",
        "description": "Image consultant. Rating: 5/5 from 52 reviews",
        "address": "350 Washington St Suite 314, Wellesley, Massachusetts",
        "phone": "",
        "website": "https://www.splashofcolorack.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Splash%20of%20Color%20ACK&query_place_id=ChIJlbRVs3SB44kRQlgKJl6tTP0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjOdBChY0sLNao15aK5je8N8PnlwE1Rt-wvj9z=w408-h306-k-no"
      },
      {
        "name": "Colors By Hope",
        "description": "Stylist. Rating: 4.9/5 from 11 reviews",
        "address": "",
        "phone": "(617) 475-0626",
        "website": "http://www.colorsbyhope.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20By%20Hope&query_place_id=ChIJaVcIKk5344kRHU5mFZ3hsoM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPhzcqyJcxKKj3SgwhbZ_dwQwBjoedRTLpk9UFm=w408-h361-k-no"
      }
    ]
  },
  {
    "name": "Arizona",
    "slug": "arizona",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Color Palette Salon",
        "description": "Hair salon. Rating: 5/5 from 25 reviews",
        "address": "2914 N Campbell Ave suite 128, Tucson, Arizona",
        "phone": "(520) 736-0287",
        "website": "https://thecolorpalettesalon.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Palette%20Salon&query_place_id=ChIJ5181FAlx1oYR520v75Z7y5U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOsBmc1UL96EQdf2aELlKXqD0AtjRqYucgqsL5G=w408-h541-k-no"
      },
      {
        "name": "PIVOT | Personal Stylist for Men & Men's Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 97 reviews",
        "address": "",
        "phone": "(619) 880-0321",
        "website": "http://www.pivotimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=PIVOT%20%7C%20Personal%20Stylist%20for%20Men%20%26%20Men's%20Image%20Consulting&query_place_id=ChIJyVDX0EkKK4cRLSHGkZRvjPo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipONR57f4mV4sNrvqr-o9xOgvbk9r--aREEcwo7l=w408-h408-k-no"
      },
      {
        "name": "Color Therapy Salon",
        "description": "Beauty salon. Rating: 4.6/5 from 73 reviews",
        "address": "1515 N Gilbert Rd Suite D109-110, Gilbert, Arizona",
        "phone": "(480) 745-0010",
        "website": "http://www.colortherapyaz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Therapy%20Salon&query_place_id=ChIJIccFJPWvK4cRRLtSYwmVmFM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO8PMhGI5ctW92vK6SvtqIU45BODpOunk7KYVDK=w408-h408-k-no"
      },
      {
        "name": "House of Colour Central Scottsdale - Brooke Svec",
        "description": "Stylist. Rating: 5/5 from 20 reviews",
        "address": "1411 E Orangewood Ave, Phoenix, Arizona",
        "phone": "(402) 759-1430",
        "website": "https://max.houseofcolour.co.uk/book/17314-BrookeSvec",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Central%20Scottsdale%20-%20Brooke%20Svec&query_place_id=ChIJgY0OJ7VtK4cRh3CkwyX9LuA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0yyYNRMaH2y8JvfG_BCk0zfRzODVw7aO7-B0y=w408-h272-k-no"
      },
      {
        "name": "House of Colour, Central Scottsdale, Lexi Rasmussen",
        "description": "Consultant",
        "address": "15425 N Greenway Hayden Loop SUITE 100, Scottsdale, Arizona",
        "phone": "(402) 432-1909",
        "website": "https://www.houseofcolour.com/stylists/lexi-rasmussen-scottsdale-phoenix-arizona",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%2C%20Central%20Scottsdale%2C%20Lexi%20Rasmussen&query_place_id=ChIJc-csErJ1K4cRX0PhC_QHDt8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=n5Alw3Q8I4cRd4Gvh1y0Ew&cb_client=search.gws-prod.gps&w=408&h=240&yaw=148.76448&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Indiana",
    "slug": "indiana",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Carmel",
        "description": "Image consultant",
        "address": "775 Eden Woods Pl, Carmel, Indiana",
        "phone": "(317) 506-8804",
        "website": "https://houseofcolour.com/stylists/angie-scott-carmel-indiana",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Carmel&query_place_id=ChIJ-byPoZ-tFIgRBj3UxfH8OQI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=fvH69-bAv3MPHtQ43s-SWA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=131.18477&pitch=0&thumbfov=100"
      },
      {
        "name": "SG Style Collective",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "2253 Greencroft St, Carmel, Indiana",
        "phone": "(317) 698-8234",
        "website": "https://sgstylecollective.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=SG%20Style%20Collective&query_place_id=ChIJpa_YJEWrFIgRbpKi-zgLrtI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPnA-VcQHeg64raMwV_kjLqTBjQYsB5Mf62jP9T=w408-h613-k-no"
      },
      {
        "name": "EASE color + style",
        "description": "Image consultant. Rating: 5/5 from 23 reviews",
        "address": "600 E Carmel Dr SUITE 116, Carmel, Indiana",
        "phone": "(317) 500-1844",
        "website": "https://www.easecolorandstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=EASE%20color%20%2B%20style&query_place_id=ChIJNecWPYqtFIgRZ5LSS-tvWIM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMIGPd5zIPJo0TU7qi50EnDIVwVk8jG5dFjaV4=w408-h408-k-no"
      },
      {
        "name": "The Color Coordinator",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(224) 358-7795",
        "website": "https://www.thecolorcoordinator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Coordinator&query_place_id=ChIJae0et8QrBkoRc0PqwKY1Lrs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipODIjgYG2z9C_Z-B8xXrcmT_YgWujIo5gBOF1rT=w408-h272-k-no"
      },
      {
        "name": "House of Colour New Palestine Indiana",
        "description": "Image consultant",
        "address": "5969 W 1050 N, Fountaintown, Indiana",
        "phone": "(317) 430-9945",
        "website": "https://amber-fitzgerald-style-llc.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20New%20Palestine%20Indiana&query_place_id=ChIJQ8bCupE5a4gRY0zlrjSxrFc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM1qin6kOKGetLV1NL4XTVYz0u3UTv5969QLHM7=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "Tennessee",
    "slug": "tennessee",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Franklin Central",
        "description": "Image consultant. Rating: 5/5 from 26 reviews",
        "address": "434 Maplegrove Dr, Franklin, Tennessee",
        "phone": "(615) 305-7396",
        "website": "https://www.houseofcolour.com/stylists/dana-guy-franklin-central-tennessee",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Franklin%20Central&query_place_id=ChIJcS24Q8d_ZIgR1JZQec2-3ts",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNRJCwnBkS6JvlFzh075W8W3ZL3cILZe7sEdFdV=w408-h725-k-no"
      },
      {
        "name": "House of Colour Oakland",
        "description": "Image consultant",
        "address": "",
        "phone": "(901) 687-8378",
        "website": "https://www.houseofcolour.com/stylists/marylynne-jones-oakland-tennessee",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Oakland&query_place_id=ChIJm4dX-QiouasR1Nt2Ggdb-40",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjzEqr_BQ5X9hAVTIQQLm3U-JRyu1BoRao95Kc=w408-h612-k-no"
      },
      {
        "name": "Color Analysis by Ronda",
        "description": "Stylist",
        "address": "136 S Main St, Goodlettsville, Tennessee",
        "phone": "(629) 279-1749",
        "website": "https://coloranalysisbyronda.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Analysis%20by%20Ronda&query_place_id=ChIJW7Ba5INFZIgRRzwYQd23XkI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPhv6_5Ju92G4WukHt1u5WrWOsuPfEfoagCDa2n=w408-h520-k-no"
      },
      {
        "name": "The Color Palette Salon & Boutique",
        "description": "Hair salon. Rating: 4.8/5 from 55 reviews",
        "address": "1948 Old Fort Pkwy, Murfreesboro, Tennessee",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Palette%20Salon%20%26%20Boutique&query_place_id=ChIJjwir5tQhZIgRkIG04n8NhV8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNXp0UDME2qyHaiZI4qK--95zMwHLX1rxkVfa8U=w408-h725-k-no"
      }
    ]
  },
  {
    "name": "Missouri",
    "slug": "missouri",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Dardenne Prairie",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "1676 Bryan Rd #112, Dardenne Prairie, Missouri",
        "phone": "(314) 370-1011",
        "website": "https://max.houseofcolour.co.uk/book/22350-AnnaPalmer",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Dardenne%20Prairie&query_place_id=ChIJQ-2k0VzR3ocRMZvAd1ULJuc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNg6x-3lEbsR-3pBUUenM1y2tgx4EBfMZ-MXM5v=w408-h272-k-no"
      },
      {
        "name": "Perfect Colors for You (color analysis & seasonal draping)",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "5308 E Riverview St, Springfield, Missouri",
        "phone": "",
        "website": "https://perfectcolorsforyou.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Perfect%20Colors%20for%20You%20(color%20analysis%20%26%20seasonal%20draping)&query_place_id=ChIJpQKr4Bp7z4cRP05EW_3CryI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=w7GZQ_zP85SwwTwh6QnzzA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=189.99274&pitch=0&thumbfov=100"
      },
      {
        "name": "House Of Colour Columbia & Mid Missouri",
        "description": "Image consultant",
        "address": "3198 County Rd 240, Kingdom City, Missouri",
        "phone": "(573) 489-0610",
        "website": "https://linktr.ee/hoc_ashleyshryock?fbclid=PAAaaqe_cOZZomCZZNH6HCfftW1f-k8HpBtvYsvphh3OZXrB3_Na3dYHoOzns",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20Of%20Colour%20Columbia%20%26%20Mid%20Missouri&query_place_id=ChIJXZFr9G7t3IcRkdC5BBoujM4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOfSRO5PfC1BLsMlf58Hb4JB6f42t9LG_0V6Zmb=w408-h306-k-no"
      },
      {
        "name": "Color Club KC",
        "description": "Stylist. Rating: 5/5 from 39 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorclubkc.trafft.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Club%20KC&query_place_id=ChIJzalewgyeLEgRaLB1usXCDNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDVoHmsVrTplLLGK-dUPmlcmxy6A0pvsBpt8Hy=w408-h259-k-no"
      }
    ]
  },
  {
    "name": "Maryland",
    "slug": "maryland",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour - Tarcela Knight",
        "description": "Image consultant",
        "address": "",
        "phone": "",
        "website": "https://www.instagram.com/houseofcolour_tarcelaknight/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Tarcela%20Knight&query_place_id=ChIJu89b96ikHkgRgxMpllg_b4Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPdf47n7PhaAnozyhJn_b8TcKYdrUeSpPYhjwps=w408-h272-k-no"
      },
      {
        "name": "True Color By Michelle",
        "description": "Hair salon. Rating: 5/5 from 1 reviews",
        "address": "15639 Emerald Way Suite 18, Bowie, Maryland",
        "phone": "(202) 642-6012",
        "website": "https://michellesadler.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=True%20Color%20By%20Michelle&query_place_id=ChIJrR5PL73tt4kRnBnenVUtkiI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPNMv7G8yq_GkBA815GgZB5UXCeZ0WsPPU4OJGi=w408-h408-k-no"
      },
      {
        "name": "Wardrobe Wizard of Baltimore",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "1620 Roundhill Rd, Baltimore, Maryland",
        "phone": "(410) 513-9496",
        "website": "http://www.wardrobewiz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Wardrobe%20Wizard%20of%20Baltimore&query_place_id=ChIJGXTbnuoEyIkR1keb0f-MnO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM2R9ey-YpidI6ue3ZzMSi5c6HvawR8grtLgD3B=w408-h664-k-no"
      }
    ]
  },
  {
    "name": "Wisconsin",
    "slug": "wisconsin",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Rose Colored Glasses Salon and Spa",
        "description": "Hair salon. Rating: 4.9/5 from 277 reviews",
        "address": "710 York St, Manitowoc, Wisconsin",
        "phone": "(920) 686-0600",
        "website": "http://www.rcgsalon.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=Rose%20Colored%20Glasses%20Salon%20and%20Spa&query_place_id=ChIJfx8ZIks2A4gRqIVMZoJV8L4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOPdvWqdnSHbCxIw7JPzBzHW2V4pCDY2YOmWzau=w408-h725-k-no"
      },
      {
        "name": "colors by carmen",
        "description": "Hair salon. Rating: 5/5 from 81 reviews",
        "address": "901 Main St Suite 103, Green Bay, Wisconsin",
        "phone": "(920) 659-5056",
        "website": "http://colorbycarmen.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=colors%20by%20carmen&query_place_id=ChIJVc_8_XflAogRGAp-uaoJaXA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPewchaR_0qJO9IRhnOf7Gj9nPcHIdFuMe_W8a6=w408-h554-k-no"
      },
      {
        "name": "Trucolors Salon & Spa",
        "description": "Hair salon. Rating: 4.6/5 from 66 reviews",
        "address": "3901 Webster Ave, Green Bay, Wisconsin",
        "phone": "(920) 337-2922",
        "website": "http://trucolorssalonspa.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Trucolors%20Salon%20%26%20Spa&query_place_id=ChIJk3bNMYP8AogRILwHi6KuhiE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM_tZVlwh84SjItpge9PZey0v85BdW9vT3djc50=w408-h306-k-no"
      },
      {
        "name": "House of Colour Madison",
        "description": "Image consultant. Rating: 4.9/5 from 44 reviews",
        "address": "3892 Pioneer Rd, Verona, Wisconsin",
        "phone": "(608) 698-9557",
        "website": "https://house-of-colour-madison.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Madison&query_place_id=ChIJ1ZG8WCOlB4gRgFt950oFO6E",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMqjrtObSQQ-P9rSaFeeVzkuWqTw9b5T9Mkb9dQ=w408-h408-k-no"
      },
      {
        "name": "House of Colour Madison - West",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "6018 Meadowood Dr, Madison, Wisconsin",
        "phone": "(608) 347-8115",
        "website": "https://max.houseofcolour.co.uk/book/27288-Melissa_Jenkins",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Madison%20-%20West&query_place_id=ChIJo2KAgP6vB4gRvTUNT8pjVCs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOTyzqzHEkdxm074tJuVUgQyIKO8WBDi8QrcFhJ=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Colorado",
    "slug": "colorado",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "ColorBook: 12 Season Color Analysis",
        "description": "Image Consultant. Rating: 5/5 from 67 reviews",
        "address": "1913 Mohawk St, Fort Collins, Colorado",
        "phone": "",
        "website": "https://www.shopcolorbook.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColorBook%3A%2012%20Season%20Color%20Analysis&query_place_id=ChIJW02z3NlLaYcR54u1-aP2plI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMPrpI7oRu3hEID50deKEmVJlfXiayGRPZvcV5W=w408-h272-k-no"
      },
      {
        "name": "Color Savvy Studio Personal Color Analysis",
        "description": "Image Consultant. Rating: 5/5 from 34 reviews",
        "address": "26 W Dry Creek Cir, Littleton, Colorado",
        "phone": "+1 586-747-1330",
        "website": "http://www.colorsavvystudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Savvy%20Studio%20Personal%20Color%20Analysis&query_place_id=ChIJA60hx05NDogR0R8LIIioW9s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNNuMpQiFWMi_1hvmDIzBLPiSnuWA-vHBmqayFA=w426-h240-k-no"
      },
      {
        "name": "Dress Your Colors",
        "description": "Image consultant. Rating: 5/5 from 63 reviews",
        "address": "8003 E 50th Dr, Denver, Colorado",
        "phone": "(862) 812-3912",
        "website": "http://dressyourcolors.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Dress%20Your%20Colors&query_place_id=ChIJVyWp4sh7bIcR2WUCT272HN0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOK-vfpUb_GbNHnUnjRmcgRaW_R5TIdy877GcYS=w412-h240-k-no"
      },
      {
        "name": "House of Colour - Loveland, Colorado",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "(402) 332-8803",
        "website": "https://www.houseofcolour.com/stylists/amy-smith-loveland-colorado",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Loveland%2C%20Colorado&query_place_id=ChIJma5sqXxUvYoR_6kkv_2IQQ4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP0_1NAZypDG__mQyT4474bR2AZN6Wo25lleZvV=w408-h408-k-no"
      },
      {
        "name": "House of Colour Central Colorado Springs",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "3745 Cottage Dr, Colorado Springs, Colorado",
        "phone": "(970) 759-4764",
        "website": "https://www.houseofcolour.com/stylists/amber-thorne-central-colorado-springs-colorado",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Central%20Colorado%20Springs&query_place_id=ChIJVbUOKnlNE4cR9O80-VwDjBA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOcDMcJYJW2i13V4jlyRSPai6ctTPBzRBezLz35=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Minnesota",
    "slug": "minnesota",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Stylery Color Analysis Studio",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(262) 994-6184",
        "website": "https://www.stylerycoloranalysis.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Stylery%20Color%20Analysis%20Studio&query_place_id=ChIJYeoYUso1s1IRl3qlBy0P6W0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPLi5ZjULmge9vKao2f8osezRLfKBwPCHywbyP_=w426-h240-k-no"
      },
      {
        "name": "Colour House Minneapolis",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "(262) 994-6184",
        "website": "https://www.colour-house-minneapolis.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20House%20Minneapolis&query_place_id=ChIJzfHiHROGjgwRJeBKHuEXSyU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOKOdQ0jSPS1mN0zSC3oj_bi_bbsuITfYD8temN=w408-h544-k-no"
      },
      {
        "name": "House of Colour - Southwest Minneapolis",
        "description": "Stylist. Rating: 4.8/5 from 9 reviews",
        "address": "5407 Excelsior Blvd Unit C, St Louis Park, Minnesota",
        "phone": "(952) 738-2281",
        "website": "https://www.houseofcolour.com/stylists-directory/marissa-ray-minneapolis-minnesota/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Southwest%20Minneapolis&query_place_id=ChIJf-Iv70sZ9ocRvx91n02QUCo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM98ZfD-CxHgEZ6zTiZUc0_xmxndfGXiJ2pqOtF=w408-h544-k-no"
      },
      {
        "name": "House of Colour New Prague",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "3001 267th St E, Webster, Minnesota",
        "phone": "(952) 261-2625",
        "website": "https://www.houseofcolour.com/stylists/danielle-simon-new-prague-minnesota",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20New%20Prague&query_place_id=ChIJpTOkfC63MiYRvznqFYcfA2w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNtPVpx75XOg8wE7H3vEmj8hVDNGPGVc6VffOii=w408-h544-k-no"
      },
      {
        "name": "House of Colour- Farmington & Lakeville",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "4538 198th Ct W, Farmington, Minnesota",
        "phone": "(320) 224-4594",
        "website": "https://www.houseofcolour.com/stylists/holly-bernatz-farmington-minnesota",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour-%20Farmington%20%26%20Lakeville&query_place_id=ChIJh1t6XoU19ocRDmLz-MhsxEo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipObkPIuFNorvrAVy6ugf55SYZCUAnwPBkqU8ghr=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "South Carolina",
    "slug": "south-carolina",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Closet Play Image",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(706) 691-4298",
        "website": "https://www.closetplay.biz/",
        "url": "https://www.google.com/maps/search/?api=1&query=Closet%20Play%20Image&query_place_id=ChIJ9Sc55ibT-YgRP66YVYr1Dig",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM8WiifctFw8K8I6YLoL8B30SLdWrRVg3dqaNer=w630-h240-k-no"
      },
      {
        "name": "Salon Luxe",
        "description": "Beauty salon. Rating: 4.8/5 from 22 reviews",
        "address": "185 W Henry St, Spartanburg, South Carolina",
        "phone": "(864) 285-3196",
        "website": "https://linktr.ee/salonluxe",
        "url": "https://www.google.com/maps/search/?api=1&query=Salon%20Luxe&query_place_id=ChIJ26Cp7B-eV4gRH4qFADJ-TCo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgFgrcAUBSz1ERtL5jXacSsCSuyykuHlqg3WLo=w408-h408-k-no"
      },
      {
        "name": "House of Colour- Park Circle",
        "description": "Health and beauty shop. Rating: 5/5 from 1 reviews",
        "address": "5133 East Liberty Park Circle, North Charleston, South Carolina",
        "phone": "(603) 785-3744",
        "website": "https://www.instagram.com/houseofcolour_parkcircle/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour-%20Park%20Circle&query_place_id=ChIJkSWvIHxl_ogRxAnAChbCU9I",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Ve25Ug7RE4AQphDe82DGlg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=8.96046&pitch=0&thumbfov=100"
      },
      {
        "name": "The Color Palette",
        "description": "Hair salon. Rating: 3.7/5 from 22 reviews",
        "address": "4025 N Kings Hwy # 24, Myrtle Beach, South Carolina",
        "phone": "(843) 457-9364",
        "website": "https://caseycolorpalette.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Palette&query_place_id=ChIJKxW-eIlpAIkRahbKEjCH4Tw",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Bizxez8MqxiE-zVLeBll2w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=133.40598&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Alabama",
    "slug": "alabama",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Color Lab",
        "description": "Beauty salon. Rating: 4.9/5 from 34 reviews",
        "address": "3036 Healthy Way #112, Birmingham, Alabama",
        "phone": "(786) 872-1860",
        "website": "http://www.colorlabstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Lab&query_place_id=ChIJG7Ugu4UXiYgRPMc95uOLxEE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMgKRmPbgfuOiFQLtxehVlcSGy2-UMWNW7P_Gy7=w408-h408-k-no"
      },
      {
        "name": "Amanda C Stylist",
        "description": "Hair salon",
        "address": "2816 Culver Rd, Mountain Brook, Alabama",
        "phone": "(205) 259-8975",
        "website": "https://www.facebook.com/amandacstylist",
        "url": "https://www.google.com/maps/search/?api=1&query=Amanda%20C%20Stylist&query_place_id=ChIJdT52LYYZiYgRawXbhVQTmJU",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=rzGMpDYjMso78n4Qv174gA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=251.30872&pitch=0&thumbfov=100"
      },
      {
        "name": "Aveda Arts & Sciences Institute Birmingham",
        "description": "Beauty school. Rating: 4.6/5 from 230 reviews",
        "address": "3200 Galleria Cir, Birmingham, Alabama",
        "phone": "(205) 769-3500",
        "website": "https://avedaarts.edu/locations/birmingham/",
        "url": "https://www.google.com/maps/search/?api=1&query=Aveda%20Arts%20%26%20Sciences%20Institute%20Birmingham&query_place_id=ChIJiQ75tSIiiYgR0Iz15MHSYD0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNS5yP4VUjqVOiTI4td194V_58QxlXEv4-GQqMc=w408-h612-k-no"
      },
      {
        "name": "Color Melt Studio",
        "description": "Hair salon. Rating: 4.5/5 from 8 reviews",
        "address": "825 39th St S # B, Birmingham, Alabama",
        "phone": "(205) 396-4714",
        "website": "https://colormeltstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Melt%20Studio&query_place_id=ChIJpUzA1jsbiYgRQGmBPyWYU2Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMNUlxrn3LOyUoDYONUBBb2J1pegJqAelrEQI67=w408-h544-k-no"
      },
      {
        "name": "ChicMadeSimple",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "3105 Sunview Dr, Birmingham, Alabama",
        "phone": "(205) 567-5374",
        "website": "http://www.chicmadesimple.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ChicMadeSimple&query_place_id=ChIJSd3K74sXiYgRlHT-R7u4css",
        "imageUrl": ""
      }
    ]
  },
  {
    "name": "Louisiana",
    "slug": "louisiana",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Alexandria",
        "description": "Image Consultant. Rating: 5/5 from 1 reviews",
        "address": "1106 Texas Ave Suite B, Alexandria, Louisiana",
        "phone": "",
        "website": "https://max.houseofcolour.co.uk/book/24391-KimberlyPhillips",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Alexandria&query_place_id=ChIJA8mif7y1OoYRoOiPtcA2V5A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNr-ITlK3FbYIXRF6gtpzSOh5nVdj0QwxTRV9gq=w408-h272-k-no"
      },
      {
        "name": "Color Harmony at Home",
        "description": "Consultant. Rating: 5/5 from 101 reviews",
        "address": "1161 Springwater Dr, Mandeville, Louisiana",
        "phone": "+1 504-458-1416",
        "website": "https://colorharmonyathome.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Harmony%20at%20Home&query_place_id=ChIJe03-mB5bJ4YR58pt76NL2MY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPtlIm7dVRSAqF_OZUU4Y6-GOb5WgYSu-yFnV_y=w648-h240-k-no"
      },
      {
        "name": "House of Colour New Orleans West",
        "description": "Stylist",
        "address": "",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/briana-guillory-new-orleans-west-louisiana",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20New%20Orleans%20West&query_place_id=ChIJQSVj01JQ7gURt1HI2_YbTVU",
        "imageUrl": ""
      },
      {
        "name": "Carl Brue Stylist",
        "description": "Image consultant. Rating: 4.7/5 from 7 reviews",
        "address": "8738 Quarters Lake Rd # 10, Baton Rouge, Louisiana",
        "phone": "(225) 303-8172",
        "website": "https://stylist.carlbrue.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Carl%20Brue%20Stylist&query_place_id=ChIJA7zbHi-jJoYR0qqV2a5qTRY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMoyOmstWNzngbxiBycio5oT5w-vnJHeeH7-wCh=w408-h281-k-no"
      }
    ]
  },
  {
    "name": "Kentucky",
    "slug": "kentucky",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Color By Carrie",
        "description": "Permanent make-up clinic. Rating: 4.9/5 from 13 reviews",
        "address": "230 3rd St, Henderson, Kentucky",
        "phone": "(270) 724-2970",
        "website": "http://www.colorbycarrie.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20By%20Carrie&query_place_id=ChIJQVJwO98ocIgRyJz24KS8dC0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNuwMcweVwX9yNSp-G8frTAq4uaQznTI8okgKH4=w408-h286-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "House of Colour Louisville",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "11706 Main St Suite 2, Louisville, Kentucky",
        "phone": "(502) 439-6347",
        "website": "https://house-of-colour-louisville.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Louisville&query_place_id=ChIJtfnsVSmfaYgR4JcnpjmuX4g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNBn0cYP42yJJjg_2yK8FwJaGZB4Ao5hHSAh00=w408-h408-k-no"
      },
      {
        "name": "Expressive Image Solutions-Image Consultant",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "8149 New La Grange Rd Suite 101, Hurstbourne, Kentucky",
        "phone": "(502) 905-7841",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Expressive%20Image%20Solutions-Image%20Consultant&query_place_id=ChIJAxG5M9-faYgRPE6XJeS0TYw",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=YoRj6GTfnKRUn11bFdv7fw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=303.54364&pitch=0&thumbfov=100"
      },
      {
        "name": "The Right Image",
        "description": "Image consultant. Rating: 4/5 from 1 reviews",
        "address": "310 Turpin Dr, Richmond, Kentucky",
        "phone": "(859) 544-5130",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Right%20Image&query_place_id=ChIJE4KFi-r_QogRCdKgZMhHyMc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=okHruWcgYqhbwXLQXOcQrg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=208.63788&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Oregon",
    "slug": "oregon",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "ColorStylePDX",
        "description": "Image consultant. Rating: 4.9/5 from 37 reviews",
        "address": "1420 NW Lovejoy St #418, Portland, Oregon",
        "phone": "(360) 903-3659",
        "website": "http://www.colorstylepdx.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColorStylePDX&query_place_id=ChIJEdtApv4JlVQRMmwKvMINovw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMH56qxiaIXuMwx0GRHpF0qnjazsOA-Po0gfUoU=w408-h497-k-no"
      },
      {
        "name": "Color Palette Salon",
        "description": "Beauty salon. Rating: 5/5 from 26 reviews",
        "address": "10741 SW 49th Ave, Portland, Oregon",
        "phone": "(971) 322-3431",
        "website": "http://thecolorpalettesalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Palette%20Salon&query_place_id=ChIJVVWpAqIMlVQRR8RxLEXAv1U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPKjNG-vng1NHvi1FaY3FSsy3jYdNW-qxjuT46c=w408-h544-k-no"
      },
      {
        "name": "Lissa Liverman | House of Colour",
        "description": "Stylist",
        "address": "131 NW Hawthorne Ave STE 102, Bend, Oregon",
        "phone": "(830) 998-1978",
        "website": "https://www.instagram.com/houseofcolour_lissaliverman/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lissa%20Liverman%20%7C%20House%20of%20Colour&query_place_id=ChIJv9jqzDvHuFQRA-uiqE-MXHs",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=emyQzwcNOxmG-20ye6o1Ow&cb_client=search.gws-prod.gps&w=408&h=240&yaw=188.53407&pitch=0&thumbfov=100"
      },
      {
        "name": "colormoxie",
        "description": "Consultant. Rating: 5/5 from 76 reviews",
        "address": "",
        "phone": "(503) 422-0868",
        "website": "http://www.colormoxienw.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=colormoxie&query_place_id=ChIJwSOiIM52lVQRo35G1f5iCNU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP7JBmeqCmhElHXWNscBwtxov8nYoUnN_2KlV55=w408-h510-k-no"
      },
      {
        "name": "Colour by April",
        "description": "Beauty salon",
        "address": "473 3rd St, Lake Oswego, Oregon",
        "phone": "(503) 878-1277",
        "website": "https://colourbyapril.wixsite.com/mysite",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20by%20April&query_place_id=ChIJS7P1kfV1lVQR8R5h8z1uHzA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=-Cd4qAZUwkyUhPdrpEKmCg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=272.6825&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Oklahoma",
    "slug": "oklahoma",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Norman - Cassidy Bliss & Anna Parker",
        "description": "Image consultant. Rating: 5/5 from 70 reviews",
        "address": "",
        "phone": "(405) 821-2184",
        "website": "http://www.hocnorman.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Norman%20-%20Cassidy%20Bliss%20%26%20Anna%20Parker&query_place_id=ChIJ15yjrA9M4o8REQgiUW48x2c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP86uA5Hx5_jzGuivKtxOu2fKxH2RXZRsgZAIU3=w408-h394-k-no"
      },
      {
        "name": "House of Colour",
        "description": "Image consultant",
        "address": "2886 Hart Ave, Melbourne, Iowa",
        "phone": "(641) 485-9724",
        "website": "https://thenewspacedsm.com/our-stylists/#april-c",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour&query_place_id=ChIJSbtZiRar74cR0r4CaQCgfnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMExI_rB5bhU3HohMn5VIF6kkMF0xXaMFYgDLn3=w408-h610-k-no"
      },
      {
        "name": "Cultivate Color: Personal Color Analysis",
        "description": "Image consultant. Rating: 5/5 from 12 reviews",
        "address": "116 N Main St, Altus, Oklahoma",
        "phone": "(580) 482-8888",
        "website": "https://www.cultivatecolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Cultivate%20Color%3A%20Personal%20Color%20Analysis&query_place_id=ChIJX3GnCMllq4cRCd_Xmi3yNbY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMmRbapi0xCDuj4ba7xLbfZQcygmwrrgNVTC9rf=w408-h408-k-no"
      },
      {
        "name": "Coated in Color",
        "description": "Stylist",
        "address": "",
        "phone": "(918) 625-8954",
        "website": "https://linktr.ee/coated.in.color",
        "url": "https://www.google.com/maps/search/?api=1&query=Coated%20in%20Color&query_place_id=ChIJbXtcfSPpGWYRxomdOGmy19M",
        "imageUrl": ""
      },
      {
        "name": "Melanie Harbin",
        "description": "Beauty salon",
        "address": "3904 S Detroit Ave, Tulsa, Oklahoma",
        "phone": "(918) 607-6441",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Melanie%20Harbin&query_place_id=ChIJXZaUMS6TtocR9X1vIahip8U",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Op2_jDJdkzj08Oc-W6GQig&cb_client=search.gws-prod.gps&w=408&h=240&yaw=266.0674&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Connecticut",
    "slug": "connecticut",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Lash Lab byPatrice, PLLC",
        "description": "Beauty salon. Rating: 4.8/5 from 121 reviews",
        "address": "The Lash Lab ByPatrice, PLLC, 701 Cottage Grove Rd Suite D-220, Bloomfield, Connecticut",
        "phone": "(860) 263-8150",
        "website": "http://www.thelashlabbypatrice.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Lash%20Lab%20byPatrice%2C%20PLLC&query_place_id=ChIJQWbn-VVV5okRO4idWG6bm0A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNDXK4XNQ_wHVtEfnl5jBWbEoXrF7x-sKEw0mD-=w408-h405-k-no"
      },
      {
        "name": "Headline-Hair Designers",
        "description": "Beauty salon. Rating: 4.7/5 from 72 reviews",
        "address": "45 Pearl St #2306, Hartford, Connecticut",
        "phone": "",
        "website": "http://www.headlinect.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Headline-Hair%20Designers&query_place_id=ChIJb-rGzWRT5okRe2aA3evzY64",
        "imageUrl": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhGL3Xw-7dOxJAgYBJuXyLEn1GMmKVnQlt9Yr8_J9gdY3RaZ_vsXgyCCw8ITXPNsAIVklqzzqhNBWbnUnfEPMy-oAfzJw-xH8uV5cU6KQsn3CrFrdCiCxIe0b04ZroLb1hE9LExhW8r0N9XkzQtbpG05w1KlFqA_oBgA-aMpulMn-1I0YsyvBo0lw7sPyysJaNHvZIw=w408-h271-k-no"
      }
    ]
  },
  {
    "name": "Utah",
    "slug": "utah",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Color Compass",
        "description": "Image consultant. Rating: 5/5 from 42 reviews",
        "address": "650 N Main St Suite 102, North Salt Lake, Utah",
        "phone": "(801) 706-4699",
        "website": "http://www.mycolorcompass.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Compass&query_place_id=ChIJnx9rTF_3UocRFtjOUsVM8MA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNaLOffgSo3JApiTO62Ot1LK3x3lwiytmxiVpNK=w408-h544-k-no"
      },
      {
        "name": "Color Clarity Color Analysis",
        "description": "Image consultant",
        "address": "",
        "phone": "",
        "website": "http://www.color-clarity.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Clarity%20Color%20Analysis&query_place_id=ChIJLx_3Ky3vJi0RTGK425KX-ws",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMqO0jz-erJHK4Xqi_uioIxuK3_QccNC4Ir0sPh=w408-h408-k-no"
      },
      {
        "name": "The Confident Hue",
        "description": "Image consultant",
        "address": "6414 Stone Ridge Dr, Mountain Green, Utah",
        "phone": "(801) 209-4000",
        "website": "http://theconfidenthue.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Confident%20Hue&query_place_id=ChIJnSIGa8GvU4cRWBcy182v78g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO7b42OCcWroL8_heO9Ov0YiYb7h7sWlQeF2xRj=w408-h447-k-no"
      },
      {
        "name": "The Depot Salon",
        "description": "Beauty Salon. Rating: 4.8/5 from 81 reviews",
        "address": "68 W 100 N, Logan, Utah",
        "phone": "+1 435-294-3079",
        "website": "https://thedepotsalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Depot%20Salon&query_place_id=ChIJVVUJ1Th-VIcRovplqr6sMAw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOmQ4VISPF4ZnFoMkatI_rItLgNekFOg4M6Tixv=w408-h300-k-no"
      },
      {
        "name": "Mary’s Color Studio",
        "description": "Image consultant",
        "address": "2830 Marrcrest N Cir, Provo, Utah",
        "phone": "",
        "website": "https://www.etsy.com/shop/MarysColorStudio",
        "url": "https://www.google.com/maps/search/?api=1&query=Mary%E2%80%99s%20Color%20Studio&query_place_id=ChIJ3RYs7LmRTYcRJ4Ixnwvu6z8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMUzu9a7EPhRuCiFupQCstHl82sjCWL2XaUKMlZ=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Iowa",
    "slug": "iowa",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Ames",
        "description": "Image consultant. Rating: 5/5 from 23 reviews",
        "address": "4611 Mortensen Rd # 107, Ames, Iowa",
        "phone": "",
        "website": "https://www.kimjensenstyle.com/hoc",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Ames&query_place_id=ChIJebfjjDt57ocRh6PP7Icytg8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNantoH9fzYYpYtSB3n6Q8YheHeISDeJDt0PEQG=w408-h541-k-no"
      },
      {
        "name": "The New Space House of Colour Des Moines",
        "description": "Stylist. Rating: 5/5 from 7 reviews",
        "address": "5465 Mills Civic Pkwy #235, West Des Moines, Iowa",
        "phone": "(515) 223-5256",
        "website": "https://thenewspacedsm.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20New%20Space%20House%20of%20Colour%20Des%20Moines&query_place_id=ChIJvc2ciPQh7IcRdK_fIvn0MK0",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=2UZu-iZoxeLAYIpj3_Gaew&cb_client=search.gws-prod.gps&w=408&h=240&yaw=80.449104&pitch=0&thumbfov=100"
      },
      {
        "name": "Hue & Harmony Color Bar",
        "description": "Image consultant. Rating: 5/5 from 29 reviews",
        "address": "",
        "phone": "(515) 803-9764",
        "website": "http://www.hueandharmonycolorbar.org/",
        "url": "https://www.google.com/maps/search/?api=1&query=Hue%20%26%20Harmony%20Color%20Bar&query_place_id=ChIJcZzSUvCD7ocRkJFOXMODno0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNdUtOvN43WVvKP416zBqjanvs0xxn1iG5dAGf-=w408-h408-k-no"
      },
      {
        "name": "House of Colour Independence",
        "description": "Image consultant. Rating: 5/5 from 17 reviews",
        "address": "2300 Swan Lake Blvd Suite 302, Independence, Iowa",
        "phone": "",
        "website": "https://linktr.ee/hoc.crystalblin?utm_source=linktree_admin_share",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Independence&query_place_id=ChIJx55eP2Uv5YcRtiWPZHqbdJc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM48nWZjPENMzQC44ya9PmyApHzSVyaHubMSytI=w408-h244-k-no"
      },
      {
        "name": "House of Colour",
        "description": "Image consultant",
        "address": "2886 Hart Ave, Melbourne, Iowa",
        "phone": "(641) 485-9724",
        "website": "https://thenewspacedsm.com/our-stylists/#april-c",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour&query_place_id=ChIJSbtZiRar74cR0r4CaQCgfnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMExI_rB5bhU3HohMn5VIF6kkMF0xXaMFYgDLn3=w408-h610-k-no"
      }
    ]
  },
  {
    "name": "Nevada",
    "slug": "nevada",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Lynda Jean Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 27 reviews",
        "address": "360 Bloor St E, Toronto, Ontario",
        "phone": "+1 416-960-1333",
        "website": "https://www.lyndajean.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lynda%20Jean%20Image%20Consulting&query_place_id=ChIJ2yeBqBzL1IkRfufJhmpNkqA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN6YFDLEAhuzG59feKhgszJy-X0OU-lwXNdp5Iv=w408-h270-k-no"
      },
      {
        "name": "Styling Insider",
        "description": "Image consultant. Rating: 5/5 from 41 reviews",
        "address": "",
        "phone": "(702) 339-8980",
        "website": "http://www.stylinginsider.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Styling%20Insider&query_place_id=ChIJI6D5a4fByIARWkKOTsEo5Ag",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO4fg0NyisC9nNTPQJyGjTQpYp4W7gcJKuBzOqh=w408-h408-k-no"
      },
      {
        "name": "Cristina's Color Experts",
        "description": "Beauty salon. Rating: 4.7/5 from 14 reviews",
        "address": "10075 S Eastern Ave #115, Henderson, Nevada",
        "phone": "(702) 878-4247",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Cristina's%20Color%20Experts&query_place_id=ChIJw7Q2FhLOyIARs0Nx6p4Gl1o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNETYwycRH3HVlOxy_01mtMQA4pWQsZai5LDJzc=w408-h544-k-no"
      },
      {
        "name": "Joni Rankin Color Specialists",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "4280 S Hualapai Wy suite 200, Las Vegas, Nevada",
        "phone": "(702) 528-3315",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Joni%20Rankin%20Color%20Specialists&query_place_id=ChIJCSBjAvXHyIARIypTN01iuZ8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPdd5b8mm6GotSXHddhOsbMxizjK7CF0y1waiWj=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Arkansas",
    "slug": "arkansas",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Batesville",
        "description": "Image consultant",
        "address": "60 Woodlands Cir, Batesville, Arkansas",
        "phone": "",
        "website": "https://hocamandareaves.as.me/schedule.php",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Batesville&query_place_id=ChIJecDWGALb04cR_5E1THdT_vc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=76eq25W_p_t9Hb6rfrcmEQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=265.65054&pitch=0&thumbfov=100"
      },
      {
        "name": "A Design & Color Salon",
        "description": "Beauty salon. Rating: 4.9/5 from 17 reviews",
        "address": "1515 Market St #3, Little Rock, Arkansas",
        "phone": "(501) 217-3500",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=A%20Design%20%26%20Color%20Salon&query_place_id=ChIJF9SFLEGh0ocRSe2eSi-p4_k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOfWrpe67D3S3fFzmeUcXna36rUE4FJsusaEAsK=w408-h240-k-no-pi0-ya234.97717-ro-0-fo100"
      },
      {
        "name": "House of Colour North Fayetteville, AR",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "",
        "phone": "+1 312-848-1832",
        "website": "https://max.houseofcolour.co.uk/book/28927-Rachael_Wilson",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20North%20Fayetteville%2C%20AR&query_place_id=ChIJjWSLsgdryYcROEQN0gJbLKM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM4_vONWIrYcQAK_s0Tmqlc1fd2_LP3iSU6B91m=w408-h434-k-no"
      },
      {
        "name": "Distinct Colour & Design",
        "description": "Hair salon. Rating: 5/5 from 1 reviews",
        "address": "5 N Halsted Cir Suite 3, Rogers, Arkansas",
        "phone": "+1 479-644-1915",
        "website": "https://m.facebook.com/pages/category/Hair-Salon/Distinct-Colour-Design-819761994779355/",
        "url": "https://www.google.com/maps/search/?api=1&query=Distinct%20Colour%20%26%20Design&query_place_id=ChIJm2MCEe8RyYcR2BGXOgEkInQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOe--IrO6FFPGJb5gMutJgR45dtDT9FBJrqcW6w=w408-h581-k-no"
      },
      {
        "name": "Get Snappin'",
        "description": "Fashion accessories store. Rating: 5/5 from 40 reviews",
        "address": "1700 Dodson Ave, Fort Smith, Arkansas",
        "phone": "",
        "website": "http://letsgetsnappin.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Get%20Snappin'&query_place_id=ChIJYewqjMexy4cRde_-IB8gkrM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPEl8DXOfVdYgD-CaJ3VPqxssm5xE1eQpYI_9dp=w408-h612-k-no"
      }
    ]
  },
  {
    "name": "Mississippi",
    "slug": "mississippi",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "House of Colour Mississippi Gulf Coast West",
        "description": "Image consultant. Rating: 5/5 from 17 reviews",
        "address": "524 Courthouse Rd Suite #5, Gulfport, Mississippi",
        "phone": "(504) 512-5299",
        "website": "https://www.houseofcolour.com/roxannedenicola",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Mississippi%20Gulf%20Coast%20West&query_place_id=ChIJOUqFyU8XnIgRknmy2BlZxsM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOod3xqKUt0DpNtZgte2OPQz6dZQKDVBgKdEvyY=w408-h408-k-no"
      },
      {
        "name": "Merle Norman Cosmetic Studio",
        "description": "Beauty salon",
        "address": "600 MS-16, Carthage, Mississippi",
        "phone": "(601) 267-4865",
        "website": "https://www.merlenorman.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Merle%20Norman%20Cosmetic%20Studio&query_place_id=ChIJsYt4eOs3gogRNLhc5THXd0c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPZA-oqRqG0Q-skCWAKgVupQaJLpiP1sYE4LMhf=w426-h240-k-no"
      },
      {
        "name": "House of Colour Golden Triangle",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "500 N Montgomery St Suite K, Starkville, Mississippi",
        "phone": "(662) 364-5199",
        "website": "https://www.houseofcolour.com/stylists/julie-tompkins-golden-triangle-mississippi",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Golden%20Triangle&query_place_id=ChIJC00An0I1gYgR_Ri_Eq2F4QI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=ujaztNXDgQm6qjkW6fTI6w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=88.756516&pitch=0&thumbfov=100"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Kansas",
    "slug": "kansas",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour, Kansas City",
        "description": "Image consultant. Rating: 4.9/5 from 161 reviews",
        "address": "2645 W 139th Terrace, Leawood, Kansas",
        "phone": "(913) 689-8501",
        "website": "http://www.hockansascity.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%2C%20Kansas%20City&query_place_id=ChIJNxv832nCwIcR3qLeOyZ3HUs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPks1pcKSzYY6i5GFvXu7b7N1WdwN6GK_eCydOV=w408-h408-k-no"
      },
      {
        "name": "Radiant Color Studio",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "(913) 709-2114",
        "website": "https://radiantcolorstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Radiant%20Color%20Studio&query_place_id=ChIJkWcPiDyXwIcRTlnv-DDhvwo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMNtfXJTLYOp0zPU6htkqZYv4muwtGFwwHF9ZLH=w408-h612-k-no"
      },
      {
        "name": "House of Colour Topeka",
        "description": "Image consultant",
        "address": "7622 SW 19th St, Topeka, Kansas",
        "phone": "(785) 438-0398",
        "website": "http://houseofcolourtopeka.as.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Topeka&query_place_id=ChIJ85IQS339vocR580LoCyhp14",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=PMtogQ9ahT1fYYo0hL__IQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=1.6155974&pitch=0&thumbfov=100"
      },
      {
        "name": "Platinum & Company",
        "description": "Beauty salon. Rating: 4.6/5 from 596 reviews",
        "address": "227 Blue Earth Pl #171, Manhattan, Kansas",
        "phone": "(785) 539-7621",
        "website": "http://platinumandcompany.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Platinum%20%26%20Company&query_place_id=ChIJCzbhjXnSvYcRrGuEP5z6ZL4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOjcso9JiQHWUQHHGwksmLU-qBpJAOB4YN7uHhx=w408-h408-k-no"
      },
      {
        "name": "Bellus Academy",
        "description": "Beauty school. Rating: 4.8/5 from 1311 reviews",
        "address": "1130 Westloop Pl, Manhattan, Kansas",
        "phone": "(785) 539-1837",
        "website": "https://bellusacademy.edu/location/manhattan-ks/?utm_source=google&utm_medium=organic&utm_campaign=gbp-listing",
        "url": "https://www.google.com/maps/search/?api=1&query=Bellus%20Academy&query_place_id=ChIJATkqmLnNvYcRalm2wCeUaFc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOWfsTqzCBTwI8EkQTEXHdHKomGh73B9B7jCi8F=w426-h240-k-no"
      }
    ]
  },
  {
    "name": "New Mexico",
    "slug": "new-mexico",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Curl Up & Dye",
        "description": "Beauty salon. Rating: 4.8/5 from 92 reviews",
        "address": "141 Pleasant St, Farmington, Maine",
        "phone": "(207) 778-3673",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Curl%20Up%20%26%20Dye&query_place_id=ChIJZyYmczSasUwROsA21VYHDpY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNLIM8z9jmslaaOEd6LkYHZz826pH5Sshn1RCGV=w408-h725-k-no"
      },
      {
        "name": "House of Colour Northeast Albuquerque",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "(505) 226-6036",
        "website": "https://max.houseofcolour.co.uk/book/23914-TerynMartinez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Northeast%20Albuquerque&query_place_id=ChIJCZvCdOzbsYMR3x4Gw8eeThI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNEXtPMMUHllNH-PyEvOEKytsDXexHfzGc9WkbV=w408-h408-k-no"
      },
      {
        "name": "House of Colour Four Corners",
        "description": "Image consultant",
        "address": "5708 Jack Rabbit Jct, Farmington, New Mexico",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Four%20Corners&query_place_id=ChIJuXGBaXeJO4cRzlZvkOkSN1M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPdVm498NMtMrHEUeoMYoSRxXnK4q-RyzAQEfcX=w408-h272-k-no"
      },
      {
        "name": "The Color Bar",
        "description": "Hair salon. Rating: 4.8/5 from 75 reviews",
        "address": "21 Bisbee Ct H, Santa Fe, New Mexico",
        "phone": "(505) 310-4903",
        "website": "http://thecolorbarsantafe.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Bar&query_place_id=ChIJ50bBHWJaGIcR0Ri1l4swuRg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMoDZaSw3Qf8jIgyLQefl2frNb5KwLsEhaMaEdQ=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "Nebraska",
    "slug": "nebraska",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour West Omaha",
        "description": "Image consultant. Rating: 4.9/5 from 16 reviews",
        "address": "12320 Emmet St, Omaha, Nebraska",
        "phone": "(402) 672-1122",
        "website": "https://houseofcolour.com/stylists/darcie-zauha-west-omaha-nebraska",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20West%20Omaha&query_place_id=ChIJfcZRFj3vk4cRnCb80WR8n3g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHc7f9ooBrqpbbJsMljn0-e3hkZxpiU6j8BMH_=w408-h408-k-no"
      },
      {
        "name": "Color Coated",
        "description": "Image consultant. Rating: 5/5 from 17 reviews",
        "address": "5930 S 58th St Suite J, Lincoln, Nebraska",
        "phone": "",
        "website": "http://www.getcolorcoated.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Coated&query_place_id=ChIJIysPEoOXlocRK61V9fcCZb4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN9cHKcFBYh0ZcX4LYFiKc7kAcvY0M0ibEGoRwC=w408-h272-k-no"
      },
      {
        "name": "HOUSE OF COLOUR - LINCOLNReinwald",
        "description": "Image consultant",
        "address": "4019 S 48th St, Lincoln, Nebraska",
        "phone": "(402) 570-9000",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=HOUSE%20OF%20COLOUR%20-%20LINCOLNReinwald&query_place_id=ChIJVVWl05eVlocRGzzo5eJ8YIs",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=jMy7iP_--_A5cGGUKrga-w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=268.2103&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour- Nicole Lindhorst",
        "description": "Image consultant. Rating: 5/5 from 40 reviews",
        "address": "2507 13th St, Columbus, Nebraska",
        "phone": "(402) 910-8525",
        "website": "https://campsite.bio/houseofcolournicolelindhorst",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour-%20Nicole%20Lindhorst&query_place_id=ChIJJbI68GvrkIcRRimyuy09aWQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP7ATP6bwZOYWLMsbT5rb_zqbzBrIa1L2AK48fo=w408-h342-k-no"
      },
      {
        "name": "HOUSE OF COLOUR - MICHELLE OLSON",
        "description": "Stylist",
        "address": "650 Chestnut St #1, Hickman, Nebraska",
        "phone": "(402) 858-8880",
        "website": "http://www.thestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=HOUSE%20OF%20COLOUR%20-%20MICHELLE%20OLSON&query_place_id=ChIJHdlkSvqRlocRlhiuUYHeSBA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=DXS34o4nWxU-hX-blRcsUQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=141.82426&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "West Virginia",
    "slug": "west-virginia",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Uniquely You Color",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "20 Blackburn Ct, Charles Town, West Virginia",
        "phone": "(713) 443-4432",
        "website": "https://www.uniquelyyoucolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Uniquely%20You%20Color&query_place_id=ChIJS6Z6XOIDtokRrbsTu_ypBv4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNDLhhwIoUrc6rJr_4ylqAxO4iTO8rVsrw65WDs=w408-h402-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "The Colour Bar",
        "description": "Hair salon. Rating: 4.7/5 from 110 reviews",
        "address": "498 Buffington St, Huntington, West Virginia",
        "phone": "(681) 888-5941",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Colour%20Bar&query_place_id=ChIJq9PDkmgHRogRagCCElnKJsM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCJZ4qHZUcRO3HKEYgIFb-eXRh4VPgCo6BNAkv=w408-h544-k-no"
      },
      {
        "name": "Rita Daisy | Personal Stylist",
        "description": "Stylist",
        "address": "",
        "phone": "",
        "website": "https://www.theritadaisy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Rita%20Daisy%20%7C%20Personal%20Stylist&query_place_id=ChIJmUDm5_fUA2ARkoP5wmZ8-wg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMm27aUmhGK_b1AvE3Af3DmEb8yXPzghgGiILM=w408-h408-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Idaho",
    "slug": "idaho",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Jackie K",
        "description": "Image consultant. Rating: 5/5 from 14 reviews",
        "address": "3113 Limelight lane, Pocatello, Idaho",
        "phone": "(505) 402-1013",
        "website": "https://hocjackiek.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Jackie%20K&query_place_id=ChIJA_5acwpPVVMRgoxLLMWEwUE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMUzhz3xxQl748sUl7m1ux0EZ7a2Uga389o6uHy=w408-h544-k-no"
      },
      {
        "name": "Colour & Co.",
        "description": "Beauty salon. Rating: 5/5 from 13 reviews",
        "address": "C2, 16 E Bullion St B, Hailey, Idaho",
        "phone": "(208) 450-9876",
        "website": "https://www.vagaro.com/colourandco60",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20%26%20Co.&query_place_id=ChIJI2piga_Vq1QRO3UsWxQbYxY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO80LRoy0TKgNZfR0CLr5SZ-hhb5epyY5S57pAo=w408-h306-k-no"
      },
      {
        "name": "Total Image Salon",
        "description": "Beauty salon. Rating: 4.6/5 from 20 reviews",
        "address": "102 Courthouse Dr, Salmon, Idaho",
        "phone": "(208) 993-1309",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Total%20Image%20Salon&query_place_id=ChIJu9fmUcA1WFMRLMMVuq1apOk",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=2SIs7-YdqQPy1w_e4jj4uw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=196.82251&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour Downtown Coeur d'Alene",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "1424 E Sherman Ave ste 500 c, Coeur d'Alene, Idaho",
        "phone": "(509) 879-3592",
        "website": "https://www.houseofcolour.com/stylists/haley-zizzi-downtown-coeur-dalene-idaho",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Downtown%20Coeur%20d'Alene&query_place_id=ChIJX9C0Sb7HYVMR3HonSqv0_34",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNhQEOz4MGYCeqVdXmCf_rc200L-EAQPh1XCLE_=w408-h611-k-no"
      }
    ]
  },
  {
    "name": "Hawaii",
    "slug": "hawaii",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Kailua",
        "description": "Image consultant. Rating: 5/5 from 2 reviews",
        "address": "Keolu Dr, Kailua, Hawaii",
        "phone": "(808) 366-3669",
        "website": "https://www.houseofcolour.com/stylists/erin-martinez-kailua-hawaii",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Kailua&query_place_id=ChIJ7ZFp4N0VAHwRXSRQmKcVrg4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=OCK9_IcPdJ5Imik4rzHljw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=57.795563&pitch=0&thumbfov=100"
      },
      {
        "name": "Elizabeth Kosich Styling",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "1109 Maunakea St Loft #217, Honolulu, Hawaii",
        "phone": "(917) 743-9240",
        "website": "https://elizabethkosichstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Elizabeth%20Kosich%20Styling&query_place_id=ChIJ50-hd0TYPU0RA4-k0sAi92M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNoECI2r2JPNse7GvglazmXFlcVUy7qvXppuLiR=w408-h306-k-no"
      },
      {
        "name": "Ume Personal Color",
        "description": "Image consultant",
        "address": "",
        "phone": "(323) 991-2105",
        "website": "https://www.umepersonalcolor.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=7433919747832458961",
        "url": "https://www.google.com/maps/search/?api=1&query=Ume%20Personal%20Color&query_place_id=ChIJ2aSMcpKgsAsRFFHq6tsdmUc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8WlGLYI8t0PwPd5zxkGVkL-0RsQxdZyLwPe9s=w408-h612-k-no"
      },
      {
        "name": "Thecolorbarhi",
        "description": "Beauty salon. Rating: 2.9/5 from 26 reviews",
        "address": "46-018 Kamehameha Hwy #205, Kaneohe, Hawaii",
        "phone": "(808) 429-9546",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Thecolorbarhi&query_place_id=ChIJseKg3vtqAHwRa1hamaYw87w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMmmyr9iJUgM5vqz4DEurJeJev6LNQny8JrjvuD=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "New Hampshire",
    "slug": "new-hampshire",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Image Enhancement Studio",
        "description": "Image consultant. Rating: 5/5 from 2 reviews",
        "address": "14A S Spring St, Concord, New Hampshire",
        "phone": "(603) 229-0211",
        "website": "http://theimageenhancementstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Image%20Enhancement%20Studio&query_place_id=ChIJofyY4ZBq4okR1NR8qnyGq4g",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=YrgpjSzWWiHaxYYestqY3g&cb_client=search.gws-prod.gps&w=408&h=240&yaw=258.5161&pitch=0&thumbfov=100"
      },
      {
        "name": "James Thomas Salon",
        "description": "Beauty salon. Rating: 4.8/5 from 30 reviews",
        "address": "83 Grove St, Peterborough, New Hampshire",
        "phone": "(603) 924-4247",
        "website": "https://jamesthomassalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=James%20Thomas%20Salon&query_place_id=ChIJITdU6wLW44kR5Zs86Ktb9EM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOsNOAeAo5IDX4TWnbtU2TtT05A04WO8Bm55CCb=w408-h544-k-no"
      },
      {
        "name": "Reflections West Salon",
        "description": "Beauty salon. Rating: 4.7/5 from 23 reviews",
        "address": "Peterborough Bypass, 19 Wilton Rd #21, Peterborough, NH",
        "phone": "(603) 924-6627",
        "website": "https://www.reflectionswest.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Reflections%20West%20Salon&query_place_id=ChIJZVFWSR3W44kRYedndkQ34XY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN2o0D78dm1jasEyu1gB1PA6wcRzQYvAPCcn9mn=w408-h544-k-no"
      },
      {
        "name": "Be Image Consulting",
        "description": "Consultant",
        "address": "Waumbec Mill, 250 Commercial St #2017, Manchester, New Hampshire",
        "phone": "(603) 496-6667",
        "website": "https://beimageconsulting.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Be%20Image%20Consulting&query_place_id=ChIJ5QqRySlP4okRgWXH8f6PrPw",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=aR5sVap9gch80KEIc7ejKQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=285.1124&pitch=0&thumbfov=100"
      },
      {
        "name": "Apryl The Coloryst @ Parlor 45",
        "description": "Hairdresser. Rating: 5/5 from 17 reviews",
        "address": "@ Parlor, 45 Broad St #45, Nashua, New Hampshire",
        "phone": "(603) 785-8746",
        "website": "https://www.aprylthehaircoloryst.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Apryl%20The%20Coloryst%20%40%20Parlor%2045&query_place_id=ChIJnSkYlMy344kRo3Ml8V2gXqQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOUlsFUoArQxaBNuaKyanYgv9uVakoDpxVlhDCl=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "Maine",
    "slug": "maine",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Radiant Image",
        "description": "Image consultant. Rating: 5/5 from 39 reviews",
        "address": "101 Valview Dr, Auburn, Maine",
        "phone": "(207) 576-7500",
        "website": "http://www.radiantimage.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=Radiant%20Image&query_place_id=ChIJnR6xRvZpskwRxlR-Y3lah1c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPhiCX8IPbWJ0Gx4OQzZjC9qVpvXHDB4JT02ZOS=w408-h267-k-no"
      },
      {
        "name": "Julie Cunningham Color",
        "description": "Consultant. Rating: 4.5/5 from 6 reviews",
        "address": "12 Kingsmark Ln, Portland, Maine",
        "phone": "(207) 773-5500",
        "website": "https://juliecunninghamcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Julie%20Cunningham%20Color&query_place_id=ChIJ9fK3LMObskwRiMUUwrpiE88",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Gg-1FHz9EEi231-kS-vg2Q&cb_client=search.gws-prod.gps&w=408&h=240&yaw=282.6319&pitch=0&thumbfov=100"
      },
      {
        "name": "Resurface Skin & Body Health",
        "description": "Facial spa. Rating: 5/5 from 31 reviews",
        "address": "9 Town Hall Pl, Brunswick, Maine",
        "phone": "(207) 373-1919",
        "website": "http://resurface-me.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Resurface%20Skin%20%26%20Body%20Health&query_place_id=ChIJV0Nme26HrUwRjC3MXb8Lg1M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNLGS-FaSzhf-swbHDinp47SinHYyGWRrd_jnQ=w408-h541-k-no"
      },
      {
        "name": "Color Perfect Maine",
        "description": "Consultant",
        "address": "",
        "phone": "(207) 604-2537",
        "website": "https://www.colorperfectmaine.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Perfect%20Maine&query_place_id=ChIJ9WorBWiZ2qIRuHgjPqph4Sc",
        "imageUrl": ""
      },
      {
        "name": "Curl Up & Dye",
        "description": "Beauty salon. Rating: 4.8/5 from 92 reviews",
        "address": "141 Pleasant St, Farmington, Maine",
        "phone": "(207) 778-3673",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Curl%20Up%20%26%20Dye&query_place_id=ChIJZyYmczSasUwROsA21VYHDpY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNLIM8z9jmslaaOEd6LkYHZz826pH5Sshn1RCGV=w408-h725-k-no"
      }
    ]
  },
  {
    "name": "Montana",
    "slug": "montana",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour West Billings",
        "description": "Image consultant. Rating: 5/5 from 8 reviews",
        "address": "100 N 27th St, Billings, Montana",
        "phone": "(406) 861-3209",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20West%20Billings&query_place_id=ChIJpVnpBfB7SFMRVtBOioSap70",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=FibXBdIx7uaatM-TiXBJIQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=230.66518&pitch=0&thumbfov=100"
      },
      {
        "name": "Imagine That",
        "description": "Beauty salon. Rating: 4.8/5 from 79 reviews",
        "address": "22 N Montana Ave, Miles City, Montana",
        "phone": "(406) 234-7626",
        "website": "http://www.imaginethatsalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Imagine%20That&query_place_id=ChIJwQxjQBp0OlMRjfR2z-wqWuY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMzDNEWe2rZxacQeeUhCkBnsbsxqkK_FTtnoTQH=w408-h544-k-no"
      },
      {
        "name": "Anne's Color Salon",
        "description": "Beauty salon. Rating: 4/5 from 1 reviews",
        "address": "810 Mandeville Ln, Bozeman, Montana",
        "phone": "(406) 587-1401",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Anne's%20Color%20Salon&query_place_id=ChIJyZzHJDtERVMR8tCx-rdFC_E",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=py9lbZrKaPZv6c3y6nw2kA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=169.25569&pitch=0&thumbfov=100"
      },
      {
        "name": "406 Colour Studio",
        "description": "Beauty salon. Rating: 4.9/5 from 47 reviews",
        "address": "2708 Harrison Ave, Butte, Montana",
        "phone": "(406) 565-7275",
        "website": "https://406colourstudio.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=406%20Colour%20Studio&query_place_id=ChIJj0PZq5QJW1MRABQQm3TRbNg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMfUTVLEenkjP8SM_RIwKcz0AqmqglB73P5eR7D=w448-h240-k-no"
      }
    ]
  },
  {
    "name": "Rhode Island",
    "slug": "rhode-island",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Colored By Amie LLC",
        "description": "Consultant. Rating: 5/5 from 17 reviews",
        "address": "",
        "phone": "(401) 327-0195",
        "website": "https://coloredbyamie.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colored%20By%20Amie%20LLC&query_place_id=ChIJkQKo5wOwuGgRHJrPKLNFEAY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNiN3x83Uqj-jxWP5OShDLz5v9X-PFXglU4XwcN=w408-h408-k-no"
      },
      {
        "name": "True Colors Salon",
        "description": "Beauty salon. Rating: 5/5 from 11 reviews",
        "address": "404 Main St, Wakefield, Rhode Island",
        "phone": "(401) 789-6777",
        "website": "https://truecolorsri.wixsite.com/truecolorssalonri",
        "url": "https://www.google.com/maps/search/?api=1&query=True%20Colors%20Salon&query_place_id=ChIJY1UwLki55YkRBlN_IeDT2yU",
        "imageUrl": ""
      },
      {
        "name": "Facials, Skin Care, Waxing, Makeup",
        "description": "Beauty salon",
        "address": "1227 Main St, West Warwick, Rhode Island",
        "phone": "(401) 353-4656",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Facials%2C%20Skin%20Care%2C%20Waxing%2C%20Makeup&query_place_id=ChIJpZVOxqpL5IkRcnvm26dclfQ",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=3Nmpf2KbR4DYUh2BF_7WAg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=289.9617&pitch=0&thumbfov=100"
      }
    ]
  },
  {
    "name": "Delaware",
    "slug": "delaware",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Color Palette",
        "description": "Hair extensions supplier",
        "address": "4760 W Sahara Ave Suite 5, Las Vegas, Nevada",
        "phone": "(702) 258-0559",
        "website": "http://www.colorpalettestudios.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Palette&query_place_id=ChIJfbV6ur_ByIARZKZi8AZgsTc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=-2FfExg6PR4KQY4JeUc3Kg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=172.67827&pitch=0&thumbfov=100"
      },
      {
        "name": "Pasca",
        "description": "Hair salon. Rating: 4.8/5 from 194 reviews",
        "address": "4559 New Linden Hill Rd, Wilmington, Delaware",
        "phone": "(302) 428-1388",
        "website": "http://www.salonpasca.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Pasca&query_place_id=ChIJxZPDpSIAx4kRndtDzsfJaFg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipObhr146rhbsajUnGgO4u1wCliQVxWefacmwltW=w408-h508-k-no"
      },
      {
        "name": "MERLE NORMAN COSMETICS AND DAY SPA",
        "description": "Cosmetics store. Rating: 4.9/5 from 14 reviews",
        "address": "1030 Forrest Ave Suite 113, Dover, Delaware",
        "phone": "(302) 736-1961",
        "website": "http://www.merlenormandoverde.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=MERLE%20NORMAN%20COSMETICS%20AND%20DAY%20SPA&query_place_id=ChIJt_SjPad8x4kRwNIN1Rs4PB4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNDAVcykQ_pWuTAWLUhHM81DiO-ROXRmhC9Y3tc=w408-h544-k-no"
      }
    ]
  },
  {
    "name": "South Dakota",
    "slug": "south-dakota",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Color the Hills",
        "description": "Image consultant",
        "address": "",
        "phone": "",
        "website": "https://colorthehills.org/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20the%20Hills&query_place_id=ChIJmea7ai-KCC8RT3OsxPcAsw0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPqpqWrar7hGYbxBEk3Ej4AYL1Cu65aV44LPEjE=w408-h490-k-no"
      },
      {
        "name": "House of Colour - Sioux Falls",
        "description": "Women's clothing store. Rating: 5/5 from 3 reviews",
        "address": "7401 Bitterroot Pl #208, Sioux Falls, South Dakota",
        "phone": "(605) 610-8726",
        "website": "https://thewardroberevolution.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Sioux%20Falls&query_place_id=ChIJARRxC3e3jocRNBLz_W3Skkc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNBpFnsFEqsBhik1-37WxSwlTAsqFmm4-RU12vC=w420-h240-k-no"
      },
      {
        "name": "Siouxland House of Colour",
        "description": "Image consultant",
        "address": "884 E Pinehurst Trail, North Sioux City, South Dakota",
        "phone": "(641) 590-1488",
        "website": "https://www.siouxlandhouseofcolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Siouxland%20House%20of%20Colour&query_place_id=ChIJhfJz6RUHjocR8-3Me6C5vG0",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=wkHZptVrMnhT-jIGbxpdwg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=230.58147&pitch=0&thumbfov=100"
      },
      {
        "name": "Color Confidence by Kelly",
        "description": "Stylist",
        "address": "617 N Main St, Spearfish, South Dakota",
        "phone": "(605) 639-3255",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Confidence%20by%20Kelly&query_place_id=ChIJ8WUoyreZMlMRf2JRrS_5R_U",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=eXcYIXVFVl4bq-z2MEWPoQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=253.86047&pitch=0&thumbfov=100"
      },
      {
        "name": "Blush Beauty Salon and Spa",
        "description": "Hair salon. Rating: 5/5 from 4 reviews",
        "address": "123 E 3rd St, Miller, South Dakota",
        "phone": "(605) 295-1822",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Blush%20Beauty%20Salon%20and%20Spa&query_place_id=ChIJXbZTH9tbh4cRIhpngUBkhYk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNFpRW_98SusdKxnH_sDc2JzrqnS01y7DPrdYfQ=w408-h305-k-no"
      }
    ]
  },
  {
    "name": "North Dakota",
    "slug": "north-dakota",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The Colour Room",
        "description": "Beauty salon. Rating: 5/5 from 9 reviews",
        "address": "1407 S Broadway Suite F Room 107, Minot, North Dakota",
        "phone": "(701) 595-0686",
        "website": "https://square.site/book/7WBP40WBPKV4S/the-colour-room-minot-nd",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Colour%20Room&query_place_id=ChIJxfGQNRgr31IRgXDUxxBWNVE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNB3OlAyG2jok9s8qaPGoqOWVbR610CQap5sn9x=w408-h544-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "SalonCentric",
        "description": "Beauty supply store. Rating: 5/5 from 3 reviews",
        "address": "3220 S Broadway b, Minot, North Dakota",
        "phone": "(701) 420-2760",
        "website": "https://stores.saloncentric.com/nd/minot/4904",
        "url": "https://www.google.com/maps/search/?api=1&query=SalonCentric&query_place_id=ChIJg7Iguvcq31IRDOuBDRz2xyI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMfgg6JiAvAezAqR_7EcNPFrJpm3D9-LXCMNJfn=w426-h240-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "Alaska",
    "slug": "alaska",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "The color palette by Carrie peacock",
        "description": "Hairdresser",
        "address": "135 W Dimond Blvd #200, Anchorage, Alaska",
        "phone": "(480) 458-8616",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20color%20palette%20by%20Carrie%20peacock&query_place_id=ChIJdVAcKcKZyFYRExFb7nV3xCA",
        "imageUrl": ""
      },
      {
        "name": "The Hair And Color Bar",
        "description": "Hair salon. Rating: 4.7/5 from 152 reviews",
        "address": "1830 E Parks Hwy a-115, Wasilla, Alaska",
        "phone": "(907) 373-1773",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Hair%20And%20Color%20Bar&query_place_id=ChIJKyZHLBzeyFYRiyPiF470JHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvcsWEJMrTuxcCuh1rdjNnvXs41VWD6SsxtirN=w408-h272-k-no"
      }
    ]
  },
  {
    "name": "District of Columbia",
    "slug": "district-of-columbia",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "GetDressedGo",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.getdressedgo.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-website&utm_content=website_button",
        "url": "https://www.google.com/maps/search/?api=1&query=GetDressedGo&query_place_id=ChIJb7smD7XNt4kRdbFwLa5Gp_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOv7m2VCeXAKyHU87bzXjY1rzHiZovYZVwT79O4=w408-h272-k-no"
      },
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      }
    ]
  },
  {
    "name": "Vermont",
    "slug": "vermont",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "House of Colour Essex Junction",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "4 Park St Suite 204D, Essex Junction, Vermont",
        "phone": "(206) 437-0588",
        "website": "https://www.houseofcolour.com/stylists/christina-merhar-essex-junction-vermont",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Essex%20Junction&query_place_id=ChIJHboXlS13ykwR4XoXDCv5XxM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMshuVA7-GRvojI0lF9EhQyeuAKKLkWt-icFfDi=w408-h272-k-no"
      },
      {
        "name": "Ana Luz - Personal Stylist and Personal Color Analysis in Montreal",
        "description": "Image consultant. Rating: 5/5 from 12 reviews",
        "address": "",
        "phone": "+1 438-528-1163",
        "website": "http://www.quotidian.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ana%20Luz%20-%20Personal%20Stylist%20and%20Personal%20Color%20Analysis%20in%20Montreal&query_place_id=ChIJ5UwTu2VmtysRSz8XweFz_HA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipME2hCp5Rw0K3ep9A7_UXZ3_FumZWTbz6sFdXPY=w408-h544-k-no"
      },
      {
        "name": "House of Colour Stowe",
        "description": "Stylist",
        "address": "166 S Main St, Stowe, Vermont",
        "phone": "(207) 576-2386",
        "website": "https://www.houseofcolour.com/stylists/katie-hardy-stowe-vermont",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Stowe&query_place_id=ChIJNaI6yj6XtUwRHAppCiIwbo0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNUF2oIyCdfrMXIFF0TV-Uy0ZNbmGjsn6S4phaq=w408-h544-k-no"
      },
      {
        "name": "N. Coolidge Hair Design & Aesthetics",
        "description": "Beauty salon. Rating: 4.9/5 from 86 reviews",
        "address": "123 Mattison Rd, Sunderland, Vermont",
        "phone": "(802) 375-4499",
        "website": "https://www.instagram.com/n.coolidgehairdesignaesthetics",
        "url": "https://www.google.com/maps/search/?api=1&query=N.%20Coolidge%20Hair%20Design%20%26%20Aesthetics&query_place_id=ChIJr5ajy8SK4IkRvDNJ1d-WeSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP50gHfbhR44BIWWp6miH5X_WsAw6diZ8PbvZbi=w426-h240-k-no"
      }
    ]
  },
  {
    "name": "Wyoming",
    "slug": "wyoming",
    "type": "state",
    "country": "United States",
    "businesses": [
      {
        "name": "Laura McColley-Sheridan, WY",
        "description": "Image consultant",
        "address": "52 S Main St Suite 1, Sheridan, Wyoming",
        "phone": "(307) 683-6083",
        "website": "http://www.houseofcolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Laura%20McColley-Sheridan%2C%20WY&query_place_id=ChIJ40mCp1v7NVMRDRSLFP_gaEc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=9vGkQvyF24_MPiTkoR2qfQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=270.9166&pitch=0&thumbfov=100"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ]
  },
  {
    "name": "New York",
    "slug": "new-york",
    "type": "city",
    "country": "United States",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "name": "Seklab Color Analysis Studio",
        "description": "Image consultant. Rating: 5/5 from 81 reviews",
        "address": "202 W 40th St Suite 400, New York, New York",
        "phone": "(332) 999-4179",
        "website": "https://www.seklabnyc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Seklab%20Color%20Analysis%20Studio&query_place_id=ChIJwxeSMoRZwokR2BBukhq4lSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlfS2fOpii7qpV5M8BXyKf45AA6DJvTiYJ3GQr=w408-h544-k-no"
      },
      {
        "name": "The Outfit Curator",
        "description": "Stylist. Rating: 4.8/5 from 17 reviews",
        "address": "123 W 81st St, New York, New York",
        "phone": "",
        "website": "http://www.theoutfitcurator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Outfit%20Curator&query_place_id=ChIJjUVyL1BZwokRE7p_2_X0RMI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQjkI8r7-F7JkalGMJ0YXHyz8VSArJYQ_0AG0T=w408-h612-k-no"
      },
      {
        "name": "Lily's Color Lab",
        "description": "Stylist. Rating: 4.9/5 from 71 reviews",
        "address": "1239 Broadway, New York, New York",
        "phone": "(202) 813-9687",
        "website": "https://lilyscolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lily's%20Color%20Lab&query_place_id=ChIJqxVpZRFbwokRPYp0fw-xRUI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7oWbYDctv5kuqOqA8g16iw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=91.2156&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour NYC - Downtown Brooklyn",
        "description": "Stylist. Rating: 5/5 from 11 reviews",
        "address": "325 Gold St Suite 703A, Brooklyn, New York",
        "phone": "(929) 266-7228",
        "website": "https://houseofcolour.com/fernandavazquez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NYC%20-%20Downtown%20Brooklyn&query_place_id=ChIJPRb8F6tbwokRbe-woVjHwO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOUh0ZE6PWUcygcamfo11_uH1HiI4QGjPSEEqSC=w408-h544-k-no"
      }
    ],
    "description": "New York City offers a diverse range of color analysis services, from high-end fashion consultants to more affordable options. The city's fast-paced lifestyle and fashion-forward culture make color analysis a popular choice for professionals and style enthusiasts alike."
  },
  {
    "name": "Los Angeles",
    "slug": "los-angeles",
    "type": "city",
    "country": "United States",
    "pricingRange": "$100 - $500",
    "businesses": [
      {
        "name": "Jennifer Butler Living Colors",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "848 S Lucerne Blvd, Los Angeles, California",
        "phone": "(323) 931-2626",
        "website": "http://jenniferbutlercolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Jennifer%20Butler%20Living%20Colors&query_place_id=ChIJIXumc-24woARdAHbQJdIlz8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=46RAvyMI53m1sP09Roe2Ag&cb_client=search.gws-prod.gps&w=408&h=240&yaw=110.95381&pitch=0&thumbfov=100"
      },
      {
        "name": "COLORBRITY",
        "description": "Image consultant. Rating: 5/5 from 198 reviews",
        "address": "3407 W 6th St #523, Los Angeles, California",
        "phone": "(213) 537-8620",
        "website": "https://www.colorbrity.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLORBRITY&query_place_id=ChIJk3niDnDHwoARBdPo74lTcB0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO91fJnDk5cWe6jslN6hava9rDxF61oDAHg8SVy=w408-h544-k-no"
      }
    ],
    "description": "Los Angeles offers a diverse range of color analysis services, from personal shoppers to specialized color consultants. The city's fashion-forward culture and entertainment industry influence make it a hub for innovative and personalized color analysis experiences. Whether you're looking for an affordable option or a high-end styling service, LA has options to suit various preferences and budgets."
  },
  {
    "name": "Chicago",
    "slug": "chicago",
    "type": "city",
    "country": "United States",
    "pricingRange": "$125 - $350",
    "businesses": [
      {
        "name": "Raquelle Corazza Stylist and Image Consulting",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "Chicago, Illinois",
        "phone": "(516) 724-2662",
        "website": "https://raquellecorazza.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Raquelle%20Corazza%20Stylist%20and%20Image%20Consulting&query_place_id=ChIJaeTWT_fTD4gREaY6gpECy3s",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=vqR6CzO1oIF6rXU833m4sw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=127.74743&pitch=0&thumbfov=100"
      },
      {
        "name": "Beauty in Colors Makeup Artistry",
        "description": "Make-up artist. Rating: 5/5 from 1 reviews",
        "address": "2953 W Lyndale St #1, Chicago, Illinois",
        "phone": "(773) 245-0588",
        "website": "http://www.beautyincolors.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20in%20Colors%20Makeup%20Artistry&query_place_id=ChIJKfG-6JjRD4gRJyjp7csso6M",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=gKwWl-1qpj1dL7TO9WFJxg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=183.9727&pitch=0&thumbfov=100"
      },
      {
        "name": "My Chicago Stylist",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "901 N Wood St, Chicago, Illinois",
        "phone": "(312) 725-0625",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Chicago%20Stylist&query_place_id=ChIJD50tA1DTD4gRtAXQKvuZXk0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOYJbZkA5WGsXE1gWQHRyhIjlokmKPAkhljmtwh=w408-h725-k-no"
      }
    ],
    "description": "Chicago's color analysis scene blends Midwestern practicality with urban sophistication. Services range from personal consultations to group workshops, catering to the city's diverse population and varying seasons."
  },
  {
    "name": "San Francisco",
    "slug": "san-francisco",
    "type": "city",
    "country": "United States",
    "pricingRange": "$175 - $450",
    "businesses": [
      {
        "name": "Lili Henry - Personal Fashion Stylist",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "Union St, San Francisco, California",
        "phone": "(925) 788-4504",
        "website": "https://www.lilihenrystylist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lili%20Henry%20-%20Personal%20Fashion%20Stylist&query_place_id=ChIJxfGXZ9CAhYARSNhsxX58RCc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPQFLdBfR942vHK6JQnxzldlcBnZ9v8qSwST5Io=w408-h272-k-no"
      },
      {
        "name": "K Griffin Style Color Analysis and Wardrobe Consultant",
        "description": "Stylist. Rating: 5/5 from 79 reviews",
        "address": "130 Lake St, San Francisco, California",
        "phone": "(415) 521-9316",
        "website": "https://www.kgriffinstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=K%20Griffin%20Style%20Color%20Analysis%20and%20Wardrobe%20Consultant&query_place_id=ChIJYcBm9HyHhYARNk6wwVNQz98",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMBiquGSNFyOwSehWWKP5TDl8NDNtvukUNkFihr=w408-h304-k-no"
      },
      {
        "name": "Color by Kara",
        "description": "Hair salon",
        "address": "Sacramento St, San Francisco, California",
        "phone": "",
        "website": "http://charleswthomassalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20by%20Kara&query_place_id=ChIJ3RtWwvqHhYARG_x5FAW9-iU",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=t4_AQ62VBUab-qQiYWKyQg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=185.89186&pitch=0&thumbfov=100"
      },
      {
        "name": "Colors With Kate",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorswithkate.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20With%20Kate&query_place_id=ChIJb9AAmOsO-CwRjmjy_aqOsSM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3qvDzt-MMqZfR0U2M3AbNYt_xSFpVMzqOv0Kf=w408-h306-k-no"
      },
      {
        "name": "Guerrilla Makeovers",
        "description": "Image consultant. Rating: 4.5/5 from 8 reviews",
        "address": "166 Geary St #1606, San Francisco, California",
        "phone": "(866) 293-7895",
        "website": "https://www.guerrillamakeovers.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Guerrilla%20Makeovers&query_place_id=ChIJ3diY24iAhYAR8rOr3hHw4rI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNyjhFwnzJdQFMdqn6VvBSdRJ_I52IFLJaVAAw=w408-h544-k-no"
      }
    ],
    "description": "San Francisco's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's foggy climate and casual-chic style influence color choices and analysis methods."
  },
  {
    "name": "Miami",
    "slug": "miami",
    "type": "city",
    "country": "United States",
    "pricingRange": "$100 - $300",
    "businesses": [
      {
        "name": "Wardrobe stylist Miami Anna Ruiz",
        "description": "Stylist. Rating: 5/5 from 35 reviews",
        "address": "900 Biscayne Blvd, Miami, Florida",
        "phone": "(305) 439-2341",
        "website": "http://www.stylebyannaruiz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Wardrobe%20stylist%20Miami%20Anna%20Ruiz&query_place_id=ChIJ6Z2Xm9K32YgRPy_8Dr9VJA4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3uvrGPMspG_HwBfmjKEh8xXgowW01LGfqNss=w408-h267-k-no"
      },
      {
        "name": "Bamboo Beauty & Wellness Spa",
        "description": "Beauty salon. Rating: 4.7/5 from 743 reviews",
        "address": "1717 N Bayshore Dr Suite 112, Miami, Florida",
        "phone": "(786) 575-3475",
        "website": "https://bamboospamiami.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bamboo%20Beauty%20%26%20Wellness%20Spa&query_place_id=ChIJW-06u7m12YgR6Xl9YBo6nsI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPoDhy1rUjqEB5URuk0VwI8ruIguiyUvTGC6YGL=w408-h257-k-no"
      }
    ],
    "description": "Miami's vibrant culture and tropical climate are reflected in its color analysis services. Analysts often specialize in bright, bold palettes suitable for the city's lively fashion scene and year-round sunshine."
  },
  {
    "name": "Houston",
    "slug": "houston",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "ByMaja Color Analysis Services",
        "description": "Image consultant. Rating: 5/5 from 183 reviews",
        "address": "540 Heights Blvd Suite 117, Houston, Texas",
        "phone": "",
        "website": "https://bymaja.org/",
        "url": "https://www.google.com/maps/search/?api=1&query=ByMaja%20Color%20Analysis%20Services&query_place_id=ChIJD_bLtwvHQIYRIiV4wjznfCA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO0_C9IsRyN0VaWw6U5SS9N85jWf3CW0vOATKsM=w408-h612-k-no"
      },
      {
        "name": "Love of Color HTX",
        "description": "Image consultant. Rating: 5/5 from 48 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.loveofcolorhtx.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Love%20of%20Color%20HTX&query_place_id=ChIJr5zfzklLR4YRiHmV4iGFL1s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPFfzkUyoFQ4IHBdtpZYv24OQkMCAWMcB5422xg=w408-h544-k-no"
      },
      {
        "name": "Colour Haus",
        "description": "Hair salon. Rating: 5/5 from 15 reviews",
        "address": "5757 Woodway Dr G100 Studio #6, Houston, Texas",
        "phone": "(832) 630-1237",
        "website": "http://colour-haus.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Haus&query_place_id=ChIJVR5iTZXDQIYRb5SROeM_5-Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOs17s0GlXEqoda2YVpnSU8Bgitj_3-uuq8Kjvo=w408-h544-k-no"
      },
      {
        "name": "Selina Möller Color Consulting",
        "description": "Consultant. Rating: 3/5 from 2 reviews",
        "address": "",
        "phone": "(713) 471-0653",
        "website": "http://selinamoller.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Selina%20M%C3%B6ller%20Color%20Consulting&query_place_id=ChIJy2UXaUnBQIYRLjQrQT19aY8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO8Ui9t1mCMXRqy4nBGPTdHlPfL6kHWnZo3XFSy=w408-h408-k-no"
      }
    ],
    "description": "Houston's color analysis services reflect the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Phoenix",
    "slug": "phoenix",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "House of Colour Central Scottsdale - Brooke Svec",
        "description": "Stylist. Rating: 5/5 from 20 reviews",
        "address": "1411 E Orangewood Ave, Phoenix, Arizona",
        "phone": "(402) 759-1430",
        "website": "https://max.houseofcolour.co.uk/book/17314-BrookeSvec",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Central%20Scottsdale%20-%20Brooke%20Svec&query_place_id=ChIJgY0OJ7VtK4cRh3CkwyX9LuA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0yyYNRMaH2y8JvfG_BCk0zfRzODVw7aO7-B0y=w408-h272-k-no"
      },
      {
        "name": "Color & Commentary",
        "description": "Beauty salon. Rating: 5/5 from 23 reviews",
        "address": "19805 N 51st Ave #17, Glendale, Arizona",
        "phone": "(480) 271-7415",
        "website": "https://color-commentary.chrone.work/?utm_source=googlemaps&utm_medium=googlemapsprofile&utm_campaign=googlemaps",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20%26%20Commentary&query_place_id=ChIJ99xlVOlpK4cRNaMo3sCTo7g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP67Jd09xawz7j4d7jnT-L1R7UxbuOw1Vhiw4oc=w408-h408-k-no"
      },
      {
        "name": "Mima's Beauty College",
        "description": "Beauty school. Rating: 4.8/5 from 86 reviews",
        "address": "8115 N 35th Ave Suite #4, Phoenix, Arizona",
        "phone": "(602) 698-1499",
        "website": "http://mimasbc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Mima's%20Beauty%20College&query_place_id=ChIJm1vCBINtK4cR2aoNJjBKt24",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMs5u-yGC7O92ZgA3bqE1sm0GnnSOf1XcrFnDkL=w408-h306-k-no"
      },
      {
        "name": "The Studio Academy of Beauty: Phoenix",
        "description": "Beauty school. Rating: 4.9/5 from 1960 reviews",
        "address": "8046 N 19th Ave, Phoenix, Arizona",
        "phone": "(602) 841-5993",
        "website": "https://thestudioacademyofbeauty.com/?utm_source=google&utm_medium=gmb&utm_campaign=phoenix",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Studio%20Academy%20of%20Beauty%3A%20Phoenix&query_place_id=ChIJEY2_P-NsK4cR_wsvhV7Xo38",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMyGXE-M6AhG1zxhKHPsMiOthLOio-ReJjirpjA=w427-h240-k-no"
      }
    ],
    "description": "Phoenix's color analysis services are influenced by the desert climate and sunny weather. Analysts often specialize in warm and earthy palettes that complement the natural surroundings."
  },
  {
    "name": "Philadelphia",
    "slug": "philadelphia",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "House of Colour - Jill Rafter",
        "description": "Image consultant",
        "address": "",
        "phone": "(215) 840-3414",
        "website": "https://max.houseofcolour.co.uk/book/27275-Jill_Rafter",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Jill%20Rafter&query_place_id=ChIJK_heji1j60cRSlNbjfSO7QU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf2l4QO5Mr2tNPTD8ZwypYaH3hcWqgSgJx4amQ=w408-h408-k-no"
      },
      {
        "name": "Advanced Skin Care Solutions",
        "description": "Facial spa. Rating: 4.8/5 from 47 reviews",
        "address": "1404 S 5th St, Philadelphia, Pennsylvania",
        "phone": "(215) 203-0995",
        "website": "http://advancedskincarephl.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Advanced%20Skin%20Care%20Solutions&query_place_id=ChIJW_AIEEHIxokRWqotCTL_2kQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNULJaZKLFhESuKYql2KiWbC7CF08y5lPEMb8vi=w408-h306-k-no"
      },
      {
        "name": "Pressed & Loc'd",
        "description": "Beauty salon. Rating: 5/5 from 2 reviews",
        "address": "side door ( white door, 1255 N 52nd St, W Thompson St, Philadelphia, PA",
        "phone": "(267) 702-0565",
        "website": "https://pressedbeautybar.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Pressed%20%26%20Loc'd&query_place_id=ChIJr8inzgrHxokRE3lTCAmC4gE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMjkgiOKpSPOf4zRsTeKIo-Lk-lHRJXahK1Trc=w408-h725-k-no"
      }
    ],
    "description": "Philadelphia's color analysis scene blends historical charm with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "San Antonio",
    "slug": "san-antonio",
    "type": "city",
    "country": "United States",
    "pricingRange": "$100 - $300",
    "businesses": [
      {
        "name": "Laura Infante: Circular Fashion Connection",
        "description": "Stylist. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(210) 875-6098",
        "website": "https://www.circularfashionconnection.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Laura%20Infante%3A%20Circular%20Fashion%20Connection&query_place_id=ChIJMXQYN5Du_QIRFIUns59ZT2s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvEdLwGTSZll4cSv01hBCertDSYxT9O_cFfA8=w408-h612-k-no"
      },
      {
        "name": "Color Star Studio, LLC",
        "description": "Image consultant. Rating: 5/5 from 29 reviews",
        "address": "2929 Mossrock Ste. 228, San Antonio, Texas",
        "phone": "(210) 887-7600",
        "website": "http://www.colorstarstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Star%20Studio%2C%20LLC&query_place_id=ChIJh7fBVmpdXIYRQ6BEGeeqgvU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN-3Bn68_DlZlVbt0F_DPelYwRU9bcQpBlsDEPx=w408-h306-k-no"
      },
      {
        "name": "House of Colour Brooks",
        "description": "Stylist. Rating: 5/5 from 9 reviews",
        "address": "3166 SE Military Dr #104, San Antonio, Texas",
        "phone": "(210) 338-0088",
        "website": "http://bit.ly/hoc_brookscitybase",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Brooks&query_place_id=ChIJsVnFC9n3XIYRQn4Zap9MWxo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPl0gcMIwngP49QkfL8pu0nxeVdJZvSaAUAzC4N=w408-h272-k-no"
      },
      {
        "name": "HAIR LAB | The Science of Beauty",
        "description": "Hair salon. Rating: 5/5 from 27 reviews",
        "address": "6827 Camp Bullis Rd Suite 105, San Antonio, Texas",
        "phone": "(210) 879-8897",
        "website": "http://www.hairlabsa.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=HAIR%20LAB%20%7C%20The%20Science%20of%20Beauty&query_place_id=ChIJ3USmR_ZlXIYR7o1J5yPIvIQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN7gF3D9g8tWddDkwZ5g4m33SJIhW8NPe_TM7rY=w408-h510-k-no"
      }
    ],
    "description": "San Antonio's color analysis services reflect the city's rich cultural heritage and warm climate. Analysts often focus on vibrant and festive palettes suitable for the lively local fashion scene."
  },
  {
    "name": "San Diego",
    "slug": "san-diego",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Colours Cosmetique",
        "description": "Skin care clinic",
        "address": "2307 Fenton Marketplace Driveway Ste 107, San Diego, California",
        "phone": "(619) 255-8524",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20Cosmetique&query_place_id=ChIJ3cXTf9dT2YARhlQWj6pIyos",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=2XqES9ZA8s4VDUJxFfYgKQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=35.37653&pitch=0&thumbfov=100"
      },
      {
        "name": "Color me Kat Salon",
        "description": "Hair salon. Rating: 5/5 from 6 reviews",
        "address": "6512 El Cajon Blvd A, San Diego, California",
        "phone": "(619) 905-1780",
        "website": "https://color-me-kat.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20me%20Kat%20Salon&query_place_id=ChIJSQeyDaNX2YARoB5FXtQ10p8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP5ubrmUsdCyp4O6yLPVdn9mVMuC4Q9Wh5k_SkB=w408-h408-k-no"
      },
      {
        "name": "Style By Mr. Angeles Noir",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "(323) 522-7817",
        "website": "https://www.stylebynoir.com/gallery",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20By%20Mr.%20Angeles%20Noir&query_place_id=ChIJzWFLsq9V2YARdBVpsAuriAU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN3xPdT--G9RxYs4RSxDtz_-tODls0xaRami63C=w408-h408-k-no"
      },
      {
        "name": "Natura Hair Removal & Skin Care",
        "description": "Waxing hair removal service. Rating: 4.8/5 from 155 reviews",
        "address": "1300 Grand Ave #C, San Diego, California",
        "phone": "(858) 270-4929",
        "website": "https://naturaspa.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Natura%20Hair%20Removal%20%26%20Skin%20Care&query_place_id=ChIJ_QkmYOoB3IAR1zybZ03UeVI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDJCGoWzJzEc8CaPvnK54S7mw9SM1e56Z7kJ-K=w408-h398-k-no"
      }
    ],
    "description": "San Diego's color analysis services are influenced by the coastal climate and laid-back lifestyle. Analysts often specialize in fresh and breezy palettes that reflect the city's beach culture."
  },
  {
    "name": "Dallas",
    "slug": "dallas",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Bethany Siggins Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 24 reviews",
        "address": "",
        "phone": "(469) 363-0193",
        "website": "http://www.bethanysiggins.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bethany%20Siggins%20Image%20Consulting&query_place_id=ChIJz6e_Y3-hToYR6IK5SgbG2PQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMYd8zIL9106RVad6ymIlV8cxWFHO3S1wyzDUvH=w426-h240-k-no"
      },
      {
        "name": "House of Colour Dallas",
        "description": "Image consultant",
        "address": "",
        "phone": "(972) 674-9546",
        "website": "https://www.houseofcolour.com/stylists/ginny-hudnut-far-north-dallas-texas",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Dallas&query_place_id=ChIJadopVBQaaKkREIb0UH1zIyI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM-V13uVfyN2_AQ0zoAU9RrIN-F5Xj636wJu8VL=w408-h562-k-no"
      },
      {
        "name": "Gbori Colour Studio",
        "description": "Make-up artist. Rating: 5/5 from 13 reviews",
        "address": "210 S Madison Ave, Dallas, Texas",
        "phone": "(214) 578-5352",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Gbori%20Colour%20Studio&query_place_id=ChIJKdVMPOqZToYRF3vvU1IabOQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPe3X3I-WACg2D8KY6eycE3gDfG9dbEDTbMqFf7=w408-h417-k-no"
      }
    ],
    "description": "Dallas's color analysis scene combines Southern charm with urban sophistication. Services range from personal consultations to corporate workshops, catering to the city's diverse clientele."
  },
  {
    "name": "San Jose",
    "slug": "san-jose",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $400",
    "businesses": [
      {
        "name": "In Lovely Color",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "1700 Newbury Park Dr Suite 30 room 209, San Jose, California",
        "phone": "(408) 836-7450",
        "website": "http://inlovelycolor.org/?utm_source=gmb&utm_medium=referral",
        "url": "https://www.google.com/maps/search/?api=1&query=In%20Lovely%20Color&query_place_id=ChIJMVnKegjNj4AReE7shLYUEgM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDcXa8jRDjA31L5C9TTp5eDDXLNZBOBUkmQW_m=w408-h408-k-no"
      },
      {
        "name": "House of Colours",
        "description": "Beauty salon. Rating: 4.6/5 from 9 reviews",
        "address": "900 Park Ave, San Jose, California",
        "phone": "(650) 207-3828",
        "website": "http://www.hairbycarolinek.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colours&query_place_id=ChIJ5VkY3T81joARHrbdj135-tg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbb8TXVC9AvEEc0YocRSDjTGMTIMh_74AfFNGE=w426-h240-k-no"
      },
      {
        "name": "LE VU Beauty Bar",
        "description": "Beauty salon. Rating: 4.8/5 from 31 reviews",
        "address": "1087 Meridian Ave #20, San Jose, California",
        "phone": "(408) 320-4338",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=LE%20VU%20Beauty%20Bar&query_place_id=ChIJSboalDM1joARXdxs4yCGESw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNNkwkrvW3Hpe2Za6vwSATZdhHooOvttMpnCPPL=w408-h491-k-no"
      },
      {
        "name": "Skin Deep Beauty & Beyond",
        "description": "Skin care clinic. Rating: 4.9/5 from 19 reviews",
        "address": "1203 Park Ave, San Jose, California",
        "phone": "(408) 623-2902",
        "website": "https://mewist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skin%20Deep%20Beauty%20%26%20Beyond&query_place_id=ChIJiVgYzETLj4ARxRmYlX74XIQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQ0qUWuZ1Sr59125Q0PBjYxThyRCXi9yAcLgaX=w408-h306-k-no"
      },
      {
        "name": "Visions of Venus",
        "description": "Beauty salon. Rating: 5/5 from 12 reviews",
        "address": "",
        "phone": "(408) 430-2511",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Visions%20of%20Venus&query_place_id=ChIJe0Tt5TzLj4AR3ksgMn1hYJ0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPvH7ycMXBNNX0JAQYEZQkqf-vN-NWg7D_0ZWQZ=w408-h309-k-no"
      }
    ],
    "description": "San Jose's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's innovative spirit influences color choices and analysis methods."
  },
  {
    "name": "Austin",
    "slug": "austin",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Colorier | Korean Color Analysis in Austin",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "1170-1304 Wells Branch Pkwy, Pflugerville, Texas",
        "phone": "",
        "website": "https://thecolorier.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorier%20%7C%20Korean%20Color%20Analysis%20in%20Austin&query_place_id=ChIJCUy_0tFSFWURIttA130NqNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_sNy6l8Mj143Fp6sY45Kfeh9d7wC7GF22o1g1=w408-h611-k-no"
      },
      {
        "name": "House of Colour | Austin | Karlotta DesJarlais",
        "description": "Stylist. Rating: 5/5 from 9 reviews",
        "address": "11300 Jollyville Rd, Austin, Texas",
        "phone": "(512) 655-9224",
        "website": "https://max.houseofcolour.co.uk/book/27224-Karlotta_Desjarlais",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20%7C%20Austin%20%7C%20Karlotta%20DesJarlais&query_place_id=ChIJ0Z6N_QrLRIYR3AxMZ0_aerg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMwCbWx6CLLPL74pq9lfec49iIoVYRPM5pphHu9=w408-h532-k-no"
      },
      {
        "name": "Color Chroma",
        "description": "Stylist. Rating: 5/5 from 50 reviews",
        "address": "11601 Menchaca Rd, Austin, Texas",
        "phone": "(512) 777-1210",
        "website": "https://maddymemmer.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Chroma&query_place_id=ChIJ8Wg3QqpNW4YRx4ClSvnbjzQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvlQWU5hFPQ2D7cNywEuUqbxv8UE6W4_MP7FMI=w408-h306-k-no"
      }
    ],
    "description": "Austin's color analysis services reflect the city's eclectic and creative vibe. Analysts often focus on unique and artistic palettes that cater to the local music and arts scene."
  },
  {
    "name": "Columbus",
    "slug": "columbus",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Kelly Does Color",
        "description": "Image consultant",
        "address": "4400 N High St, Columbus, Ohio",
        "phone": "",
        "website": "https://kellydoescolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Kelly%20Does%20Color&query_place_id=ChIJp8ec7KWNOIgRPenYCvJFhBc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=y6iAavhzY48L8QHTcmRCNQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=266.46585&pitch=0&thumbfov=100"
      },
      {
        "name": "Everyday Couture",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(513) 449-1043",
        "website": "https://www.everydaycouture.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Everyday%20Couture&query_place_id=ChIJGyCZ7VDKr2wRmOXIWEiHFLw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNz-qoN3HamVXRcru-po-cpz2H_FVGVPv4MywMR=w408-h408-k-no"
      },
      {
        "name": "JuEllz Designz",
        "description": "Hair salon. Rating: 5/5 from 21 reviews",
        "address": "1100 Morse Rd #100c, Columbus, Ohio",
        "phone": "",
        "website": "http://www.juellzdesignz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=JuEllz%20Designz&query_place_id=ChIJv1KqyeaJOIgR73s6I6qC20w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMkMH6HHfJ16NazWceleHgCqryALFYMlZnw03X5=w408-h544-k-no"
      },
      {
        "name": "More Than Style",
        "description": "Stylist. Rating: 5/5 from 18 reviews",
        "address": "",
        "phone": "",
        "website": "http://morethanstylellc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=More%20Than%20Style&query_place_id=ChIJecc9tKHp-KcRD-4Qk8cMzPw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPXul2T8SD6HxamrRH6_RhSuaWa8SrraO8hapq_=w408-h544-k-no"
      }
    ],
    "description": "Columbus's color analysis scene blends Midwestern practicality with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Fort Worth",
    "slug": "fort-worth",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Stoltz Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 28 reviews",
        "address": "4961 S Hulen St, Fort Worth, Texas",
        "phone": "(817) 924-8300",
        "website": "https://stoltzimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Stoltz%20Image%20Consulting&query_place_id=ChIJ4dYooltyToYRUy5TAqPYakE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNpGuf0d1InxYUnXN2XgdV1G2r7g0xwwBKtbJAQ=w408-h543-k-no"
      },
      {
        "name": "Colour Basis",
        "description": "Image consultant. Rating: 5/5 from 18 reviews",
        "address": "1721 W Berry St, Fort Worth, Texas",
        "phone": "(888) 797-0806",
        "website": "http://colourbasis.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Basis&query_place_id=ChIJ_-ZvUfNxToYREDk7zdrm3XU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN21ZGkg40xfvj3BDXaOT46DEw_SgTHVVCZach5=w408-h408-k-no"
      }
    ],
    "description": "Fort Worth's color analysis services reflect the city's Western heritage and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Indianapolis",
    "slug": "indianapolis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Beth Divine Style",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "",
        "phone": "(317) 416-2782",
        "website": "http://www.bethdivinestyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beth%20Divine%20Style&query_place_id=ChIJ_aUocqBTa4gRgLRKKNibdQ8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO6f97XZ8M0SyikhezWCYrNVk7jSJl6HKonqxAn=w408-h338-k-no"
      },
      {
        "name": "The Color Coordinator",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(224) 358-7795",
        "website": "https://www.thecolorcoordinator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Coordinator&query_place_id=ChIJae0et8QrBkoRc0PqwKY1Lrs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipODIjgYG2z9C_Z-B8xXrcmT_YgWujIo5gBOF1rT=w408-h272-k-no"
      },
      {
        "name": "Colour By Courtney",
        "description": "Make-up artist. Rating: 4.9/5 from 80 reviews",
        "address": "",
        "phone": "(317) 294-5257",
        "website": "https://www.colourbycourtney.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20By%20Courtney&query_place_id=ChIJ0Uz0lRY0a4gR1mbSEi5n1HU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMMt8INNlXLJIA4a2DWc9NBdkQwwPSDnVpG_vbH=w408-h619-k-no"
      },
      {
        "name": "Chic in Color",
        "description": "Stylist",
        "address": "",
        "phone": "(734) 634-7557",
        "website": "https://www.chicincolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Chic%20in%20Color&query_place_id=ChIJOw1O8N7legARR0_irE5dclk",
        "imageUrl": ""
      }
    ],
    "description": "Indianapolis's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Charlotte",
    "slug": "charlotte",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "House of Colour Charlotte Center City - Jenn Traynor",
        "description": "Image consultant",
        "address": "Lilac Rd, Charlotte, North Carolina",
        "phone": "(704) 756-6725",
        "website": "http://www.parttimefancy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Charlotte%20Center%20City%20-%20Jenn%20Traynor&query_place_id=ChIJpZ_0KrKfVogR2RDpMXh8GsM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=GEtXKFNpgcdPMDSkV9BzOg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=70.29101&pitch=0&thumbfov=100"
      },
      {
        "name": "Lisa Jenkins Color",
        "description": "Consultant. Rating: 5/5 from 63 reviews",
        "address": "",
        "phone": "(980) 833-6299",
        "website": "https://www.lisajenkinscolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lisa%20Jenkins%20Color&query_place_id=ChIJsVuGyS29VogRNIrlszlchFc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvt4hcJi777jXjSesJfPdshT3fHjznJrKu0B9E=w426-h240-k-no"
      },
      {
        "name": "Liz Buie | House of Colour (HOC) Charlotte - SouthPark",
        "description": "Consultant. Rating: 5/5 from 9 reviews",
        "address": "Carmel Rd, Charlotte, North Carolina",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/liz-buie-charlotte-southpark-north-carolina",
        "url": "https://www.google.com/maps/search/?api=1&query=Liz%20Buie%20%7C%20House%20of%20Colour%20(HOC)%20Charlotte%20-%20SouthPark&query_place_id=ChIJS5r-0wKdVogRojt2xFTUzGs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOsVHbexU61KkJWVD6YJ97-x4lhcwEaWlH-Ndft=w408-h380-k-no"
      },
      {
        "name": "Michelle Glass Styling",
        "description": "Image consultant. Rating: 5/5 from 10 reviews",
        "address": "",
        "phone": "(839) 216-0257",
        "website": "https://www.michelleglassstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Michelle%20Glass%20Styling&query_place_id=ChIJBSja5NRueGQRg5nkbiaUbRA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM4IG14tLIJBI6OxrW3upliO80ibk4ChcA1MqDa=w408-h612-k-no"
      }
    ],
    "description": "Charlotte's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Seattle",
    "slug": "seattle",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $400",
    "businesses": [
      {
        "name": "House of Colour Ballard",
        "description": "Image consultant. Rating: 5/5 from 35 reviews",
        "address": "1102 NW 70th St, Seattle, Washington",
        "phone": "(206) 550-0931",
        "website": "https://houseofcolourseattleballard.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Ballard&query_place_id=ChIJ1z0AkSIVkFQRVRga6yVkFoM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOKi97JjFeI1BHWPJoFTKszc706YBCrSwYebtl_=w408-h391-k-no"
      },
      {
        "name": "House of Colour Seattle - Magnolia",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/kim-mathisen-magnolia-washington",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Seattle%20-%20Magnolia&query_place_id=ChIJ08tTUeUVkFQRwSGYHHUA5Zw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNbnCu-QhwbIgCGuaXfLyu5fCSa9ZDDVZaFoxIr=w408-h282-k-no"
      },
      {
        "name": "House of Colour NE Seattle",
        "description": "Image consultant. Rating: 5/5 from 13 reviews",
        "address": "9804 Lake City Way NE, Seattle, Washington",
        "phone": "(206) 697-3663",
        "website": "https://www.houseofcolour.com/stylists/lindy-day-northeast-seattle-washington",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NE%20Seattle&query_place_id=ChIJMT7C-roTkFQR6lQ8WsyIPrY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNI86NzMCRsRy92Kbj7bd0mSsJp_TOy4gp4epKn=w408-h279-k-no"
      },
      {
        "name": "Atelier SOZO",
        "description": "Make-up artist. Rating: 5/5 from 19 reviews",
        "address": "Cedar St, Seattle, Washington",
        "phone": "(202) 525-9351",
        "website": "https://www.ateliersozo.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Atelier%20SOZO&query_place_id=ChIJk8Hv8_UVkFQRneTdIn1ZsGg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMtN1aWownTgUyfV6bQQXDWrnKzU0vo1oewbkjZ=w408-h707-k-no"
      },
      {
        "name": "Intermezzo Salon & Spa",
        "description": "Beauty salon. Rating: 4.9/5 from 65 reviews",
        "address": "407 W Galer St, Seattle, Washington",
        "phone": "(206) 281-7833",
        "website": "https://www.intermezzosalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Intermezzo%20Salon%20%26%20Spa&query_place_id=ChIJqYMUDGwVkFQR26AHW5HCLtU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOir_qaWfwITuHT_sbdI3j9pEZfsWZte4lB6hhs=w408-h272-k-no"
      }
    ],
    "description": "Seattle's color analysis services are influenced by the city's tech-savvy population and rainy climate. Analysts often specialize in fresh and vibrant palettes that brighten up the city's gray days."
  },
  {
    "name": "Denver",
    "slug": "denver",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Dress Your Colors",
        "description": "Image consultant. Rating: 5/5 from 63 reviews",
        "address": "8003 E 50th Dr, Denver, Colorado",
        "phone": "(862) 812-3912",
        "website": "http://dressyourcolors.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Dress%20Your%20Colors&query_place_id=ChIJVyWp4sh7bIcR2WUCT272HN0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOK-vfpUb_GbNHnUnjRmcgRaW_R5TIdy877GcYS=w412-h240-k-no"
      },
      {
        "name": "The Style Studio by Keri Blair",
        "description": "Consultant. Rating: 4.6/5 from 35 reviews",
        "address": "300 Josephine St #260, Denver, Colorado",
        "phone": "(303) 575-1606",
        "website": "https://www.keriblair.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Style%20Studio%20by%20Keri%20Blair&query_place_id=ChIJq76ukpV-bIcRFpH9s7uEqw4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMLGXKc2hN7A0BX9q6aqw-YmgcBf1aao4LqsUOJ=w408-h544-k-no"
      }
    ],
    "description": "Denver's color analysis services reflect the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Washington D.C.",
    "slug": "washington-dc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "GetDressedGo",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.getdressedgo.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-website&utm_content=website_button",
        "url": "https://www.google.com/maps/search/?api=1&query=GetDressedGo&query_place_id=ChIJb7smD7XNt4kRdbFwLa5Gp_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOv7m2VCeXAKyHU87bzXjY1rzHiZovYZVwT79O4=w408-h272-k-no"
      }
    ],
    "description": "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  {
    "name": "Boston",
    "slug": "boston",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $400",
    "businesses": [
      {
        "name": "House of Colour Chestnut Hill",
        "description": "Image consultant. Rating: 5/5 from 8 reviews",
        "address": "",
        "phone": "(617) 606-2110",
        "website": "https://www.houseofcolour.com/stylists/talia-rosenbloom-chestnut-hill-massachusetts",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Chestnut%20Hill&query_place_id=ChIJfUkJ9xwG3QoR-9OVL7R_Pdg",
        "imageUrl": ""
      },
      {
        "name": "SKA Hair | Color | Design",
        "description": "Beauty salon. Rating: 4.7/5 from 82 reviews",
        "address": "185 Essex St, Boston, Massachusetts",
        "phone": "(857) 991-1118",
        "website": "http://www.skabos.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=SKA%20Hair%20%7C%20Color%20%7C%20Design&query_place_id=ChIJxcaBkod744kRDvfPIVuZy5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOkJeDHPhwcwBY-tZPcOSahmzFnRf3SW4mSCPBm=w408-h612-k-no"
      },
      {
        "name": "SKA Scalp | Skin Care",
        "description": "Beauty salon. Rating: 4.6/5 from 102 reviews",
        "address": "36 Essex St, Boston, Massachusetts",
        "phone": "(617) 338-9222",
        "website": "http://www.skabos.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=SKA%20Scalp%20%7C%20Skin%20Care&query_place_id=ChIJBT2V-Hd644kRdOZA0xoMniE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsM9bYZqTNMlUhYuBW2DhvDtsFkY51-l4LHyqa=w408-h534-k-no"
      }
    ],
    "description": "Boston's color analysis services reflect the city's historical charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Las Vegas",
    "slug": "las-vegas",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Styling Insider",
        "description": "Image consultant. Rating: 5/5 from 41 reviews",
        "address": "",
        "phone": "(702) 339-8980",
        "website": "http://www.stylinginsider.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Styling%20Insider&query_place_id=ChIJI6D5a4fByIARWkKOTsEo5Ag",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO4fg0NyisC9nNTPQJyGjTQpYp4W7gcJKuBzOqh=w408-h408-k-no"
      },
      {
        "name": "Diamond Esthetique Salon Suites",
        "description": "Beauty salon. Rating: 5/5 from 5 reviews",
        "address": "6820 W Sahara Ave suite 1, Las Vegas, Nevada",
        "phone": "(702) 912-0983",
        "website": "https://diamondsalonvegas.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Diamond%20Esthetique%20Salon%20Suites&query_place_id=ChIJw1kg8bPByIARpROI9HWGT0k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMzq1pRWJW9xkvANyNYAPphF1kdhXFSlybv9WFE=w408-h306-k-no"
      },
      {
        "name": "Colour Beauty Salon",
        "description": "Beauty salon. Rating: 4.7/5 from 71 reviews",
        "address": "60 N 25th St #130, Las Vegas, Nevada",
        "phone": "(702) 207-0252",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Beauty%20Salon&query_place_id=ChIJR_vBhDjdyIARuLoWZCx7vhc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOtiNvEnMYo0DI5BDnwayWMRofNSTNKx-JieQJa=w408-h408-k-no"
      }
    ],
    "description": "Las Vegas's color analysis services are influenced by the city's vibrant nightlife and desert climate. Analysts often specialize in bold and dramatic palettes suitable for the city's lively fashion scene."
  },
  {
    "name": "Atlanta",
    "slug": "atlanta",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "The Discerning Palette Personal Color Analysis",
        "description": "Stylist",
        "address": "",
        "phone": "(404) 375-5479",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Discerning%20Palette%20Personal%20Color%20Analysis&query_place_id=ChIJE93lWP_MdQMRt1xQNdy9PwU",
        "imageUrl": ""
      },
      {
        "name": "Beauty by KaSh",
        "description": "Beauty salon. Rating: 5/5 from 10 reviews",
        "address": "519 Memorial Dr SE D-06 #203, Atlanta, Georgia",
        "phone": "",
        "website": "http://linktr.ee/BeWellthy",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20by%20KaSh&query_place_id=ChIJ7fgHuUED9YgRrAU-kELqHOY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO5gMaL6_IIE1VZBW4xw20n1AAINwTyDnyNgmNX=w408-h510-k-no"
      },
      {
        "name": "Aviary Beauty + Wellness Summerhill",
        "description": "Beauty salon. Rating: 5/5 from 151 reviews",
        "address": "72 Georgia Ave SE Suite 400, Atlanta, Georgia",
        "phone": "(404) 313-3383",
        "website": "https://www.aviarybeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Aviary%20Beauty%20%2B%20Wellness%20Summerhill&query_place_id=ChIJBYqBIAUD9YgRM_23t0fKim8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOwuV7FjwvX72JAH8B2GKmh5lKM3gakqNOsGUxB=w408-h551-k-no"
      }
    ],
    "description": "Atlanta's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "New Orleans",
    "slug": "new-orleans",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "House of Colour New Orleans West",
        "description": "Stylist",
        "address": "",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/briana-guillory-new-orleans-west-louisiana",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20New%20Orleans%20West&query_place_id=ChIJQSVj01JQ7gURt1HI2_YbTVU",
        "imageUrl": ""
      },
      {
        "name": "Awoo Style",
        "description": "Stylist",
        "address": "2524 Chippewa St, New Orleans, Louisiana",
        "phone": "(678) 570-7368",
        "website": "https://awoostyle.ueniweb.com/?utm_campaign=gmb",
        "url": "https://www.google.com/maps/search/?api=1&query=Awoo%20Style&query_place_id=ChIJHySorKWlIIYRawSNNhj2XNo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPztMOssyQ5yd1DZcwvFTytRh21v7OKRUAemkHA=w408-h408-k-no"
      },
      {
        "name": "Color Bar Salon",
        "description": "Beauty salon. Rating: 4.6/5 from 28 reviews",
        "address": "2039 Magazine St, New Orleans, Louisiana",
        "phone": "(504) 544-5254",
        "website": "http://colorbarsalonnola.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Bar%20Salon&query_place_id=ChIJA2wdntWlIIYRyBtS5DTGmqI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMCKzpAuQ97uNQ3X0q7ZcxLADb33G9jdW6mJNW_=w408-h544-k-no"
      },
      {
        "name": "Glasskin",
        "description": "Facial spa. Rating: 4.9/5 from 186 reviews",
        "address": "4025 Magazine St, New Orleans, Louisiana",
        "phone": "(504) 581-8356",
        "website": "http://www.getglasskin.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Glasskin&query_place_id=ChIJxSpA0vOlIIYRUdBmIJPP-T8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_Q-_ic41awDzb9spe0j80YyU6y4lJk05aERMm=w408-h544-k-no"
      }
    ],
    "description": "New Orleans's color analysis services are influenced by the city's vibrant culture and warm climate. Analysts often specialize in bold and festive palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Portland",
    "slug": "portland",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $400",
    "businesses": [
      {
        "name": "ColorStylePDX",
        "description": "Image consultant. Rating: 4.9/5 from 37 reviews",
        "address": "1420 NW Lovejoy St #418, Portland, Oregon",
        "phone": "(360) 903-3659",
        "website": "http://www.colorstylepdx.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColorStylePDX&query_place_id=ChIJEdtApv4JlVQRMmwKvMINovw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMH56qxiaIXuMwx0GRHpF0qnjazsOA-Po0gfUoU=w408-h497-k-no"
      },
      {
        "name": "The Look Editor",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.thelookeditor.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Look%20Editor&query_place_id=ChIJ9zMQ2IJX5igRYHZs9MZX6O4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP1ZgJYK5JSHe8wjM8wN-oM4vG1QQaXg4XJVrdL=w408-h612-k-no"
      },
      {
        "name": "Colour Authority",
        "description": "Hair salon. Rating: 4.7/5 from 103 reviews",
        "address": "11121 NE Halsey St, Portland, Oregon",
        "phone": "(503) 255-4510",
        "website": "http://www.colourauthority.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Authority&query_place_id=ChIJO4E5knuhlVQRbNJ5zd-2jjs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPVyQb2PUOkBT2l-Qzpgb8kyd9pB48sGbkYRWKN=w408-h306-k-no"
      },
      {
        "name": "Color Palette Salon",
        "description": "Beauty salon. Rating: 5/5 from 26 reviews",
        "address": "10741 SW 49th Ave, Portland, Oregon",
        "phone": "(971) 322-3431",
        "website": "http://thecolorpalettesalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Palette%20Salon&query_place_id=ChIJVVWpAqIMlVQRR8RxLEXAv1U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPKjNG-vng1NHvi1FaY3FSsy3jYdNW-qxjuT46c=w408-h544-k-no"
      }
    ],
    "description": "Portland's color analysis services reflect the city's eclectic and creative vibe. Analysts often focus on unique and artistic palettes that cater to the local arts scene."
  },
  {
    "name": "Nashville",
    "slug": "nashville",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Evolve by Jessi | Personal Styling, Closet Editing, Wardrobe Styling & Management",
        "description": "Stylist. Rating: 5/5 from 10 reviews",
        "address": "",
        "phone": "(317) 694-0282",
        "website": "http://www.jessisheehan.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Evolve%20by%20Jessi%20%7C%20Personal%20Styling%2C%20Closet%20Editing%2C%20Wardrobe%20Styling%20%26%20Management&query_place_id=ChIJj4NVidg71YwRju_KljaE088",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO3RKOcpPc7HY6k5syauuEFBN2wWcGu6pmvfsqR=w426-h240-k-no"
      }
    ],
    "description": "Nashville's color analysis services are influenced by the city's vibrant music scene and Southern charm. Analysts often specialize in bold and stylish palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Detroit",
    "slug": "detroit",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Color Suite",
        "description": "Beauty salon",
        "address": "3792 Clements St #3, Detroit, Michigan",
        "phone": "(313) 955-4077",
        "website": "http://www.appointfix.com/book/Color-Suite",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Suite&query_place_id=ChIJ48xXuefNJIgRqGNVg1f6CDM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmZPUzzqLZ2yiSA5TOjhTrdaxKfmaq-PrkrpSc=w408-h544-k-no"
      }
    ],
    "description": "Detroit's color analysis scene blends industrial practicality with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Baltimore",
    "slug": "baltimore",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Wardrobe Wizard of Baltimore",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "1620 Roundhill Rd, Baltimore, Maryland",
        "phone": "(410) 513-9496",
        "website": "http://www.wardrobewiz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Wardrobe%20Wizard%20of%20Baltimore&query_place_id=ChIJGXTbnuoEyIkR1keb0f-MnO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM2R9ey-YpidI6ue3ZzMSi5c6HvawR8grtLgD3B=w408-h664-k-no"
      }
    ],
    "description": "Baltimore's color analysis services reflect the city's historical charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "LA",
    "slug": "la",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Jennifer Butler Living Colors",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "848 S Lucerne Blvd, Los Angeles, California",
        "phone": "(323) 931-2626",
        "website": "http://jenniferbutlercolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Jennifer%20Butler%20Living%20Colors&query_place_id=ChIJIXumc-24woARdAHbQJdIlz8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=46RAvyMI53m1sP09Roe2Ag&cb_client=search.gws-prod.gps&w=408&h=240&yaw=110.95381&pitch=0&thumbfov=100"
      },
      {
        "name": "colorlab tierrasal",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "548 S Spring St #101, Los Angeles, California",
        "phone": "(213) 787-3464",
        "website": "http://juvenciojimenez-valdez.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=colorlab%20tierrasal&query_place_id=ChIJT4rTnErGwoARQtGnzIKLP-E",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=s60QVGkfjrT9BaZ3ppONFA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=286.90656&pitch=0&thumbfov=100"
      },
      {
        "name": "COLORBRITY",
        "description": "Image consultant. Rating: 5/5 from 198 reviews",
        "address": "3407 W 6th St #523, Los Angeles, California",
        "phone": "(213) 537-8620",
        "website": "https://www.colorbrity.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLORBRITY&query_place_id=ChIJk3niDnDHwoARBdPo74lTcB0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO91fJnDk5cWe6jslN6hava9rDxF61oDAHg8SVy=w408-h544-k-no"
      },
      {
        "name": "MiKADO Personal Styling",
        "description": "Image consultant. Rating: 5/5 from 42 reviews",
        "address": "1249 S Grand Ave Suite 504, Los Angeles, California",
        "phone": "(800) 982-1628",
        "website": "http://www.mikadopersonalstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=MiKADO%20Personal%20Styling&query_place_id=ChIJmTEOCLfHwoARauZziCpJOI4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmpj0lC76OYDwoaxJC1dyu4noGI1znCfVXsIIw=w408-h365-k-no"
      }
    ],
    "description": "In Los Angeles, color analysis services cater to both the entertainment industry and everyday fashion lovers. The sunny climate influences color choices, with many analysts specializing in vibrant, camera-ready palettes."
  },
  {
    "name": "NYC",
    "slug": "nyc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$160 - $450",
    "businesses": [
      {
        "name": "Seklab Color Analysis Studio",
        "description": "Image consultant. Rating: 5/5 from 81 reviews",
        "address": "202 W 40th St Suite 400, New York, New York",
        "phone": "(332) 999-4179",
        "website": "https://www.seklabnyc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Seklab%20Color%20Analysis%20Studio&query_place_id=ChIJwxeSMoRZwokR2BBukhq4lSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlfS2fOpii7qpV5M8BXyKf45AA6DJvTiYJ3GQr=w408-h544-k-no"
      },
      {
        "name": "House of Colour NYC - Downtown Brooklyn",
        "description": "Stylist. Rating: 5/5 from 11 reviews",
        "address": "325 Gold St Suite 703A, Brooklyn, New York",
        "phone": "(929) 266-7228",
        "website": "https://houseofcolour.com/fernandavazquez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NYC%20-%20Downtown%20Brooklyn&query_place_id=ChIJPRb8F6tbwokRbe-woVjHwO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOUh0ZE6PWUcygcamfo11_uH1HiI4QGjPSEEqSC=w408-h544-k-no"
      },
      {
        "name": "Lily's Color Lab",
        "description": "Stylist. Rating: 4.9/5 from 71 reviews",
        "address": "1239 Broadway, New York, New York",
        "phone": "(202) 813-9687",
        "website": "https://lilyscolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lily's%20Color%20Lab&query_place_id=ChIJqxVpZRFbwokRPYp0fw-xRUI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7oWbYDctv5kuqOqA8g16iw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=91.2156&pitch=0&thumbfov=100"
      },
      {
        "name": "The Outfit Curator",
        "description": "Stylist. Rating: 4.8/5 from 17 reviews",
        "address": "123 W 81st St, New York, New York",
        "phone": "",
        "website": "http://www.theoutfitcurator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Outfit%20Curator&query_place_id=ChIJjUVyL1BZwokRE7p_2_X0RMI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQjkI8r7-F7JkalGMJ0YXHyz8VSArJYQ_0AG0T=w408-h612-k-no"
      }
    ],
    "description": "New York City's color analysis scene is as diverse as its population. Services range from high-end fashion consultations to more affordable options, catering to the city's fast-paced lifestyle and varying seasons."
  },
  {
    "name": "SF",
    "slug": "sf",
    "type": "city",
    "country": "United States",
    "pricingRange": "$175 - $450",
    "businesses": [
      {
        "name": "K Griffin Style Color Analysis and Wardrobe Consultant",
        "description": "Stylist. Rating: 5/5 from 79 reviews",
        "address": "130 Lake St, San Francisco, California",
        "phone": "(415) 521-9316",
        "website": "https://www.kgriffinstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=K%20Griffin%20Style%20Color%20Analysis%20and%20Wardrobe%20Consultant&query_place_id=ChIJYcBm9HyHhYARNk6wwVNQz98",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMBiquGSNFyOwSehWWKP5TDl8NDNtvukUNkFihr=w408-h304-k-no"
      },
      {
        "name": "Colors With Kate",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorswithkate.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20With%20Kate&query_place_id=ChIJb9AAmOsO-CwRjmjy_aqOsSM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3qvDzt-MMqZfR0U2M3AbNYt_xSFpVMzqOv0Kf=w408-h306-k-no"
      },
      {
        "name": "Lili Henry - Personal Fashion Stylist",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "Union St, San Francisco, California",
        "phone": "(925) 788-4504",
        "website": "https://www.lilihenrystylist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lili%20Henry%20-%20Personal%20Fashion%20Stylist&query_place_id=ChIJxfGXZ9CAhYARSNhsxX58RCc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPQFLdBfR942vHK6JQnxzldlcBnZ9v8qSwST5Io=w408-h272-k-no"
      },
      {
        "name": "Ensemble Personal Image Consulting",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(415) 741-0011",
        "website": "https://www.ensemble-consulting.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ensemble%20Personal%20Image%20Consulting&query_place_id=ChIJU5V-2qeAhYARdfdzhepR6B0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMJfqGCaRwMjLxhe3U7Pxvmj4HnoSAmo5eynami=w408-h301-k-no"
      }
    ],
    "description": "San Francisco's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's foggy climate and casual-chic style influence color choices and analysis methods."
  },
  {
    "name": "DC",
    "slug": "dc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "Apopofcolor by Terri Fe’",
        "description": "Hair salon. Rating: 5/5 from 3 reviews",
        "address": "Phenix Salon, 2350 Washington Pl NE Suite 131, Washington, District of Columbia",
        "phone": "(727) 279-0403",
        "website": "https://my-site-109500-100681.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Apopofcolor%20by%20Terri%20Fe%E2%80%99&query_place_id=ChIJSTk-dCO5t4kRrEpPn8iChGQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM7tTEkMcDOTtTgh8JLxnVIjX2xdcnDEpnHp4y3=w408-h272-k-no"
      },
      {
        "name": "GetDressedGo",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.getdressedgo.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-website&utm_content=website_button",
        "url": "https://www.google.com/maps/search/?api=1&query=GetDressedGo&query_place_id=ChIJb7smD7XNt4kRdbFwLa5Gp_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOv7m2VCeXAKyHU87bzXjY1rzHiZovYZVwT79O4=w408-h272-k-no"
      }
    ],
    "description": "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  {
    "name": "Washington",
    "slug": "washington",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "Color Lab Salon by TSR",
        "description": "Hair salon. Rating: 4.8/5 from 585 reviews",
        "address": "1607 Connecticut Ave NW, Washington, District of Columbia",
        "phone": "(202) 800-8816",
        "website": "http://www.besthairdc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Lab%20Salon%20by%20TSR&query_place_id=ChIJScAJymq3t4kRoc8Am0vhOkg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOGCB_nE9NWahtieDv-btRv8av0W3NXDtqm3soc=w408-h544-k-no"
      }
    ],
    "description": "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  {
    "name": "Philly",
    "slug": "philly",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "House of Colour - Jill Rafter",
        "description": "Image consultant",
        "address": "",
        "phone": "(215) 840-3414",
        "website": "https://max.houseofcolour.co.uk/book/27275-Jill_Rafter",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Jill%20Rafter&query_place_id=ChIJK_heji1j60cRSlNbjfSO7QU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf2l4QO5Mr2tNPTD8ZwypYaH3hcWqgSgJx4amQ=w408-h408-k-no"
      },
      {
        "name": "Richard Nicholas Hair Studio",
        "description": "Beauty salon. Rating: 4.8/5 from 420 reviews",
        "address": "1716 Sansom St, Philadelphia, Pennsylvania",
        "phone": "(215) 567-4790",
        "website": "http://www.richardnicholas.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Richard%20Nicholas%20Hair%20Studio&query_place_id=ChIJG2XUjjDGxokRV9JveU6X1Jk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPmFo5DA84DZsMpHezO8ReOYplluDJVL4VdiUZc=w408-h544-k-no"
      },
      {
        "name": "La Pearl Beauty Emporium Inc",
        "description": "Beauty salon. Rating: 4.4/5 from 83 reviews",
        "address": "3857 Lancaster Ave, Philadelphia, Pennsylvania",
        "phone": "(215) 387-8232",
        "website": "https://lapearlbeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=La%20Pearl%20Beauty%20Emporium%20Inc&query_place_id=ChIJj8z_BVXGxokRNllmGChqamk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOjvDKIR2SWC0dKpPW7BKxTPYqurllDsppngiBO=w408-h544-k-no"
      },
      {
        "name": "Skin House Facial Bar Rittenhouse",
        "description": "Facial spa. Rating: 4.7/5 from 46 reviews",
        "address": "2020 Locust St, Philadelphia, Pennsylvania",
        "phone": "(215) 201-3953",
        "website": "https://www.skinhousefacialbar.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skin%20House%20Facial%20Bar%20Rittenhouse&query_place_id=ChIJm7w0hr7HxokRaLJLd1dBPP0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPnFomYWTxbx_t9heq5jqk2gdMVBoBLho4X8ixu=w408-h612-k-no"
      }
    ],
    "description": "Philadelphia's color analysis scene blends historical charm with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Minneapolis",
    "slug": "minneapolis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Brenda Iris - Mindful Styling",
        "description": "Image consultant. Rating: 5/5 from 31 reviews",
        "address": "1624 Harmon Pl, Minneapolis, Minnesota",
        "phone": "",
        "website": "http://www.mindful-styling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Brenda%20Iris%20-%20Mindful%20Styling&query_place_id=ChIJs-1vFM07s1IRg48KRUHw55Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNF-8DRW3GAnj9kdQFDc4HE02DBvHom_vtjyDfS=w426-h240-k-no"
      },
      {
        "name": "The Refinery",
        "description": "Waxing hair removal service. Rating: 4.6/5 from 262 reviews",
        "address": "34 13th Ave NE Suite 102, Minneapolis, Minnesota",
        "phone": "(612) 331-9055",
        "website": "http://refinerympls.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Refinery&query_place_id=ChIJ89oZKhIts1IR2B4JGhCQa_c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP0yvBf378Ckpupx6oEXLbpjTitZYU8MyrtZN4o=w532-h240-k-no"
      }
    ],
    "description": "Minneapolis's color analysis services reflect the city's Midwestern practicality and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "St. Louis",
    "slug": "st-louis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Style Your Glow",
        "description": "Image consultant. Rating: 5/5 from 17 reviews",
        "address": "",
        "phone": "(314) 907-8088",
        "website": "http://www.styleyourglow.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Your%20Glow&query_place_id=ChIJCwbm15gys1IRdZvPsclODFQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJqIxWOPI5U6blc7HATVmjYnQogmWzZDVdxNWL=w408-h306-k-no"
      },
      {
        "name": "TheHairXP!",
        "description": "Beauty salon. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.thehairxp.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=TheHairXP!&query_place_id=ChIJz4Hqutq02IcRP9QuyQpQVOk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPE8yVLq3M81uuDREJYSWCXK4yDcL8hkW8vO6j7=w426-h240-k-no"
      }
    ],
    "description": "St. Louis's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Kansas City",
    "slug": "kansas-city",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Color Club KC",
        "description": "Stylist. Rating: 5/5 from 39 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorclubkc.trafft.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Club%20KC&query_place_id=ChIJzalewgyeLEgRaLB1usXCDNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDVoHmsVrTplLLGK-dUPmlcmxy6A0pvsBpt8Hy=w408-h259-k-no"
      },
      {
        "name": "House of Heavilin Beauty College",
        "description": "Beauty salon. Rating: 4.6/5 from 142 reviews",
        "address": "5720 Troost Ave, Kansas City, Missouri",
        "phone": "(816) 229-9000",
        "website": "http://kc-hair.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Heavilin%20Beauty%20College&query_place_id=ChIJR4NX2XfvwIcRw5TtKNcuQ4U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMnVFybfJXAU8_QFObK1gfbcJm6Jvi4c-k8FUhW=w408-h347-k-no"
      },
      {
        "name": "Antidote Hair Salon and Beauty Bar",
        "description": "Beauty salon. Rating: 4.6/5 from 188 reviews",
        "address": "211 Westport Rd, Kansas City, Missouri",
        "phone": "(816) 756-5606",
        "website": "http://antidotesalonkc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Antidote%20Hair%20Salon%20and%20Beauty%20Bar&query_place_id=ChIJ9T5IdNvvwIcRwXSBPthlfa4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNN4fai--Losqm3kRTs0VsYLgvM4yKeV9bX9y8O=w408-h510-k-no"
      }
    ],
    "description": "Kansas City's color analysis services reflect the city's Midwestern practicality and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Cleveland",
    "slug": "cleveland",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Colour Me Beautiful Salon",
        "description": "Beauty salon. Rating: 3.7/5 from 6 reviews",
        "address": "12501 Union Ave, Cleveland, Ohio",
        "phone": "(216) 278-6464",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful%20Salon&query_place_id=ChIJm66hTbL9MIgR_PmxnPkVO9c",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=qWyx2FRXRYJx36fTT7uztQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=346.65488&pitch=0&thumbfov=100"
      }
    ],
    "description": "Cleveland's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Pittsburgh",
    "slug": "pittsburgh",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Signature Styling Services",
        "description": "Stylist. Rating: 5/5 from 16 reviews",
        "address": "",
        "phone": "",
        "website": "https://wearyoursignature.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Signature%20Styling%20Services&query_place_id=ChIJx4sUS1XvNIgRhIsOSOHXvuY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNqqlJ7OOng-wDpMno1UqHLywnFNCWxCNQsVZ3A=w408-h272-k-no"
      },
      {
        "name": "Badass Styling",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "3361 Delaware St, Pittsburgh, Pennsylvania",
        "phone": "(412) 225-5109",
        "website": "http://www.badassstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Badass%20Styling&query_place_id=ChIJ39w5hgj0NIgRESR0UXj_Fls",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOBLWaPfxRWaBvol3xGrVpgN5Gq7sWzeOlm5qYB=w408-h368-k-no"
      }
    ],
    "description": "Pittsburgh's color analysis services reflect the city's industrial heritage and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Cincinnati",
    "slug": "cincinnati",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $300",
    "businesses": [
      {
        "name": "Valerio Consulting",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "1127 Belvedere St, Cincinnati, Ohio",
        "phone": "(513) 703-0164",
        "website": "http://www.valeriollc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Valerio%20Consulting&query_place_id=ChIJr26Qt-CzQYgRyxoEFooLrVk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8ETo1nlU2_5GcxcT0lfzMgLcW_VsnrlFZo6O5=w408-h544-k-no"
      },
      {
        "name": "A Style Breeze Personal Stylist",
        "description": "Image consultant. Rating: 5/5 from 57 reviews",
        "address": "",
        "phone": "(513) 673-0013",
        "website": "http://www.astylebreeze.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=A%20Style%20Breeze%20Personal%20Stylist&query_place_id=ChIJ2efVl2OzQYgRod3f7BiZnkA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPmrTluaK24f1xAqOECF3gqPH9FmzMETA1tDYLT=w408-h510-k-no"
      },
      {
        "name": "Beatriz Justi | Image Consultancy",
        "description": "Image consultant. Rating: 5/5 from 2 reviews",
        "address": "",
        "phone": "(513) 568-2832",
        "website": "http://www.beatrizjusti.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beatriz%20Justi%20%7C%20Image%20Consultancy&query_place_id=ChIJ7xvIpvCGFCQR3P6NWTIvEFY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN7u5NWGEwjTmK7zdocJEXbNIM6lYiTZhUCV7-X=w408-h612-k-no"
      },
      {
        "name": "Omega Beauty & Wellness",
        "description": "Beauty salon. Rating: 4.9/5 from 317 reviews",
        "address": "1126 Broadway, Cincinnati, Ohio",
        "phone": "(513) 402-2216",
        "website": "http://www.omegabeautycincy.com/?y_source=1_MjI1MDM3NjQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
        "url": "https://www.google.com/maps/search/?api=1&query=Omega%20Beauty%20%26%20Wellness&query_place_id=ChIJrUDpjvazQYgRb59Om0zYj3M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPCfjRuiWm-M8wKbBRl-XHVNBOkRA2xp6mqBOEm=w408-h306-k-no"
      }
    ],
    "description": "Cincinnati's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  {
    "name": "Orlando",
    "slug": "orlando",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Moon Over Star - Personal Color Analyst",
        "description": "Stylist. Rating: 5/5 from 10 reviews",
        "address": "201 S Bumby Ave, Orlando, Florida",
        "phone": "(407) 917-7131",
        "website": "https://moonoverstar.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Moon%20Over%20Star%20-%20Personal%20Color%20Analyst&query_place_id=ChIJ448wia9754gRxcyhRJWe9N8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOVAa4L7iCVq0mXhBf2AfvTewzM0mhQhhd47k94=w408-h408-k-no"
      },
      {
        "name": "AC.Styles -- Personal Shopping & Styling Services",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "1726 E Jersey Ave, Orlando, Florida",
        "phone": "(321) 521-4064",
        "website": "http://www.ac-styles.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=AC.Styles%20--%20Personal%20Shopping%20%26%20Styling%20Services&query_place_id=ChIJT0Br0Gl754gRXrw0DgZT50c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOIrAq9q61UhPXJoUxwa13a6_8KO6LwR5gt8dtX=w408-h272-k-no"
      },
      {
        "name": "Bloom Esthetic Studio",
        "description": "Skin care clinic. Rating: 5/5 from 102 reviews",
        "address": "56 Park Lake St, Orlando, Florida",
        "phone": "(407) 494-1168",
        "website": "http://www.bloomestheticstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bloom%20Esthetic%20Studio&query_place_id=ChIJO6o1-F1754gRPxqKBtSa4Tw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_nz6taA1z1xHPTeIPS213ClaBDkNVTi1XTpQg=w408-h444-k-no"
      }
    ],
    "description": "Orlando's color analysis services are influenced by the city's vibrant tourism industry and sunny climate. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Tampa",
    "slug": "tampa",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Invent Your Image",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "4320 W El Prado Blvd Suite 19, Tampa, Florida",
        "phone": "(813) 766-8375",
        "website": "http://www.inventyourimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Invent%20Your%20Image&query_place_id=ChIJY13WUYPDwogRSdqG--JXykw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMn6r2KCxT83IbAAGRuhN9OcASFt9PKY7cbsFAl=w408-h271-k-no"
      },
      {
        "name": "Femme Akoi Beauty Studio Tampa",
        "description": "Beauty salon. Rating: 4.9/5 from 34 reviews",
        "address": "2002 E 5th Ave UNIT 104, Tampa, Florida",
        "phone": "(727) 266-2233",
        "website": "http://www.femmeakoi.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Femme%20Akoi%20Beauty%20Studio%20Tampa&query_place_id=ChIJxdiVsV3EwogRCDs4DLAyr6g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNntheS_A_DUvECQn-vKmF7PvuMTnzUv0PTdGV-=w426-h240-k-no"
      },
      {
        "name": "Glow By Natalia Barlow LLC",
        "description": "Image consultant. Rating: 5/5 from 49 reviews",
        "address": "4615 W North A St, Tampa, Florida",
        "phone": "(813) 603-7141",
        "website": "https://www.nataliabarlow.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Glow%20By%20Natalia%20Barlow%20LLC&query_place_id=ChIJD6Z0j3PDwogRJ0A8wGm-Pgc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNL33Bx3MCYLMZYwCDjmx_WzGD5L2_Epaid1Zs2=w408-h611-k-no"
      },
      {
        "name": "Addicted to Skin the Beauty & Aesthetics Studio",
        "description": "Beauty salon. Rating: 4.9/5 from 52 reviews",
        "address": "5009 N Central Ave, Tampa, Florida",
        "phone": "(727) 648-0619",
        "website": "http://www.addictedtoskin.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Addicted%20to%20Skin%20the%20Beauty%20%26%20Aesthetics%20Studio&query_place_id=ChIJQ_rE_E_9wogREbgOEZK_-00",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNwd-rSES3Bxi2Ht7GNrQpnEo1m2W31ju2xdkt7=w408-h408-k-no"
      }
    ],
    "description": "Tampa's color analysis services reflect the city's coastal climate and vibrant culture. Analysts often focus on fresh and breezy palettes suitable for the varying climate."
  },
  {
    "name": "Raleigh",
    "slug": "raleigh",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "Color Me Beautiful Raleigh",
        "description": "Image consultant. Rating: 5/5 from 67 reviews",
        "address": "7486 Creedmoor Rd, Raleigh, North Carolina",
        "phone": "",
        "website": "http://colormebeautifulraleigh.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Me%20Beautiful%20Raleigh&query_place_id=ChIJ-8iVJjT3rIkRKcE9Oe4_dAk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDS6tIHXnkwFbW6gzLh9LztaanqSH8oF8CK6I=w408-h714-k-no"
      },
      {
        "name": "KroCia LLC (Image/Wardrobe Consultant Raleigh-Durham-Charlotte)",
        "description": "Image consultant. Rating: 5/5 from 49 reviews",
        "address": "1100 Logger Ct Suite f102, Raleigh, North Carolina",
        "phone": "(919) 322-9369",
        "website": "http://yourstyleconsultant.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=KroCia%20LLC%20(Image%2FWardrobe%20Consultant%20Raleigh-Durham-Charlotte)&query_place_id=ChIJJwR_NQFZrIkR73YKIotyfrs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOyqfi0gk-nTet0kKd-pwvxeJVe2zCholN8-nXJ=w428-h240-k-no"
      },
      {
        "name": "Color Scheme G.B.A. LLC",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "6350 Plantation Center Dr # 111, Raleigh, North Carolina",
        "phone": "(504) 388-1177",
        "website": "http://color-scheme-gba.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Scheme%20G.B.A.%20LLC&query_place_id=ChIJqbNkhb1ZrIkRREUgCBvS7aQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHJAmbLVtIgMAykmuz74fxzmKZII6bvgPQ5sV2=w408-h542-k-no"
      },
      {
        "name": "The Color and Curl Co•Lab",
        "description": "Hair salon. Rating: 5/5 from 27 reviews",
        "address": "7361 Six Forks Rd Suite 112, Raleigh, North Carolina",
        "phone": "(919) 617-7760",
        "website": "http://thecolorandcurlcolab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20and%20Curl%20Co%E2%80%A2Lab&query_place_id=ChIJmbhra8VZrIkRdGzhQCc1quU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNSy7BXCsKQ9NS9tzrvU9U-wgMfEjezGoaX9km_=w408-h544-k-no"
      },
      {
        "name": "The Makeover Expert",
        "description": "Consultant. Rating: 5/5 from 9 reviews",
        "address": "7521 Windmill Harbor Way, Raleigh, North Carolina",
        "phone": "(919) 624-2294",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Makeover%20Expert&query_place_id=ChIJ-8Qlenf6rIkRNyjeFn8GOys",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=O9MFAFArXN3UvpDpOSD_bw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=71.566376&pitch=0&thumbfov=100"
      }
    ],
    "description": "Raleigh's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Salt Lake City",
    "slug": "salt-lake-city",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Salt Lake City's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Sacramento",
    "slug": "sacramento",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Colors Salon & Education",
        "description": "Beauty salon. Rating: 4.8/5 from 37 reviews",
        "address": "1417 28th St, Sacramento, California",
        "phone": "(916) 538-6319",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20Salon%20%26%20Education&query_place_id=ChIJw6Svd-vQmoARyOh1f2t6POo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMjrNDSyzXVM_yh54vhhdMRJChIqpHfz7KcGCtR=w408-h272-k-no"
      },
      {
        "name": "Color by Kaela",
        "description": "Hairdresser. Rating: 5/5 from 6 reviews",
        "address": "927 20th St, Sacramento, California",
        "phone": "(530) 906-4462",
        "website": "https://colorbykaela.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20by%20Kaela&query_place_id=ChIJx1RpApnRmoARJ1PkLy4Yeac",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPNpn2sftFHu2IldgcJBz9P_4_tylqALAKdOSBD=w408-h612-k-no"
      },
      {
        "name": "Studio Solstice",
        "description": "Beauty salon. Rating: 5/5 from 9 reviews",
        "address": "2060 Markham Way, Sacramento, California",
        "phone": "(279) 499-2652",
        "website": "http://thestudiosolstice.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20Solstice&query_place_id=ChIJ_dKWkbvRmoARoSYnZWLr7jY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP5U5TyyAiELQm-FvVgzHbqzPDyi9OgcOQGfBjZ=w408-h611-k-no"
      },
      {
        "name": "RKS SALON",
        "description": "Beauty salon. Rating: 4.6/5 from 51 reviews",
        "address": "2212 K St, Sacramento, California",
        "phone": "(916) 444-7474",
        "website": "http://rkssalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=RKS%20SALON&query_place_id=ChIJfUHwWsfRmoARlVaZqANTnZw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOxJMEl4Ma1HCCqPcHradXRCdMd63AXOfrB_6hG=w408-h544-k-no"
      }
    ],
    "description": "Sacramento's color analysis services reflect the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Honolulu",
    "slug": "honolulu",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Elizabeth Kosich Styling",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "1109 Maunakea St Loft #217, Honolulu, Hawaii",
        "phone": "(917) 743-9240",
        "website": "https://elizabethkosichstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Elizabeth%20Kosich%20Styling&query_place_id=ChIJ50-hd0TYPU0RA4-k0sAi92M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNoECI2r2JPNse7GvglazmXFlcVUy7qvXppuLiR=w408-h306-k-no"
      },
      {
        "name": "COLOR LOUNGE",
        "description": "Beauty salon. Rating: 4.8/5 from 27 reviews",
        "address": "3660 Waialae Ave Lounge, Honolulu, Hawaii",
        "phone": "(808) 734-5858",
        "website": "http://www.colorloungehawaii.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLOR%20LOUNGE&query_place_id=ChIJy1SBcIZtAHwROAMC0rvoXFo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJ3S54XaXmKCPY-8BRNBvw0Ydu_LTJl4hoHfOV=w426-h240-k-no"
      },
      {
        "name": "Ume Personal Color",
        "description": "Image consultant",
        "address": "",
        "phone": "(323) 991-2105",
        "website": "https://www.umepersonalcolor.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=7433919747832458961",
        "url": "https://www.google.com/maps/search/?api=1&query=Ume%20Personal%20Color&query_place_id=ChIJ2aSMcpKgsAsRFFHq6tsdmUc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8WlGLYI8t0PwPd5zxkGVkL-0RsQxdZyLwPe9s=w408-h612-k-no"
      },
      {
        "name": "Alluring LLC",
        "description": "Consultant. Rating: 5/5 from 7 reviews",
        "address": "1617 Kapiolani Blvd STE 206, Honolulu, Hawaii",
        "phone": "(808) 368-3709",
        "website": "https://www.alluringllc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Alluring%20LLC&query_place_id=ChIJ__8vjfFtAHwRgBSvcNneuUQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOZIAKFId9fYS4XKY-LUNN-dKmXm5a-T2rY8XeR=w408-h408-k-no"
      }
    ],
    "description": "Honolulu's color analysis services are influenced by the city's tropical climate and vibrant culture. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Anchorage",
    "slug": "anchorage",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "The color palette by Carrie peacock",
        "description": "Hairdresser",
        "address": "135 W Dimond Blvd #200, Anchorage, Alaska",
        "phone": "(480) 458-8616",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20color%20palette%20by%20Carrie%20peacock&query_place_id=ChIJdVAcKcKZyFYRExFb7nV3xCA",
        "imageUrl": ""
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ],
    "description": "Anchorage's color analysis services reflect the city's unique climate and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Albuquerque",
    "slug": "albuquerque",
    "type": "city",
    "country": "United States",
    "pricingRange": "$100 - $300",
    "businesses": [
      {
        "name": "The Honest Image",
        "description": "Make-up artist. Rating: 5/5 from 70 reviews",
        "address": "7107 Prospect Pl NE, Albuquerque, New Mexico",
        "phone": "(505) 250-5335",
        "website": "http://thehonestimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Honest%20Image&query_place_id=ChIJ62NLCegLIocRL02tT2HZltw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNwSumYwssshxHxqRGl61LDSNtnytA83aF1cDxk=w408-h272-k-no"
      },
      {
        "name": "House of Colour Northeast Albuquerque",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "(505) 226-6036",
        "website": "https://max.houseofcolour.co.uk/book/23914-TerynMartinez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Northeast%20Albuquerque&query_place_id=ChIJCZvCdOzbsYMR3x4Gw8eeThI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNEXtPMMUHllNH-PyEvOEKytsDXexHfzGc9WkbV=w408-h408-k-no"
      },
      {
        "name": "Bella Derm",
        "description": "Skin care clinic. Rating: 4.8/5 from 30 reviews",
        "address": "5701 Carmel Ave NE Suite B, Albuquerque, New Mexico",
        "phone": "(505) 308-1566",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Bella%20Derm&query_place_id=ChIJDQg6-fR1IocRg5JvDXr0GvE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNk5yyJ9RI3Rp8QwLfBawP1lScPstbReTks5flF=w408-h292-k-no"
      },
      {
        "name": "M & Company Hair and Color Lounge",
        "description": "Beauty salon. Rating: 4.7/5 from 185 reviews",
        "address": "10701 Montgomery Blvd NE Ste K, Albuquerque, New Mexico",
        "phone": "(505) 296-0374",
        "website": "https://www.mcohairlounge.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=M%20%26%20Company%20Hair%20and%20Color%20Lounge&query_place_id=ChIJ2wiMP-6fGIcRUMrsDu8BzdU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOed6nv7lP22oe7I-Mhbb_TKd-w-kAypFYexn1m=w408-h408-k-no"
      },
      {
        "name": "Indelible Beauty",
        "description": "Beauty salon. Rating: 4.9/5 from 68 reviews",
        "address": "10400 Academy Rd NE # 140, Albuquerque, New Mexico",
        "phone": "(505) 980-6326",
        "website": "http://indeliblebeautybyjulie.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Indelible%20Beauty&query_place_id=ChIJq-08skZxIocR78dQIBBDocE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMUs-uMce3rFdK9cAv0wJh4KmSIPS5eS-Mui0hG=w408-h421-k-no"
      }
    ],
    "description": "Albuquerque's color analysis services reflect the city's unique blend of cultural influences and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Houston",
    "slug": "houston",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "ByMaja Color Analysis Services",
        "description": "Image consultant. Rating: 5/5 from 183 reviews",
        "address": "540 Heights Blvd Suite 117, Houston, Texas",
        "phone": "",
        "website": "https://bymaja.org/",
        "url": "https://www.google.com/maps/search/?api=1&query=ByMaja%20Color%20Analysis%20Services&query_place_id=ChIJD_bLtwvHQIYRIiV4wjznfCA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO0_C9IsRyN0VaWw6U5SS9N85jWf3CW0vOATKsM=w408-h612-k-no"
      },
      {
        "name": "Love of Color HTX",
        "description": "Image consultant. Rating: 5/5 from 48 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.loveofcolorhtx.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Love%20of%20Color%20HTX&query_place_id=ChIJr5zfzklLR4YRiHmV4iGFL1s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPFfzkUyoFQ4IHBdtpZYv24OQkMCAWMcB5422xg=w408-h544-k-no"
      },
      {
        "name": "Colour Haus",
        "description": "Hair salon. Rating: 5/5 from 15 reviews",
        "address": "5757 Woodway Dr G100 Studio #6, Houston, Texas",
        "phone": "(832) 630-1237",
        "website": "http://colour-haus.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Haus&query_place_id=ChIJVR5iTZXDQIYRb5SROeM_5-Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOs17s0GlXEqoda2YVpnSU8Bgitj_3-uuq8Kjvo=w408-h544-k-no"
      },
      {
        "name": "Selina Möller Color Consulting",
        "description": "Consultant. Rating: 3/5 from 2 reviews",
        "address": "",
        "phone": "(713) 471-0653",
        "website": "http://selinamoller.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Selina%20M%C3%B6ller%20Color%20Consulting&query_place_id=ChIJy2UXaUnBQIYRLjQrQT19aY8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO8Ui9t1mCMXRqy4nBGPTdHlPfL6kHWnZo3XFSy=w408-h408-k-no"
      }
    ],
    "description": "Houston's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Phoenix",
    "slug": "phoenix",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "House of Colour Central Scottsdale - Brooke Svec",
        "description": "Stylist. Rating: 5/5 from 20 reviews",
        "address": "1411 E Orangewood Ave, Phoenix, Arizona",
        "phone": "(402) 759-1430",
        "website": "https://max.houseofcolour.co.uk/book/17314-BrookeSvec",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Central%20Scottsdale%20-%20Brooke%20Svec&query_place_id=ChIJgY0OJ7VtK4cRh3CkwyX9LuA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0yyYNRMaH2y8JvfG_BCk0zfRzODVw7aO7-B0y=w408-h272-k-no"
      },
      {
        "name": "Color & Commentary",
        "description": "Beauty salon. Rating: 5/5 from 23 reviews",
        "address": "19805 N 51st Ave #17, Glendale, Arizona",
        "phone": "(480) 271-7415",
        "website": "https://color-commentary.chrone.work/?utm_source=googlemaps&utm_medium=googlemapsprofile&utm_campaign=googlemaps",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20%26%20Commentary&query_place_id=ChIJ99xlVOlpK4cRNaMo3sCTo7g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP67Jd09xawz7j4d7jnT-L1R7UxbuOw1Vhiw4oc=w408-h408-k-no"
      },
      {
        "name": "Mima's Beauty College",
        "description": "Beauty school. Rating: 4.8/5 from 86 reviews",
        "address": "8115 N 35th Ave Suite #4, Phoenix, Arizona",
        "phone": "(602) 698-1499",
        "website": "http://mimasbc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Mima's%20Beauty%20College&query_place_id=ChIJm1vCBINtK4cR2aoNJjBKt24",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMs5u-yGC7O92ZgA3bqE1sm0GnnSOf1XcrFnDkL=w408-h306-k-no"
      },
      {
        "name": "The Studio Academy of Beauty: Phoenix",
        "description": "Beauty school. Rating: 4.9/5 from 1960 reviews",
        "address": "8046 N 19th Ave, Phoenix, Arizona",
        "phone": "(602) 841-5993",
        "website": "https://thestudioacademyofbeauty.com/?utm_source=google&utm_medium=gmb&utm_campaign=phoenix",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Studio%20Academy%20of%20Beauty%3A%20Phoenix&query_place_id=ChIJEY2_P-NsK4cR_wsvhV7Xo38",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMyGXE-M6AhG1zxhKHPsMiOthLOio-ReJjirpjA=w427-h240-k-no"
      }
    ],
    "description": "Phoenix's color analysis services reflect the city's desert climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Philadelphia",
    "slug": "philadelphia",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "House of Colour - Jill Rafter",
        "description": "Image consultant",
        "address": "",
        "phone": "(215) 840-3414",
        "website": "https://max.houseofcolour.co.uk/book/27275-Jill_Rafter",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Jill%20Rafter&query_place_id=ChIJK_heji1j60cRSlNbjfSO7QU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf2l4QO5Mr2tNPTD8ZwypYaH3hcWqgSgJx4amQ=w408-h408-k-no"
      },
      {
        "name": "Advanced Skin Care Solutions",
        "description": "Facial spa. Rating: 4.8/5 from 47 reviews",
        "address": "1404 S 5th St, Philadelphia, Pennsylvania",
        "phone": "(215) 203-0995",
        "website": "http://advancedskincarephl.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Advanced%20Skin%20Care%20Solutions&query_place_id=ChIJW_AIEEHIxokRWqotCTL_2kQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNULJaZKLFhESuKYql2KiWbC7CF08y5lPEMb8vi=w408-h306-k-no"
      },
      {
        "name": "Pressed & Loc'd",
        "description": "Beauty salon. Rating: 5/5 from 2 reviews",
        "address": "side door ( white door, 1255 N 52nd St, W Thompson St, Philadelphia, PA",
        "phone": "(267) 702-0565",
        "website": "https://pressedbeautybar.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Pressed%20%26%20Loc'd&query_place_id=ChIJr8inzgrHxokRE3lTCAmC4gE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMjkgiOKpSPOf4zRsTeKIo-Lk-lHRJXahK1Trc=w408-h725-k-no"
      }
    ],
    "description": "Philadelphia's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "San Antonio",
    "slug": "san-antonio",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Laura Infante: Circular Fashion Connection",
        "description": "Stylist. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(210) 875-6098",
        "website": "https://www.circularfashionconnection.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Laura%20Infante%3A%20Circular%20Fashion%20Connection&query_place_id=ChIJMXQYN5Du_QIRFIUns59ZT2s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvEdLwGTSZll4cSv01hBCertDSYxT9O_cFfA8=w408-h612-k-no"
      },
      {
        "name": "Color Star Studio, LLC",
        "description": "Image consultant. Rating: 5/5 from 29 reviews",
        "address": "2929 Mossrock Ste. 228, San Antonio, Texas",
        "phone": "(210) 887-7600",
        "website": "http://www.colorstarstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Star%20Studio%2C%20LLC&query_place_id=ChIJh7fBVmpdXIYRQ6BEGeeqgvU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN-3Bn68_DlZlVbt0F_DPelYwRU9bcQpBlsDEPx=w408-h306-k-no"
      },
      {
        "name": "House of Colour Brooks",
        "description": "Stylist. Rating: 5/5 from 9 reviews",
        "address": "3166 SE Military Dr #104, San Antonio, Texas",
        "phone": "(210) 338-0088",
        "website": "http://bit.ly/hoc_brookscitybase",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Brooks&query_place_id=ChIJsVnFC9n3XIYRQn4Zap9MWxo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPl0gcMIwngP49QkfL8pu0nxeVdJZvSaAUAzC4N=w408-h272-k-no"
      },
      {
        "name": "HAIR LAB | The Science of Beauty",
        "description": "Hair salon. Rating: 5/5 from 27 reviews",
        "address": "6827 Camp Bullis Rd Suite 105, San Antonio, Texas",
        "phone": "(210) 879-8897",
        "website": "http://www.hairlabsa.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=HAIR%20LAB%20%7C%20The%20Science%20of%20Beauty&query_place_id=ChIJ3USmR_ZlXIYR7o1J5yPIvIQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN7gF3D9g8tWddDkwZ5g4m33SJIhW8NPe_TM7rY=w408-h510-k-no"
      }
    ],
    "description": "San Antonio's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "San Diego",
    "slug": "san-diego",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Colours Cosmetique",
        "description": "Skin care clinic",
        "address": "2307 Fenton Marketplace Driveway Ste 107, San Diego, California",
        "phone": "(619) 255-8524",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20Cosmetique&query_place_id=ChIJ3cXTf9dT2YARhlQWj6pIyos",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=2XqES9ZA8s4VDUJxFfYgKQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=35.37653&pitch=0&thumbfov=100"
      },
      {
        "name": "Color me Kat Salon",
        "description": "Hair salon. Rating: 5/5 from 6 reviews",
        "address": "6512 El Cajon Blvd A, San Diego, California",
        "phone": "(619) 905-1780",
        "website": "https://color-me-kat.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20me%20Kat%20Salon&query_place_id=ChIJSQeyDaNX2YARoB5FXtQ10p8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP5ubrmUsdCyp4O6yLPVdn9mVMuC4Q9Wh5k_SkB=w408-h408-k-no"
      },
      {
        "name": "Style By Mr. Angeles Noir",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "(323) 522-7817",
        "website": "https://www.stylebynoir.com/gallery",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20By%20Mr.%20Angeles%20Noir&query_place_id=ChIJzWFLsq9V2YARdBVpsAuriAU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN3xPdT--G9RxYs4RSxDtz_-tODls0xaRami63C=w408-h408-k-no"
      },
      {
        "name": "Natura Hair Removal & Skin Care",
        "description": "Waxing hair removal service. Rating: 4.8/5 from 155 reviews",
        "address": "1300 Grand Ave #C, San Diego, California",
        "phone": "(858) 270-4929",
        "website": "https://naturaspa.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Natura%20Hair%20Removal%20%26%20Skin%20Care&query_place_id=ChIJ_QkmYOoB3IAR1zybZ03UeVI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDJCGoWzJzEc8CaPvnK54S7mw9SM1e56Z7kJ-K=w408-h398-k-no"
      }
    ],
    "description": "San Diego's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Dallas",
    "slug": "dallas",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Bethany Siggins Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 24 reviews",
        "address": "",
        "phone": "(469) 363-0193",
        "website": "http://www.bethanysiggins.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bethany%20Siggins%20Image%20Consulting&query_place_id=ChIJz6e_Y3-hToYR6IK5SgbG2PQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMYd8zIL9106RVad6ymIlV8cxWFHO3S1wyzDUvH=w426-h240-k-no"
      },
      {
        "name": "House of Colour Dallas",
        "description": "Image consultant",
        "address": "",
        "phone": "(972) 674-9546",
        "website": "https://www.houseofcolour.com/stylists/ginny-hudnut-far-north-dallas-texas",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Dallas&query_place_id=ChIJadopVBQaaKkREIb0UH1zIyI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM-V13uVfyN2_AQ0zoAU9RrIN-F5Xj636wJu8VL=w408-h562-k-no"
      },
      {
        "name": "Gbori Colour Studio",
        "description": "Make-up artist. Rating: 5/5 from 13 reviews",
        "address": "210 S Madison Ave, Dallas, Texas",
        "phone": "(214) 578-5352",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Gbori%20Colour%20Studio&query_place_id=ChIJKdVMPOqZToYRF3vvU1IabOQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPe3X3I-WACg2D8KY6eycE3gDfG9dbEDTbMqFf7=w408-h417-k-no"
      }
    ],
    "description": "Dallas's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "San Jose",
    "slug": "san-jose",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "In Lovely Color",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "1700 Newbury Park Dr Suite 30 room 209, San Jose, California",
        "phone": "(408) 836-7450",
        "website": "http://inlovelycolor.org/?utm_source=gmb&utm_medium=referral",
        "url": "https://www.google.com/maps/search/?api=1&query=In%20Lovely%20Color&query_place_id=ChIJMVnKegjNj4AReE7shLYUEgM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDcXa8jRDjA31L5C9TTp5eDDXLNZBOBUkmQW_m=w408-h408-k-no"
      },
      {
        "name": "House of Colours",
        "description": "Beauty salon. Rating: 4.6/5 from 9 reviews",
        "address": "900 Park Ave, San Jose, California",
        "phone": "(650) 207-3828",
        "website": "http://www.hairbycarolinek.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colours&query_place_id=ChIJ5VkY3T81joARHrbdj135-tg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbb8TXVC9AvEEc0YocRSDjTGMTIMh_74AfFNGE=w426-h240-k-no"
      },
      {
        "name": "LE VU Beauty Bar",
        "description": "Beauty salon. Rating: 4.8/5 from 31 reviews",
        "address": "1087 Meridian Ave #20, San Jose, California",
        "phone": "(408) 320-4338",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=LE%20VU%20Beauty%20Bar&query_place_id=ChIJSboalDM1joARXdxs4yCGESw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNNkwkrvW3Hpe2Za6vwSATZdhHooOvttMpnCPPL=w408-h491-k-no"
      },
      {
        "name": "Skin Deep Beauty & Beyond",
        "description": "Skin care clinic. Rating: 4.9/5 from 19 reviews",
        "address": "1203 Park Ave, San Jose, California",
        "phone": "(408) 623-2902",
        "website": "https://mewist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skin%20Deep%20Beauty%20%26%20Beyond&query_place_id=ChIJiVgYzETLj4ARxRmYlX74XIQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQ0qUWuZ1Sr59125Q0PBjYxThyRCXi9yAcLgaX=w408-h306-k-no"
      },
      {
        "name": "Visions of Venus",
        "description": "Beauty salon. Rating: 5/5 from 12 reviews",
        "address": "",
        "phone": "(408) 430-2511",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Visions%20of%20Venus&query_place_id=ChIJe0Tt5TzLj4AR3ksgMn1hYJ0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPvH7ycMXBNNX0JAQYEZQkqf-vN-NWg7D_0ZWQZ=w408-h309-k-no"
      }
    ],
    "description": "San Jose's color analysis services are influenced by the city's tech-driven culture and vibrant lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Austin",
    "slug": "austin",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Colorier | Korean Color Analysis in Austin",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "1170-1304 Wells Branch Pkwy, Pflugerville, Texas",
        "phone": "",
        "website": "https://thecolorier.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorier%20%7C%20Korean%20Color%20Analysis%20in%20Austin&query_place_id=ChIJCUy_0tFSFWURIttA130NqNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_sNy6l8Mj143Fp6sY45Kfeh9d7wC7GF22o1g1=w408-h611-k-no"
      },
      {
        "name": "House of Colour | Austin | Karlotta DesJarlais",
        "description": "Stylist. Rating: 5/5 from 9 reviews",
        "address": "11300 Jollyville Rd, Austin, Texas",
        "phone": "(512) 655-9224",
        "website": "https://max.houseofcolour.co.uk/book/27224-Karlotta_Desjarlais",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20%7C%20Austin%20%7C%20Karlotta%20DesJarlais&query_place_id=ChIJ0Z6N_QrLRIYR3AxMZ0_aerg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMwCbWx6CLLPL74pq9lfec49iIoVYRPM5pphHu9=w408-h532-k-no"
      },
      {
        "name": "Color Chroma",
        "description": "Stylist. Rating: 5/5 from 50 reviews",
        "address": "11601 Menchaca Rd, Austin, Texas",
        "phone": "(512) 777-1210",
        "website": "https://maddymemmer.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Chroma&query_place_id=ChIJ8Wg3QqpNW4YRx4ClSvnbjzQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvlQWU5hFPQ2D7cNywEuUqbxv8UE6W4_MP7FMI=w408-h306-k-no"
      }
    ],
    "description": "Austin's color analysis services reflect the city's unique blend of cultural influences and vibrant music scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Columbus",
    "slug": "columbus",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Kelly Does Color",
        "description": "Image consultant",
        "address": "4400 N High St, Columbus, Ohio",
        "phone": "",
        "website": "https://kellydoescolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Kelly%20Does%20Color&query_place_id=ChIJp8ec7KWNOIgRPenYCvJFhBc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=y6iAavhzY48L8QHTcmRCNQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=266.46585&pitch=0&thumbfov=100"
      },
      {
        "name": "Everyday Couture",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(513) 449-1043",
        "website": "https://www.everydaycouture.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Everyday%20Couture&query_place_id=ChIJGyCZ7VDKr2wRmOXIWEiHFLw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNz-qoN3HamVXRcru-po-cpz2H_FVGVPv4MywMR=w408-h408-k-no"
      },
      {
        "name": "JuEllz Designz",
        "description": "Hair salon. Rating: 5/5 from 21 reviews",
        "address": "1100 Morse Rd #100c, Columbus, Ohio",
        "phone": "",
        "website": "http://www.juellzdesignz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=JuEllz%20Designz&query_place_id=ChIJv1KqyeaJOIgR73s6I6qC20w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMkMH6HHfJ16NazWceleHgCqryALFYMlZnw03X5=w408-h544-k-no"
      },
      {
        "name": "More Than Style",
        "description": "Stylist. Rating: 5/5 from 18 reviews",
        "address": "",
        "phone": "",
        "website": "http://morethanstylellc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=More%20Than%20Style&query_place_id=ChIJecc9tKHp-KcRD-4Qk8cMzPw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPXul2T8SD6HxamrRH6_RhSuaWa8SrraO8hapq_=w408-h544-k-no"
      }
    ],
    "description": "Columbus's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Fort Worth",
    "slug": "fort-worth",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Stoltz Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 28 reviews",
        "address": "4961 S Hulen St, Fort Worth, Texas",
        "phone": "(817) 924-8300",
        "website": "https://stoltzimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Stoltz%20Image%20Consulting&query_place_id=ChIJ4dYooltyToYRUy5TAqPYakE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNpGuf0d1InxYUnXN2XgdV1G2r7g0xwwBKtbJAQ=w408-h543-k-no"
      },
      {
        "name": "Colour Basis",
        "description": "Image consultant. Rating: 5/5 from 18 reviews",
        "address": "1721 W Berry St, Fort Worth, Texas",
        "phone": "(888) 797-0806",
        "website": "http://colourbasis.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Basis&query_place_id=ChIJ_-ZvUfNxToYREDk7zdrm3XU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN21ZGkg40xfvj3BDXaOT46DEw_SgTHVVCZach5=w408-h408-k-no"
      }
    ],
    "description": "Fort Worth's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Indianapolis",
    "slug": "indianapolis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Beth Divine Style",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "",
        "phone": "(317) 416-2782",
        "website": "http://www.bethdivinestyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beth%20Divine%20Style&query_place_id=ChIJ_aUocqBTa4gRgLRKKNibdQ8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO6f97XZ8M0SyikhezWCYrNVk7jSJl6HKonqxAn=w408-h338-k-no"
      },
      {
        "name": "The Color Coordinator",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "(224) 358-7795",
        "website": "https://www.thecolorcoordinator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Coordinator&query_place_id=ChIJae0et8QrBkoRc0PqwKY1Lrs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipODIjgYG2z9C_Z-B8xXrcmT_YgWujIo5gBOF1rT=w408-h272-k-no"
      },
      {
        "name": "Colour By Courtney",
        "description": "Make-up artist. Rating: 4.9/5 from 80 reviews",
        "address": "",
        "phone": "(317) 294-5257",
        "website": "https://www.colourbycourtney.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20By%20Courtney&query_place_id=ChIJ0Uz0lRY0a4gR1mbSEi5n1HU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMMt8INNlXLJIA4a2DWc9NBdkQwwPSDnVpG_vbH=w408-h619-k-no"
      },
      {
        "name": "Chic in Color",
        "description": "Stylist",
        "address": "",
        "phone": "(734) 634-7557",
        "website": "https://www.chicincolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Chic%20in%20Color&query_place_id=ChIJOw1O8N7legARR0_irE5dclk",
        "imageUrl": ""
      }
    ],
    "description": "Indianapolis's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Charlotte",
    "slug": "charlotte",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "House of Colour Charlotte Center City - Jenn Traynor",
        "description": "Image consultant",
        "address": "Lilac Rd, Charlotte, North Carolina",
        "phone": "(704) 756-6725",
        "website": "http://www.parttimefancy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Charlotte%20Center%20City%20-%20Jenn%20Traynor&query_place_id=ChIJpZ_0KrKfVogR2RDpMXh8GsM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=GEtXKFNpgcdPMDSkV9BzOg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=70.29101&pitch=0&thumbfov=100"
      },
      {
        "name": "Lisa Jenkins Color",
        "description": "Consultant. Rating: 5/5 from 63 reviews",
        "address": "",
        "phone": "(980) 833-6299",
        "website": "https://www.lisajenkinscolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lisa%20Jenkins%20Color&query_place_id=ChIJsVuGyS29VogRNIrlszlchFc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOvt4hcJi777jXjSesJfPdshT3fHjznJrKu0B9E=w426-h240-k-no"
      },
      {
        "name": "Liz Buie | House of Colour (HOC) Charlotte - SouthPark",
        "description": "Consultant. Rating: 5/5 from 9 reviews",
        "address": "Carmel Rd, Charlotte, North Carolina",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/liz-buie-charlotte-southpark-north-carolina",
        "url": "https://www.google.com/maps/search/?api=1&query=Liz%20Buie%20%7C%20House%20of%20Colour%20(HOC)%20Charlotte%20-%20SouthPark&query_place_id=ChIJS5r-0wKdVogRojt2xFTUzGs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOsVHbexU61KkJWVD6YJ97-x4lhcwEaWlH-Ndft=w408-h380-k-no"
      },
      {
        "name": "Michelle Glass Styling",
        "description": "Image consultant. Rating: 5/5 from 10 reviews",
        "address": "",
        "phone": "(839) 216-0257",
        "website": "https://www.michelleglassstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Michelle%20Glass%20Styling&query_place_id=ChIJBSja5NRueGQRg5nkbiaUbRA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM4IG14tLIJBI6OxrW3upliO80ibk4ChcA1MqDa=w408-h612-k-no"
      }
    ],
    "description": "Charlotte's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Seattle",
    "slug": "seattle",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "House of Colour Ballard",
        "description": "Image consultant. Rating: 5/5 from 35 reviews",
        "address": "1102 NW 70th St, Seattle, Washington",
        "phone": "(206) 550-0931",
        "website": "https://houseofcolourseattleballard.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Ballard&query_place_id=ChIJ1z0AkSIVkFQRVRga6yVkFoM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOKi97JjFeI1BHWPJoFTKszc706YBCrSwYebtl_=w408-h391-k-no"
      },
      {
        "name": "House of Colour Seattle - Magnolia",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/kim-mathisen-magnolia-washington",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Seattle%20-%20Magnolia&query_place_id=ChIJ08tTUeUVkFQRwSGYHHUA5Zw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNbnCu-QhwbIgCGuaXfLyu5fCSa9ZDDVZaFoxIr=w408-h282-k-no"
      },
      {
        "name": "House of Colour NE Seattle",
        "description": "Image consultant. Rating: 5/5 from 13 reviews",
        "address": "9804 Lake City Way NE, Seattle, Washington",
        "phone": "(206) 697-3663",
        "website": "https://www.houseofcolour.com/stylists/lindy-day-northeast-seattle-washington",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NE%20Seattle&query_place_id=ChIJMT7C-roTkFQR6lQ8WsyIPrY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNI86NzMCRsRy92Kbj7bd0mSsJp_TOy4gp4epKn=w408-h279-k-no"
      },
      {
        "name": "Atelier SOZO",
        "description": "Make-up artist. Rating: 5/5 from 19 reviews",
        "address": "Cedar St, Seattle, Washington",
        "phone": "(202) 525-9351",
        "website": "https://www.ateliersozo.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Atelier%20SOZO&query_place_id=ChIJk8Hv8_UVkFQRneTdIn1ZsGg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMtN1aWownTgUyfV6bQQXDWrnKzU0vo1oewbkjZ=w408-h707-k-no"
      },
      {
        "name": "Intermezzo Salon & Spa",
        "description": "Beauty salon. Rating: 4.9/5 from 65 reviews",
        "address": "407 W Galer St, Seattle, Washington",
        "phone": "(206) 281-7833",
        "website": "https://www.intermezzosalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Intermezzo%20Salon%20%26%20Spa&query_place_id=ChIJqYMUDGwVkFQR26AHW5HCLtU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOir_qaWfwITuHT_sbdI3j9pEZfsWZte4lB6hhs=w408-h272-k-no"
      }
    ],
    "description": "Seattle's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Denver",
    "slug": "denver",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Dress Your Colors",
        "description": "Image consultant. Rating: 5/5 from 63 reviews",
        "address": "8003 E 50th Dr, Denver, Colorado",
        "phone": "(862) 812-3912",
        "website": "http://dressyourcolors.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Dress%20Your%20Colors&query_place_id=ChIJVyWp4sh7bIcR2WUCT272HN0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOK-vfpUb_GbNHnUnjRmcgRaW_R5TIdy877GcYS=w412-h240-k-no"
      },
      {
        "name": "The Style Studio by Keri Blair",
        "description": "Consultant. Rating: 4.6/5 from 35 reviews",
        "address": "300 Josephine St #260, Denver, Colorado",
        "phone": "(303) 575-1606",
        "website": "https://www.keriblair.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Style%20Studio%20by%20Keri%20Blair&query_place_id=ChIJq76ukpV-bIcRFpH9s7uEqw4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMLGXKc2hN7A0BX9q6aqw-YmgcBf1aao4LqsUOJ=w408-h544-k-no"
      }
    ],
    "description": "Denver's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Washington D.C.",
    "slug": "washington-dc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "GetDressedGo",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.getdressedgo.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-website&utm_content=website_button",
        "url": "https://www.google.com/maps/search/?api=1&query=GetDressedGo&query_place_id=ChIJb7smD7XNt4kRdbFwLa5Gp_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOv7m2VCeXAKyHU87bzXjY1rzHiZovYZVwT79O4=w408-h272-k-no"
      }
    ],
    "description": "Washington D.C.'s color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Boston",
    "slug": "boston",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "House of Colour Chestnut Hill",
        "description": "Image consultant. Rating: 5/5 from 8 reviews",
        "address": "",
        "phone": "(617) 606-2110",
        "website": "https://www.houseofcolour.com/stylists/talia-rosenbloom-chestnut-hill-massachusetts",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Chestnut%20Hill&query_place_id=ChIJfUkJ9xwG3QoR-9OVL7R_Pdg",
        "imageUrl": ""
      },
      {
        "name": "SKA Hair | Color | Design",
        "description": "Beauty salon. Rating: 4.7/5 from 82 reviews",
        "address": "185 Essex St, Boston, Massachusetts",
        "phone": "(857) 991-1118",
        "website": "http://www.skabos.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=SKA%20Hair%20%7C%20Color%20%7C%20Design&query_place_id=ChIJxcaBkod744kRDvfPIVuZy5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOkJeDHPhwcwBY-tZPcOSahmzFnRf3SW4mSCPBm=w408-h612-k-no"
      },
      {
        "name": "SKA Scalp | Skin Care",
        "description": "Beauty salon. Rating: 4.6/5 from 102 reviews",
        "address": "36 Essex St, Boston, Massachusetts",
        "phone": "(617) 338-9222",
        "website": "http://www.skabos.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=SKA%20Scalp%20%7C%20Skin%20Care&query_place_id=ChIJBT2V-Hd644kRdOZA0xoMniE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsM9bYZqTNMlUhYuBW2DhvDtsFkY51-l4LHyqa=w408-h534-k-no"
      }
    ],
    "description": "Boston's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Las Vegas",
    "slug": "las-vegas",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Styling Insider",
        "description": "Image consultant. Rating: 5/5 from 41 reviews",
        "address": "",
        "phone": "(702) 339-8980",
        "website": "http://www.stylinginsider.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Styling%20Insider&query_place_id=ChIJI6D5a4fByIARWkKOTsEo5Ag",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO4fg0NyisC9nNTPQJyGjTQpYp4W7gcJKuBzOqh=w408-h408-k-no"
      },
      {
        "name": "Diamond Esthetique Salon Suites",
        "description": "Beauty salon. Rating: 5/5 from 5 reviews",
        "address": "6820 W Sahara Ave suite 1, Las Vegas, Nevada",
        "phone": "(702) 912-0983",
        "website": "https://diamondsalonvegas.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Diamond%20Esthetique%20Salon%20Suites&query_place_id=ChIJw1kg8bPByIARpROI9HWGT0k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMzq1pRWJW9xkvANyNYAPphF1kdhXFSlybv9WFE=w408-h306-k-no"
      },
      {
        "name": "Colour Beauty Salon",
        "description": "Beauty salon. Rating: 4.7/5 from 71 reviews",
        "address": "60 N 25th St #130, Las Vegas, Nevada",
        "phone": "(702) 207-0252",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Beauty%20Salon&query_place_id=ChIJR_vBhDjdyIARuLoWZCx7vhc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOtiNvEnMYo0DI5BDnwayWMRofNSTNKx-JieQJa=w408-h408-k-no"
      }
    ],
    "description": "Las Vegas's color analysis services are influenced by the city's vibrant nightlife and entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Atlanta",
    "slug": "atlanta",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "The Discerning Palette Personal Color Analysis",
        "description": "Stylist",
        "address": "",
        "phone": "(404) 375-5479",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Discerning%20Palette%20Personal%20Color%20Analysis&query_place_id=ChIJE93lWP_MdQMRt1xQNdy9PwU",
        "imageUrl": ""
      },
      {
        "name": "Beauty by KaSh",
        "description": "Beauty salon. Rating: 5/5 from 10 reviews",
        "address": "519 Memorial Dr SE D-06 #203, Atlanta, Georgia",
        "phone": "",
        "website": "http://linktr.ee/BeWellthy",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20by%20KaSh&query_place_id=ChIJ7fgHuUED9YgRrAU-kELqHOY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO5gMaL6_IIE1VZBW4xw20n1AAINwTyDnyNgmNX=w408-h510-k-no"
      },
      {
        "name": "Aviary Beauty + Wellness Summerhill",
        "description": "Beauty salon. Rating: 5/5 from 151 reviews",
        "address": "72 Georgia Ave SE Suite 400, Atlanta, Georgia",
        "phone": "(404) 313-3383",
        "website": "https://www.aviarybeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Aviary%20Beauty%20%2B%20Wellness%20Summerhill&query_place_id=ChIJBYqBIAUD9YgRM_23t0fKim8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOwuV7FjwvX72JAH8B2GKmh5lKM3gakqNOsGUxB=w408-h551-k-no"
      }
    ],
    "description": "Atlanta's color analysis services reflect the city's unique blend of cultural influences and vibrant music scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "New Orleans",
    "slug": "new-orleans",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "House of Colour New Orleans West",
        "description": "Stylist",
        "address": "",
        "phone": "",
        "website": "https://www.houseofcolour.com/stylists/briana-guillory-new-orleans-west-louisiana",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20New%20Orleans%20West&query_place_id=ChIJQSVj01JQ7gURt1HI2_YbTVU",
        "imageUrl": ""
      },
      {
        "name": "Awoo Style",
        "description": "Stylist",
        "address": "2524 Chippewa St, New Orleans, Louisiana",
        "phone": "(678) 570-7368",
        "website": "https://awoostyle.ueniweb.com/?utm_campaign=gmb",
        "url": "https://www.google.com/maps/search/?api=1&query=Awoo%20Style&query_place_id=ChIJHySorKWlIIYRawSNNhj2XNo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPztMOssyQ5yd1DZcwvFTytRh21v7OKRUAemkHA=w408-h408-k-no"
      },
      {
        "name": "Color Bar Salon",
        "description": "Beauty salon. Rating: 4.6/5 from 28 reviews",
        "address": "2039 Magazine St, New Orleans, Louisiana",
        "phone": "(504) 544-5254",
        "website": "http://colorbarsalonnola.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Bar%20Salon&query_place_id=ChIJA2wdntWlIIYRyBtS5DTGmqI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMCKzpAuQ97uNQ3X0q7ZcxLADb33G9jdW6mJNW_=w408-h544-k-no"
      },
      {
        "name": "Glasskin",
        "description": "Facial spa. Rating: 4.9/5 from 186 reviews",
        "address": "4025 Magazine St, New Orleans, Louisiana",
        "phone": "(504) 581-8356",
        "website": "http://www.getglasskin.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Glasskin&query_place_id=ChIJxSpA0vOlIIYRUdBmIJPP-T8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_Q-_ic41awDzb9spe0j80YyU6y4lJk05aERMm=w408-h544-k-no"
      }
    ],
    "description": "New Orleans's color analysis services are influenced by the city's vibrant culture and warm climate. Analysts often specialize in bold and festive palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Portland",
    "slug": "portland",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "ColorStylePDX",
        "description": "Image consultant. Rating: 4.9/5 from 37 reviews",
        "address": "1420 NW Lovejoy St #418, Portland, Oregon",
        "phone": "(360) 903-3659",
        "website": "http://www.colorstylepdx.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColorStylePDX&query_place_id=ChIJEdtApv4JlVQRMmwKvMINovw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMH56qxiaIXuMwx0GRHpF0qnjazsOA-Po0gfUoU=w408-h497-k-no"
      },
      {
        "name": "The Look Editor",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.thelookeditor.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Look%20Editor&query_place_id=ChIJ9zMQ2IJX5igRYHZs9MZX6O4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP1ZgJYK5JSHe8wjM8wN-oM4vG1QQaXg4XJVrdL=w408-h612-k-no"
      },
      {
        "name": "Colour Authority",
        "description": "Hair salon. Rating: 4.7/5 from 103 reviews",
        "address": "11121 NE Halsey St, Portland, Oregon",
        "phone": "(503) 255-4510",
        "website": "http://www.colourauthority.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Authority&query_place_id=ChIJO4E5knuhlVQRbNJ5zd-2jjs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPVyQb2PUOkBT2l-Qzpgb8kyd9pB48sGbkYRWKN=w408-h306-k-no"
      },
      {
        "name": "Color Palette Salon",
        "description": "Beauty salon. Rating: 5/5 from 26 reviews",
        "address": "10741 SW 49th Ave, Portland, Oregon",
        "phone": "(971) 322-3431",
        "website": "http://thecolorpalettesalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Palette%20Salon&query_place_id=ChIJVVWpAqIMlVQRR8RxLEXAv1U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPKjNG-vng1NHvi1FaY3FSsy3jYdNW-qxjuT46c=w408-h544-k-no"
      }
    ],
    "description": "Portland's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Nashville",
    "slug": "nashville",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Evolve by Jessi | Personal Styling, Closet Editing, Wardrobe Styling & Management",
        "description": "Stylist. Rating: 5/5 from 10 reviews",
        "address": "",
        "phone": "(317) 694-0282",
        "website": "http://www.jessisheehan.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Evolve%20by%20Jessi%20%7C%20Personal%20Styling%2C%20Closet%20Editing%2C%20Wardrobe%20Styling%20%26%20Management&query_place_id=ChIJj4NVidg71YwRju_KljaE088",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO3RKOcpPc7HY6k5syauuEFBN2wWcGu6pmvfsqR=w426-h240-k-no"
      }
    ],
    "description": "Nashville's color analysis services are influenced by the city's vibrant music scene and rich cultural heritage. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Detroit",
    "slug": "detroit",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Color Suite",
        "description": "Beauty salon",
        "address": "3792 Clements St #3, Detroit, Michigan",
        "phone": "(313) 955-4077",
        "website": "http://www.appointfix.com/book/Color-Suite",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Suite&query_place_id=ChIJ48xXuefNJIgRqGNVg1f6CDM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmZPUzzqLZ2yiSA5TOjhTrdaxKfmaq-PrkrpSc=w408-h544-k-no"
      }
    ],
    "description": "Detroit's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Baltimore",
    "slug": "baltimore",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Wardrobe Wizard of Baltimore",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "1620 Roundhill Rd, Baltimore, Maryland",
        "phone": "(410) 513-9496",
        "website": "http://www.wardrobewiz.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Wardrobe%20Wizard%20of%20Baltimore&query_place_id=ChIJGXTbnuoEyIkR1keb0f-MnO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM2R9ey-YpidI6ue3ZzMSi5c6HvawR8grtLgD3B=w408-h664-k-no"
      }
    ],
    "description": "Baltimore's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "LA",
    "slug": "la",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Jennifer Butler Living Colors",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "848 S Lucerne Blvd, Los Angeles, California",
        "phone": "(323) 931-2626",
        "website": "http://jenniferbutlercolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Jennifer%20Butler%20Living%20Colors&query_place_id=ChIJIXumc-24woARdAHbQJdIlz8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=46RAvyMI53m1sP09Roe2Ag&cb_client=search.gws-prod.gps&w=408&h=240&yaw=110.95381&pitch=0&thumbfov=100"
      },
      {
        "name": "colorlab tierrasal",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "548 S Spring St #101, Los Angeles, California",
        "phone": "(213) 787-3464",
        "website": "http://juvenciojimenez-valdez.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=colorlab%20tierrasal&query_place_id=ChIJT4rTnErGwoARQtGnzIKLP-E",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=s60QVGkfjrT9BaZ3ppONFA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=286.90656&pitch=0&thumbfov=100"
      },
      {
        "name": "COLORBRITY",
        "description": "Image consultant. Rating: 5/5 from 198 reviews",
        "address": "3407 W 6th St #523, Los Angeles, California",
        "phone": "(213) 537-8620",
        "website": "https://www.colorbrity.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLORBRITY&query_place_id=ChIJk3niDnDHwoARBdPo74lTcB0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO91fJnDk5cWe6jslN6hava9rDxF61oDAHg8SVy=w408-h544-k-no"
      },
      {
        "name": "MiKADO Personal Styling",
        "description": "Image consultant. Rating: 5/5 from 42 reviews",
        "address": "1249 S Grand Ave Suite 504, Los Angeles, California",
        "phone": "(800) 982-1628",
        "website": "http://www.mikadopersonalstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=MiKADO%20Personal%20Styling&query_place_id=ChIJmTEOCLfHwoARauZziCpJOI4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmpj0lC76OYDwoaxJC1dyu4noGI1znCfVXsIIw=w408-h365-k-no"
      }
    ],
    "description": "LA's color analysis services reflect the city's unique blend of cultural influences and vibrant entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "NYC",
    "slug": "nyc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Seklab Color Analysis Studio",
        "description": "Image consultant. Rating: 5/5 from 81 reviews",
        "address": "202 W 40th St Suite 400, New York, New York",
        "phone": "(332) 999-4179",
        "website": "https://www.seklabnyc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Seklab%20Color%20Analysis%20Studio&query_place_id=ChIJwxeSMoRZwokR2BBukhq4lSs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlfS2fOpii7qpV5M8BXyKf45AA6DJvTiYJ3GQr=w408-h544-k-no"
      },
      {
        "name": "House of Colour NYC - Downtown Brooklyn",
        "description": "Stylist. Rating: 5/5 from 11 reviews",
        "address": "325 Gold St Suite 703A, Brooklyn, New York",
        "phone": "(929) 266-7228",
        "website": "https://houseofcolour.com/fernandavazquez",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20NYC%20-%20Downtown%20Brooklyn&query_place_id=ChIJPRb8F6tbwokRbe-woVjHwO8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOUh0ZE6PWUcygcamfo11_uH1HiI4QGjPSEEqSC=w408-h544-k-no"
      },
      {
        "name": "Lily's Color Lab",
        "description": "Stylist. Rating: 4.9/5 from 71 reviews",
        "address": "1239 Broadway, New York, New York",
        "phone": "(202) 813-9687",
        "website": "https://lilyscolorlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lily's%20Color%20Lab&query_place_id=ChIJqxVpZRFbwokRPYp0fw-xRUI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7oWbYDctv5kuqOqA8g16iw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=91.2156&pitch=0&thumbfov=100"
      },
      {
        "name": "The Outfit Curator",
        "description": "Stylist. Rating: 4.8/5 from 17 reviews",
        "address": "123 W 81st St, New York, New York",
        "phone": "",
        "website": "http://www.theoutfitcurator.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Outfit%20Curator&query_place_id=ChIJjUVyL1BZwokRE7p_2_X0RMI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQjkI8r7-F7JkalGMJ0YXHyz8VSArJYQ_0AG0T=w408-h612-k-no"
      }
    ],
    "description": "NYC's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "SF",
    "slug": "sf",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "K Griffin Style Color Analysis and Wardrobe Consultant",
        "description": "Stylist. Rating: 5/5 from 79 reviews",
        "address": "130 Lake St, San Francisco, California",
        "phone": "(415) 521-9316",
        "website": "https://www.kgriffinstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=K%20Griffin%20Style%20Color%20Analysis%20and%20Wardrobe%20Consultant&query_place_id=ChIJYcBm9HyHhYARNk6wwVNQz98",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMBiquGSNFyOwSehWWKP5TDl8NDNtvukUNkFihr=w408-h304-k-no"
      },
      {
        "name": "Colors With Kate",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorswithkate.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20With%20Kate&query_place_id=ChIJb9AAmOsO-CwRjmjy_aqOsSM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3qvDzt-MMqZfR0U2M3AbNYt_xSFpVMzqOv0Kf=w408-h306-k-no"
      },
      {
        "name": "Lili Henry - Personal Fashion Stylist",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "Union St, San Francisco, California",
        "phone": "(925) 788-4504",
        "website": "https://www.lilihenrystylist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lili%20Henry%20-%20Personal%20Fashion%20Stylist&query_place_id=ChIJxfGXZ9CAhYARSNhsxX58RCc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPQFLdBfR942vHK6JQnxzldlcBnZ9v8qSwST5Io=w408-h272-k-no"
      },
      {
        "name": "Ensemble Personal Image Consulting",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "",
        "phone": "(415) 741-0011",
        "website": "https://www.ensemble-consulting.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ensemble%20Personal%20Image%20Consulting&query_place_id=ChIJU5V-2qeAhYARdfdzhepR6B0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMJfqGCaRwMjLxhe3U7Pxvmj4HnoSAmo5eynami=w408-h301-k-no"
      }
    ],
    "description": "SF's color analysis services reflect the city's unique blend of cultural influences and vibrant tech scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "DC",
    "slug": "dc",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "Apopofcolor by Terri Fe’",
        "description": "Hair salon. Rating: 5/5 from 3 reviews",
        "address": "Phenix Salon, 2350 Washington Pl NE Suite 131, Washington, District of Columbia",
        "phone": "(727) 279-0403",
        "website": "https://my-site-109500-100681.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Apopofcolor%20by%20Terri%20Fe%E2%80%99&query_place_id=ChIJSTk-dCO5t4kRrEpPn8iChGQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM7tTEkMcDOTtTgh8JLxnVIjX2xdcnDEpnHp4y3=w408-h272-k-no"
      },
      {
        "name": "GetDressedGo",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.getdressedgo.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb-website&utm_content=website_button",
        "url": "https://www.google.com/maps/search/?api=1&query=GetDressedGo&query_place_id=ChIJb7smD7XNt4kRdbFwLa5Gp_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOv7m2VCeXAKyHU87bzXjY1rzHiZovYZVwT79O4=w408-h272-k-no"
      }
    ],
    "description": "DC's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Washington",
    "slug": "washington",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Doctor of Dress",
        "description": "Image consultant",
        "address": "4028 Mansion Ct NW, Washington, District of Columbia",
        "phone": "(202) 338-8781",
        "website": "http://www.doctorofdress.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Doctor%20of%20Dress&query_place_id=ChIJQ974-RW2t4kRqprCLDFfpXs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbP6mJxCJq0edQvyVYXQeY8sskUJgc5IivW3Y4=w408-h459-k-no"
      },
      {
        "name": "ColourPoint LLC",
        "description": "Hair salon",
        "address": "1635 R St NW, Washington, District of Columbia",
        "phone": "(202) 607-1270",
        "website": "http://itsgivingcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourPoint%20LLC&query_place_id=ChIJaa7dWhK3t4kRRKfdeJKCT5I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOCAQc7KYWJoeQnzIEJN3Tv57PIECnAn-7feAik=w408-h544-k-no"
      },
      {
        "name": "The Kolour Lab",
        "description": "Hair salon. Rating: 4.6/5 from 19 reviews",
        "address": "803 Florida Ave NW Second Floor, Washington, District of Columbia",
        "phone": "(240) 716-2647",
        "website": "https://thekolourlab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Kolour%20Lab&query_place_id=ChIJG95-Qmi3t4kRtiIeACeowm0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM0otDc37e9_-DUSpV_Fm39a1BBgDholn79NBIo=w408-h420-k-no"
      },
      {
        "name": "Divine Style LLC",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "2221 12th Pl NW, Washington, District of Columbia",
        "phone": "(202) 492-1761",
        "website": "https://divinestyle.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=Divine%20Style%20LLC&query_place_id=ChIJxajv5uC3t4kRLAt56WGZQ1k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMxrLYj83u-izI5VC-iTldoz2cKsJcgUjP8sSNi=w425-h240-k-no"
      },
      {
        "name": "Color Lab Salon by TSR",
        "description": "Hair salon. Rating: 4.8/5 from 585 reviews",
        "address": "1607 Connecticut Ave NW, Washington, District of Columbia",
        "phone": "(202) 800-8816",
        "website": "http://www.besthairdc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Lab%20Salon%20by%20TSR&query_place_id=ChIJScAJymq3t4kRoc8Am0vhOkg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOGCB_nE9NWahtieDv-btRv8av0W3NXDtqm3soc=w408-h544-k-no"
      }
    ],
    "description": "Washington's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Philly",
    "slug": "philly",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "House of Colour - Jill Rafter",
        "description": "Image consultant",
        "address": "",
        "phone": "(215) 840-3414",
        "website": "https://max.houseofcolour.co.uk/book/27275-Jill_Rafter",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20-%20Jill%20Rafter&query_place_id=ChIJK_heji1j60cRSlNbjfSO7QU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf2l4QO5Mr2tNPTD8ZwypYaH3hcWqgSgJx4amQ=w408-h408-k-no"
      },
      {
        "name": "Richard Nicholas Hair Studio",
        "description": "Beauty salon. Rating: 4.8/5 from 420 reviews",
        "address": "1716 Sansom St, Philadelphia, Pennsylvania",
        "phone": "(215) 567-4790",
        "website": "http://www.richardnicholas.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Richard%20Nicholas%20Hair%20Studio&query_place_id=ChIJG2XUjjDGxokRV9JveU6X1Jk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPmFo5DA84DZsMpHezO8ReOYplluDJVL4VdiUZc=w408-h544-k-no"
      },
      {
        "name": "La Pearl Beauty Emporium Inc",
        "description": "Beauty salon. Rating: 4.4/5 from 83 reviews",
        "address": "3857 Lancaster Ave, Philadelphia, Pennsylvania",
        "phone": "(215) 387-8232",
        "website": "https://lapearlbeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=La%20Pearl%20Beauty%20Emporium%20Inc&query_place_id=ChIJj8z_BVXGxokRNllmGChqamk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOjvDKIR2SWC0dKpPW7BKxTPYqurllDsppngiBO=w408-h544-k-no"
      },
      {
        "name": "Skin House Facial Bar Rittenhouse",
        "description": "Facial spa. Rating: 4.7/5 from 46 reviews",
        "address": "2020 Locust St, Philadelphia, Pennsylvania",
        "phone": "(215) 201-3953",
        "website": "https://www.skinhousefacialbar.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skin%20House%20Facial%20Bar%20Rittenhouse&query_place_id=ChIJm7w0hr7HxokRaLJLd1dBPP0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPnFomYWTxbx_t9heq5jqk2gdMVBoBLho4X8ixu=w408-h612-k-no"
      }
    ],
    "description": "Philly's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Minneapolis",
    "slug": "minneapolis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Brenda Iris - Mindful Styling",
        "description": "Image consultant. Rating: 5/5 from 31 reviews",
        "address": "1624 Harmon Pl, Minneapolis, Minnesota",
        "phone": "",
        "website": "http://www.mindful-styling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Brenda%20Iris%20-%20Mindful%20Styling&query_place_id=ChIJs-1vFM07s1IRg48KRUHw55Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNF-8DRW3GAnj9kdQFDc4HE02DBvHom_vtjyDfS=w426-h240-k-no"
      },
      {
        "name": "The Refinery",
        "description": "Waxing hair removal service. Rating: 4.6/5 from 262 reviews",
        "address": "34 13th Ave NE Suite 102, Minneapolis, Minnesota",
        "phone": "(612) 331-9055",
        "website": "http://refinerympls.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Refinery&query_place_id=ChIJ89oZKhIts1IR2B4JGhCQa_c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP0yvBf378Ckpupx6oEXLbpjTitZYU8MyrtZN4o=w532-h240-k-no"
      }
    ],
    "description": "Minneapolis's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "St. Louis",
    "slug": "st-louis",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Style Your Glow",
        "description": "Image consultant. Rating: 5/5 from 17 reviews",
        "address": "",
        "phone": "(314) 907-8088",
        "website": "http://www.styleyourglow.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Your%20Glow&query_place_id=ChIJCwbm15gys1IRdZvPsclODFQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJqIxWOPI5U6blc7HATVmjYnQogmWzZDVdxNWL=w408-h306-k-no"
      },
      {
        "name": "TheHairXP!",
        "description": "Beauty salon. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.thehairxp.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=TheHairXP!&query_place_id=ChIJz4Hqutq02IcRP9QuyQpQVOk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPE8yVLq3M81uuDREJYSWCXK4yDcL8hkW8vO6j7=w426-h240-k-no"
      }
    ],
    "description": "St. Louis's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Kansas City",
    "slug": "kansas-city",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Color Club KC",
        "description": "Stylist. Rating: 5/5 from 39 reviews",
        "address": "",
        "phone": "",
        "website": "https://colorclubkc.trafft.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Club%20KC&query_place_id=ChIJzalewgyeLEgRaLB1usXCDNc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDVoHmsVrTplLLGK-dUPmlcmxy6A0pvsBpt8Hy=w408-h259-k-no"
      },
      {
        "name": "House of Heavilin Beauty College",
        "description": "Beauty salon. Rating: 4.6/5 from 142 reviews",
        "address": "5720 Troost Ave, Kansas City, Missouri",
        "phone": "(816) 229-9000",
        "website": "http://kc-hair.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Heavilin%20Beauty%20College&query_place_id=ChIJR4NX2XfvwIcRw5TtKNcuQ4U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMnVFybfJXAU8_QFObK1gfbcJm6Jvi4c-k8FUhW=w408-h347-k-no"
      },
      {
        "name": "Antidote Hair Salon and Beauty Bar",
        "description": "Beauty salon. Rating: 4.6/5 from 188 reviews",
        "address": "211 Westport Rd, Kansas City, Missouri",
        "phone": "(816) 756-5606",
        "website": "http://antidotesalonkc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Antidote%20Hair%20Salon%20and%20Beauty%20Bar&query_place_id=ChIJ9T5IdNvvwIcRwXSBPthlfa4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNN4fai--Losqm3kRTs0VsYLgvM4yKeV9bX9y8O=w408-h510-k-no"
      }
    ],
    "description": "Kansas City's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Cleveland",
    "slug": "cleveland",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Colour Me Beautiful Salon",
        "description": "Beauty salon. Rating: 3.7/5 from 6 reviews",
        "address": "12501 Union Ave, Cleveland, Ohio",
        "phone": "(216) 278-6464",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful%20Salon&query_place_id=ChIJm66hTbL9MIgR_PmxnPkVO9c",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=qWyx2FRXRYJx36fTT7uztQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=346.65488&pitch=0&thumbfov=100"
      }
    ],
    "description": "Cleveland's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Pittsburgh",
    "slug": "pittsburgh",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Signature Styling Services",
        "description": "Stylist. Rating: 5/5 from 16 reviews",
        "address": "",
        "phone": "",
        "website": "https://wearyoursignature.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Signature%20Styling%20Services&query_place_id=ChIJx4sUS1XvNIgRhIsOSOHXvuY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNqqlJ7OOng-wDpMno1UqHLywnFNCWxCNQsVZ3A=w408-h272-k-no"
      },
      {
        "name": "Badass Styling",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "3361 Delaware St, Pittsburgh, Pennsylvania",
        "phone": "(412) 225-5109",
        "website": "http://www.badassstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Badass%20Styling&query_place_id=ChIJ39w5hgj0NIgRESR0UXj_Fls",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOBLWaPfxRWaBvol3xGrVpgN5Gq7sWzeOlm5qYB=w408-h368-k-no"
      }
    ],
    "description": "Pittsburgh's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Cincinnati",
    "slug": "cincinnati",
    "type": "city",
    "country": "United States",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Valerio Consulting",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "1127 Belvedere St, Cincinnati, Ohio",
        "phone": "(513) 703-0164",
        "website": "http://www.valeriollc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Valerio%20Consulting&query_place_id=ChIJr26Qt-CzQYgRyxoEFooLrVk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8ETo1nlU2_5GcxcT0lfzMgLcW_VsnrlFZo6O5=w408-h544-k-no"
      },
      {
        "name": "A Style Breeze Personal Stylist",
        "description": "Image consultant. Rating: 5/5 from 57 reviews",
        "address": "",
        "phone": "(513) 673-0013",
        "website": "http://www.astylebreeze.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=A%20Style%20Breeze%20Personal%20Stylist&query_place_id=ChIJ2efVl2OzQYgRod3f7BiZnkA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPmrTluaK24f1xAqOECF3gqPH9FmzMETA1tDYLT=w408-h510-k-no"
      },
      {
        "name": "Beatriz Justi | Image Consultancy",
        "description": "Image consultant. Rating: 5/5 from 2 reviews",
        "address": "",
        "phone": "(513) 568-2832",
        "website": "http://www.beatrizjusti.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beatriz%20Justi%20%7C%20Image%20Consultancy&query_place_id=ChIJ7xvIpvCGFCQR3P6NWTIvEFY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN7u5NWGEwjTmK7zdocJEXbNIM6lYiTZhUCV7-X=w408-h612-k-no"
      },
      {
        "name": "Omega Beauty & Wellness",
        "description": "Beauty salon. Rating: 4.9/5 from 317 reviews",
        "address": "1126 Broadway, Cincinnati, Ohio",
        "phone": "(513) 402-2216",
        "website": "http://www.omegabeautycincy.com/?y_source=1_MjI1MDM3NjQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
        "url": "https://www.google.com/maps/search/?api=1&query=Omega%20Beauty%20%26%20Wellness&query_place_id=ChIJrUDpjvazQYgRb59Om0zYj3M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPCfjRuiWm-M8wKbBRl-XHVNBOkRA2xp6mqBOEm=w408-h306-k-no"
      }
    ],
    "description": "Cincinnati's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Orlando",
    "slug": "orlando",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Moon Over Star - Personal Color Analyst",
        "description": "Stylist. Rating: 5/5 from 10 reviews",
        "address": "201 S Bumby Ave, Orlando, Florida",
        "phone": "(407) 917-7131",
        "website": "https://moonoverstar.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Moon%20Over%20Star%20-%20Personal%20Color%20Analyst&query_place_id=ChIJ448wia9754gRxcyhRJWe9N8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOVAa4L7iCVq0mXhBf2AfvTewzM0mhQhhd47k94=w408-h408-k-no"
      },
      {
        "name": "AC.Styles -- Personal Shopping & Styling Services",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "1726 E Jersey Ave, Orlando, Florida",
        "phone": "(321) 521-4064",
        "website": "http://www.ac-styles.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=AC.Styles%20--%20Personal%20Shopping%20%26%20Styling%20Services&query_place_id=ChIJT0Br0Gl754gRXrw0DgZT50c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOIrAq9q61UhPXJoUxwa13a6_8KO6LwR5gt8dtX=w408-h272-k-no"
      },
      {
        "name": "Bloom Esthetic Studio",
        "description": "Skin care clinic. Rating: 5/5 from 102 reviews",
        "address": "56 Park Lake St, Orlando, Florida",
        "phone": "(407) 494-1168",
        "website": "http://www.bloomestheticstudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bloom%20Esthetic%20Studio&query_place_id=ChIJO6o1-F1754gRPxqKBtSa4Tw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_nz6taA1z1xHPTeIPS213ClaBDkNVTi1XTpQg=w408-h444-k-no"
      }
    ],
    "description": "Orlando's color analysis services reflect the city's unique blend of cultural influences and vibrant entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Tampa",
    "slug": "tampa",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Invent Your Image",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "4320 W El Prado Blvd Suite 19, Tampa, Florida",
        "phone": "(813) 766-8375",
        "website": "http://www.inventyourimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Invent%20Your%20Image&query_place_id=ChIJY13WUYPDwogRSdqG--JXykw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMn6r2KCxT83IbAAGRuhN9OcASFt9PKY7cbsFAl=w408-h271-k-no"
      },
      {
        "name": "Femme Akoi Beauty Studio Tampa",
        "description": "Beauty salon. Rating: 4.9/5 from 34 reviews",
        "address": "2002 E 5th Ave UNIT 104, Tampa, Florida",
        "phone": "(727) 266-2233",
        "website": "http://www.femmeakoi.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Femme%20Akoi%20Beauty%20Studio%20Tampa&query_place_id=ChIJxdiVsV3EwogRCDs4DLAyr6g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNntheS_A_DUvECQn-vKmF7PvuMTnzUv0PTdGV-=w426-h240-k-no"
      },
      {
        "name": "Glow By Natalia Barlow LLC",
        "description": "Image consultant. Rating: 5/5 from 49 reviews",
        "address": "4615 W North A St, Tampa, Florida",
        "phone": "(813) 603-7141",
        "website": "https://www.nataliabarlow.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Glow%20By%20Natalia%20Barlow%20LLC&query_place_id=ChIJD6Z0j3PDwogRJ0A8wGm-Pgc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNL33Bx3MCYLMZYwCDjmx_WzGD5L2_Epaid1Zs2=w408-h611-k-no"
      },
      {
        "name": "Addicted to Skin the Beauty & Aesthetics Studio",
        "description": "Beauty salon. Rating: 4.9/5 from 52 reviews",
        "address": "5009 N Central Ave, Tampa, Florida",
        "phone": "(727) 648-0619",
        "website": "http://www.addictedtoskin.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Addicted%20to%20Skin%20the%20Beauty%20%26%20Aesthetics%20Studio&query_place_id=ChIJQ_rE_E_9wogREbgOEZK_-00",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNwd-rSES3Bxi2Ht7GNrQpnEo1m2W31ju2xdkt7=w408-h408-k-no"
      }
    ],
    "description": "Tampa's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Raleigh",
    "slug": "raleigh",
    "type": "city",
    "country": "United States",
    "pricingRange": "$140 - $350",
    "businesses": [
      {
        "name": "Color Me Beautiful Raleigh",
        "description": "Image consultant. Rating: 5/5 from 67 reviews",
        "address": "7486 Creedmoor Rd, Raleigh, North Carolina",
        "phone": "",
        "website": "http://colormebeautifulraleigh.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Me%20Beautiful%20Raleigh&query_place_id=ChIJ-8iVJjT3rIkRKcE9Oe4_dAk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDS6tIHXnkwFbW6gzLh9LztaanqSH8oF8CK6I=w408-h714-k-no"
      },
      {
        "name": "KroCia LLC (Image/Wardrobe Consultant Raleigh-Durham-Charlotte)",
        "description": "Image consultant. Rating: 5/5 from 49 reviews",
        "address": "1100 Logger Ct Suite f102, Raleigh, North Carolina",
        "phone": "(919) 322-9369",
        "website": "http://yourstyleconsultant.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=KroCia%20LLC%20(Image%2FWardrobe%20Consultant%20Raleigh-Durham-Charlotte)&query_place_id=ChIJJwR_NQFZrIkR73YKIotyfrs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOyqfi0gk-nTet0kKd-pwvxeJVe2zCholN8-nXJ=w428-h240-k-no"
      },
      {
        "name": "Color Scheme G.B.A. LLC",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "6350 Plantation Center Dr # 111, Raleigh, North Carolina",
        "phone": "(504) 388-1177",
        "website": "http://color-scheme-gba.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Scheme%20G.B.A.%20LLC&query_place_id=ChIJqbNkhb1ZrIkRREUgCBvS7aQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHJAmbLVtIgMAykmuz74fxzmKZII6bvgPQ5sV2=w408-h542-k-no"
      },
      {
        "name": "The Color and Curl Co•Lab",
        "description": "Hair salon. Rating: 5/5 from 27 reviews",
        "address": "7361 Six Forks Rd Suite 112, Raleigh, North Carolina",
        "phone": "(919) 617-7760",
        "website": "http://thecolorandcurlcolab.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20and%20Curl%20Co%E2%80%A2Lab&query_place_id=ChIJmbhra8VZrIkRdGzhQCc1quU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNSy7BXCsKQ9NS9tzrvU9U-wgMfEjezGoaX9km_=w408-h544-k-no"
      },
      {
        "name": "The Makeover Expert",
        "description": "Consultant. Rating: 5/5 from 9 reviews",
        "address": "7521 Windmill Harbor Way, Raleigh, North Carolina",
        "phone": "(919) 624-2294",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Makeover%20Expert&query_place_id=ChIJ-8Qlenf6rIkRNyjeFn8GOys",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=O9MFAFArXN3UvpDpOSD_bw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=71.566376&pitch=0&thumbfov=100"
      }
    ],
    "description": "Raleigh's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Salt Lake City",
    "slug": "salt-lake-city",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Salt Lake City's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Sacramento",
    "slug": "sacramento",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Colors Salon & Education",
        "description": "Beauty salon. Rating: 4.8/5 from 37 reviews",
        "address": "1417 28th St, Sacramento, California",
        "phone": "(916) 538-6319",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20Salon%20%26%20Education&query_place_id=ChIJw6Svd-vQmoARyOh1f2t6POo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMjrNDSyzXVM_yh54vhhdMRJChIqpHfz7KcGCtR=w408-h272-k-no"
      },
      {
        "name": "Color by Kaela",
        "description": "Hairdresser. Rating: 5/5 from 6 reviews",
        "address": "927 20th St, Sacramento, California",
        "phone": "(530) 906-4462",
        "website": "https://colorbykaela.square.site/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20by%20Kaela&query_place_id=ChIJx1RpApnRmoARJ1PkLy4Yeac",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPNpn2sftFHu2IldgcJBz9P_4_tylqALAKdOSBD=w408-h612-k-no"
      },
      {
        "name": "Studio Solstice",
        "description": "Beauty salon. Rating: 5/5 from 9 reviews",
        "address": "2060 Markham Way, Sacramento, California",
        "phone": "(279) 499-2652",
        "website": "http://thestudiosolstice.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20Solstice&query_place_id=ChIJ_dKWkbvRmoARoSYnZWLr7jY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP5U5TyyAiELQm-FvVgzHbqzPDyi9OgcOQGfBjZ=w408-h611-k-no"
      },
      {
        "name": "RKS SALON",
        "description": "Beauty salon. Rating: 4.6/5 from 51 reviews",
        "address": "2212 K St, Sacramento, California",
        "phone": "(916) 444-7474",
        "website": "http://rkssalon.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=RKS%20SALON&query_place_id=ChIJfUHwWsfRmoARlVaZqANTnZw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOxJMEl4Ma1HCCqPcHradXRCdMd63AXOfrB_6hG=w408-h544-k-no"
      }
    ],
    "description": "Sacramento's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Honolulu",
    "slug": "honolulu",
    "type": "city",
    "country": "United States",
    "pricingRange": "$150 - $400",
    "businesses": [
      {
        "name": "Elizabeth Kosich Styling",
        "description": "Image consultant. Rating: 5/5 from 9 reviews",
        "address": "1109 Maunakea St Loft #217, Honolulu, Hawaii",
        "phone": "(917) 743-9240",
        "website": "https://elizabethkosichstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Elizabeth%20Kosich%20Styling&query_place_id=ChIJ50-hd0TYPU0RA4-k0sAi92M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNoECI2r2JPNse7GvglazmXFlcVUy7qvXppuLiR=w408-h306-k-no"
      },
      {
        "name": "COLOR LOUNGE",
        "description": "Beauty salon. Rating: 4.8/5 from 27 reviews",
        "address": "3660 Waialae Ave Lounge, Honolulu, Hawaii",
        "phone": "(808) 734-5858",
        "website": "http://www.colorloungehawaii.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=COLOR%20LOUNGE&query_place_id=ChIJy1SBcIZtAHwROAMC0rvoXFo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJ3S54XaXmKCPY-8BRNBvw0Ydu_LTJl4hoHfOV=w426-h240-k-no"
      },
      {
        "name": "Ume Personal Color",
        "description": "Image consultant",
        "address": "",
        "phone": "(323) 991-2105",
        "website": "https://www.umepersonalcolor.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=7433919747832458961",
        "url": "https://www.google.com/maps/search/?api=1&query=Ume%20Personal%20Color&query_place_id=ChIJ2aSMcpKgsAsRFFHq6tsdmUc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8WlGLYI8t0PwPd5zxkGVkL-0RsQxdZyLwPe9s=w408-h612-k-no"
      },
      {
        "name": "Alluring LLC",
        "description": "Consultant. Rating: 5/5 from 7 reviews",
        "address": "1617 Kapiolani Blvd STE 206, Honolulu, Hawaii",
        "phone": "(808) 368-3709",
        "website": "https://www.alluringllc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Alluring%20LLC&query_place_id=ChIJ__8vjfFtAHwRgBSvcNneuUQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOZIAKFId9fYS4XKY-LUNN-dKmXm5a-T2rY8XeR=w408-h408-k-no"
      }
    ],
    "description": "Honolulu's color analysis services are influenced by the city's tropical climate and vibrant culture. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Anchorage",
    "slug": "anchorage",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $350",
    "businesses": [
      {
        "name": "The color palette by Carrie peacock",
        "description": "Hairdresser",
        "address": "135 W Dimond Blvd #200, Anchorage, Alaska",
        "phone": "(480) 458-8616",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20color%20palette%20by%20Carrie%20peacock&query_place_id=ChIJdVAcKcKZyFYRExFb7nV3xCA",
        "imageUrl": ""
      },
      {
        "name": "Ulta Beauty",
        "description": "Beauty salon. Rating: 3.9/5 from 359 reviews",
        "address": "800 E Dimond Blvd Suite 190, Anchorage, Alaska",
        "phone": "(907) 336-7100",
        "website": "https://www.ulta.com/stores/anchorage-ak-1162",
        "url": "https://www.google.com/maps/search/?api=1&query=Ulta%20Beauty&query_place_id=ChIJLe_y_NCZyFYRos2BBNFYRE0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNgHOaSIqquqkkpq3Imrp9sqtzc7yMYj5TEHtvU=w408-h408-k-no"
      }
    ],
    "description": "Anchorage's color analysis services reflect the city's unique climate and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Tucson",
    "slug": "tucson",
    "type": "city",
    "country": "United States",
    "pricingRange": "$110 - $320",
    "businesses": [
      {
        "name": "The Color Palette Salon",
        "description": "Hair salon. Rating: 5/5 from 25 reviews",
        "address": "2914 N Campbell Ave suite 128, Tucson, Arizona",
        "phone": "(520) 736-0287",
        "website": "https://thecolorpalettesalon.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Palette%20Salon&query_place_id=ChIJ5181FAlx1oYR520v75Z7y5U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOsBmc1UL96EQdf2aELlKXqD0AtjRqYucgqsL5G=w408-h541-k-no"
      },
      {
        "name": "Salon Nouveau",
        "description": "Beauty salon. Rating: 4.9/5 from 1004 reviews",
        "address": "2204 E Fort Lowell Rd, Tucson, Arizona",
        "phone": "(520) 323-0770",
        "website": "http://www.salondenouveau.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Salon%20Nouveau&query_place_id=ChIJweFrj4Vx1oYR9GmcoJO7OFI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMHA8-jHEOnzs_fegejrCKtGED3Ktimq5iHgqGq=w408-h262-k-no"
      },
      {
        "name": "Robert Markley Salon Spa",
        "description": "Beauty salon. Rating: 4.6/5 from 161 reviews",
        "address": "5350 E Broadway Blvd, Tucson, Arizona",
        "phone": "(520) 747-1388",
        "website": "http://www.robertmarkley.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Robert%20Markley%20Salon%20Spa&query_place_id=ChIJ6V85Qr9v1oYR8xBMnXEnK2I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMteO-LTQbmKEfvGKn4fw3jnmikhg8ottbIG0mk=w408-h544-k-no"
      }
    ],
    "description": "Tucson's color analysis services reflect the city's desert climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Fresno",
    "slug": "fresno",
    "type": "city",
    "country": "United States",
    "pricingRange": "$120 - $330",
    "businesses": [
      {
        "name": "Color Me Chula",
        "description": "Facial spa. Rating: 5/5 from 52 reviews",
        "address": "530 E Olive Ave, Fresno, California",
        "phone": "(559) 554-1587",
        "website": "https://colormechula.glossgenius.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Me%20Chula&query_place_id=ChIJ6ykU_01nlIARtYoVvz6hS7U",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPHYOHtdqEB_SkHBsoas1QKEUKo7tz9sB5Yl0M=w408-h725-k-no"
      },
      {
        "name": "Colors 2 Dye 4",
        "description": "Beauty salon. Rating: 4.7/5 from 6 reviews",
        "address": "1474 W Shaw Ave, Fresno, California",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%202%20Dye%204&query_place_id=ChIJgyIvUgxnlIAR7iX1u59-S78",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Pw_wWQxydXFJlkg6jfKGsA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=13.586338&pitch=0&thumbfov=100"
      }
    ],
    "description": "Fresno's color analysis services are influenced by the city's agricultural roots and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Toronto",
    "slug": "toronto",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Lynda Jean Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 27 reviews",
        "address": "360 Bloor St E, Toronto, Ontario",
        "phone": "+1 416-960-1333",
        "website": "https://www.lyndajean.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lynda%20Jean%20Image%20Consulting&query_place_id=ChIJ2yeBqBzL1IkRfufJhmpNkqA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN6YFDLEAhuzG59feKhgszJy-X0OU-lwXNdp5Iv=w408-h270-k-no"
      },
      {
        "name": "W10 Colours Inc Custom Designs- Custom Couture Women Business Suits And Evening Wear, Colours, Image Consultants And Wedding",
        "description": "Image consultant. Rating: 4.7/5 from 12 reviews",
        "address": "Bloor Yorkville, 111 Yorkville Ave 3rd Floor, Suite 4, Toronto, Ontario",
        "phone": "+1 416-962-1224",
        "website": "http://w10inc.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=W10%20Colours%20Inc%20Custom%20Designs-%20Custom%20Couture%20Women%20Business%20Suits%20And%20Evening%20Wear%2C%20Colours%2C%20Image%20Consultants%20And%20Wedding&query_place_id=ChIJoVULuXs1K4gRUvvM8OYf1_s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNMDGBElpm_1RJZH4WTS4qGaMt5Ix--MkMJqf45=w408-h504-k-no"
      },
      {
        "name": "International Image Institute Inc.",
        "description": "Consultant",
        "address": "1200 Bay St. Suite 202, Toronto, Ontario",
        "phone": "+1 416-809-4321",
        "website": "https://www.imageinstitute.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=International%20Image%20Institute%20Inc.&query_place_id=ChIJzVDYsH3WKogRjlBwoSSTdsI",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=W1_wRe6nk35fc1Cz2_9iAg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=235.0294&pitch=0&thumbfov=100"
      },
      {
        "name": "Hannah The Color Lady",
        "description": "Image consultant",
        "address": "3875 Sheppard Ave E #1003, Scarborough, Ontario",
        "phone": "+1 647-761-0849",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Hannah%20The%20Color%20Lady&query_place_id=ChIJD0nT0xHR1IkRQfWXMpAskiU",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=XnY5Ol5d_CPuktsW7qTgCQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=197.82634&pitch=0&thumbfov=100"
      }
    ],
    "description": "Toronto's color analysis services reflect the city's diverse culture and vibrant fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Montreal",
    "slug": "montreal",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$120 - $340",
    "businesses": [
      {
        "name": "StyleSandra | Personal Style Brand Strategist",
        "description": "Image consultant. Rating: 4.9/5 from 42 reviews",
        "address": "100 Alexis-Nihon Blvd suite 980, Saint-Laurent, Quebec",
        "phone": "+1 514-241-1342",
        "website": "http://www.stylesandra.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=StyleSandra%20%7C%20Personal%20Style%20Brand%20Strategist&query_place_id=ChIJwz-bjWMXyUwR50jpPNQ9Dok",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMnYQYGCxRqTwkw4qef3uKoR0oIsSUQg1FZbw4Q=w408-h510-k-no"
      },
      {
        "name": "Personal Stylist Yun",
        "description": "Fashion designer",
        "address": "1307 Saint-Catherine St W, Montreal, Quebec",
        "phone": "+1 438-822-4974",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Stylist%20Yun&query_place_id=ChIJcdZueuEbyUwRdBzbDJBaohQ",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=gqeU5RsbS2xuVaWeAfzUNw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=214.12799&pitch=0&thumbfov=100"
      },
      {
        "name": "West Spa",
        "description": "Beauty salon. Rating: 4.6/5 from 293 reviews",
        "address": "4655 Boul. Saint-Jean #202, Dollard-Des Ormeaux, Quebec",
        "phone": "+1 514-626-7722",
        "website": "http://www.westspa.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=West%20Spa&query_place_id=ChIJS3GfnXE7yUwR7w1RilOfsIQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNf_GtTeinq37AYeDxR0GBTmv7MYU-6hLGSqELn=w408-h612-k-no"
      }
    ],
    "description": "Montreal's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Vancouver",
    "slug": "vancouver",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$140 - $360",
    "businesses": [
      {
        "name": "Top Ten Colour Consultant Canada",
        "description": "Consultant. Rating: 5/5 from 2 reviews",
        "address": "8860 Montcalm St #205, Vancouver, British Columbia",
        "phone": "(888) 265-6878",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Top%20Ten%20Colour%20Consultant%20Canada&query_place_id=ChIJe2SMsRh1hlQRFbc7fkyXgSM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOzwki02NtL7e5RZKAtPPeKP7n7TuK5avsdZVjn=w427-h240-k-no"
      },
      {
        "name": "The Colour Closet",
        "description": "Consultant",
        "address": "207-402 W Pender St, Vancouver, British Columbia",
        "phone": "+1 639-317-7724",
        "website": "http://thecolourcloset.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Colour%20Closet&query_place_id=ChIJCQBQGXlxhlQRMJY_9Scp0r4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=CvCpicJiv0L5FRwqAJEsvA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=216.89487&pitch=0&thumbfov=100"
      },
      {
        "name": "Juliana Bicudo Personal Stylist",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "+1 778-863-7323",
        "website": "http://www.julianabicudo.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=Juliana%20Bicudo%20Personal%20Stylist&query_place_id=ChIJAVrc4HAvm00Rd-WUiPEO8ns",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPiUXD0nrqF1sJxqCqDci52VNYtbMrrhvg3nda9=w408-h509-k-no"
      },
      {
        "name": "JH | Creative Color Parlor",
        "description": "Beauty salon. Rating: 5/5 from 23 reviews",
        "address": "1120 Homer St, Vancouver, British Columbia",
        "phone": "+1 604-783-0255",
        "website": "http://www.jh-creative.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=JH%20%7C%20Creative%20Color%20Parlor&query_place_id=ChIJS_YLtNNzhlQRiHT1Lb5mZcQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOu_0KL0H602bg25dvCEeMX1_QerehlOasjVixW=w408-h398-k-no"
      },
      {
        "name": "Diana Kilgour Image Consultants",
        "description": "Consultant",
        "address": "602 - 738 Broughton St, Vancouver, British Columbia",
        "phone": "+1 604-688-2889",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Diana%20Kilgour%20Image%20Consultants&query_place_id=ChIJYypT9oZxhlQRCDfDCoiRH-4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=oSKxZRXxYb2_KixNQsnEYA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=114.73838&pitch=0&thumbfov=100"
      }
    ],
    "description": "Vancouver's color analysis services reflect the city's coastal climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Calgary",
    "slug": "calgary",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "Build Color Confidence",
        "description": "Image consultant. Rating: 5/5 from 43 reviews",
        "address": "",
        "phone": "+1 780-231-3855",
        "website": "http://www.buildcolorconfidence.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Build%20Color%20Confidence&query_place_id=ChIJTw2UKFT1n1MRFFycpaFna7M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP6abKv9SmJY8-o6dpXAFAYrnb_xCdDach6gTSd=w408-h272-k-no"
      },
      {
        "name": "Style by Fiona Style and Image Consulting",
        "description": "Stylist. Rating: 5/5 from 8 reviews",
        "address": "29 Erin Green Way SE, Calgary, Alberta",
        "phone": "+1 403-966-5896",
        "website": "http://www.stylebyfiona.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20by%20Fiona%20Style%20and%20Image%20Consulting&query_place_id=ChIJpRIyfP17cVMR_6pDtWutfKw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNRGOdCRoqcfbgmG6YkmF6M3mPPHTJE4h9XJm9d=w408-h418-k-no"
      },
      {
        "name": "Essential Style Consulting",
        "description": "Stylist. Rating: 5/5 from 31 reviews",
        "address": "",
        "phone": "+1 403-826-9910",
        "website": "https://essentialstyleconsulting.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=Essential%20Style%20Consulting&query_place_id=ChIJjSTO47fAegkRdEPH3nlyFlY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPzSDnQDe3W62AXvPBqtzZYkEunpimI_4P-IhsN=w408-h408-k-no"
      },
      {
        "name": "The Canadian Image Company",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "106 Master's Place, Calgary, AB",
        "phone": "+1 403-427-1424",
        "website": "http://www.caimage.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Canadian%20Image%20Company&query_place_id=ChIJwZ8aJH-DcVMRXhJmPVeWZwc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNpgsXVONcYn3TA1jtG6lIZRgS_xHNKXx6XGa7r=w408-h272-k-no"
      }
    ],
    "description": "Calgary's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Edmonton",
    "slug": "edmonton",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$120 - $340",
    "businesses": [
      {
        "name": "All Set Style & Image Consulting",
        "description": "Image consultant. Rating: 5/5 from 38 reviews",
        "address": "",
        "phone": "+1 780-800-9928",
        "website": "http://www.allsetstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=All%20Set%20Style%20%26%20Image%20Consulting&query_place_id=ChIJE6KKXyseoFMRY_EEf27Eqdw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPzhTcu8LJVpNrS7glGsj5P20HuAHSz5HaGrbdC=w427-h240-k-no"
      },
      {
        "name": "Get Dressed on Purpose",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "104 Allard Link SW, Edmonton, Alberta",
        "phone": "+1 587-590-6430",
        "website": "http://getdressedonpurpose.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Get%20Dressed%20on%20Purpose&query_place_id=ChIJfb1oCQ0doFMRuNWRgYksb0I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMSAPhqSJCIku8d1WsC_0H673eP9wwQ5IHKwPzY=w408-h406-k-no"
      },
      {
        "name": "Gold Standard Style",
        "description": "Stylist. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.goldstandardstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Gold%20Standard%20Style&query_place_id=ChIJtzbrJQ_T1ikRqnJkqPBtApk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMKm3rFLq8lNxe3nL3MP3YkQJg17L5JjL0A2DcE=w408-h272-k-no"
      },
      {
        "name": "Ash Styles",
        "description": "Stylist. Rating: 5/5 from 61 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.ash-styles.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ash%20Styles&query_place_id=ChIJicCjHIPKbKsRZYSMLC7CqMQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNq-Q6VVkUUB4cBkVJQuzxeNby2sJV1II85J1BS=w426-h240-k-no"
      },
      {
        "name": "Marie Zydek Styling",
        "description": "Stylist. Rating: 5/5 from 16 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.mariezydek.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Marie%20Zydek%20Styling&query_place_id=ChIJrcD7jQEqYAkRz0cGSiVPI9s",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOqBTqEaaO9oUKw_XbJlkHGXu8AVqMkWviH9cgU=w408-h343-k-no"
      }
    ],
    "description": "Edmonton's color analysis services reflect the city's unique climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Ottawa",
    "slug": "ottawa",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "HAIRCLUB",
        "description": "Beauty salon. Rating: 4.4/5 from 44 reviews",
        "address": "1730 St. Laurent Blvd #430, Ottawa, Ontario",
        "phone": "+1 613-655-1506",
        "website": "https://www.hairclub.com/locations/canada/ontario/ottawa/ottawa/?pub=DGSEOGOOLOCA00017295&utm_source=google&utm_medium=organic&utm_campaign=gbp-listing-ottawa",
        "url": "https://www.google.com/maps/search/?api=1&query=HAIRCLUB&query_place_id=ChIJ8_pEzHIPzkwR1zSK-EkbmbE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMWLvuPrMli8oR2XJ5bA7x8cys9JN2WEhfDXPRf=w408-h408-k-no"
      },
      {
        "name": "The Style CV",
        "description": "Stylist. Rating: 4.9/5 from 19 reviews",
        "address": "",
        "phone": "+1 613-852-0033",
        "website": "https://www.thestylecv.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Style%20CV&query_place_id=ChIJnW0E9iG8qEkR8ZCXWpbJCms",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNW7ebdAcR7TET4bfimQthWAec22V_EegPX_OHj=w408-h593-k-no"
      }
    ],
    "description": "Ottawa's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Winnipeg",
    "slug": "winnipeg",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$120 - $340",
    "businesses": [
      {
        "name": "HIGHStyle Impression Management",
        "description": "Image consultant",
        "address": "192 Tache Ave, Winnipeg, Manitoba",
        "phone": "+1 204-999-5057",
        "website": "http://highstyleimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=HIGHStyle%20Impression%20Management&query_place_id=ChIJr6m6JrR26lIRKn6bOt2nDTI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP6LY5FFQgonUuSBx0HdMFEf3UolHvLJNI92CUQ=w691-h240-k-no"
      },
      {
        "name": "Colors Beauty and Wellness",
        "description": "Hair salon. Rating: 4.9/5 from 154 reviews",
        "address": "1072 Pembina Hwy, Winnipeg, Manitoba",
        "phone": "+1 204-477-9723",
        "website": "https://www.colorsbeautywellness.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20Beauty%20and%20Wellness&query_place_id=ChIJAbkEfDB06lIRxCNnoDer33A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP6WlPJI5th4C8T2_l17KAhI9LzddZOuyASkUY=w408-h408-k-no"
      },
      {
        "name": "SEPHORA",
        "description": "Cosmetics store. Rating: 3.8/5 from 209 reviews",
        "address": "1225 St Mary's Rd Unit #124, Winnipeg, Manitoba",
        "phone": "+1 204-255-0005",
        "website": "https://www.sephora.com/happening/stores/winnipeg-st-vital?utm_source=gmb&utm_medium=Yext",
        "url": "https://www.google.com/maps/search/?api=1&query=SEPHORA&query_place_id=ChIJDQF8Hm926lIR-ORtt_d-DWU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOoxBongDDfr4_XpBzJb3Cb3oqpANOFyoxxvcbH=w433-h240-k-no"
      }
    ],
    "description": "Winnipeg's color analysis services reflect the city's unique climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Quebec City",
    "slug": "quebec-city",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Quebec City's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Hamilton",
    "slug": "hamilton",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$120 - $340",
    "businesses": [
      {
        "name": "Unique To You Colour Consultation",
        "description": "undefined",
        "address": "23 Alba St, Stoney Creek, Ontario",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Unique%20To%20You%20Colour%20Consultation&query_place_id=ChIJXcpygl6YLIgRt_7PEHwvh_0",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=D5MA28bg1jY1BiFwC3W59w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=166.32143&pitch=0&thumbfov=100"
      }
    ],
    "description": "Hamilton's color analysis services reflect the city's industrial roots and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Halifax",
    "slug": "halifax",
    "type": "city",
    "country": "Canada",
    "pricingRange": "$130 - $350",
    "businesses": [
      {
        "name": "High Chroma - Colour Analysis Halifax",
        "description": "Image consultant. Rating: 5/5 from 8 reviews",
        "address": "1556 Queen St, Halifax, Nova Scotia",
        "phone": "",
        "website": "https://www.highchroma.ca/",
        "url": "https://www.google.com/maps/search/?api=1&query=High%20Chroma%20-%20Colour%20Analysis%20Halifax&query_place_id=ChIJt1KKr_UjWksRGiw86Q_QrN4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=yUXDDmZua_JM4r1j98OXAQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=242.32802&pitch=0&thumbfov=100"
      },
      {
        "name": "Colours",
        "description": "Cosmetics store",
        "address": "7001 Mumford Rd, Halifax, Nova Scotia",
        "phone": "+1 902-422-3111",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours&query_place_id=ChIJT8kCppkhWksRZtbe_2u9Zew",
        "imageUrl": ""
      }
    ],
    "description": "Halifax's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "London",
    "slug": "london",
    "type": "city",
    "country": "United Kingdom",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "House of Colour Worcester Park, Raynes Park, Epsom & Banstead",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "115 Bridgewood Rd, Worcester Park",
        "phone": "+44 7816 539513",
        "website": "http://www.houseofcolour.co.uk/isobelpenny",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Worcester%20Park%2C%20Raynes%20Park%2C%20Epsom%20%26%20Banstead&query_place_id=ChIJqZFdjmMJdkgRZZzzHLWSZew",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPXOpxhUOyBQwbtJmc9IoJ8xJPY_-XUBMlKYyHV=w408-h272-k-no"
      },
      {
        "name": "Chromology - Colour Analysis & Image Consultancy",
        "description": "Consultant. Rating: 5/5 from 18 reviews",
        "address": "7-9, Light Centre Belgravia, Eccleston St, London",
        "phone": "+44 7753 350734",
        "website": "http://www.chromology.co.uk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Chromology%20-%20Colour%20Analysis%20%26%20Image%20Consultancy&query_place_id=ChIJsYEvlpi2dkgRHXX_-B5Y6NI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOPELZ0jqfjm6GJyomIUeOU5iEJJOEjh1dcJqZL=w408-h613-k-no"
      },
      {
        "name": "Colours by Linda",
        "description": "Image consultant. Rating: 5/5 from 21 reviews",
        "address": "London",
        "phone": "+44 7703 837210",
        "website": "https://instagram.com/colours_by_linda?utm_source=qr",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20by%20Linda&query_place_id=ChIJw8PuhpADdkgR3G1xvjkjbp8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=wi8CIL-s_397hCYYd48MZg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=12.310653&pitch=0&thumbfov=100"
      },
      {
        "name": "House of Colour Sandy Lancaster",
        "description": "Image consultant. Rating: 5/5 from 58 reviews",
        "address": "42 Grosvenor Rd, London",
        "phone": "+44 7768 233895",
        "website": "https://linktr.ee/houseofcolour_sandylancaster",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Sandy%20Lancaster&query_place_id=ChIJczlsqRMPdkgRjvVbWzWWOEg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNW4WXo0UTtjr35TsX-QC2TbsavfOY4SBEWhdqL=w408-h383-k-no"
      },
      {
        "name": "Julia Calliano - Personal Stylist & Image Consultant",
        "description": "Consultant. Rating: 5/5 from 90 reviews",
        "address": "Ribblesdale Rd, London",
        "phone": "",
        "website": "http://www.juliacalliano.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Julia%20Calliano%20-%20Personal%20Stylist%20%26%20Image%20Consultant&query_place_id=ChIJD0pZDwgHdkgRgXix7BtuNNA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMgMMrUyyow378KQZQJufObHnSCVv873KU64NIp=w408-h408-k-no"
      }
    ],
    "description": "London's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Paris",
    "slug": "paris",
    "type": "city",
    "country": "France",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "name": "The Color Fashionista",
        "description": "Image consultant. Rating: 5/5 from 28 reviews",
        "address": "Rue Dalou, Paris",
        "phone": "+33 6 25 47 01 55",
        "website": "https://thecolorfashionista.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Color%20Fashionista&query_place_id=ChIJuZn_tUzTHW4RWQ4aHcuN7kQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPR79WRtSVYzEfd8ACpR4IuYKOPLQYypNX4hOP3=w408-h408-k-no"
      },
      {
        "name": "BWonderful",
        "description": "Consultant. Rating: 5/5 from 5 reviews",
        "address": "52 Rue de Clichy, Paris",
        "phone": "+33 6 66 01 75 44",
        "website": "https://www.bwonderful.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=BWonderful&query_place_id=ChIJgwhg4Etu5kcRJj3vmMAeZzo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO0MqP8li1e0sEJH4fOI7XO4Clyde329l3vBXyU=w427-h240-k-no"
      },
      {
        "name": "My Color Match",
        "description": "Stylist. Rating: 5/5 from 7 reviews",
        "address": "",
        "phone": "+33 6 65 26 60 31",
        "website": "http://www.mycolormatch.fr/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Color%20Match&query_place_id=ChIJj0A5BFTDq2kRgGav9YI6tkM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO52rqiwa_HX7s3XNlA347y8PTEQKWv91Gno-rm=w408-h475-k-no"
      },
      {
        "name": "Miriam Lasserre",
        "description": "Stylist. Rating: 5/5 from 32 reviews",
        "address": "109 Rue du Bac, Paris",
        "phone": "+33 6 47 19 72 35",
        "website": "http://www.miriamlasserre.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Miriam%20Lasserre&query_place_id=ChIJLyKcA9xx5kcRs1EwphrJI0o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM_iWZBZB9Hy_WtNu-E9VJe4-S0tfsjgdAPv4ME=w408-h334-k-no"
      }
    ],
    "description": "Paris is known for its high-end fashion and sophisticated color analysis services. Analysts often focus on chic and elegant palettes that reflect the city's style."
  },
  {
    "name": "Berlin",
    "slug": "berlin",
    "type": "city",
    "country": "Germany",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "LOOK AT YOU- Style and Colour Coaching Berlin",
        "description": "Stylist. Rating: 5/5 from 50 reviews",
        "address": "",
        "phone": "+49 179 2144516",
        "website": "http://lookatyou.net/",
        "url": "https://www.google.com/maps/search/?api=1&query=LOOK%20AT%20YOU-%20Style%20and%20Colour%20Coaching%20Berlin&query_place_id=ChIJ46xf4ABSqEcRN4j1xD1ojVI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMBcyuSut7vlqEGwOj39W4ZjbsaK4yMYhLHcYRT=w408-h408-k-no"
      },
      {
        "name": "Ana Paula Moraes - Ground Your Style",
        "description": "Stylist. Rating: 5/5 from 7 reviews",
        "address": "",
        "phone": "+49 1512 4194003",
        "website": "https://groundyourstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ana%20Paula%20Moraes%20-%20Ground%20Your%20Style&query_place_id=ChIJ0fn1NeFs_g4Roo11Wb5Apq4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOiDZ_68GapL8MAQ1EZqW8IQ0kB9Jagsw1ZXrjX=w408-h544-k-no"
      },
      {
        "name": "KARLIE MUEHLBACH | Farb-, Stilberatung & Imageberatung",
        "description": "Image consultant. Rating: 5/5 from 50 reviews",
        "address": "Apostel-Paulus-Straße 2, Berlin",
        "phone": "+49 176 43626602",
        "website": "https://www.karliemuehlbach.de/",
        "url": "https://www.google.com/maps/search/?api=1&query=KARLIE%20MUEHLBACH%20%7C%20Farb-%2C%20Stilberatung%20%26%20Imageberatung&query_place_id=ChIJ2eT4KOpRqEcRmhn_6qS9W8c",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMQbbczWkvTtQRcZGDYyINL7u_lpxg4eBlzxjuC=w408-h272-k-no"
      }
    ],
    "description": "Berlin's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Madrid",
    "slug": "madrid",
    "type": "city",
    "country": "Spain",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "23 Estilos",
        "description": "Image consultant",
        "address": "",
        "phone": "+34 626 23 45 46",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=23%20Estilos&query_place_id=ChIJmResUzYnQg0R2ScsjfG5tlQ",
        "imageUrl": ""
      },
      {
        "name": "Universidad de la Imagen Escuela de Peluquería en Madrid",
        "description": "Escuela de peluquería. Rating: 4.9/5 from 37 reviews",
        "address": "C. de Coslada, 15, Madrid, Madrid",
        "phone": "+34 618 45 90 62",
        "website": "https://www.universidaddelaimagen.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Universidad%20de%20la%20Imagen%20Escuela%20de%20Peluquer%C3%ADa%20en%20Madrid&query_place_id=ChIJcZKG-qMpQg0RrfUNF8bq3Kk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM6X22Rh5rjSXluJKD4WxYAy7OWE8uaXf30QNgn=w408-h272-k-no"
      },
      {
        "name": "Verónica Lima - Consultora de Imagen y Estilo",
        "description": "Asesor de imagen. Rating: 5/5 from 22 reviews",
        "address": "",
        "phone": "+34 690 64 78 06",
        "website": "https://linktr.ee/veronicalima_es",
        "url": "https://www.google.com/maps/search/?api=1&query=Ver%C3%B3nica%20Lima%20-%20Consultora%20de%20Imagen%20y%20Estilo&query_place_id=ChIJA40LSyEtQg0RXpo8h24e-yU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPX7TLOBKK-nlpWpKGH3SasANY_ovGpwtpMAtWT=w408-h544-k-no"
      },
      {
        "name": "Sol Aliaga Tú Asesora de Imagen Personal",
        "description": "Asesor de imagen. Rating: 5/5 from 23 reviews",
        "address": "C. de Ríos Rosas, 39, Madrid, Madrid",
        "phone": "+34 683 17 89 62",
        "website": "https://solaliaga.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Sol%20Aliaga%20T%C3%BA%20Asesora%20de%20Imagen%20Personal&query_place_id=ChIJAbc-z5SVQQ0RLZaNXXo0QrI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNmoFQTSenuM_L4FRnDWO-d9Jv4qneeHsH59t31=w408-h408-k-no"
      },
      {
        "name": "Esmera School",
        "description": "Academia de estética. Rating: 4.7/5 from 920 reviews",
        "address": "P.º de Sta. María de la Cabeza, 10, local 2, Madrid, Madrid",
        "phone": "+34 912 79 59 75",
        "website": "https://www.esmeraschool.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Esmera%20School&query_place_id=ChIJe8pYOPcnQg0RAk0651KVZzw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNTwszuCRqcsVwAc5qJSrWOuiDxi751jNh6WX6q=w408-h306-k-no"
      }
    ],
    "description": "Madrid's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Rome",
    "slug": "rome",
    "type": "city",
    "country": "Italy",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "name": "Monica Micò Consulente d'Immagine e Armocromia",
        "description": "Image consultant. Rating: 5/5 from 460 reviews",
        "address": "Via Pellegrino Matteucci, 41, Rome, Metropolitan City of Rome Capital",
        "phone": "+39 06 8692 2211",
        "website": "http://www.monicamico.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Monica%20Mic%C3%B2%20Consulente%20d'Immagine%20e%20Armocromia&query_place_id=ChIJc3rsDitgLxMR4u0rJ7NL9pQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPHxVReWCQdyOxnuENFC3OLrip1dMHlXFjpZl3v=w408-h610-k-no"
      },
      {
        "name": "Artemisia Lab Biolevi",
        "description": "Beauty salon. Rating: 4.2/5 from 137 reviews",
        "address": "Via Nomentana, 523, Rome, Metropolitan City of Rome Capital",
        "phone": "+39 06 4429 1367",
        "website": "https://artemisialab.it/artemisia-biolevi/",
        "url": "https://www.google.com/maps/search/?api=1&query=Artemisia%20Lab%20Biolevi&query_place_id=ChIJZS0lS1hhLxMRGcVBFbOtpZQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMFJjdixl6wwU7mGBcdJmCOFjiM1r3RfJod7XW_=w408-h544-k-no"
      },
      {
        "name": "EP MakeOver",
        "description": "Image consultant. Rating: 5/5 from 7 reviews",
        "address": "Via Quinto Publicio, 19, Rome, Metropolitan City of Rome Capital",
        "phone": "+39 342 074 7246",
        "website": "https://epmakeover.it/",
        "url": "https://www.google.com/maps/search/?api=1&query=EP%20MakeOver&query_place_id=ChIJRTY3kZqJJRMRNFjxRZ62odw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP7u7J41FnXmnkxayHkwNtfMKr3_pKdXgPjjkkU=w408-h544-k-no"
      },
      {
        "name": "Sephora",
        "description": "Cosmetics store. Rating: 4.2/5 from 963 reviews",
        "address": "Via Dante, 14/Ground Floor, Milan, Metropolitan City of Milan",
        "phone": "+39 02 8645 8470",
        "website": "https://www.sephora.it/informazioni-store?storeID=IT28&utm_source=local&utm_support=organic&utm_campaign=uberall",
        "url": "https://www.google.com/maps/search/?api=1&query=Sephora&query_place_id=ChIJLWaPolLBhkcRhxefQVKdGuk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNsB2PauqjmcbX100Dc1z1xBf5MoBx8Lo3N9m4I=w408-h306-k-no"
      },
      {
        "name": "Simona Calavetta - analisi del colore",
        "description": "Image consultant. Rating: 5/5 from 31 reviews",
        "address": "Via Pio IX, 74, Rome, Metropolitan City of Rome Capital",
        "phone": "",
        "website": "http://www.simonacalavetta.it/%20%20%20simona.calavetta@gmail.com",
        "url": "https://www.google.com/maps/search/?api=1&query=Simona%20Calavetta%20-%20analisi%20del%20colore&query_place_id=ChIJse3QfO5fLxMRCClg2OKoak4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipORYyata0jjO-FurpGRv8bmb0VEAFPZ1_u1jjo_=w408-h544-k-no"
      }
    ],
    "description": "Rome's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Amsterdam",
    "slug": "amsterdam",
    "type": "city",
    "country": "Netherlands",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "House of Colour Amsterdam",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "Daniël Stalpertstraat 99, Amsterdam",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=House%20of%20Colour%20Amsterdam&query_place_id=ChIJ24KKxE4JxkcRG8YHECdtyMY",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=LjwYzntSgJFad9lFKtCxJg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=349.77847&pitch=0&thumbfov=100"
      },
      {
        "name": "Blueprint Styling",
        "description": "Image consultant. Rating: 5/5 from 8 reviews",
        "address": "",
        "phone": "+31 6 18432235",
        "website": "https://www.blueprintstyling.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Blueprint%20Styling&query_place_id=ChIJK0zWQVZQoAMREjb5grqwYhw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPgBPNNDezzyeZFSK51v-899YwKGBDAAp6h1V60=w408-h408-k-no"
      },
      {
        "name": "Shine by Abir",
        "description": "Stylist. Rating: 5/5 from 16 reviews",
        "address": "",
        "phone": "+31 6 50995212",
        "website": "http://www.instagram.com/shine.byabir",
        "url": "https://www.google.com/maps/search/?api=1&query=Shine%20by%20Abir&query_place_id=ChIJn60w2yXANAQRdRt-5htVsmI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNUU2A-9DY3FKPEdBuUU0b9U3Rin9S_9iB27VsN=w408-h612-k-no"
      },
      {
        "name": "Clothes & Confidence - Personal Stylist / Color Consultant",
        "description": "Stylist. Rating: 5/5 from 51 reviews",
        "address": "Admiraal de Ruijterweg 358AH, Amsterdam",
        "phone": "+31 6 81566319",
        "website": "https://clothesandconfidence.nl/",
        "url": "https://www.google.com/maps/search/?api=1&query=Clothes%20%26%20Confidence%20-%20Personal%20Stylist%20%2F%20Color%20Consultant&query_place_id=ChIJ2x7rmGi9UYoRoXLK4O_6RGE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNXH8ILDptJXoNiBbe4PTUzv7_7N5ffsbQP5997=w408-h272-k-no"
      },
      {
        "name": "DC Styling Studio",
        "description": "Stylist. Rating: 5/5 from 2 reviews",
        "address": "Stadhouderskade 151, Amsterdam",
        "phone": "+31 6 21465358",
        "website": "https://stylemedc.as.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=DC%20Styling%20Studio&query_place_id=ChIJAboMHJ0JxkcR7T46kwFhCSY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPXvaySUzbZ8N3QloyFhHHlFbwZ65eMcWNagfGe=w496-h240-k-no"
      }
    ],
    "description": "Amsterdam's color analysis services are influenced by the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Vienna",
    "slug": "vienna",
    "type": "city",
    "country": "Austria",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Blēo Studio",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "Lambrechtgasse 8A, Vienna",
        "phone": "",
        "website": "https://www.instagram.com/bleo.studio?igsh=NTI4ZWhkNW5nb2U3",
        "url": "https://www.google.com/maps/search/?api=1&query=Bl%C4%93o%20Studio&query_place_id=ChIJmT8Y2nmpbUcRWBNVhz3pWHM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNm_xdpCAoAgl3FqFr5YPUnGNekws_KxBs7d66w=w408-h725-k-no"
      },
      {
        "name": "byKristina",
        "description": "Consultant. Rating: 4.9/5 from 37 reviews",
        "address": "Estepl. 8/7, Vienna",
        "phone": "+43 676 3167209",
        "website": "http://www.bykristina.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=byKristina&query_place_id=ChIJk-6D5NcAbUcRJlL32PZacY0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM22IpwVW1s-PsxoyqrgBqvq_0MPBhFafRdsXbk=w648-h240-k-no"
      },
      {
        "name": "Imageberatung Claudia Schober",
        "description": "Image consultant. Rating: 5/5 from 3 reviews",
        "address": "Argentinierstraße 4/6, Vienna",
        "phone": "+43 699 10894598",
        "website": "http://www.schober-color-image.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=Imageberatung%20Claudia%20Schober&query_place_id=ChIJmfjRD3MHbUcRrcmRPUqMPoc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=YrVbt8b93XpHjeG8rk_njQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=254.097&pitch=0&thumbfov=100"
      },
      {
        "name": "Styling with Karima",
        "description": "Image consultant. Rating: 5/5 from 23 reviews",
        "address": "",
        "phone": "+43 676 3729873",
        "website": "http://www.stylingwithkarima.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Styling%20with%20Karima&query_place_id=ChIJgWsWJOVq12sR8U39MfBGBPY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPAbuS_4_0mcaztkTJOS3iCn0TtSVkvxmhixUjg=w408-h544-k-no"
      },
      {
        "name": "Stylingcoach Verena Weiss",
        "description": "Stylist. Rating: 5/5 from 25 reviews",
        "address": "Hockegasse, Vienna",
        "phone": "+43 650 2206830",
        "website": "https://www.stylingcoach.at/",
        "url": "https://www.google.com/maps/search/?api=1&query=Stylingcoach%20Verena%20Weiss&query_place_id=ChIJA-UU2lkJbUcRPJvxOgXI8w8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMZtogTlgRq-UEdypjRvb-ILWRkEfk0gKCNZZ8Q=w408-h611-k-no"
      }
    ],
    "description": "Vienna's color analysis services reflect the city's rich cultural heritage and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Moscow",
    "slug": "moscow",
    "type": "city",
    "country": "Russia",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Moscow's color analysis services are known for their bold and dramatic approach. Analysts often specialize in vibrant and striking palettes."
  },
  {
    "name": "Stockholm",
    "slug": "stockholm",
    "type": "city",
    "country": "Sweden",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "The Colour Insight",
        "description": "Stylist",
        "address": "Halmstadsvägen 53, Johanneshov",
        "phone": "+46 76 627 72 77",
        "website": "http://www.thecolourinsight.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Colour%20Insight&query_place_id=ChIJteXM3mkBVI4RogOBiKbJO6A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOj4-E5nakZbGb0IIbUPHtVNqfLEMqOk2nj5yXE=w408-h291-k-no"
      },
      {
        "name": "Make Up Institute Stockholm",
        "description": "Make-up artist. Rating: 4.4/5 from 79 reviews",
        "address": "Sergels Torg 11, Stockholm",
        "phone": "+46 8 30 06 40",
        "website": "http://www.makeupinstitute.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Make%20Up%20Institute%20Stockholm&query_place_id=ChIJp-hwz3GdX0YR-odWRtTRKHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPF7ZmeNusrY5FV7i-3emJM7mpdCg5zqPw2epEf=w408-h654-k-no"
      }
    ],
    "description": "Stockholm's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  {
    "name": "Prague",
    "slug": "prague",
    "type": "city",
    "country": "Czech Republic",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colors On Me",
        "description": "Image consultant",
        "address": "Kapitulská 12, Prague 4",
        "phone": "+420 778 742 985",
        "website": "https://colorsonme.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20On%20Me&query_place_id=ChIJ6bA-YcuTC0cRowVQpDvNCI4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Uw7mNl10K_Tljif0uYJeZg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=175.92375&pitch=0&thumbfov=100"
      },
      {
        "name": "Redamakeup Artist",
        "description": "Beauty salon. Rating: 5/5 from 13 reviews",
        "address": "Beauty Coworking Time, Voctářova 18, Prague 8-Palmovka",
        "phone": "+420 732 710 422",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Redamakeup%20Artist&query_place_id=ChIJv1qBa1eVC0cR3wEkNXDZnDQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPEQMpXiTAq2Uf-ArN41JTvq4VPsyA821_aBS6t=w427-h240-k-no"
      },
      {
        "name": "Martha's Inspirations",
        "description": "Beauty salon. Rating: 4.6/5 from 77 reviews",
        "address": "Blanická 1066/23, Vinohrady",
        "phone": "+420 737 654 208",
        "website": "http://www.marthasinspiration.cz/service",
        "url": "https://www.google.com/maps/search/?api=1&query=Martha's%20Inspirations&query_place_id=ChIJWR5v8Y-UC0cRw1hqpRgZaj0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNbmHyI_7NOHwX7gORckOeQ9n-qOv6_hv855SYJ=w408-h498-k-no"
      }
    ],
    "description": "Prague's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  {
    "name": "Munich",
    "slug": "munich",
    "type": "city",
    "country": "Germany",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Daniela Venbruex Farb - und Stilberatung. Be yourself - color up your life",
        "description": "Stylist. Rating: 5/5 from 36 reviews",
        "address": "Polkostraße 6, Munich",
        "phone": "+49 171 2969418",
        "website": "http://www.farbenundstil.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Daniela%20Venbruex%20Farb%20-%20und%20Stilberatung.%20Be%20yourself%20-%20color%20up%20your%20life&query_place_id=ChIJSzC-x955nkcRhHHmQyeSCFU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOwPYXsDGkrCvQuRi7ZpK1by8EwMU1CKU4LvHlv=w408-h612-k-no"
      },
      {
        "name": "Monica Cheung Personal Style and Colour",
        "description": "Stylist. Rating: 5/5 from 3 reviews",
        "address": "",
        "phone": "+49 176 41113252",
        "website": "http://www.monicacheungstyle.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Monica%20Cheung%20Personal%20Style%20and%20Colour&query_place_id=ChIJ5XmQyJsUrosRCgDM5VzhzMg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOeA0iuoGFvQkNAkhu7GOH6KnOKgGeW_-79FtRG=w408-h508-k-no"
      },
      {
        "name": "Own Your Colors - Farbberatung und Stilberatung München",
        "description": "Stylist. Rating: 5/5 from 16 reviews",
        "address": "Ismaninger Str. 35, Munich",
        "phone": "+49 15679 212167",
        "website": "https://www.ownyourcolors.de/",
        "url": "https://www.google.com/maps/search/?api=1&query=Own%20Your%20Colors%20-%20Farbberatung%20und%20Stilberatung%20M%C3%BCnchen&query_place_id=ChIJH4aaFlz5ZoIR5Lcq96QhmiQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMbKHZN4P7UvGFOV2xWcYquhUp9whXTPwCtqKOX=w426-h240-k-no"
      },
      {
        "name": "Style Advisor Twins Farbberatung und Stilberatung München",
        "description": "Stylist. Rating: 5/5 from 142 reviews",
        "address": "Moosacher Str. 82a, Munich",
        "phone": "+49 163 1648885",
        "website": "https://style-advisor.de/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Advisor%20Twins%20Farbberatung%20und%20Stilberatung%20M%C3%BCnchen&query_place_id=ChIJE0HUvoN3nkcRthne4O_5lak",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNecVIP7Aglvg_hbCIKpo5LJ-FViJYMT2sSgA_I=w480-h240-k-no"
      },
      {
        "name": "colour of the day",
        "description": "Artist. Rating: 4.8/5 from 5 reviews",
        "address": "c/o Schels – Büro für Gestaltung, Thalkirchner Str. 210/Haus 6, Munich",
        "phone": "+49 89 74791221",
        "website": "https://colouroftheday.de/",
        "url": "https://www.google.com/maps/search/?api=1&query=colour%20of%20the%20day&query_place_id=ChIJc8p2dj7fnUcRoCUshYZrkj0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPHrOnacWkLYYzAfkIDDAwyWmNuYSppxdl5ivxd=w408-h408-k-no"
      }
    ],
    "description": "Munich's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Barcelona",
    "slug": "barcelona",
    "type": "city",
    "country": "Spain",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "NIX Cultura Vegana Peluqueria Expertos en Alisados, Colorimetria y Mechas Balayage",
        "description": "Peluquería. Rating: 5/5 from 24 reviews",
        "address": "Avinguda Meridiana, 460, Barcelona, Barcelona",
        "phone": "+34 623 03 28 58",
        "website": "https://www.nixculturavegana.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=NIX%20Cultura%20Vegana%20Peluqueria%20Expertos%20en%20Alisados%2C%20Colorimetria%20y%20Mechas%20Balayage&query_place_id=ChIJl8jOfjO9pBIRWSWYuf8ImAQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8Hs72WU0igTEqaezfXfHuReHqPBWMhq2yazU=w426-h240-k-no"
      },
      {
        "name": "Cazcarra Image School",
        "description": "Academia de estética. Rating: 4.4/5 from 155 reviews",
        "address": "Carrer del Comte Borrell, 230, Barcelona, Barcelona",
        "phone": "+34 933 23 51 48",
        "website": "http://www.cazcarra.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Cazcarra%20Image%20School&query_place_id=ChIJYyG_CISipBIRrSZGMlVD1-g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPPRJx6K7bqvUtxaSImQjmo48D5js44uP2D9RY_=w408-h271-k-no"
      },
      {
        "name": "Josep Pons Formación",
        "description": "Centro de formación profesional. Rating: 4.4/5 from 128 reviews",
        "address": "Carrer de Loreto, 42, Barcelona, Barcelona",
        "phone": "+34 933 21 55 00",
        "website": "https://joseppons.com/formacion/",
        "url": "https://www.google.com/maps/search/?api=1&query=Josep%20Pons%20Formaci%C3%B3n&query_place_id=ChIJ-yGW0XmYpBIR3XjxaUjO3Z8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP1jAC8RykI2R8QdTFykvbx1fgUhgA2MTyv3oJy=w408-h272-k-no"
      },
      {
        "name": "Peluquería Bajomisombrero (Hair Salon) Premio Nacional Mejor Peluquería Kerastase",
        "description": "Centro de estética. Rating: 4.6/5 from 548 reviews",
        "address": "Av. de Madrid, 135, Barcelona, Barcelona",
        "phone": "+34 680 28 48 56",
        "website": "http://www.bajomisombrero.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Peluquer%C3%ADa%20Bajomisombrero%20(Hair%20Salon)%20Premio%20Nacional%20Mejor%20Peluquer%C3%ADa%20Kerastase&query_place_id=ChIJ_RZj8omYpBIRI1D-LDA5VMw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP0PCgH6d10hx_hGApz1QrcFLlJRn7Q6Ki-q9TF=w408-h408-k-no"
      },
      {
        "name": "JAQUELINE JIMÉNEZ PELUQUERÍA Y ESTÉTICA",
        "description": "Peluquería. Rating: 4.4/5 from 19 reviews",
        "address": "Carrer de l'Artesania, 9, Barcelona, Barcelona",
        "phone": "+34 644 60 06 39",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=JAQUELINE%20JIM%C3%89NEZ%20PELUQUER%C3%8DA%20Y%20EST%C3%89TICA&query_place_id=ChIJyWqOf_C9pBIRGVIYgCWJSrU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM_Zpbdjy6Li-oFhHiEO5eGXY4K-4y0sIJy-DDB=w408-h408-k-no"
      }
    ],
    "description": "Barcelona's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Milan",
    "slug": "milan",
    "type": "city",
    "country": "Italy",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "name": "VirgoImage di Angela Bianchi",
        "description": "Image consultant. Rating: 4.9/5 from 83 reviews",
        "address": "Via Console Marcello, 20a, Milan, Metropolitan City of Milan",
        "phone": "+39 393 890 5093",
        "website": "http://www.virgoimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=VirgoImage%20di%20Angela%20Bianchi&query_place_id=ChIJV6i5RFXAhkcRDeBPJe13p8Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN2SxvkHIir4I4SpoMTJ0ommH99mrxvI40luZc8=w408-h408-k-no"
      },
      {
        "name": "Francesca Marini - Consulente di immagine e stile",
        "description": "Image consultant. Rating: 4.9/5 from 41 reviews",
        "address": "Via Vincenzo Foppa, 17, Milan, Metropolitan City of Milan",
        "phone": "+39 351 602 9080",
        "website": "http://www.joyfully.it/",
        "url": "https://www.google.com/maps/search/?api=1&query=Francesca%20Marini%20-%20Consulente%20di%20immagine%20e%20stile&query_place_id=ChIJ2zH-zWbDhkcRezeyOYgyKXY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMi0HKYbwOJji-1Vb6GH1c5YIK18kI00KM-yzkf=w408-h272-k-no"
      },
      {
        "name": "Shopping Con Te - Consulenza d'immagine",
        "description": "Image consultant. Rating: 5/5 from 12 reviews",
        "address": "Viale Campania, 17, Milan, Metropolitan City of Milan",
        "phone": "+39 366 975 2366",
        "website": "http://www.shoppingconte.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Shopping%20Con%20Te%20-%20Consulenza%20d'immagine&query_place_id=ChIJr9xrcbjHhkcR3d_PNnojl6Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8DGptMK5yEbZkp7mBAJspHaw6nfGDpiM3ivu3=w408-h387-k-no"
      },
      {
        "name": "Wear.it Milano",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.wearitmilano.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Wear.it%20Milano&query_place_id=ChIJba4e966poQ8RBgEWfzgT1O4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMWtR1p0-DUzLwVkL1iLlduPvWM4qBaxLCuXc2I=w408-h729-k-no"
      },
      {
        "name": "Sephora",
        "description": "Cosmetics store. Rating: 4.2/5 from 963 reviews",
        "address": "Via Dante, 14/Ground Floor, Milan, Metropolitan City of Milan",
        "phone": "+39 02 8645 8470",
        "website": "https://www.sephora.it/informazioni-store?storeID=IT28&utm_source=local&utm_support=organic&utm_campaign=uberall",
        "url": "https://www.google.com/maps/search/?api=1&query=Sephora&query_place_id=ChIJLWaPolLBhkcRhxefQVKdGuk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNsB2PauqjmcbX100Dc1z1xBf5MoBx8Lo3N9m4I=w408-h306-k-no"
      }
    ],
    "description": "Milan's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Copenhagen",
    "slug": "copenhagen",
    "type": "city",
    "country": "Denmark",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colours By Cph ApS",
        "description": "undefined. Rating: 5/5 from 1 reviews",
        "address": "Falkoner Alle 1, 3. sal, Frederiksberg",
        "phone": "",
        "website": "http://www.coloursbycph.dk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colours%20By%20Cph%20ApS&query_place_id=ChIJAyvZbaBTUkYRwmgvwfNr4Cg",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=rpMSn3uaLq9ywYrQEokKXQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=289.9915&pitch=0&thumbfov=100"
      },
      {
        "name": "yourcolors.byjoana",
        "description": "Image consultant. Rating: 4.9/5 from 54 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.instagram.com/yourcolors.byjoana?igsh=MXV1MHpvcjhna2p2bw==&utm_source=qr",
        "url": "https://www.google.com/maps/search/?api=1&query=yourcolors.byjoana&query_place_id=ChIJGbTIpSxH_4ERLZOMASYVyV8",
        "imageUrl": ""
      },
      {
        "name": "Element Copenhagen",
        "description": "Beauty salon. Rating: 5/5 from 107 reviews",
        "address": "Knabrostræde 14, Copenhagen",
        "phone": "+45 29 28 28 95",
        "website": "http://www.elementcopenhagen.dk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Element%20Copenhagen&query_place_id=ChIJd9XMCxFTUkYRpIIHF3aDwQM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMkU-lC30O1S_Grsd49LpbbC4SphnT53f4CFuNd=w408-h612-k-no"
      }
    ],
    "description": "Copenhagen's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  {
    "name": "Dublin",
    "slug": "dublin",
    "type": "city",
    "country": "Ireland",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Your Colors",
        "description": "Stylist",
        "address": "",
        "phone": "+353 83 327 6540",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Your%20Colors&query_place_id=ChIJrXJIrMkNZ0gRqQAPx_Y2yXw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNVh8q1OMpANJV5Li4OBJx5ksorbyE4nZ68NwYf=w630-h240-k-no"
      },
      {
        "name": "Vivi Pinho Fashion Stylist",
        "description": "Image consultant. Rating: 5/5 from 150 reviews",
        "address": "Old City Music Hall, Bea Brow Artistry, 8 On top of, Cow's Ln, Dublin",
        "phone": "+353 83 049 4722",
        "website": "http://vivipinho.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Vivi%20Pinho%20Fashion%20Stylist&query_place_id=ChIJ_2JTNLwPZ0gR1iHF7JTe9wU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPGuFs36dH4Z5IoUsqBb12LwTt5YAJQAl_0s2KZ=w426-h240-k-no"
      },
      {
        "name": "Rafaela Barros | Consultora de Imagem e Estilo",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "",
        "phone": "+353 83 086 1367",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Rafaela%20Barros%20%7C%20Consultora%20de%20Imagem%20e%20Estilo&query_place_id=ChIJyS4Uj9MNZ0gR1P5-zEXZePQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN1jkrGdH2P2XtMDmY1iGWa5fWmW4x_u6D5amEb=w408-h510-k-no"
      },
      {
        "name": "Positive Image",
        "description": "Consultant. Rating: 5/5 from 3 reviews",
        "address": "10 St Ita's Rd, Botanic Ave, Dublin 9",
        "phone": "+353 86 226 3951",
        "website": "http://www.positiveimage.ie/",
        "url": "https://www.google.com/maps/search/?api=1&query=Positive%20Image&query_place_id=ChIJW8YL0LEPZ0gRpjBsMfsj5VA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN0ZNJmesZh_LzxvSS98ZmnuI5mJoKIEyEi042T=w528-h240-k-no"
      },
      {
        "name": "Color Therapy",
        "description": "Hairdresser. Rating: 4.8/5 from 26 reviews",
        "address": "199 Kimmage Rd Lower, Dublin 6W, Co. Dublin",
        "phone": "+353 1 492 6308",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Therapy&query_place_id=ChIJmakbPXMMZ0gRkKjmfosSPLA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOk8Mg3o9GaA5F8NySvuxtcqdN_5v1l1uKscuGa=w408-h612-k-no"
      }
    ],
    "description": "Dublin's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Brussels",
    "slug": "brussels",
    "type": "city",
    "country": "Belgium",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Le Reflet Conseil",
        "description": "Image consultant. Rating: 5/5 from 100 reviews",
        "address": "Rue du Vieux Marché aux Grains 1, Brussels",
        "phone": "+33 6 78 93 74 06",
        "website": "http://lerefletconseil.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Le%20Reflet%20Conseil&query_place_id=ChIJYc_66lLFw0cRnbvwwkOduXw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM3hDi37h6BCqE0Mn3WSyZ5HpV23zBk70uRmIqU=w408-h612-k-no"
      }
    ],
    "description": "Brussels' color analysis services are influenced by the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Lisbon",
    "slug": "lisbon",
    "type": "city",
    "country": "Portugal",
    "pricingRange": "$150 - $450",
    "businesses": [],
    "description": "Lisbon's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  {
    "name": "Athens",
    "slug": "athens",
    "type": "city",
    "country": "Greece",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Biocolours Cosmetics",
        "description": "Beauty supply store. Rating: 4.9/5 from 33 reviews",
        "address": "3is Septemvriou 99, Athens",
        "phone": "+30 21 0825 3469",
        "website": "http://www.biocolours.gr/",
        "url": "https://www.google.com/maps/search/?api=1&query=Biocolours%20Cosmetics&query_place_id=ChIJhcDeIM2ioRQRvvOFKIDDvfs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPPhydlif0p4KwbxoSFxf-ocPUBiFRFGZxUnrAu=w408-h306-k-no"
      }
    ],
    "description": "Athens' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Warsaw",
    "slug": "warsaw",
    "type": "city",
    "country": "Poland",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Kolor.styka Analiza kolorystyczna Paulina Wysińska",
        "description": "Stylist. Rating: 5/5 from 26 reviews",
        "address": "Astronautów 15, Warsaw",
        "phone": "",
        "website": "https://www.instagram.com/kolor.styka/",
        "url": "https://www.google.com/maps/search/?api=1&query=Kolor.styka%20Analiza%20kolorystyczna%20Paulina%20Wysi%C5%84ska&query_place_id=ChIJCTqL70ozGUcRWZiLjZcKpd8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMNWxO2e3_LCI0AyIhc8FcNVBgr2FHp5Abyde5d=w408-h612-k-no"
      },
      {
        "name": "Lip Lab",
        "description": "Beauty salon. Rating: 4.9/5 from 170 reviews",
        "address": "Juliana Bartoszewicza 11, Warsaw",
        "phone": "+48 730 500 114",
        "website": "http://www.liplab.pl/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=11052125436972897277",
        "url": "https://www.google.com/maps/search/?api=1&query=Lip%20Lab&query_place_id=ChIJz_x0uL_NHkcRvfK6poOrekc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMDR7XB41UTy9gwlf72JHC20pzTuMBbiLBbW0jl=w408-h261-k-no"
      }
    ],
    "description": "Warsaw's color analysis services reflect the city's diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Budapest",
    "slug": "budapest",
    "type": "city",
    "country": "Hungary",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "ColourMyStyle",
        "description": "Stylist. Rating: 5/5 from 8 reviews",
        "address": "Márvány u. 33-2.em, Budapest",
        "phone": "+36 30 597 9988",
        "website": "http://www.colourmystyle.hu/",
        "url": "https://www.google.com/maps/search/?api=1&query=ColourMyStyle&query_place_id=ChIJExact0XcQUcRtQ1uWMCDvHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNIOWbocNGKKiOQrb06qGrbdSBkF9KKuabDcWl_=w408-h272-k-no"
      },
      {
        "name": "Agi Huszka Style",
        "description": "Stylist. Rating: 4.6/5 from 10 reviews",
        "address": "Honvéd tér 10, Budapest",
        "phone": "+36 30 650 6944",
        "website": "http://agihuszka.hu/",
        "url": "https://www.google.com/maps/search/?api=1&query=Agi%20Huszka%20Style&query_place_id=ChIJnxQntE7dQUcRk2oQwIFkqEg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMIkFmi4Oi55zwSsLyg8QQgTRBeYoaumlA9-ROs=w408-h272-k-no"
      },
      {
        "name": "True Colours Szín- és Stílustanácsadás",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "",
        "phone": "+36 30 947 5875",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=True%20Colours%20Sz%C3%ADn-%20%C3%A9s%20St%C3%ADlustan%C3%A1csad%C3%A1s&query_place_id=ChIJf8VqZVjdQUcROSGPKr4un3s",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=LXh4dwB1_OWxQtb4AHKvwg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=164.8432&pitch=0&thumbfov=100"
      }
    ],
    "description": "Budapest's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Helsinki",
    "slug": "helsinki",
    "type": "city",
    "country": "Finland",
    "pricingRange": "$100 - $400",
    "businesses": [],
    "description": "Helsinki's color analysis services reflect the city's unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  {
    "name": "Oslo",
    "slug": "oslo",
    "type": "city",
    "country": "Norway",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colour Me Beautiful",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "Henerala Almazova St, 18/7, Kyiv",
        "phone": "+380 67 645 1945",
        "website": "http://colourmebeautiful.com.ua/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful&query_place_id=ChIJ9R-_NKjP1EARwjLlxQ6qwnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPapXvVN5JZXUDY7wU6zUznK9I4buXRyyd9Hggp=w408-h271-k-no"
      }
    ],
    "description": "Oslo's color analysis services reflect the city's unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  {
    "name": "Zurich",
    "slug": "zurich",
    "type": "city",
    "country": "Switzerland",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Personal Color Analysis by Smart Color",
        "description": "Image consultant. Rating: 5/5 from 14 reviews",
        "address": "Badenerstrasse 97, Zürich",
        "phone": "",
        "website": "https://www.smartcolor.ch/",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Color%20Analysis%20by%20Smart%20Color&query_place_id=ChIJecmYU7ILkEcR4kRa0ICiaeU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN-QTmpyqwLW2UB6PPuDun4x4_BQmjTb_jO0Lqd=w408-h423-k-no"
      },
      {
        "name": "Chromatologie",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "Kasernenstrasse 77A, Zürich",
        "phone": "+41 79 886 83 00",
        "website": "http://www.chromatologie.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=1719969468228396437",
        "url": "https://www.google.com/maps/search/?api=1&query=Chromatologie&query_place_id=ChIJ_-Cs9WxljEcRNV_qBKJOAVg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOS9UPQbwWc6gpX-uYtlOmOfukD5gBy5NyhCNW7=w408-h725-k-no"
      },
      {
        "name": "Giuliab Stylecoach",
        "description": "Image consultant. Rating: 5/5 from 11 reviews",
        "address": "Rankstrasse 6, Zürich",
        "phone": "+41 78 666 50 59",
        "website": "https://giuliabstylecoach.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Giuliab%20Stylecoach&query_place_id=ChIJdSUnasanmkcRiOfSCj0SEG4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOFCHKO0VjGYKQ_l5p-LnUua8NwczaOuYAoCEUr=w408-h306-k-no"
      },
      {
        "name": "Accent Style - Stilberatung für sie und ihn",
        "description": "Consultant. Rating: 4.9/5 from 24 reviews",
        "address": "Feldeggstrasse 55, Zürich",
        "phone": "+41 79 333 58 63",
        "website": "http://www.accentstyle.ch/",
        "url": "https://www.google.com/maps/search/?api=1&query=Accent%20Style%20-%20Stilberatung%20f%C3%BCr%20sie%20und%20ihn&query_place_id=ChIJheOCdU6nmkcRTaJJQyANUrU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlbBBKPVr8qdAD0mNo7HuDPpQCReKEgr2QUIiU=w408-h272-k-no"
      },
      {
        "name": "love for detail",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "Bahnhofstrasse, Zürich",
        "phone": "+41 79 890 15 81",
        "website": "http://lovefordetail.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=love%20for%20detail&query_place_id=ChIJ18XG12elmkcRJoDTkvWGaU4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN5HsCVKMppVIOx0lNZ1WIgJRJGgMsN4a2HpOIT=w408-h272-k-no"
      }
    ],
    "description": "Zurich's color analysis services reflect the city's rich cultural heritage and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Edinburgh",
    "slug": "edinburgh",
    "type": "city",
    "country": "United Kingdom",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Cobalt - Style & Colour Consultant",
        "description": "Consultant",
        "address": "Bruntsfield Gardens, Edinburgh",
        "phone": "",
        "website": "http://www.cobalt-colour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Cobalt%20-%20Style%20%26%20Colour%20Consultant&query_place_id=ChIJZ5e1qgvHh0gRdM7GuPMw0P0",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=bdAZuwvMj87YsPh2mMuXBA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=220.72148&pitch=0&thumbfov=100"
      },
      {
        "name": "Lindsay Kirkwood Styling",
        "description": "Image consultant. Rating: 5/5 from 12 reviews",
        "address": "17 Dean Park St, Edinburgh",
        "phone": "+44 7543 763168",
        "website": "http://www.lindsaykirkwood.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Lindsay%20Kirkwood%20Styling&query_place_id=ChIJo8X4spfHh0gRToclkGWJprM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=jP3tIEAk3Ha9Kilxu6z9xw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=88.01843&pitch=0&thumbfov=100"
      },
      {
        "name": "Yellow Souls Studio",
        "description": "Stylist. Rating: 5/5 from 45 reviews",
        "address": "40 Constitution St, Edinburgh",
        "phone": "",
        "website": "http://yellowsouls.co.uk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Yellow%20Souls%20Studio&query_place_id=ChIJq7QUUvrHh0gRRaBDBEqteQU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM4tZ3Nj9xKpOsxCLrr9LzbX6Vf--sIeax4DPlj=w408-h306-k-no"
      },
      {
        "name": "McGills Hairdressing",
        "description": "Beauty salon. Rating: 4.8/5 from 230 reviews",
        "address": "5 Home St, Edinburgh",
        "phone": "+44 131 228 8882",
        "website": "https://mcgillshairdressing.co.uk/?utm_source=Google+Business+Listing&utm_medium=Listing&utm_campaign=GMB&utm_id=GMB",
        "url": "https://www.google.com/maps/search/?api=1&query=McGills%20Hairdressing&query_place_id=ChIJKXrvPp_Hh0gRrhnn3xgE_Ds",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNfdU-hbRGY2UbrWav9--hH0drlRGCfXmB9-eId=w408-h408-k-no"
      }
    ],
    "description": "Edinburgh's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Hamburg",
    "slug": "hamburg",
    "type": "city",
    "country": "Germany",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colour Me Beautiful",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "Henerala Almazova St, 18/7, Kyiv",
        "phone": "+380 67 645 1945",
        "website": "http://colourmebeautiful.com.ua/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful&query_place_id=ChIJ9R-_NKjP1EARwjLlxQ6qwnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPapXvVN5JZXUDY7wU6zUznK9I4buXRyyd9Hggp=w408-h271-k-no"
      },
      {
        "name": "image&impression - Individuelle Farbberatung, Stilberatung, Imageberatung",
        "description": "Image consultant. Rating: 5/5 from 22 reviews",
        "address": "Ringheide 20G, Hamburg",
        "phone": "+49 176 24730370",
        "website": "http://www.imageandimpression.de/",
        "url": "https://www.google.com/maps/search/?api=1&query=image%26impression%20-%20Individuelle%20Farbberatung%2C%20Stilberatung%2C%20Imageberatung&query_place_id=ChIJF0JM2gGbsUcRtNco6TZl-k0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN6vBKEl3-mBmSB1NrM73ZP_sMNpCRNKIJ0Ln5M=w408-h408-k-no"
      },
      {
        "name": "Hey Beautiful Farbberatung & Stilberatung",
        "description": "Image consultant. Rating: 5/5 from 15 reviews",
        "address": "Boberger Lohe 39, Hamburg",
        "phone": "+49 173 6351888",
        "website": "https://heybeautiful.eu/",
        "url": "https://www.google.com/maps/search/?api=1&query=Hey%20Beautiful%20Farbberatung%20%26%20Stilberatung&query_place_id=ChIJRzcXzYqNsUcRI1IAb9OzRYY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNsy2gqah4SpSw5oTeQX4NO9ydLWI7taiYkElFQ=w408-h408-k-no"
      },
      {
        "name": "Imageberatung Katrin Hermanns",
        "description": "Image consultant. Rating: 5/5 from 19 reviews",
        "address": "Iserbarg 23, Hamburg",
        "phone": "+49 174 1808580",
        "website": "http://www.khpersonalstylist.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Imageberatung%20Katrin%20Hermanns&query_place_id=ChIJo1YolluDsUcRG6vvxdHBG9M",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPICkUoMiakDHg8eVkPV9axq887QrzTEuNU5eeC=w408-h544-k-no"
      }
    ],
    "description": "Hamburg's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Frankfurt",
    "slug": "frankfurt",
    "type": "city",
    "country": "Germany",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Michaela Leitz - Confidence & Style",
        "description": "Consultant. Rating: 5/5 from 11 reviews",
        "address": "August-Siebert-Straße 1, Frankfurt",
        "phone": "+49 176 80368200",
        "website": "http://www.confidence-style.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Michaela%20Leitz%20-%20Confidence%20%26%20Style&query_place_id=ChIJLW7vv4cJvUcRWnnmuwk22F4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=c1Nz2cWpWhCxlzqQLHlHMg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=250.71938&pitch=0&thumbfov=100"
      },
      {
        "name": "Color me Perfect",
        "description": "Beauty salon. Rating: 5/5 from 2 reviews",
        "address": "Textorstraße 75, Frankfurt",
        "phone": "+49 491 778852008",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20me%20Perfect&query_place_id=ChIJmxyu7xsMvUcR8Z6zISZzdtk",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=BXzuDFecs2OhN6EN7dpAcQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=179.88814&pitch=0&thumbfov=100"
      },
      {
        "name": "colour your way",
        "description": "Hair salon",
        "address": "West-Höchster Str. 65, Frankfurt",
        "phone": "+49 1516 1233108",
        "website": "http://www.colouryourway.de/kontakt/",
        "url": "https://www.google.com/maps/search/?api=1&query=colour%20your%20way&query_place_id=ChIJuQr5yh2hvUcRuc4avR5vncY",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=Y-UDLQ7nQeMpUC0zsPr64g&cb_client=search.gws-prod.gps&w=408&h=240&yaw=193.09871&pitch=0&thumbfov=100"
      }
    ],
    "description": "Frankfurt's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Lyon",
    "slug": "lyon",
    "type": "city",
    "country": "France",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Lyon's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Marseille",
    "slug": "marseille",
    "type": "city",
    "country": "France",
    "pricingRange": "$200 - $500",
    "businesses": [],
    "description": "Marseille's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Naples",
    "slug": "naples",
    "type": "city",
    "country": "Italy",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Naples' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Venice",
    "slug": "venice",
    "type": "city",
    "country": "Italy",
    "pricingRange": "$200 - $500",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Venice's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Krakow",
    "slug": "krakow",
    "type": "city",
    "country": "Poland",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Ola Bialikiewicz - Analiza Kolorystyczna",
        "description": "Stylist. Rating: 5/5 from 156 reviews",
        "address": "rondo Mogilskie 1, Kraków",
        "phone": "",
        "website": "https://ola-bialikiewicz.my.canva.site/oferta?fbclid=PAAabAhu02lMwEHlDFw7IgRIX2wvI_x1uRTQn15fJUHQF8HCmkY_pBHDoTmq0",
        "url": "https://www.google.com/maps/search/?api=1&query=Ola%20Bialikiewicz%20-%20Analiza%20Kolorystyczna&query_place_id=ChIJR10wiUpbFkcRWDhjq1ctBb8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP8gdfdM4vtAFJKAgoiM_cA2NubW1VZJUPhxtQ6=w408-h544-k-no"
      }
    ],
    "description": "Krakow's color analysis services reflect the city's diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  {
    "name": "Bucharest",
    "slug": "bucharest",
    "type": "city",
    "country": "Romania",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Mariana Romanică Style Studio",
        "description": "Image consultant. Rating: 4.9/5 from 35 reviews",
        "address": "Strada Poenaru Bordea 8-10, Bucharest",
        "phone": "+40 744 514 871",
        "website": "http://www.consultantadeimagine.ro/",
        "url": "https://www.google.com/maps/search/?api=1&query=Mariana%20Romanic%C4%83%20Style%20Studio&query_place_id=ChIJM_pprVH_sUARgENHvZIFf-g",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOgihs1QWznivDhxzHvokIkLZFQJ7_EJyl1Gj5h=w408-h408-k-no"
      },
      {
        "name": "Colors Experience",
        "description": "Beauty salon. Rating: 4.3/5 from 188 reviews",
        "address": "Strada Daniel Danielopolu 30, Bucharest",
        "phone": "+40 767 112 322",
        "website": "https://www.colors-experience.ro/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colors%20Experience&query_place_id=ChIJk13VN24DskAR79oyCH1ELvQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNWhK7yDu1ITlcRrx9mW5bPbai_Cf2QlLR2OjRd=w408-h544-k-no"
      }
    ],
    "description": "Bucharest's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Sofia",
    "slug": "sofia",
    "type": "city",
    "country": "Bulgaria",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Анна Хабре - Имидж Консултант",
        "description": "Image consultant. Rating: 5/5 from 29 reviews",
        "address": "ul. \"Razlaka\" 3, Sofia",
        "phone": "+359 87 822 0531",
        "website": "https://www.annahabre.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=%D0%90%D0%BD%D0%BD%D0%B0%20%D0%A5%D0%B0%D0%B1%D1%80%D0%B5%20-%20%D0%98%D0%BC%D0%B8%D0%B4%D0%B6%20%D0%9A%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%82%D0%B0%D0%BD%D1%82&query_place_id=ChIJJ4iDWE-FqkARmGBeTNTGYZo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP7w1ubHQRaILTRkim3fkrQTrPL59GFt0C_2r4d=w408-h611-k-no"
      },
      {
        "name": "Coloriten",
        "description": "Image consultant. Rating: 4.7/5 from 27 reviews",
        "address": "ul. \"Lyutibrod\" 3, Sofia",
        "phone": "+359 89 864 4252",
        "website": "https://coloriten.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Coloriten&query_place_id=ChIJTY7Z7J-FqkARkctKsFRw0JA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=1j1oHR3PBshJ7x5hzPSLDQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=302.06726&pitch=0&thumbfov=100"
      }
    ],
    "description": "Sofia's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Belgrade",
    "slug": "belgrade",
    "type": "city",
    "country": "Serbia",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Belgrade's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Kiev",
    "slug": "kiev",
    "type": "city",
    "country": "Ukraine",
    "pricingRange": "$100 - $400",
    "businesses": [
      {
        "name": "Colour Me Beautiful",
        "description": "Stylist. Rating: 5/5 from 1 reviews",
        "address": "Henerala Almazova St, 18/7, Kyiv",
        "phone": "+380 67 645 1945",
        "website": "http://colourmebeautiful.com.ua/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful&query_place_id=ChIJ9R-_NKjP1EARwjLlxQ6qwnw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPapXvVN5JZXUDY7wU6zUznK9I4buXRyyd9Hggp=w408-h271-k-no"
      }
    ],
    "description": "Kiev's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  {
    "name": "Tokyo",
    "slug": "tokyo",
    "type": "city",
    "country": "Japan",
    "pricingRange": "$100 - $300",
    "businesses": [
      {
        "name": "【Ciccy】パーソナルカラー診断・骨格診断・顔タイプ診断(Personal color analysis)",
        "description": "Image consultant. Rating: 5/5 from 18 reviews",
        "address": "3 Chome-17-11 Shibaura, Minato City, Tokyo",
        "phone": "+81 80-3522-7785",
        "website": "https://ciccy-image.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E3%80%90Ciccy%E3%80%91%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%E8%A8%BA%E6%96%AD%E3%83%BB%E9%AA%A8%E6%A0%BC%E8%A8%BA%E6%96%AD%E3%83%BB%E9%A1%94%E3%82%BF%E3%82%A4%E3%83%97%E8%A8%BA%E6%96%AD(Personal%20color%20analysis)&query_place_id=ChIJranZR7KLGGARSOKunxa5zB0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO-6UlCy2JImu-ULwVFbRC9cOOOuiKUB7wEyC0k=w408-h288-k-no"
      },
      {
        "name": "パーソナルカラー診断サロン【Lily Beaute/リリーボーテ】渋谷・恵比寿店(Color analysis)",
        "description": "Image consultant. Rating: 4.9/5 from 138 reviews",
        "address": "Ebisunishi, 1 Chome−15−2 706 アパルトマンイトウ 7F 706号室 7th floor, Shibuya, Tokyo",
        "phone": "+81 70-1215-1031",
        "website": "https://www.instagram.com/yurina_color/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%E8%A8%BA%E6%96%AD%E3%82%B5%E3%83%AD%E3%83%B3%E3%80%90Lily%20Beaute%2F%E3%83%AA%E3%83%AA%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%86%E3%80%91%E6%B8%8B%E8%B0%B7%E3%83%BB%E6%81%B5%E6%AF%94%E5%AF%BF%E5%BA%97(Color%20analysis)&query_place_id=ChIJWRlXOfCLGGARE4k8MFTcHUI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipML3YYMcellMpR3qFrRvE-SuU8UvQLNSjnp_pqU=w408-h408-k-no"
      },
      {
        "name": "BeautyStyle",
        "description": "Image consultant. Rating: 4.8/5 from 259 reviews",
        "address": "Shimomeguro, 2 Chome−18−15 キャトルセゾン目黒 401, Meguro City, Tokyo",
        "phone": "+81 3-6373-0974",
        "website": "https://beautystyle.co.jp/",
        "url": "https://www.google.com/maps/search/?api=1&query=BeautyStyle&query_place_id=ChIJbUj0v3-LGGAR3kbL3ek4gaQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOaqcNqGiTHpeEH_S51Pqxp_p-jRwJF0g_VshGv=w444-h240-k-no"
      },
      {
        "name": "make up salon AGIRL",
        "description": "Make-up artist. Rating: 4.8/5 from 100 reviews",
        "address": "Minamiaoyama, 2 Chome−22−15 クリヤマビル 2F, Minato City, Tokyo",
        "phone": "+81 3-6206-1159",
        "website": "https://agirlbeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=make%20up%20salon%20AGIRL&query_place_id=ChIJT7wVXe6LGGARzIFtHsKssOs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNd4-UNjQ0FZvQTHRN8RWxHANm5okoPbKRh4zFj=w408-h408-k-no"
      },
      {
        "name": "Redigo",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "Jingumae, 1 Chome−10−11 パークアクシス明治神宮前, Shibuya, Tokyo",
        "phone": "",
        "website": "http://readygojapan.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Redigo&query_place_id=ChIJLS-bzKSMGGARisyMTmD-0A4",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=GHBx4o0lBAsFAYJH7CoDyA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=250.99608&pitch=0&thumbfov=100"
      }
    ],
    "description": "Tokyo offers a range of color analysis services, from affordable to high-end options. Many analysts are well-trained in modern color systems and some offer services in English. Prices are generally reasonable compared to European and US rates."
  },
  {
    "name": "Seoul",
    "slug": "seoul",
    "type": "city",
    "country": "South Korea",
    "pricingRange": "₩60,000 - ₩400,000",
    "businesses": [
      {
        "name": "COCORY Color inc",
        "description": "Image consultant. Rating: 4.9/5 from 210 reviews",
        "address": "Yeonhui-ro, 193-3 코코리색채연구소, Seodaemun-gu, Seoul",
        "phone": "",
        "website": "https://cocory.kr/",
        "url": "https://www.google.com/maps/search/?api=1&query=COCORY%20Color%20inc&query_place_id=ChIJe5xIMfBnezURuImCr9cs-HE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM40Zk7hpfQkKGSQJm55GSmvWxSYBJINYWyW-8L=w408-h306-k-no"
      },
      {
        "name": "Color by Nari PERSONAL COLOR in Hongdae Seoul",
        "description": "Image consultant. Rating: 5/5 from 22 reviews",
        "address": "6th floor, 3-14 Donggyo-ro 27-gil, Mapo-gu, Seoul",
        "phone": "+82 10-9593-5256",
        "website": "https://colorbynari.simplybook.asia/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20by%20Nari%20PERSONAL%20COLOR%20in%20Hongdae%20Seoul&query_place_id=ChIJf39aT6-ZfDURCa1o4rCRztE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMdbqTH9H8ut0nJnhhH49-FzckxbIPygZjHBj9F=w408-h408-k-no"
      },
      {
        "name": "MYCOLORLAB",
        "description": "Coaching center. Rating: 5/5 from 161 reviews",
        "address": "Namdaemun-ro 10-gil, 28 우석빌딩 603호, Jung District, Seoul",
        "phone": "+82 10-4894-1045",
        "website": "https://naver.me/xmtHNv69",
        "url": "https://www.google.com/maps/search/?api=1&query=MYCOLORLAB&query_place_id=ChIJ0-3UwhejfDURZ_wKibmRwdE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOxE1uqXmiIIbYxfuLcMULyfRYTkKjycIqRfnmj=w513-h240-k-no"
      },
      {
        "name": "Color Society",
        "description": "Beauty salon. Rating: 4.9/5 from 16 reviews",
        "address": "Donggyo-ro, 215 301호, Mapo-gu, Seoul",
        "phone": "",
        "website": "https://instagram.com/color.society",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Society&query_place_id=ChIJoV-Anf6ZfDUR9obaMb9vVOk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMVr9SYPcDU40ghXHgutvEvqkuk-ahOsaShplZn=w408-h304-k-no"
      },
      {
        "name": "Coloryeon",
        "description": "Image consultant. Rating: 4.9/5 from 11 reviews",
        "address": "Nonhyeon-ro 149-gil, 75 302호, Gangnam District, Seoul",
        "phone": "+82 50-71327-0585",
        "website": "https://coloryeon.simplybook.asia/",
        "url": "https://www.google.com/maps/search/?api=1&query=Coloryeon&query_place_id=ChIJmdDmRF6jfDUR8l1pbWHgBGo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOXmL3VqwDSQY66L-auiRroSz_EXYv8II-Z7oKl=w408-h306-k-no"
      }
    ],
    "description": "Seoul is a hub for personal color analysis in South Korea. The city offers a variety of options from budget-friendly group sessions to high-end individual consultations. Many services include detailed makeup advice and product recommendations tailored to your color palette. English-speaking options are widely available, making it accessible for international visitors."
  },
  {
    "name": "Beijing",
    "slug": "beijing",
    "type": "city",
    "country": "China",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Beijing's color analysis services reflect the city's rich history and modern development. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Shanghai",
    "slug": "shanghai",
    "type": "city",
    "country": "China",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Shanghai's color analysis services are influenced by the city's cosmopolitan lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Mumbai",
    "slug": "mumbai",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $400",
    "businesses": [
      {
        "name": "Neha D Gupta (Image Consultant)",
        "description": "Image consultant. Rating: 5/5 from 35 reviews",
        "address": "501, La-Vie, Mumbai, Maharashtra",
        "phone": "+91 98212 98601",
        "website": "https://www.thefinishingschool.co.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=Neha%20D%20Gupta%20(Image%20Consultant)&query_place_id=ChIJYSW8PAzJ5zsRdyyZ1Ulyl_A",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMttfn_vSI9tY_LPE1gwqgH-X82MniyC2eg5LDm=w408-h550-k-no"
      },
      {
        "name": "Hemangi Goud - Image Consultant",
        "description": "Image consultant. Rating: 4.9/5 from 38 reviews",
        "address": "La Pinta Building, 2nd Floor, D Block, V.L Prdnekar, Parel Rd, Mumbai, Maharashtra",
        "phone": "+91 99306 70048",
        "website": "https://youtu.be/Bs86LF4XED4",
        "url": "https://www.google.com/maps/search/?api=1&query=Hemangi%20Goud%20-%20Image%20Consultant&query_place_id=ChIJ5caJl7bP5zsRMQWS13bLLi4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO34TLxIZm90vLI2J7pDevr1r6iyaVZE0aDFh-f=w408-h408-k-no"
      },
      {
        "name": "TIA The Image Academy",
        "description": "Image consultant. Rating: 5/5 from 28 reviews",
        "address": "Mumbai, Maharashtra",
        "phone": "+91 99871 51898",
        "website": "http://www.imagetia.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=TIA%20The%20Image%20Academy&query_place_id=ChIJO29hD73H5zsRA7VzYPHcPIo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOHiAyZkJBi_Fe-gMOdVFolzDd0ky9Ohji1i9RG=w408-h408-k-no"
      },
      {
        "name": "Meerras International School of Makeup and More",
        "description": "Stylist. Rating: 4.9/5 from 125 reviews",
        "address": "2nd flr, Monterossa, 201, 90 Feet Rd, opp. Siddhivinayak Mandir, above IDFC First Bank, Mumbai, Maharashtra",
        "phone": "+91 98207 00556",
        "website": "https://www.meerramevawala.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Meerras%20International%20School%20of%20Makeup%20and%20More&query_place_id=ChIJg_y0wTHG5zsRRlNhgwQ9fTM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN5BTLIv3uzRP20Wfv7PLzR5lcrq5vfmFKgsD0=w426-h240-k-no"
      }
    ],
    "description": "Mumbai's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Bangkok",
    "slug": "bangkok",
    "type": "city",
    "country": "Thailand",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Spiral Color Salon /Personal color analysis",
        "description": "Education center. Rating: 5/5 from 20 reviews",
        "address": "RSU tower, 10F, Sukhumvit soi31, Khlong Toei, Bangkok",
        "phone": "+66 80 938 7817",
        "website": "https://www.spiralcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Spiral%20Color%20Salon%20%2FPersonal%20color%20analysis&query_place_id=ChIJHxsVGAWf4jARu3r-yLmmv1o",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPF1ssEVzdN73p6FleKIrpZ5Y77FCHbLl_GnpdP=w408-h306-k-no"
      },
      {
        "name": "Chroma Club",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "44/14 Soi Sukhumvit 62, Phra Khanong, Bangkok",
        "phone": "+66 85 566 5653",
        "website": "http://lin.ee/p61ogPp",
        "url": "https://www.google.com/maps/search/?api=1&query=Chroma%20Club&query_place_id=ChIJu4gGQ-af4jARfpqjVkF8Quc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMigTjc_FgoP6zkgxzTvzwOXVLx-okw0k9Hl6MK=w408-h306-k-no"
      },
      {
        "name": "Personal Color",
        "description": "Image consultant",
        "address": "Ratchathewi, Bangkok",
        "phone": "+66 82 580 1929",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Personal%20Color&query_place_id=ChIJwzbxYbmf4jAR3Pr6Gr8dGUo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPWq4O7c60WkisCd87b5REi8isJVYj96uh8cWSg=w408-h408-k-no"
      },
      {
        "name": "next-level beauty by Khaofang สักปาก สักคิ้ว ใกล้ฉัน",
        "description": "Beauty salon. Rating: 5/5 from 124 reviews",
        "address": "nextlevelbeauty 580 8, Wang Thonglang, Bangkok",
        "phone": "+66 86 906 6644",
        "website": "https://lin.ee/2MvoziB",
        "url": "https://www.google.com/maps/search/?api=1&query=next-level%20beauty%20by%20Khaofang%20%E0%B8%AA%E0%B8%B1%E0%B8%81%E0%B8%9B%E0%B8%B2%E0%B8%81%20%E0%B8%AA%E0%B8%B1%E0%B8%81%E0%B8%84%E0%B8%B4%E0%B9%89%E0%B8%A7%20%E0%B9%83%E0%B8%81%E0%B8%A5%E0%B9%89%E0%B8%89%E0%B8%B1%E0%B8%99&query_place_id=ChIJPcy0b7Of4jARS3m5Ytq2K84",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPSKhmpUEcZXpo4KL1nwxVK5fU79DKIODRextqy=w408-h478-k-no"
      }
    ],
    "description": "Bangkok's color analysis services are influenced by the city's vibrant culture and tropical climate. Analysts often focus on bright and cheerful palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Singapore",
    "slug": "singapore",
    "type": "city",
    "country": "Singapore",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Carmens Colours - Personal colour analysis & styling by Carmen",
        "description": "Image consultant. Rating: 5/5 from 94 reviews",
        "address": "23 Chatsworth Rd, Singapore",
        "phone": "",
        "website": "https://www.carmenscolours.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Carmens%20Colours%20-%20Personal%20colour%20analysis%20%26%20styling%20by%20Carmen&query_place_id=ChIJSwv4d1Yb2jERGgtk78IhwMQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjweqMw5b2LRsadGi0WtGJALawPBwP83IqBDAf=w408-h584-k-no"
      },
      {
        "name": "Colour Ultimate Studio",
        "description": "Image consultant. Rating: 5/5 from 26 reviews",
        "address": "1 Tanglin Rd, #05-07 Rendezvous Hotel, Singapore",
        "phone": "+65 8375 0077",
        "website": "https://colourultimatestudio.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Ultimate%20Studio&query_place_id=ChIJd5c6Ir4Z2jERw2u3WsgBASA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMvrKvVdGsmzMkIg8Z4hY5uEgvxpVyn0fQn7cLk=w408-h544-k-no"
      },
      {
        "name": "Something About Colour",
        "description": "Image consultant. Rating: 5/5 from 30 reviews",
        "address": "28 Canberra Dr, Singapore",
        "phone": "+65 8200 3396",
        "website": "https://www.somethingaboutcolour.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Something%20About%20Colour&query_place_id=ChIJy1rA7YsV2jERgOGr00VyQwo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNWnMutuQ9fFPQaH-zlFfJHhmRvLB_M4FD6KJlb=w408-h544-k-no"
      },
      {
        "name": "Style Forth",
        "description": "Image consultant. Rating: 5/5 from 24 reviews",
        "address": "30 Purvis St, #02-01, Singapore",
        "phone": "",
        "website": "https://www.styleforth.sg/",
        "url": "https://www.google.com/maps/search/?api=1&query=Style%20Forth&query_place_id=ChIJKR7nGjgZ2jERAiYLyzGneGs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMgsWtMFmLcxErhcKmtdh64S6s2ac-VWAJ7yJzT=w408-h272-k-no"
      },
      {
        "name": "Palette Pro by Studio FUSE",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "22 Sin Ming Ln, #06-82, Singapore",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Palette%20Pro%20by%20Studio%20FUSE&query_place_id=ChIJWet9rHoR2jER_pXxr1gk_3o",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=4j2rUI4ZXpAaxJ8FSLUD7w&cb_client=search.gws-prod.gps&w=408&h=240&yaw=21.36231&pitch=0&thumbfov=100"
      }
    ],
    "description": "Singapore's color analysis services reflect the city's blend of cultural influences and modern lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Hong Kong",
    "slug": "hong-kong",
    "type": "city",
    "country": "China",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "THE STYLUS personal styling consultancy",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "",
        "phone": "+852 6764 2732",
        "website": "https://the-stylus.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=THE%20STYLUS%20personal%20styling%20consultancy&query_place_id=ChIJxUNiA-8Tr2sR9i2DS8e0BOY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP-BZS77eRADK0C7leVkbHOMp27tXU41VfK0Etz=w408-h408-k-no"
      },
      {
        "name": "Skyi3",
        "description": "Image consultant. Rating: 5/5 from 33 reviews",
        "address": "805, Hua Fu Commercial Building, 111 Queen's Rd W, Sheung Wan",
        "phone": "+852 6166 8873",
        "website": "https://www.skyi3.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Skyi3&query_place_id=ChIJ69FfpqeUnCcRA4PEYyQHfbI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPLu1aW64jZsgKqMW-L2kKp195u6l2tsnma31cC=w408-h272-k-no"
      },
      {
        "name": "Colour Me Beautiful HK",
        "description": "Image consultant",
        "address": "Bldg Nos, Chit Lee Commercial Building, Office 06, 15/F, 30-46 Shau Kei Wan Rd, Sai Wan Ho",
        "phone": "+852 6685 7399",
        "website": "http://www.colourmebeautiful.hk/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Beautiful%20HK&query_place_id=ChIJT2_mxnoABDQRubqjxJz4sdE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOqxGrhRSS1ISoGfW7EIzKU2D962Hk-920yPKPs=w427-h240-k-no"
      },
      {
        "name": "My Image Consultancy 睿雅形象顧問公司",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "Unit 2107 21/F Workingview Commercial Building, 21 Yiu Wah Street, Causeway Bay, Causeway Bay",
        "phone": "+852 3705 9153",
        "website": "http://www.myimage.com.hk/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Image%20Consultancy%20%E7%9D%BF%E9%9B%85%E5%BD%A2%E8%B1%A1%E9%A1%A7%E5%95%8F%E5%85%AC%E5%8F%B8&query_place_id=ChIJuROo51AABDQREoHZWS_2iuQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO2oYN75KAYowMr1dEvTL4dM85nIWp9eiV0IlgS=w408-h306-k-no"
      },
      {
        "name": "Insightful Styles",
        "description": "Stylist. Rating: 5/5 from 6 reviews",
        "address": "17R, Emperor Hollywood Centre, 151 Hollywood Rd, Sheung Wan",
        "phone": "",
        "website": "https://www.instagram.com/mya.insightfulstyles/",
        "url": "https://www.google.com/maps/search/?api=1&query=Insightful%20Styles&query_place_id=ChIJExSnOSwBBDQRU6iFsfQ4z5Y",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPUga5O6AGUwItwPjRiRIZl68E6EaMvEGLzGsA=w408-h544-k-no"
      }
    ],
    "description": "Hong Kong's color analysis services are influenced by the city's cosmopolitan lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Jakarta",
    "slug": "jakarta",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$120 - $400",
    "businesses": [
      {
        "name": "myCOLOR ID studio",
        "description": "Consultant. Rating: 5/5 from 10 reviews",
        "address": "Jl. Pantai Mutiara No.1 blok G, RT.7/RW.16, North Jakarta City, Jakarta",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=myCOLOR%20ID%20studio&query_place_id=ChIJOXUwPQAdai4RAS30HDufKKg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3CE95I2i6z_Wn85PBhQlkYEmHmfwUl0ON49fg=w408-h306-k-no"
      },
      {
        "name": "Colourist",
        "description": "Beauty salon. Rating: 5/5 from 113 reviews",
        "address": "Kawasan District, Treasury Tower, Jl. Jend. Sudirman kav 52-53 No.8, South Jakarta City, Jakarta",
        "phone": "+62 887-0322-2119",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colourist&query_place_id=ChIJXXtiJTrxaS4R6RwXbK8MtXo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsP9cp_aEHl2A1GtLMNfkUOA6_ZwV4x-cRMURW=w408-h544-k-no"
      },
      {
        "name": "M Beauty & Academy",
        "description": "Beauty school. Rating: 5/5 from 412 reviews",
        "address": "Citywalk Sudirman Jl. K.H. Mas Mansyur No.10, RT.2/RW.6, Central Jakarta City, Jakarta",
        "phone": "+62 822-1005-0017",
        "website": "https://www.instagram.com/m_beauty_academy/",
        "url": "https://www.google.com/maps/search/?api=1&query=M%20Beauty%20%26%20Academy&query_place_id=ChIJ1VgHurX3aS4Rb5PC0HrxejI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPZsI-VTiJuymK1V-pwyEXsovYipvcgA8VLhigx=w426-h240-k-no"
      }
    ],
    "description": "Jakarta's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Manila",
    "slug": "manila",
    "type": "city",
    "country": "Philippines",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "T-Top Professional",
        "description": "Beauty salon. Rating: 3/5 from 1 reviews",
        "address": "Buenos Aires Street, Manila, Metro Manila",
        "phone": "+63 917 180 7832",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=T-Top%20Professional&query_place_id=ChIJJ3bnwM_JlzMRv_6WmF8uzXc",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=OMam_nYl6ardwaEcOU7_Ow&cb_client=search.gws-prod.gps&w=408&h=240&yaw=302.20908&pitch=0&thumbfov=100"
      }
    ],
    "description": "Manila's color analysis services are influenced by the city's vibrant culture and tropical climate. Analysts often focus on bright and cheerful palettes suitable for the lively local fashion scene."
  },
  {
    "name": "Mexico City",
    "slug": "mexico-city",
    "type": "city",
    "country": "Mexico",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "name": "Colorimetria VORO",
        "description": "Barbería. Rating: 1.3/5 from 12 reviews",
        "address": "Calz. del Hueso 921, Coyoacán, Ciudad de México, Cd. de México",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colorimetria%20VORO&query_place_id=ChIJV9bzKQABzoURRVCRM6gOJs0",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=8vhKCQXWEim0c3wDm2lF-A&cb_client=search.gws-prod.gps&w=408&h=240&yaw=180.87672&pitch=0&thumbfov=100"
      },
      {
        "name": "BELLEZA Y COLORIMETRIA S.A.DE C.V.",
        "description": "Tienda de productos de belleza. Rating: 3.9/5 from 56 reviews",
        "address": "Plaza Alonso García Bravo 10 Local D1 y D2, Cuauhtémoc, Ciudad de México, CDMX",
        "phone": "+52 55 5522 8910",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=BELLEZA%20Y%20COLORIMETRIA%20S.A.DE%20C.V.&query_place_id=ChIJA8hm1h_80YURVdyVZrBckyA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO5vA_q_5Yry8mVywCg6LK1ZocV6e2HvQOe9Ohg=w426-h240-k-no"
      },
      {
        "name": "ixtus academy /cursos de colorimetria",
        "description": "Escuela",
        "address": "Frente zorro abarrotero, Avenida tlahuac # 63 SEGUNDO PISO San Francisco tlaltenco. Alcaldía, Tláhuac, Ciudad de México, CDMX",
        "phone": "+52 55 4928 9699",
        "website": "http://www.ixtuscoloracademy.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=ixtus%20academy%20%2Fcursos%20de%20colorimetria&query_place_id=ChIJ8ZTSDvQDzoUR6GlXmgXcsUw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPAqkXWTfkCfQObvFQx92BSB5oqLq9LZDVGcZwE=w408-h255-k-no"
      },
      {
        "name": "Estetica Alex orther colorimetrista internacional Máster Mechas & Balayages Colors",
        "description": "Centro de estética. Rating: 4.6/5 from 14 reviews",
        "address": "Av. La Garita 143, Tlalpan, Ciudad de México, Cd. de México",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Estetica%20Alex%20orther%20colorimetrista%20internacional%20M%C3%A1ster%20Mechas%20%26%20Balayages%20Colors&query_place_id=ChIJlax0HXUBzoURCqUm1AK5MCE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOtAWtx3Q5c6rxDiJJU9mKuwgw5TT6_au7Xt637=w408-h719-k-no"
      },
      {
        "name": "Esbeydi Valderrama Estilista Profesional",
        "description": "Centro de estética. Rating: 5/5 from 3 reviews",
        "address": "Granada 8, Iztapalapa, Ciudad de México, Cd. de México",
        "phone": "+52 55 2152 6138",
        "website": "https://www.facebook.com/esbeydi.cb",
        "url": "https://www.google.com/maps/search/?api=1&query=Esbeydi%20Valderrama%20Estilista%20Profesional&query_place_id=ChIJ23hcqkkBzoUR8RPPhuTQgVs",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOob2bSUInvod2fz2T1Z7NxwUZRhvhqcP_u9DnN=w408-h362-k-no"
      }
    ],
    "description": "Mexico City's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "São Paulo",
    "slug": "sao-paulo",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "Dani Oliveira Stúdio | Especialista em Morena Iluminada | Loiro | Colorimetria | Itaquera",
        "description": "Centro de estética. Rating: 5/5 from 49 reviews",
        "address": "R. Heitor, 266 - Sala 1, São Paulo, State of São Paulo",
        "phone": "+55 11 95413-0307",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Dani%20Oliveira%20St%C3%BAdio%20%7C%20Especialista%20em%20Morena%20Iluminada%20%7C%20Loiro%20%7C%20Colorimetria%20%7C%20Itaquera&query_place_id=ChIJtwMxZYdnzpQR3PhBCMPUSnE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOP0ioOtIt72gPfrd6__wBlXswBT0Vk1dNqY3jt=w408-h408-k-no"
      },
      {
        "name": "Studio Wella",
        "description": "Centro de estética. Rating: 4.4/5 from 193 reviews",
        "address": "Av. Paulista, 854, São Paulo, State of São Paulo",
        "phone": "+55 11 3142-9161",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20Wella&query_place_id=ChIJj2-SgLhZzpQRzY5UZ4E3gSI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPWNjpao-sxifUAucj-b2tUEMw8PgpPHS7JyliN=w408-h544-k-no"
      },
      {
        "name": "Espaço de Beleza e Centro Técnico Silvia Vieira De Carvalho - Alisamento, Corte, Terapia capilar, colorimetria.",
        "description": "Centro de estética. Rating: 5/5 from 9 reviews",
        "address": "R. Foz do Giraldo, 70, São Paulo, State of São Paulo",
        "phone": "+55 11 96561-0385",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Espa%C3%A7o%20de%20Beleza%20e%20Centro%20T%C3%A9cnico%20Silvia%20Vieira%20De%20Carvalho%20-%20Alisamento%2C%20Corte%2C%20Terapia%20capilar%2C%20colorimetria.&query_place_id=ChIJ9_9At0NFzpQRRVCVqnXkjjM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMrvNugPxk1V2igZjzm-u_cptmAueWW2RqYzSav=w408-h615-k-no"
      },
      {
        "name": "Gabi Donato - Consultoria de Imagem e Estilo - Colorimetria Pessoal",
        "description": "Asesor de imagen. Rating: 5/5 from 21 reviews",
        "address": "R. Dr. José Elias, 141, São Paulo, State of São Paulo",
        "phone": "+55 11 95405-7784",
        "website": "https://gabidonato.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Gabi%20Donato%20-%20Consultoria%20de%20Imagem%20e%20Estilo%20-%20Colorimetria%20Pessoal&query_place_id=ChIJJQPsuKJXzpQRD6PjbwqzzfM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=itXiXXoKtq2rGZIrozOSlA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=139.16269&pitch=0&thumbfov=100"
      },
      {
        "name": "L'Oréal Professionnel Institute",
        "description": "Centro de estética. Rating: 4/5 from 218 reviews",
        "address": "R. Coelho Lisboa, 282, São Paulo, State of São Paulo",
        "phone": "+55 11 97545-2089",
        "website": "https://www.institutoloreal.com.br/unidade/tatuape/",
        "url": "https://www.google.com/maps/search/?api=1&query=L'Or%C3%A9al%20Professionnel%20Institute&query_place_id=ChIJzYWOF5VezpQRkaCprok1_W0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMvTlb-Wcfiznj7GnNQk-gAKE6xSWN0qnx8kUX1=w408-h305-k-no"
      }
    ],
    "description": "São Paulo's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Rio de Janeiro",
    "slug": "rio-de-janeiro",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "name": "Ivone Costa Coiffeur",
        "description": "Centro de estética. Rating: 4.6/5 from 23 reviews",
        "address": "R. Gonzaga Duque, 153, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 2590-3588",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Ivone%20Costa%20Coiffeur&query_place_id=ChIJqSFuKOV7mQARpQd2WUz-YYA",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=OlmtbjfX6d3Pfj5GEQtSCw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=320.57956&pitch=0&thumbfov=100"
      },
      {
        "name": "Instituto L'Oréal Tijuca",
        "description": "Academia de estética. Rating: 4/5 from 83 reviews",
        "address": "R. Carlos Vasconcelos, 145, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 99608-1877",
        "website": "https://www.institutoloreal.com.br/Unidades/Rio-de-Janeiro/Rio-de-Janeiro-RJ",
        "url": "https://www.google.com/maps/search/?api=1&query=Instituto%20L'Or%C3%A9al%20Tijuca&query_place_id=ChIJlf-yh0J_mQARMCOvB-elN_U",
        "imageUrl": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhHq-bMOJf1R_soJponxFdI9f_MvXYUKOJBF66mXOSBqLMGPx3EP3OULCfBRr0pq0KvovmibcByehVb8A6ehxzNvZcZZQwM3-_K5Ayy5fGJlZ8U9Nl9aGTLT3GXrqMaZoAqqI7cPoTVTq9U0-tDp4RmKC3Zuxn9vvdBmM26D-V7krAONhXKAWYduPbeMibVa98WoSdQ=w408-h726-k-no"
      },
      {
        "name": "Erica Martins Masterclass/ Cursos",
        "description": "Centro de estética. Rating: 4.7/5 from 30 reviews",
        "address": "R. Pedro Leão Veloso, 996, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 97528-3831",
        "website": "https://instagram.com/ericamartinsmasterclass/",
        "url": "https://www.google.com/maps/search/?api=1&query=Erica%20Martins%20Masterclass%2F%20Cursos&query_place_id=ChIJSbttdOH6mwARg_IN2JVFp00",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPGYpDhd3HpFWyB19I-qqjQgkY6yJi1Ll9zvF5g=w426-h240-k-no"
      },
      {
        "name": "Walter's Academy",
        "description": "Centro de estética. Rating: 4.5/5 from 364 reviews",
        "address": "R. Antônio Cordeiro, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 96434-5033",
        "website": "https://waltercoiffeur.com.br/academy/",
        "url": "https://www.google.com/maps/search/?api=1&query=Walter's%20Academy&query_place_id=ChIJjysxOy7YmwARrZkMeiuJAi0",
        "imageUrl": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhG6i_5yR6EJtvDCXr6xDGoR90Ksn-w4gMXOiX7tSkAIwwh9zRLN040QFoFaJEA1uyVFsMQmTbxkf2mxaSOA-VMprJt22qrqDiIJ3uGncQHKN_u0bwrVqNv8rZiCcnoruVDLhmR_ObrKC2LgBXHrmYfS4HVj_Pk-6i7ptOhG8lClmUwTB3lD2xhfYGNwtntR-Js8-w=w408-h306-k-no"
      },
      {
        "name": "Beauty Institute Ballayage",
        "description": "Centro de estética. Rating: 4.8/5 from 79 reviews",
        "address": "Av. das Américas, 11391 - loja 114, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 2141-9999",
        "website": "http://www.ballayage.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20Institute%20Ballayage&query_place_id=ChIJ-W1QXo3cmwARkTlmJ6yC9bg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPN_iim8VTuEeZx9jPqoxaMvam1ZlOw2Wt3-KKM=w408-h306-k-no"
      }
    ],
    "description": "Rio de Janeiro's color analysis scene reflects the city's vibrant beach culture and lively fashion. Analysts often focus on bright, tropical palettes suitable for the warm climate."
  },
  {
    "name": "Salvador",
    "slug": "salvador",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$120 - $400",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Salvador's color analysis services are influenced by the city's rich Afro-Brazilian heritage. Analysts often incorporate bold, vibrant colors reflecting the local culture and tropical environment."
  },
  {
    "name": "Brasília",
    "slug": "brasilia",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$130 - $410",
    "businesses": [
      {
        "name": "Salão Magui Canto especialista em Mechas e Colorimetria",
        "description": "Centro de estética. Rating: 5/5 from 57 reviews",
        "address": "R. das Opalas, Cáceres, State of Mato Grosso",
        "phone": "+55 65 99942-2392",
        "website": "https://www.instagram.com/institutedebelezamagui/",
        "url": "https://www.google.com/maps/search/?api=1&query=Sal%C3%A3o%20Magui%20Canto%20especialista%20em%20Mechas%20e%20Colorimetria&query_place_id=ChIJx3aZJDn_mpMRPAQyyncSRME",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPgmCMIjYfVIvFzjArAUT5BL8Hh2auLUbm-zKH9=w408-h408-k-no"
      },
      {
        "name": "Ceduk-Centro Educacional de Beleza",
        "description": "Escuela. Rating: 4.9/5 from 14 reviews",
        "address": "R. Belo Horizonte, 704, Governador Valadares, State of Minas Gerais",
        "phone": "+55 33 99976-6542",
        "website": "https://ceduk.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ceduk-Centro%20Educacional%20de%20Beleza&query_place_id=ChIJUeNp1AinsQARYWMy2UsP7cE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN5W50iLj9Ly9kegMk1WGN8uKzLNpVIUg3qeeZB=w494-h240-k-no"
      },
      {
        "name": "Profissionaliza",
        "description": "Institución educativa. Rating: 4.5/5 from 82 reviews",
        "address": "R. Inácio Corrêa, 150, Santarém, State of Pará",
        "phone": "+55 93 99194-4770",
        "website": "http://portalprofissionaliza.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Profissionaliza&query_place_id=ChIJLadAgZH5iJIR5raU558owe8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMt6vfZObqH2eap6nP1eigoSE6QZ9PjStLlbUVT=w408-h268-k-no"
      },
      {
        "name": "Bárbara Cardoso Hair | Colorista, especialista em Ruivos.",
        "description": "Centro de estética. Rating: 4.8/5 from 19 reviews",
        "address": "Quadra S-28, Lote 04, Comercial Acácia, Sala 301, Goiânia, State of Goiás",
        "phone": "+55 62 99186-3925",
        "website": "https://maapp.com.br/BARBARACOLORISTA",
        "url": "https://www.google.com/maps/search/?api=1&query=B%C3%A1rbara%20Cardoso%20Hair%20%7C%20Colorista%2C%20especialista%20em%20Ruivos.&query_place_id=ChIJCTfFVbvxXpMRlgdd4MLYedM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOIdJkAHH3QRzghl5vw7sK4pZOXW74FZNxTJXOj=w408-h240-k-no-pi-12.108306-ya179.11673-ro-4.2036705-fo100"
      },
      {
        "name": "Carol Andrade Consultoria de Imagem",
        "description": "Asesor de imagen. Rating: 5/5 from 25 reviews",
        "address": "",
        "phone": "+55 61 98294-7267",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Carol%20Andrade%20Consultoria%20de%20Imagem&query_place_id=ChIJp9uLbbg5WpMRvZzFBYreDIE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMnwV3n5qTvEgb0gn_ZOuWjNXbmq2S0yIrrySdN=w426-h240-k-no"
      }
    ],
    "description": "Brasília's color analysis scene blends modern aesthetics with natural influences. Analysts often focus on sophisticated palettes that complement the city's unique architecture and landscape."
  },
  {
    "name": "Fortaleza",
    "slug": "fortaleza",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Coloração Pessoal & Imagem Leidiane Lima - Online e Consultoria à Domicílio Fortaleza",
        "description": "Asesor de imagen. Rating: 5/5 from 16 reviews",
        "address": "R. Joaquim Jerônimo, 700, Fortaleza, Ceará",
        "phone": "+55 85 98719-1076",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colora%C3%A7%C3%A3o%20Pessoal%20%26%20Imagem%20Leidiane%20Lima%20-%20Online%20e%20Consultoria%20%C3%A0%20Domic%C3%ADlio%20Fortaleza&query_place_id=ChIJ32BYgpVPxwcRxL-2QHVRCrM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOVNU7sRo8kRazr-lvw3YCwOA_PwD_RmYRQxH-_=w498-h240-k-no"
      },
      {
        "name": "Instituto Iza Lima",
        "description": "Centro de estética. Rating: 4.9/5 from 70 reviews",
        "address": "R. Ernesto Pedro dos Santos, 66, Fortaleza, Ceará",
        "phone": "+55 85 98874-8670",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Instituto%20Iza%20Lima&query_place_id=ChIJ44F5COtLxwcRFnwIf4FeyHQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO_cDrLNrg6anGX0B3nABqBqCC3XvFusLwZUUO7=w408-h260-k-no"
      },
      {
        "name": "Colore Hair Brasil Instituto",
        "description": "Academia de estética. Rating: 4.8/5 from 4 reviews",
        "address": "Rua 2, 10, Fortaleza, Ceará",
        "phone": "+55 85 98922-1961",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colore%20Hair%20Brasil%20Instituto&query_place_id=ChIJ3VA6STdQxwcRt9rC5rlt1iA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMibVUKuR0B_RPC6lDS3BgHqnyheuGAujCBRBYX=w408-h272-k-no"
      },
      {
        "name": "Studio ÂngelaTolfo",
        "description": "Centro de estética. Rating: 4.9/5 from 43 reviews",
        "address": "R. Antônio Augusto, 200, Fortaleza, Ceará",
        "phone": "+55 85 98612-8563",
        "website": "https://www.instagram.com/studioangelatolfo/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20%C3%82ngelaTolfo&query_place_id=ChIJD-dSYPxJxwcRoha0EblDUuw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPm7f_UgP6FNrx_2aQuAHH4Fi01SNV4Q2Ssp2w=w408-h510-k-no"
      },
      {
        "name": "Instituto Relive Cursos e Treinamentos",
        "description": "Academia de estética. Rating: 4.9/5 from 23 reviews",
        "address": "R. duas nações, 930, Fortaleza, Ceará",
        "phone": "+55 85 98983-3577",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Instituto%20Relive%20Cursos%20e%20Treinamentos&query_place_id=ChIJdVIyCl1MxwcRtAD9QKu0RF4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN5S-fkADIpGX4dqGQqqCxtxWzeUb_mMR7wU5I=w426-h240-k-no"
      }
    ],
    "description": "Fortaleza's color analysis services reflect the city's coastal charm. Analysts often focus on fresh, beachy palettes suitable for the tropical climate and relaxed lifestyle."
  },
  {
    "name": "Belo Horizonte",
    "slug": "belo-horizonte",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$120 - $400",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Belo Horizonte's color analysis scene combines urban sophistication with natural beauty. Analysts often focus on versatile palettes that reflect the city's diverse cultural influences."
  },
  {
    "name": "Manaus",
    "slug": "manaus",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$110 - $380",
    "businesses": [
      {
        "name": "Suziane Coiffeur",
        "description": "Centro de estética. Rating: 4.9/5 from 126 reviews",
        "address": "Av. Pedro Teixeira, 989, Manaus, State of Amazonas",
        "phone": "+55 92 3657-0547",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Suziane%20Coiffeur&query_place_id=ChIJc2v0Rg0QbJIRiD-PALFvHQg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPCR5Nl4r3VRipN2NWO71UMz5A53Cl3wzM_K8s=w408-h408-k-no"
      },
      {
        "name": "CENTRO TÉCNICO DE CABELEIREIRO JÚNIOR ALVES",
        "description": "Escuela",
        "address": "R. Ovídio Gomes Monteiro, 374, Manaus, State of Amazonas",
        "phone": "+55 92 98277-6280",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=CENTRO%20T%C3%89CNICO%20DE%20CABELEIREIRO%20J%C3%9ANIOR%20ALVES&query_place_id=ChIJcX4Jf6cRbJIRRJovStyL1zc",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsJ8C9rd7lnQ5od5-ywqAnTM8Lmx2yAEZwWQM1=w408-h544-k-no"
      },
      {
        "name": "Coloração Pessoal e Consultoria de Imagem Carol Matta",
        "description": "Image consultant. Rating: 3.7/5 from 3 reviews",
        "address": "R. Salvador, 120, Manaus, State of Amazonas",
        "phone": "+55 92 98612-0747",
        "website": "https://www.instagram.com/carol_matta/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colora%C3%A7%C3%A3o%20Pessoal%20e%20Consultoria%20de%20Imagem%20Carol%20Matta&query_place_id=ChIJkT99w8QFbJIR2rhfpwHj_nQ",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=enVA8Y2u6uEA578oA-kYmA&cb_client=search.gws-prod.gps&w=408&h=240&yaw=163.46385&pitch=0&thumbfov=100"
      },
      {
        "name": "Studio Sense Especialista Em Mechas",
        "description": "Peluquería. Rating: 5/5 from 12 reviews",
        "address": "R. Cel. Ferreira de Araújo, 467, Manaus, State of Amazonas",
        "phone": "+55 92 99179-6975",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20Sense%20Especialista%20Em%20Mechas&query_place_id=ChIJz8ptD2wFbJIRN2oBuAL-rYw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNcHrYFZ5tmIQ2rbYL1ya30dcdwam3dU9oTx2Eg=w408-h545-k-no"
      },
      {
        "name": "Academia Amanda - Cursos e Treinamentos",
        "description": "Escuela técnica. Rating: 5/5 from 4 reviews",
        "address": "Av. Djalma Batista, 646, Manaus, State of Amazonas",
        "phone": "+55 92 99462-1877",
        "website": "http://academiaamanda.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Academia%20Amanda%20-%20Cursos%20e%20Treinamentos&query_place_id=ChIJ7e8H9IwZbJIRfZ-wzTdSLac",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJefu2-LtlkBSbk7UVOycR73E9R6D4rkGt762v=w408-h408-k-no"
      }
    ],
    "description": "Manaus' color analysis services are influenced by the surrounding Amazon rainforest. Analysts often incorporate lush, natural palettes inspired by the unique ecosystem."
  },
  {
    "name": "Buenos Aires",
    "slug": "buenos-aires",
    "type": "city",
    "country": "Argentina",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "name": "Cdjcolor",
        "description": "Academia de estética. Rating: 5/5 from 13 reviews",
        "address": "Matheu 1111, Cdad. Autónoma de Buenos Aires, Cdad. Autónoma de Buenos Aires",
        "phone": "+54 11 3425-6768",
        "website": "http://www.cdjcolor.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Cdjcolor&query_place_id=ChIJTdlnmxLLvJURfrQAD7-excY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPoRMrmvw8aYob-bQgGyKUEi7znkQbch2Eh_lN5=w408-h544-k-no"
      },
      {
        "name": "Pivot Point Argentina",
        "description": "Escuela de peluquería. Rating: 4.8/5 from 52 reviews",
        "address": "Sarmiento, Cdad. Autónoma de Buenos Aires, Cdad. Autónoma de Buenos Aires",
        "phone": "+54 11 4342-2421",
        "website": "http://www.pivot-point.com.ar/",
        "url": "https://www.google.com/maps/search/?api=1&query=Pivot%20Point%20Argentina&query_place_id=ChIJl7z1ntHKvJURBDqyKM5SzeI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO-fzB0oqlrYWuU11-Ncv3_MCN-TJT0gFEjgzNc=w426-h240-k-no"
      },
      {
        "name": "Maison Aubele",
        "description": "Asesor de imagen. Rating: 4.8/5 from 39 reviews",
        "address": "Av. Álvarez Thomas 198 3A, Cdad. Autónoma de Buenos Aires, Cdad. Autónoma de Buenos Aires",
        "phone": "+54 11 5047-7844",
        "website": "http://www.maisonaubele.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Maison%20Aubele&query_place_id=ChIJWzSA133KvJURWExYGDBogio",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOxpNU8786xxSyTSEGVwXSdwZWbG3pZDpDU2q7k=w408-h251-k-no"
      },
      {
        "name": "Academia Iyosei",
        "description": "Academia de estética. Rating: 4.5/5 from 39 reviews",
        "address": "Av. Independencia 1782, Cdad. Autónoma de Buenos Aires, Cdad. Autónoma de Buenos Aires",
        "phone": "+54 11 4382-0239",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Academia%20Iyosei&query_place_id=ChIJUUiNBd_KvJURL8xw2gFqsLI",
        "imageUrl": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhGngNjoX_tFTYBH0J21hLtYO30Nh5RZrQL3BjUtUNflKmass4EcxC3frV8ZIqIdDRa6BTvhSeF_QXBf-sXhfoaDzMudbfUdusKAIebfyIXojQ_GxXge88QVPGc1Rc7P4LHFKxGd8mHWl_wFqZsEZaB8xaYiUB38-SFvi_EkJ2pJfKUBaTzagcJ7n8nxyPDPzU9cr_k=w408-h306-k-no"
      },
      {
        "name": "Nune Delgado Salón de Belleza & Peluquería",
        "description": "Centro de estética. Rating: 4.7/5 from 490 reviews",
        "address": "Av. Rivadavia 926 Piso 2 211, Cdad. Autónoma de Buenos Aires, Cdad. Autónoma de Buenos Aires",
        "phone": "+54 9 11 2881-1363",
        "website": "http://www.nunedelgado.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Nune%20Delgado%20Sal%C3%B3n%20de%20Belleza%20%26%20Peluquer%C3%ADa&query_place_id=ChIJo_lKkhXLvJURy1WUSeRsT_w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNUKBb-z1IQGcmjvTtp59FAikSvwMbDLfEx72EZ=w408-h544-k-no"
      }
    ],
    "description": "Buenos Aires' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Bogotá",
    "slug": "bogota",
    "type": "city",
    "country": "Colombia",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Bogotá's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Lima",
    "slug": "lima",
    "type": "city",
    "country": "Peru",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Lima's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Santiago",
    "slug": "santiago",
    "type": "city",
    "country": "Chile",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Santiago's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Rio de Janeiro",
    "slug": "rio-de-janeiro",
    "type": "city",
    "country": "Brazil",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "Erica Martins Masterclass/ Cursos",
        "description": "Centro de estética. Rating: 4.7/5 from 30 reviews",
        "address": "R. Pedro Leão Veloso, 996, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 97528-3831",
        "website": "https://instagram.com/ericamartinsmasterclass/",
        "url": "https://www.google.com/maps/search/?api=1&query=Erica%20Martins%20Masterclass%2F%20Cursos&query_place_id=ChIJSbttdOH6mwARg_IN2JVFp00",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPGYpDhd3HpFWyB19I-qqjQgkY6yJi1Ll9zvF5g=w426-h240-k-no"
      },
      {
        "name": "Beauty Institute Ballayage",
        "description": "Centro de estética. Rating: 4.8/5 from 79 reviews",
        "address": "Av. das Américas, 11391 - loja 114, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 2141-9999",
        "website": "http://www.ballayage.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Beauty%20Institute%20Ballayage&query_place_id=ChIJ-W1QXo3cmwARkTlmJ6yC9bg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPN_iim8VTuEeZx9jPqoxaMvam1ZlOw2Wt3-KKM=w408-h306-k-no"
      },
      {
        "name": "Walter's Academy",
        "description": "Centro de estética. Rating: 4.5/5 from 364 reviews",
        "address": "R. Antônio Cordeiro, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 96434-5033",
        "website": "https://waltercoiffeur.com.br/academy/",
        "url": "https://www.google.com/maps/search/?api=1&query=Walter's%20Academy&query_place_id=ChIJjysxOy7YmwARrZkMeiuJAi0",
        "imageUrl": "https://lh3.googleusercontent.com/gps-proxy/ALd4DhG6i_5yR6EJtvDCXr6xDGoR90Ksn-w4gMXOiX7tSkAIwwh9zRLN040QFoFaJEA1uyVFsMQmTbxkf2mxaSOA-VMprJt22qrqDiIJ3uGncQHKN_u0bwrVqNv8rZiCcnoruVDLhmR_ObrKC2LgBXHrmYfS4HVj_Pk-6i7ptOhG8lClmUwTB3lD2xhfYGNwtntR-Js8-w=w408-h306-k-no"
      },
      {
        "name": "Consultoria de Coloração Pessoal Duda Accioly",
        "description": "Image consultant. Rating: 5/5 from 146 reviews",
        "address": "Rua da Passagem, 83, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 97423-4060",
        "website": "https://dudaaccioly.com.br/",
        "url": "https://www.google.com/maps/search/?api=1&query=Consultoria%20de%20Colora%C3%A7%C3%A3o%20Pessoal%20Duda%20Accioly&query_place_id=ChIJ8QlffmZ_mQARgMiSlmnu9K8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNcZ--3iLG3o77ieMhNPEcD8Z9q78NbRZeyvaOr=w408-h490-k-no"
      },
      {
        "name": "Consultoria de imagem Rio de Janeiro - Coloração Pessoal Chris Berbat",
        "description": "Image consultant. Rating: 5/5 from 48 reviews",
        "address": "Av. das Américas, 2901, Rio de Janeiro, State of Rio de Janeiro",
        "phone": "+55 21 98619-0558",
        "website": "https://instagram.com/chrisberbatconsultoria?utm_medium=copy_link",
        "url": "https://www.google.com/maps/search/?api=1&query=Consultoria%20de%20imagem%20Rio%20de%20Janeiro%20-%20Colora%C3%A7%C3%A3o%20Pessoal%20Chris%20Berbat&query_place_id=ChIJSwmwXZnbmwARUrL7sihwTP8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOklsZFL19KLQF8lFX4pegb_YuolRzkqT1LwSXe=w408-h272-k-no"
      }
    ],
    "description": "Rio de Janeiro's color analysis services reflect the city's vibrant culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Caracas",
    "slug": "caracas",
    "type": "city",
    "country": "Venezuela",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Caracas' color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Quito",
    "slug": "quito",
    "type": "city",
    "country": "Ecuador",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Quito's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Panama City",
    "slug": "panama-city",
    "type": "city",
    "country": "Panama",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Panama City's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Sydney",
    "slug": "sydney",
    "type": "city",
    "country": "Australia",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Your Colours and Style Sydney",
        "description": "Image consultant. Rating: 5/5 from 16 reviews",
        "address": "25 Clarkes Rd, Ramsgate, New South Wales",
        "phone": "+61 413 875 471",
        "website": "http://www.yourcoloursandstyle.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Your%20Colours%20and%20Style%20Sydney&query_place_id=ChIJTxl0b9i5EmsRAPeFIuDXDw8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN82hmvhbRny2hxYNRj3Zgfk9D_Escmh8T9o71f=w480-h240-k-no"
      },
      {
        "name": "Colour Me Coach",
        "description": "Image consultant. Rating: 5/5 from 43 reviews",
        "address": "573 President Ave, Sutherland, New South Wales",
        "phone": "+61 415 345 940",
        "website": "http://colourmecoach.com.au/en-au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Me%20Coach&query_place_id=ChIJLZ_4WDXGsYUR09om4Ix605w",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPb1ziUufNA8QB6pChYVQSCrlnJwEMKkFYDzr8I=w408-h544-k-no"
      },
      {
        "name": "Studio Peona",
        "description": "Beautician. Rating: 5/5 from 144 reviews",
        "address": "Suite 209/4 The Boulevarde, Strathfield, New South Wales",
        "phone": "",
        "website": "http://studiopeona.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Studio%20Peona&query_place_id=ChIJoZF8qe-lEmsRG5qyDEQp9V0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNjPVpnR7ZdRUHksf0zOeuHpAwG38gELGrPy6sT=w408-h611-k-no"
      },
      {
        "name": "Image Confidence",
        "description": "Stylist. Rating: 5/5 from 52 reviews",
        "address": "69 Allen St, Leichhardt, New South Wales",
        "phone": "+61 408 108 804",
        "website": "http://www.imageconfidence.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Image%20Confidence&query_place_id=ChIJI7DnkhywEmsRL05YnezcZYk",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMTH1FACX2FGpjkg9bQ1hlPI2laYxczypc0jyWg=w408-h612-k-no"
      },
      {
        "name": "AEGI.Lab",
        "description": "Beauty salon. Rating: 4.8/5 from 87 reviews",
        "address": "Studio 9, level 7/119 Bathurst St, Sydney, New South Wales",
        "phone": "",
        "website": "https://www.aegi.co/",
        "url": "https://www.google.com/maps/search/?api=1&query=AEGI.Lab&query_place_id=ChIJC1dEfQCvEmsR9KKunHRAHMo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPjfwIjFYhFkNvD-UoyYEMAqn3Z4X9TCytXz8To=w426-h240-k-no"
      }
    ],
    "description": "Sydney's color analysis services reflect the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Melbourne",
    "slug": "melbourne",
    "type": "city",
    "country": "Australia",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "My Colour and Style - Colour Analysis - Melbourne",
        "description": "Image consultant. Rating: 5/5 from 1 reviews",
        "address": "Flaxen Hills Rd, Doreen, Victoria",
        "phone": "+61 411 624 546",
        "website": "https://www.mycolourandstyle.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=My%20Colour%20and%20Style%20-%20Colour%20Analysis%20-%20Melbourne&query_place_id=ChIJnfD6kskZAWkRZ0UmZkfRRY4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNlheOLvher8vYPfsI6sSguHlhFIYR-2AnySuVm=w408-h266-k-no"
      },
      {
        "name": "Colour Style Lab",
        "description": "Image consultant. Rating: 5/5 from 49 reviews",
        "address": "",
        "phone": "",
        "website": "https://www.colourstylelab.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20Style%20Lab&query_place_id=ChIJywY3A_5f1moRrFc2ID9RJCY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNFLOFhcZiRjhJwIWWZGp-yGOrKylrSraoRYPpg=w408-h544-k-no"
      },
      {
        "name": "Colour You",
        "description": "Image consultant. Rating: 5/5 from 52 reviews",
        "address": "503/343 Little Collins St, Melbourne, Victoria",
        "phone": "+61 426 870 929",
        "website": "https://colouryouau.as.me/",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20You&query_place_id=ChIJyVXtRjRD1moRq7Edy92YnaY",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNuhpBEWS_IyxcHAWpvZXzPwYUHPSLVeGOiYcdI=w439-h240-k-no"
      },
      {
        "name": "Donna Cameron Personal Style Specialist",
        "description": "Image consultant. Rating: 5/5 from 72 reviews",
        "address": "118 Lee St, Carlton North, Victoria",
        "phone": "+61 418 101 235",
        "website": "https://www.donna-cameron.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Donna%20Cameron%20Personal%20Style%20Specialist&query_place_id=ChIJ7QeG7HlD1moRj-U_0aSsrZM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNAIySDN5oZk2HYOhJP70-zkpSyZv-69xLqq9oO=w408-h408-k-no"
      }
    ],
    "description": "Melbourne's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Brisbane",
    "slug": "brisbane",
    "type": "city",
    "country": "Australia",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "Kolourista - Personal Colour Analysis in North Brisbane",
        "description": "Image consultant. Rating: 5/5 from 10 reviews",
        "address": "",
        "phone": "",
        "website": "http://www.kolourista.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Kolourista%20-%20Personal%20Colour%20Analysis%20in%20North%20Brisbane&query_place_id=ChIJIYcEdMt9OIkR0UIwVxBAmrQ",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOm7pDDstqIEidg8etK5MfZjXBlYLj4HxZhO8gi=w408-h510-k-no"
      },
      {
        "name": "The PS Colour - Personal Colour Analysis in Brisbane City",
        "description": "Image consultant. Rating: 5/5 from 20 reviews",
        "address": "8 Adelaide St, Brisbane City, Queensland",
        "phone": "+61 448 299 014",
        "website": "https://www.thepscolour.com/%20?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=15866840746342700966",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20PS%20Colour%20-%20Personal%20Colour%20Analysis%20in%20Brisbane%20City&query_place_id=ChIJ43tUXhxbkWsRwkIhFc8nnlM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=wecCoQ4wdbPwVFRbkFrn7A&cb_client=search.gws-prod.gps&w=408&h=240&yaw=50.7948&pitch=0&thumbfov=100"
      },
      {
        "name": "Ann Whitaker Style",
        "description": "Image consultant. Rating: 4.9/5 from 82 reviews",
        "address": "53 Wyndarra St, Kenmore, Queensland",
        "phone": "+61 424 656 097",
        "website": "https://annwhitaker.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=Ann%20Whitaker%20Style&query_place_id=ChIJLzR1mfpRkWsRmY3wcJXdQic",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNGjyXhErbhvEhgO0gX-m0CLmQD3_ZbsG2k_oen=w408-h611-k-no"
      },
      {
        "name": "Nanda Mazzurco",
        "description": "Image consultant. Rating: 5/5 from 6 reviews",
        "address": "SHOP 123, Suite 3164/400 Newmarket Rd, Newmarket, QLD",
        "phone": "+61 478 544 202",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Nanda%20Mazzurco&query_place_id=ChIJfXP1OK9XkWsRc81QCuJzFoM",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=7CcipqDuxz5DMnjjyuZnjQ&cb_client=search.gws-prod.gps&w=408&h=240&yaw=217.57855&pitch=0&thumbfov=100"
      },
      {
        "name": "SkinFit New Farm -HIFU & Facials",
        "description": "Beauty salon. Rating: 4.7/5 from 149 reviews",
        "address": "Level 1, Suite 3, Merthyr Village Shopping Centre, 85 Merthyr Rd, New Farm, QLD",
        "phone": "+61 424 227 249",
        "website": "https://www.skinfitnewfarm.com.au/",
        "url": "https://www.google.com/maps/search/?api=1&query=SkinFit%20New%20Farm%20-HIFU%20%26%20Facials&query_place_id=ChIJWWBlNYhZkWsRZ38DBd0S2zo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOPUvJ4gaYVIunM2VPNoUQsi7gDG5Zzo_IfGHxl=w408-h725-k-no"
      }
    ],
    "description": "Brisbane's color analysis services reflect the city's tropical climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Perth",
    "slug": "perth",
    "type": "city",
    "country": "Australia",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "Hair Day",
        "description": "Hair salon. Rating: 4.3/5 from 333 reviews",
        "address": "Shop 18/789 Albany Hwy, East Victoria Park, Western Australia",
        "phone": "+61 450 959 036",
        "website": "https://bookings.gettimely.com/hairday/bb/book",
        "url": "https://www.google.com/maps/search/?api=1&query=Hair%20Day&query_place_id=ChIJJzoUdXa7MioRER_Stv0eoRA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPN2VHeFnQ0Ythk3nd0Va1kv_NVd7jNr7goqAHZ=w408-h611-k-no"
      }
    ],
    "description": "Perth's color analysis services are influenced by the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Auckland",
    "slug": "auckland",
    "type": "city",
    "country": "New Zealand",
    "pricingRange": "$150 - $450",
    "businesses": [
      {
        "name": "Georgette Pollock-Johnston Stylist",
        "description": "Stylist. Rating: 5/5 from 7 reviews",
        "address": "23A Crowhurst St, Auckland",
        "phone": "+64 21 360 198",
        "website": "https://georgettepollock.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Georgette%20Pollock-Johnston%20Stylist&query_place_id=ChIJBzvC50JJDW0Rq2V0QZdPMv8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMaxaEq5ckhnAICmb1_1u_cPxf5My_-nfAxE4L7=w408-h272-k-no"
      }
    ],
    "description": "Auckland's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Kuala Lumpur",
    "slug": "kuala-lumpur",
    "type": "city",
    "country": "Malaysia",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "name": "Moddish Craft to Style",
        "description": "Image consultant. Rating: 5/5 from 51 reviews",
        "address": "11G & 11-1, Jalan 3/109E, Jln Klang Lama, Kuala Lumpur, Federal Territory of Kuala Lumpur",
        "phone": "+60 17-242 9559",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Moddish%20Craft%20to%20Style&query_place_id=ChIJGSI_hMNLzDER15hzZVPcqIM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipME59PmfALPn1riEgPA6ka7GeHdAVGMQmpVKl9d=w459-h240-k-no"
      }
    ],
    "description": "Kuala Lumpur's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Taipei",
    "slug": "taipei",
    "type": "city",
    "country": "Taiwan",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "亞洲色彩",
        "description": "Beauty salon. Rating: 5/5 from 86 reviews",
        "address": "樓之二, 4F-2, No.213. AnHe Road Sec 2, No. 213號4, Section 2, Anhe Rd, Da’an District, Taipei City",
        "phone": "+886 2 2377 5777",
        "website": "https://www.asiacolors.com.tw/",
        "url": "https://www.google.com/maps/search/?api=1&query=%E4%BA%9E%E6%B4%B2%E8%89%B2%E5%BD%A9&query_place_id=ChIJTV0I6NGrQjQRRprHn-bkXQA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN7qIcY4fuxh4kLCbZ0XdCTI_Iw6dvt7fSCP-pX=w408-h306-k-no"
      }
    ],
    "description": "Taipei's color analysis services reflect the city's blend of traditional and modern influences. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Hanoi",
    "slug": "hanoi",
    "type": "city",
    "country": "Vietnam",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "K-Color - Trung tâm tư vấn màu sắc cá nhân",
        "description": "Photography studio. Rating: 4.9/5 from 75 reviews",
        "address": "92B Xóm Chùa, Hanoi, Tây Hồ, Hanoi",
        "phone": "+84 357 262 821",
        "website": "https://beacons.ai/info_kcolor",
        "url": "https://www.google.com/maps/search/?api=1&query=K-Color%20-%20Trung%20t%C3%A2m%20t%C6%B0%20v%E1%BA%A5n%20m%C3%A0u%20s%E1%BA%AFc%20c%C3%A1%20nh%C3%A2n&query_place_id=ChIJ3fSmzn6rNTERw-zqhjtzN64",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNw64uKzLVUpMMCn-KxIcx8YFfnODDJSCFCOFlV=w408-h544-k-no"
      }
    ],
    "description": "Hanoi's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Ho Chi Minh City",
    "slug": "ho-chi-minh-city",
    "type": "city",
    "country": "Vietnam",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "Color Visual Studio - Color Analysis & Styling in Viet Nam",
        "description": "Stylist. Rating: 5/5 from 28 reviews",
        "address": "81 Trương Định, Ho Chi Minh City, District 3, Ho Chi Minh City",
        "phone": "+84 785 338 299",
        "website": "https://colorvisual.vn/",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Visual%20Studio%20-%20Color%20Analysis%20%26%20Styling%20in%20Viet%20Nam&query_place_id=ChIJRTnw18dfJocRejTtgqufkv4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPyPXZBcDRNo9fHnSx9srDxIiC41yElfHUrhOYO=w408-h408-k-no"
      }
    ],
    "description": "Ho Chi Minh City's color analysis services reflect the city's dynamic fashion scene and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Guangzhou",
    "slug": "guangzhou",
    "type": "city",
    "country": "China",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Guangzhou's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Shenzhen",
    "slug": "shenzhen",
    "type": "city",
    "country": "China",
    "pricingRange": "$130 - $420",
    "businesses": [
      {
        "description": "undefined",
        "address": "",
        "phone": "",
        "website": "",
        "url": "",
        "imageUrl": ""
      }
    ],
    "description": "Shenzhen's color analysis services reflect the city's modern development and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Osaka",
    "slug": "osaka",
    "type": "city",
    "country": "Japan",
    "pricingRange": "$140 - $430",
    "businesses": [
      {
        "name": "Bimore 大阪店",
        "description": "Image consultant. Rating: 4.9/5 from 32 reviews",
        "address": "Bingomachi, 4 Chome−3−6 アスティナ御堂筋本町 801, Osaka, Osaka",
        "phone": "",
        "website": "https://bimore.tokyo/",
        "url": "https://www.google.com/maps/search/?api=1&query=Bimore%20%E5%A4%A7%E9%98%AA%E5%BA%97&query_place_id=ChIJh59e1nbnAGAR6ptDw0nroHA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP43Ep6wlLlE8qvJlGpMuK2NX28OYhky69Nf4eP=w408-h408-k-no"
      },
      {
        "name": "LIP LIFE LAB オリジナルリップ製作/パーソナルカラー/骨格診断/顔タイプ診断/メイクレッスン",
        "description": "Image consultant. Rating: 5/5 from 457 reviews",
        "address": "Yotsubashi Chuo Building, 603 1 Chome-6-12 Kitahorie, Osaka, Osaka",
        "phone": "+81 50-1808-9821",
        "website": "https://irodori-lgbt.com/liplifelab",
        "url": "https://www.google.com/maps/search/?api=1&query=LIP%20LIFE%20LAB%20%E3%82%AA%E3%83%AA%E3%82%B8%E3%83%8A%E3%83%AB%E3%83%AA%E3%83%83%E3%83%97%E8%A3%BD%E4%BD%9C%2F%E3%83%91%E3%83%BC%E3%82%BD%E3%83%8A%E3%83%AB%E3%82%AB%E3%83%A9%E3%83%BC%2F%E9%AA%A8%E6%A0%BC%E8%A8%BA%E6%96%AD%2F%E9%A1%94%E3%82%BF%E3%82%A4%E3%83%97%E8%A8%BA%E6%96%AD%2F%E3%83%A1%E3%82%A4%E3%82%AF%E3%83%AC%E3%83%83%E3%82%B9%E3%83%B3&query_place_id=ChIJFQtZEIXnAGARHikQdgbzBdE",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM3d6AEoDK2rE4iN97SWxpThfS0SiLERyquCcjN=w408-h544-k-no"
      },
      {
        "name": "(社)日本骨格メイク",
        "description": "Image consultant. Rating: 5/5 from 5 reviews",
        "address": "Minamisenba, 3 Chome−6−24IBC 心斎橋west4階502-1号, Osaka, Osaka",
        "phone": "",
        "website": "https://argylemake.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=(%E7%A4%BE)%E6%97%A5%E6%9C%AC%E9%AA%A8%E6%A0%BC%E3%83%A1%E3%82%A4%E3%82%AF&query_place_id=ChIJW4pD8V3nAGAR43u49Dc6N2Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOWCyS2aK6fuF-GMhRQz2tct_juO6okYEZbwtET=w408-h408-k-no"
      },
      {
        "name": "SHISEIDO SALON&SPA OSAKA",
        "description": "Beauty salon. Rating: 4.9/5 from 642 reviews",
        "address": "Umeda, 2 Chome−2−22 ハービスPLAZA ENT 6F, Osaka, Osaka",
        "phone": "+81 120-576-101",
        "website": "https://salon.shiseido.co.jp/shop/osaka/?utm_source=google&utm_medium=maps",
        "url": "https://www.google.com/maps/search/?api=1&query=SHISEIDO%20SALON%26SPA%20OSAKA&query_place_id=ChIJtbiN24zmAGARQNCoXDChFk4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPNJQkLDO3sUOVdq9dAr3OEuz_n4eAWcDEo-P9-=w480-h240-k-no"
      }
    ],
    "description": "Osaka's color analysis services are influenced by the city's blend of traditional and modern influences. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Delhi",
    "slug": "delhi",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "International Image Consulting Institute",
        "description": "Image consultant. Rating: 5/5 from 4 reviews",
        "address": "632, New Delhi, Delhi, Delhi",
        "phone": "+91 98998 72862",
        "website": "http://www.iici.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=International%20Image%20Consulting%20Institute&query_place_id=ChIJ_y0Dw1kFDTkRceCZUsoNs3I",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=UY3nBzXQWon8KkoKgxpISw&cb_client=search.gws-prod.gps&w=408&h=240&yaw=145.73494&pitch=0&thumbfov=100"
      },
      {
        "name": "Make U Up Makeup Studio & Academy",
        "description": "Beauty salon. Rating: 4.5/5 from 66 reviews",
        "address": "A-161/9, Sector-A, IInd Floor, Kishangarh, Mehrauli Mahipalpur Road Old Car Market, Near Ambience Tower, above Union Bank, New Delhi, Delhi",
        "phone": "+91 88222 50000",
        "website": "https://www.makeuupsalon.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=Make%20U%20Up%20Makeup%20Studio%20%26%20Academy&query_place_id=ChIJ-YkmGW4eDTkRu1GbFJajvr8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOOJ_a1jHM-uBmzMRvNrmGnzXiM50VhsZ0-k9a6=w408-h549-k-no"
      },
      {
        "name": "VLCC LUXE Beauty & Wellness Clinic",
        "description": "Wellness center. Rating: 4.6/5 from 200 reviews",
        "address": "The Gallery On Mg Mall, Mehrauli-Gurgaon Rd, next to Barista, New Delhi, Delhi",
        "phone": "+91 92892 51616",
        "website": "http://www.vlccluxe.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=VLCC%20LUXE%20Beauty%20%26%20Wellness%20Clinic&query_place_id=ChIJ34s8w58fDTkRmo13BRPrcGU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOBuznxXWR6JOO4mJflptSQW2stfdO4U8iHPf2F=w408-h272-k-no"
      },
      {
        "name": "StyleBuddy - Best Personal Stylist in Delhi | Personal Shopper in Delhi",
        "description": "Stylist. Rating: 4.8/5 from 9 reviews",
        "address": "K5, 3rd floor, Civil Zone, New Delhi, Delhi",
        "phone": "+91 96674 88837",
        "website": "https://www.stylebuddy.fashion/",
        "url": "https://www.google.com/maps/search/?api=1&query=StyleBuddy%20-%20Best%20Personal%20Stylist%20in%20Delhi%20%7C%20Personal%20Shopper%20in%20Delhi&query_place_id=ChIJJ1cCj3YdDTkRHqfEVpHBZJg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPev8ZxdvdvsefeVdFDBUn3WCRAK3zoEKjIttOS=w408-h408-k-no"
      }
    ],
    "description": "Delhi's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Bangalore",
    "slug": "bangalore",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "Jaya kumari: Color Analyst & Wardrobe Building Expert",
        "description": "Stylist. Rating: 5/5 from 6 reviews",
        "address": "19th Main Rd, Bengaluru, Karnataka",
        "phone": "",
        "website": "http://jayakumarikamti.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Jaya%20kumari%3A%20Color%20Analyst%20%26%20Wardrobe%20Building%20Expert&query_place_id=ChIJh6Rz1DaOYEIRUfH305jl84k",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNaV8f4r4RANWSA01yx5bPjUbN008u7cRKD5r6W=w408-h272-k-no"
      },
      {
        "name": "Enzo League",
        "description": "Image consultant. Rating: 4.4/5 from 17 reviews",
        "address": "Adjoint to Vodafone Showroom, No-408,2nd Floor, CITA Building , st cross, Bengaluru, Karnataka",
        "phone": "+91 86183 80218",
        "website": "https://www.enzoleague.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Enzo%20League&query_place_id=ChIJR95Y5x0VrjsRUB1VJNCli2Q",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPEsMdVhgkwMl2cEub-gbwbAlnRJeaTiNtNFP2J=w630-h240-k-no"
      }
    ],
    "description": "Bangalore's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Kolkata",
    "slug": "kolkata",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "Colour On Colour",
        "description": "Beauty salon. Rating: 5/5 from 4 reviews",
        "address": "8, Station Rd, Kolkata, West Bengal",
        "phone": "+91 98363 60383",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colour%20On%20Colour&query_place_id=ChIJq8mOMdpwAjoRhSCjG7ZJ0q8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPNJMAM4lUxQrhE_nLswjXcCgxOy8b0RvPHuGIT=w408-h306-k-no"
      },
      {
        "name": "Scissors & Co Salon",
        "description": "Beauty salon. Rating: 4.6/5 from 343 reviews",
        "address": "2, Deshapriya Park W, Kolkata, West Bengal",
        "phone": "+91 98300 07288",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Scissors%20%26%20Co%20Salon&query_place_id=ChIJG76E7up3AjoRzvFD62ZqTsg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNnR3V4dzQkwQKMGAyeGp1oDeN4HN83Sk-DVfHt=w408-h289-k-no"
      },
      {
        "name": "Venus Ladies Beauty Parlour",
        "description": "Beauty salon. Rating: 4.7/5 from 301 reviews",
        "address": "51/5, Biren Roy Road W, opposite MANGALAM PARK, Kolkata, West Bengal",
        "phone": "+91 98361 70765",
        "website": "http://www.venusladiesbeauty.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Venus%20Ladies%20Beauty%20Parlour&query_place_id=ChIJmVzTRml6AjoRRIB9LiB2bqM",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNFHpNTMiBqGrdnxbZqgY3P8gDU35QTsRr7u3PH=w426-h240-k-no"
      }
    ],
    "description": "Kolkata's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Hyderabad",
    "slug": "hyderabad",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "Intended Image Consulting LLP",
        "description": "Image consultant. Rating: 4.9/5 from 27 reviews",
        "address": "beside Maruthi Suzuki Service Center, Hyderabad, Telangana",
        "phone": "+91 63022 31059",
        "website": "http://www.intendedimage.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Intended%20Image%20Consulting%20LLP&query_place_id=ChIJIQLIW6CWyzsRdd7jJwBE5hs",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=4wmYfF9p6pVILKBHUe2Cfg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=316.9898&pitch=0&thumbfov=100"
      },
      {
        "name": "The ArtBeat Salon",
        "description": "Beauty salon. Rating: 4.9/5 from 32 reviews",
        "address": "1O1 First Floor, Ashoka Capitol, Road No. 2, Hyderabad, Telangana",
        "phone": "+91 73531 33333",
        "website": "https://www.instagram.com/theartbeatsalon/reels/?ref=blog&hl=en",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20ArtBeat%20Salon&query_place_id=ChIJ171rV6-RyzsRzxfCsaGq7_I",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipObJW-1_QP4MsTPL0btBo4RDVn-yBHlkyvV3Xzz=w408-h272-k-no"
      },
      {
        "name": "IMMI Makeup Studio And Academy",
        "description": "Make-up artist. Rating: 4.8/5 from 56 reviews",
        "address": "Villa 3, Nath Reliance Kuteer Rd. No :7 Banjara Hills Imam Khomeini Road, OPP: Kajaria Eternity World Showroom, road, opp. to GVK one, Hyderabad, Telangana",
        "phone": "+91 81060 11539",
        "website": "https://www.immimakeup.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=IMMI%20Makeup%20Studio%20And%20Academy&query_place_id=ChIJqwp9xreRyzsRkpT_6jou8_8",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOJe-buu40EUZqZjn8e90oEd3TEJfwN6dF2kPt3=w408-h408-k-no"
      }
    ],
    "description": "Hyderabad's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Chennai",
    "slug": "chennai",
    "type": "city",
    "country": "India",
    "pricingRange": "$120 - $410",
    "businesses": [
      {
        "name": "Namita Sayani Image Consulting",
        "description": "Image consultant. Rating: 4.5/5 from 10 reviews",
        "address": "23, Harrington Rd, Chennai, Tamil Nadu",
        "phone": "+91 99404 44534",
        "website": "https://www.namitasayani.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Namita%20Sayani%20Image%20Consulting&query_place_id=ChIJH7yI7ttnUjoRgMarqLzGWt0",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipNwaSN-2wRuk0Eb0gETCOtbXBL9JyvCHis4ijBC=w408-h272-k-no"
      },
      {
        "name": "Chennai Cosmetic Clinic",
        "description": "Skin care clinic. Rating: 4.7/5 from 95 reviews",
        "address": "2nd Avenue extension, Chintamani, D - 4/2, above RBN Stores, Chennai, Tamil Nadu",
        "phone": "+91 95970 30306",
        "website": "http://www.chennaicosmeticclinic.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=Chennai%20Cosmetic%20Clinic&query_place_id=ChIJAa3oNilkUjoR1qTlxT812FA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipN_IZeJsA0VoCupojNPnN_5AC5ZtynwAkLCq4CH=w408-h272-k-no"
      },
      {
        "name": "The Visible Difference",
        "description": "Beauty salon. Rating: 4.6/5 from 130 reviews",
        "address": "Old No. 3, New, No. 5, Balaji Ave 1st St, Chennai, Tamil Nadu",
        "phone": "+91 98841 21983",
        "website": "http://www.the-visibledifference.in/",
        "url": "https://www.google.com/maps/search/?api=1&query=The%20Visible%20Difference&query_place_id=ChIJo3ut61tmUjoRGO6ofbJwUMA",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipM00Nn_lMQgbwh8XcfphjmL65MWBafcFH_4U3Qz=w426-h240-k-no"
      },
      {
        "name": "Color palette salon and makeup studio",
        "description": "Make-up artist. Rating: 4.2/5 from 5 reviews",
        "address": "9, 10, 4th Cross St, Chennai, Tamil Nadu",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20palette%20salon%20and%20makeup%20studio&query_place_id=ChIJg3ta3JtlUjoRfijQC4XZ-F8",
        "imageUrl": "https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=_-QTdFWTD4jRFOnnLM448Q&cb_client=search.gws-prod.gps&w=408&h=240&yaw=350.11487&pitch=0&thumbfov=100"
      }
    ],
    "description": "Chennai's color analysis services reflect the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Jakarta",
    "slug": "jakarta",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "myCOLOR ID studio",
        "description": "Consultant. Rating: 5/5 from 10 reviews",
        "address": "Jl. Pantai Mutiara No.1 blok G, RT.7/RW.16, North Jakarta City, Jakarta",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=myCOLOR%20ID%20studio&query_place_id=ChIJOXUwPQAdai4RAS30HDufKKg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3CE95I2i6z_Wn85PBhQlkYEmHmfwUl0ON49fg=w408-h306-k-no"
      },
      {
        "name": "Colourist",
        "description": "Beauty salon. Rating: 5/5 from 113 reviews",
        "address": "Kawasan District, Treasury Tower, Jl. Jend. Sudirman kav 52-53 No.8, South Jakarta City, Jakarta",
        "phone": "+62 887-0322-2119",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colourist&query_place_id=ChIJXXtiJTrxaS4R6RwXbK8MtXo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsP9cp_aEHl2A1GtLMNfkUOA6_ZwV4x-cRMURW=w408-h544-k-no"
      },
      {
        "name": "M Beauty & Academy",
        "description": "Beauty school. Rating: 5/5 from 412 reviews",
        "address": "Citywalk Sudirman Jl. K.H. Mas Mansyur No.10, RT.2/RW.6, Central Jakarta City, Jakarta",
        "phone": "+62 822-1005-0017",
        "website": "https://www.instagram.com/m_beauty_academy/",
        "url": "https://www.google.com/maps/search/?api=1&query=M%20Beauty%20%26%20Academy&query_place_id=ChIJ1VgHurX3aS4Rb5PC0HrxejI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPZsI-VTiJuymK1V-pwyEXsovYipvcgA8VLhigx=w426-h240-k-no"
      }
    ],
    "description": "Jakarta's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Surabaya",
    "slug": "surabaya",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Maison Kolori - Personal Color Analysis",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "Milieu Space 2F, Jl. Imam Bonjol No.27, Surabaya, East Java",
        "phone": "+62 813-9070-9800",
        "website": "https://www.maisonkolori.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Maison%20Kolori%20-%20Personal%20Color%20Analysis&query_place_id=ChIJcaISt3771y0Ra4VBKHHj1MU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOQF-AMdahGrx5oi4LiOz8RUprNQIC4cHDhM3DA=w408-h306-k-no"
      },
      {
        "name": "Saekjo Studios - Personal Color Surabaya",
        "description": "Beauty Salon. Rating: 5/5 from 78 reviews",
        "address": "Jl. Mayjend. Jonosewojo No.Kav.3, Surabaya, East Java",
        "phone": "",
        "website": "https://www.instagram.com/saekjostudios/",
        "url": "https://www.google.com/maps/search/?api=1&query=Saekjo%20Studios%20-%20Personal%20Color%20Surabaya&query_place_id=ChIJJfxtWdj91y0Rsv49uSRrk2E",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMd3WuV23GxmMKNr9zu7wecrVAv6hxE9oclYEL8=w408-h544-k-no"
      },
      {
        "name": "SKINDA Aesthetic Clinic",
        "description": "Skin care clinic. Rating: 4.9/5 from 347 reviews",
        "address": "Ruko Surya Inti Permata, Jl. Mayjen HR. Muhammad No.177d, Surabaya, East Java",
        "phone": "+62 31 99142098",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=SKINDA%20Aesthetic%20Clinic&query_place_id=ChIJ-fAqVg_91y0R4Au1rzWxJF4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMrnSDHWr9YFPOYbHlpdvUwA1wD-4_N_K4pdhk=w408-h544-k-no"
      }
    ],
    "description": "Surabaya's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Bandung",
    "slug": "bandung",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Color Harmony - Personal Color and Beauty Consultant",
        "description": "Beauty Salon. Rating: 5/5 from 16 reviews",
        "address": "Jl. Indra Prahasta Bar. Komplek Singgasana Pradana No.17, Bandung City, West Java",
        "phone": "+62 811-2808-008",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Harmony%20-%20Personal%20Color%20and%20Beauty%20Consultant&query_place_id=ChIJc8BUYR_paC4R790bFr0a3Lw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO3rMOQ_CdQyhgpw5zwwMpz2nD347QIyvZPXJ9k=w408-h544-k-no"
      }
    ],
    "description": "Bandung's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Jakarta",
    "slug": "jakarta",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "myCOLOR ID studio",
        "description": "Consultant. Rating: 5/5 from 10 reviews",
        "address": "Jl. Pantai Mutiara No.1 blok G, RT.7/RW.16, North Jakarta City, Jakarta",
        "phone": "",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=myCOLOR%20ID%20studio&query_place_id=ChIJOXUwPQAdai4RAS30HDufKKg",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipP3CE95I2i6z_Wn85PBhQlkYEmHmfwUl0ON49fg=w408-h306-k-no"
      },
      {
        "name": "Colourist",
        "description": "Beauty salon. Rating: 5/5 from 113 reviews",
        "address": "Kawasan District, Treasury Tower, Jl. Jend. Sudirman kav 52-53 No.8, South Jakarta City, Jakarta",
        "phone": "+62 887-0322-2119",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Colourist&query_place_id=ChIJXXtiJTrxaS4R6RwXbK8MtXo",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPsP9cp_aEHl2A1GtLMNfkUOA6_ZwV4x-cRMURW=w408-h544-k-no"
      },
      {
        "name": "M Beauty & Academy",
        "description": "Beauty school. Rating: 5/5 from 412 reviews",
        "address": "Citywalk Sudirman Jl. K.H. Mas Mansyur No.10, RT.2/RW.6, Central Jakarta City, Jakarta",
        "phone": "+62 822-1005-0017",
        "website": "https://www.instagram.com/m_beauty_academy/",
        "url": "https://www.google.com/maps/search/?api=1&query=M%20Beauty%20%26%20Academy&query_place_id=ChIJ1VgHurX3aS4Rb5PC0HrxejI",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipPZsI-VTiJuymK1V-pwyEXsovYipvcgA8VLhigx=w426-h240-k-no"
      }
    ],
    "description": "Jakarta's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Surabaya",
    "slug": "surabaya",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Maison Kolori - Personal Color Analysis",
        "description": "Consultant. Rating: 5/5 from 1 reviews",
        "address": "Milieu Space 2F, Jl. Imam Bonjol No.27, Surabaya, East Java",
        "phone": "+62 813-9070-9800",
        "website": "https://www.maisonkolori.com/",
        "url": "https://www.google.com/maps/search/?api=1&query=Maison%20Kolori%20-%20Personal%20Color%20Analysis&query_place_id=ChIJcaISt3771y0Ra4VBKHHj1MU",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipOQF-AMdahGrx5oi4LiOz8RUprNQIC4cHDhM3DA=w408-h306-k-no"
      },
      {
        "name": "Saekjo Studios - Personal Color Surabaya",
        "description": "Beauty Salon. Rating: 5/5 from 78 reviews",
        "address": "Jl. Mayjend. Jonosewojo No.Kav.3, Surabaya, East Java",
        "phone": "",
        "website": "https://www.instagram.com/saekjostudios/",
        "url": "https://www.google.com/maps/search/?api=1&query=Saekjo%20Studios%20-%20Personal%20Color%20Surabaya&query_place_id=ChIJJfxtWdj91y0Rsv49uSRrk2E",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMd3WuV23GxmMKNr9zu7wecrVAv6hxE9oclYEL8=w408-h544-k-no"
      },
      {
        "name": "SKINDA Aesthetic Clinic",
        "description": "Skin care clinic. Rating: 4.9/5 from 347 reviews",
        "address": "Ruko Surya Inti Permata, Jl. Mayjen HR. Muhammad No.177d, Surabaya, East Java",
        "phone": "+62 31 99142098",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=SKINDA%20Aesthetic%20Clinic&query_place_id=ChIJ-fAqVg_91y0R4Au1rzWxJF4",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipMrnSDHWr9YFPOYbHlpdvUwA1wD-4_N_K4pdhk=w408-h544-k-no"
      }
    ],
    "description": "Surabaya's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  {
    "name": "Bandung",
    "slug": "bandung",
    "type": "city",
    "country": "Indonesia",
    "pricingRange": "$110 - $390",
    "businesses": [
      {
        "name": "Color Harmony - Personal Color and Beauty Consultant",
        "description": "Beauty Salon. Rating: 5/5 from 16 reviews",
        "address": "Jl. Indra Prahasta Bar. Komplek Singgasana Pradana No.17, Bandung City, West Java",
        "phone": "+62 811-2808-008",
        "website": "",
        "url": "https://www.google.com/maps/search/?api=1&query=Color%20Harmony%20-%20Personal%20Color%20and%20Beauty%20Consultant&query_place_id=ChIJc8BUYR_paC4R790bFr0a3Lw",
        "imageUrl": "https://lh5.googleusercontent.com/p/AF1QipO3rMOQ_CdQyhgpw5zwwMpz2nD347QIyvZPXJ9k=w408-h544-k-no"
      }
    ],
    "description": "Bandung's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  }
];