import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';
import { Stripe, ApplePayEventsEnum, PaymentSheetEventsEnum } from '@capacitor-community/stripe';

const translations = {
  en: {
    choosePlan: "Choose Your Color Analysis",
    selectPlan: "Start discovering your perfect color palette. Pay once, without subscriptions.",
    basicPlan: "Basic Plan",
    proPlan: "Pro Plan",
    enterprise: "Enterprise",
    custom: "Custom",
    oneOff: "One-off",
    choosePlanButton: "Choose Plan",
    contactUs: "Contact Us",
    colorAnalysisTest: "Color analysis test",
    tailoredPalettes: "tailored color palettes",
    picsInColors: "AI photos",
    playgroundCredits: "playground credits",
    goldSilverTest: "Gold or Silver test",
    tailoredSolutions: "Tailored color analysis solutions",
    bulkPricing: "Bulk pricing options",
    customisedFeatures: "Customised features",
    termsAgreement: "By proceeding, you agree to our",
    termsOfService: "Terms of Service",
    and: "and",
    privacyPolicy: "Privacy Policy",
    getBasic: "Get Basic",
    getPro: "Get Pro",
  },
  es: {
    choosePlan: "Elige tu Plan",
    selectPlan: "Selecciona un plan que se adapte a tus necesidades y comienza a descubrir tu paleta de colores perfecta.",
    basicPlan: "Plan Básico",
    proPlan: "Plan Pro",
    enterprise: "Empresarial",
    custom: "Personalizado",
    oneOff: "Único pago",
    choosePlanButton: "Elegir Plan",
    contactUs: "Contáctanos",
    colorAnalysisTest: "Prueba de análisis de color",
    tailoredPalettes: "paletas de colores personalizadas",
    picsInColors: "fotos de IA",
    playgroundCredits: "créditos para el playground",
    goldSilverTest: "Prueba Oro o Plata",
    tailoredSolutions: "Soluciones de análisis de color personalizadas",
    bulkPricing: "Opciones de precios por volumen",
    customisedFeatures: "Características personalizadas",
    termsAgreement: "Al continuar, aceptas nuestros",
    termsOfService: "Términos de Servicio",
    and: "y",
    privacyPolicy: "Política de Privacidad",
    getBasic: "Obtener Básico",
    getPro: "Obtener Pro",
  },
  pt: {
    choosePlan: "Escolha Seu Plano",
    selectPlan: "Selecione um plano que atenda às suas necessidades e comece a descobrir sua paleta de cores perfeita.",
    basicPlan: "Plano Básico",
    proPlan: "Plano Pro",
    enterprise: "Empresarial",
    custom: "Personalizado",
    oneOff: "Pagamento único",
    choosePlanButton: "Escolher Plano",
    contactUs: "Fale Conosco",
    colorAnalysisTest: "Teste de análise de cores",
    tailoredPalettes: "paletas de cores personalizadas",
    picsInColors: "fotos de IA",
    playgroundCredits: "créditos para o playground",
    goldSilverTest: "Teste Ouro ou Prata",
    tailoredSolutions: "Soluções de análise de cores personalizadas",
    bulkPricing: "Opções de preços em volume",
    customisedFeatures: "Recursos personalizados",
    termsAgreement: "Ao prosseguir, você concorda com nossos",
    termsOfService: "Termos de Serviço",
    and: "e",
    privacyPolicy: "Política de Privacidade",
    getBasic: "Obter Básico",
    getPro: "Obter Pro",
  },
};

const PaymentScreen = ({ language = 'en' }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const t = translations[language];
  const isNativeMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate(language === 'en' ? '/login' : `/${language}/login`);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
          { 
            token,
            appleToken: isNativeMobile ? localStorage.getItem('appleToken') : undefined
          }
        );
        if (!response.data.valid) {
          navigate(language === 'en' ? '/login' : `/${language}/login`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate(language === 'en' ? '/login' : `/${language}/login`);
      }
    };

    verifyToken();

    if (isNativeMobile) {
      Stripe.initialize({
        publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      });
    }
  }, [navigate, language, isNativeMobile]);

  const plans = [
    {
      id: 'basic',
      name: t.basicPlan,
      price: '$6.99',
      features: [
        t.colorAnalysisTest,
        `5 ${t.tailoredPalettes}`,
        t.goldSilverTest
      ]
    },
    {
      id: 'pro',
      name: t.proPlan,
      price: '$17.99',
      features: [
        t.colorAnalysisTest,
        `5 ${t.tailoredPalettes}`,
        `12 ${t.picsInColors}`,
        `150 ${t.playgroundCredits}`,
        t.goldSilverTest
      ]
    },
    {
      id: 'enterprise',
      name: t.enterprise,
      price: t.custom,
      features: [
        t.tailoredSolutions,
        t.bulkPricing,
        t.customisedFeatures
      ]
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null);

    const token = localStorage.getItem('token');
    const appleToken = isNativeMobile ? localStorage.getItem('appleToken') : undefined;

    if (!token && !appleToken) {
      navigate(language === 'en' ? '/login' : `/${language}/login`);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/payment-checkout`, {
        token,
        appleToken,
        plan: planId,
        platform: isNativeMobile ? 'mobile' : 'web'
      });

      if (isNativeMobile) {
        const { clientSecret, ephemeralKey, customerId, publishableKey, amount, currency } = response.data;

        if (!clientSecret || !ephemeralKey || !customerId || !publishableKey) {
          throw new Error('Invalid response from server');
        }

        // Initialize Stripe with the received publishable key
        await Stripe.initialize({
          publishableKey: publishableKey
        });

        // Check if Apple Pay is available
        let isApplePayAvailable = false;
        try {
          await Stripe.isApplePayAvailable();
          isApplePayAvailable = true;
          console.log('Apple Pay is available');
        } catch (error) {
          console.log('Apple Pay is not available:', error);
        }

        if (isApplePayAvailable) {
          try {
            await Stripe.createApplePay({
              paymentIntentClientSecret: clientSecret,
              paymentSummaryItems: [
                { label: 'Palette Hunt Plan', amount: amount / 100 }
              ],
              merchantIdentifier: process.env.REACT_APP_MERCHANT_ID,
              countryCode: 'US',
              currency: currency
            });

            // Add listener for Apple Pay events
            Stripe.addListener(ApplePayEventsEnum.Completed, () => {
              console.log('Apple Pay completed');
              navigate('/upload');
            });

            // Stripe.addListener(ApplePayEventsEnum.Canceled, () => {
            //   console.log('Apple Pay canceled');
            //   setError('Payment was cancelled. Please try again.');
            // });

            const result = await Stripe.presentApplePay();
            console.log('Apple Pay result:', result);

            // The result handling is now done in the event listeners above
          } catch (error) {
            console.error('Error presenting Apple Pay:', error);
            setError('Error presenting Apple Pay. Please try again.');
          }
        } else {
          // Fallback to regular Stripe payment sheet
          const initResult = await Stripe.createPaymentSheet({
            paymentIntentClientSecret: clientSecret,
            customerId: customerId,
            customerEphemeralKeySecret: ephemeralKey,
            merchantDisplayName: 'Palette Hunt',
          });

        console.log('Payment sheet initialized:', initResult);

        try {
          const { paymentResult } = await Stripe.presentPaymentSheet();
          console.log('Payment result:', paymentResult);
          Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
            console.log('Payment completed successfully');
            navigate('/upload');
          });
          if (paymentResult === 'paymentSheetCompleted') {
            console.log('Payment completed successfully');
            navigate('/upload');
          }
        } catch (presentError) {
          console.error('Error presenting payment sheet:', presentError);
          // setError('An error occurred while presenting the payment sheet. Please try again.');
        }
      }        
      } else {
        if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
        } else {
          throw new Error('Unexpected response from server');
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      if (isNativeMobile) {
        setError('An error occurred during payment. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <img src="/logo.png" alt="Loading" className="w-24 h-24 animate-pulse" />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-50' : ''} ${isNativeMobile ? 'text-gray-900' : 'text-gray-900'} min-h-screen flex flex-col items-center justify-center p-4`}>
      {!isNativeMobile && (
        <Link to={language === 'en' ? '/' : `/${language}`} className="mb-4">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-12 w-auto" />
        </Link>
      )}
      
      <h1 className={`text-2xl sm:text-4xl font-bold mb-2 text-center text-gray-900'}`}>
        {t.choosePlan}
      </h1>
      
      <p className={`${isNativeMobile ? 'text-base sm:text-2xl text-gray-700' : 'text-sm sm:text-xl text-gray-700'} mb-8 text-center max-w-xs sm:max-w-2xl`}>
        {t.selectPlan}
      </p>
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Basic Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.basicPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$6<span className="text-3xl">.99</span></span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 {t.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.goldSilverTest}</span>
                </li>
              </ul>
            </div>
            <button
              onClick={() => handlePlanSelection('basic')}
              disabled={isProcessing}
              className="w-full bg-blue-600 text-white rounded-full py-3 font-semibold hover:bg-blue-700 transition duration-300"
            >
              {isProcessing && selectedPlan === 'basic' ? (
                <ClipLoader color="#ffffff" size={20} />
              ) : (
                t.getBasic
              )}
            </button>
          </div>

          {/* Pro Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border-2 border-gray-900 p-8">
            <div className="absolute top-0 right-6 transform -translate-y-1/2">
              <span className="bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">Popular</span>
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.proPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$17<span className="text-3xl">.99</span></span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 {t.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">12 {t.picsInColors}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">150 {t.playgroundCredits}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.goldSilverTest}</span>
                </li>
              </ul>
            </div>
            <button
              onClick={() => handlePlanSelection('pro')}
              disabled={isProcessing}
              className="w-full bg-blue-600 text-white rounded-full py-3 font-semibold hover:bg-blue-700 transition duration-300"
            >
              {isProcessing && selectedPlan === 'pro' ? (
                <ClipLoader color="#ffffff" size={20} />
              ) : (
                t.getPro
              )}
            </button>
          </div>

          {/* Enterprise Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.enterprise}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">Custom</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.tailoredSolutions}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.bulkPricing}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.customisedFeatures}</span>
                </li>
              </ul>
            </div>
            <a
              href="mailto:hi@palettehunt.com"
              className="w-full bg-blue-600 text-white rounded-full py-3 font-semibold hover:bg-blue-700 transition duration-300 text-center"
            >
              {t.contactUs}
            </a>
          </div>
        </div>
      </div>
      
      {/* {!isNativeMobile && (
      <p className={`mt-4 ${isNativeMobile ? 'text-gray-700' : 'text-gray-400'} text-center max-w-xs sm:max-w-2xl text-xs sm:text-base`}>
        {t.termsAgreement} <Link to={language === 'en' ? '/terms-and-conditions' : `/${language}/terms-and-conditions`} className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>{t.termsOfService}</Link> {t.and} <Link to={language === 'en' ? '/privacy-policy' : `/${language}/privacy-policy`} className={`${isNativeMobile ? 'text-pink-500' : 'text-primary'} hover:underline`}>{t.privacyPolicy}</Link>.
      </p>
      )} */}
      
      {error && (
        <p className="text-red-500 mt-4 text-center text-sm">{error}</p>
      )}
    </div>
  );
};

export default PaymentScreen;