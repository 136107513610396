import React from 'react';
import { Link, useParams } from 'react-router-dom';

const translations = {
  en: {
    contact: "Contact",
    blog: "Blog",
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms & Conditions",
    allRightsReserved: "All rights reserved.",
  },
  es: {
    contact: "Contacto",
    blog: "Blog",
    privacyPolicy: "Política de Privacidad",
    termsConditions: "Términos y Condiciones",
    allRightsReserved: "Todos los derechos reservados.",
  },
  pt: {
    contact: "Contato",
    blog: "Blog",
    privacyPolicy: "Política de Privacidade",
    termsConditions: "Termos e Condições",
    allRightsReserved: "Todos os direitos reservados.",
  }
};

const Footer = () => {
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  const getLanguagePrefix = (lang) => {
    return lang === 'en' ? '' : `/${lang}`;
  };

  return (
    <footer className="bg-white text-gray-600 py-12 border-t border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-8 md:mb-0 text-center md:text-left">
            <Link to={getLanguagePrefix(language)} className="inline-block">
              <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 w-auto" />
            </Link>
            <p className="mt-4 text-sm text-gray-500">© 2024 Palette Hunt. {t.allRightsReserved}</p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-end gap-6 md:gap-8">
            <a 
              href="mailto:hi@palettehunt.com" 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              {t.contact}
            </a>
            <Link
              to={`${getLanguagePrefix(language)}/color-analysis-quiz`}
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              Color Analysis Quiz
            </Link>
            <Link 
              to={`${getLanguagePrefix(language)}/blog`} 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              {t.blog}
            </Link>
            <Link 
              to={`${getLanguagePrefix(language)}/privacy-policy`} 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              {t.privacyPolicy}
            </Link>
            <Link 
              to={`${getLanguagePrefix(language)}/terms-and-conditions`} 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              {t.termsConditions}
            </Link>
            <a 
              href="https://www.tiktok.com/@palettehunt" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              TikTok
            </a>
            <a 
              href="https://www.instagram.com/palettehunt_app" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-gray-600 hover:text-gray-900 transition duration-300 text-sm"
            >
              Instagram
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;