import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import { CheckCircle2, Globe, ChevronDown } from 'lucide-react';
import { Helmet } from 'react-helmet';

const translations = {
  en: {
    title: "Color Analysis Quiz",
    subtitle: "Take our quick quiz to get personalized color recommendations that will enhance your natural beauty.",
    getStarted: "Get Color Analyzed",
    loading: "Analyzing your perfect colors...",
    selfiesTitle: "All It Takes Is Some Selfies",
    selfiesSubtitle: "See your perfect colors come to life with our AI-powered analysis",
    getWebAnalysis: "Get Complete Color Analysis",
    getApp: "Get Color Analysis App",
    testimonials: {
      title: "What Our Users Say",
      items: [
        {
          text: "I never thought yellow colors would suit me, but seeing myself in them has changed my mind completely.",
          author: "Laura"
        },
        {
          text: "The AI-generated photos helped me visualize how different colors would actually look on me. It's amazing!",
          author: "Olympia"
        }
      ]
    },
    whyChooseTitle: "Why Choose Our Color Analysis?",
    features: [
      {
        title: "Professional Analysis",
        description: "Get expert color recommendations powered by advanced AI technology"
      },
      {
        title: "Instant Results",
        description: "Receive your personalized color palette in minutes"
      },
      {
        title: "Tailored Advice",
        description: "Get specific recommendations based on your unique features"
      }
    ],
    palettes: {
      warmSpring: "Warm Spring 🌸",
      warmAutumn: "Warm Autumn 🍁",
      softSummer: "Soft Summer ☀️",
      deepAutumn: "Deep Autumn 🍂"
    },
    previewTitle: "Your Color Analysis Quiz",
    quizTitle: "Your AI Color Analysis Quiz",
    quizSubtitle: "Discover your perfect colors with our AI-powered analysis",
    getFreeQuiz: "Get Free Quiz",
    getFullAnalysis: "Get Full Analysis",
    contact: "Contact",
    signIn: "Sign In",
    featuredOn: "Featured on",
    transformTitle: "Transform Your Look with AI",
    transformSubtitle: "Upload a few photos and get AI-powered color analysis in one hour",
    uploadPhotosTitle: "Upload Your Photos",
    uploadPhotosDesc: "Upload your best photos for your color analysis. Our AI will process your unique features.",
    getColorsTitle: "Get Your Colors",
    getColorsDesc: "Receive your personalized color palette based on your unique features, skin tone, and undertones.",
    seeResultsTitle: "See the Results",
    seeResultsDesc: "View yourself transformed in your perfect colors with our advanced AI technology. Experience the difference.",
    colorAnalysis: {
      title: "Finally Understand What Colors Suit You",
      subtitle: "Our AI-powered color analysis provides detailed explanations and shows you transformed in your perfect colors using advanced AI-generated photos.",
      features: [
        {
          title: "Personalized Analysis",
          description: "Get a detailed analysis of your unique coloring and discover your perfect palette."
        },
        {
          title: "AI Transformations",
          description: "See yourself transformed with AI-powered visualizations in your ideal colors."
        },
        {
          title: "Expert Recommendations",
          description: "Receive the most advanced guidance and practical tips for using your color palette."
        }
      ]
    },
    playground: {
      title: "Your Style, Reinvented",
      description1: "Step into our virtual try-on studio where imagination knows no bounds. See yourself in as many looks and colors as you want.",
      description2: "Create images of yourself in styles you never thought you could wear."
    },
    pricing: {
      title: "Choose Your Color Analysis Plan",
      choosePlan: "Choose Your Color Analysis",
      selectPlan: "Start discovering your perfect color palette. Pay once, without subscriptions.",
      basicPlan: "Basic Plan",
      proPlan: "Pro Plan",
      enterprise: "Enterprise",
      custom: "Custom",
      oneOff: "One-off",
      getStarted: "Get Started",
      getPro: "Get Pro",
      contactUs: "Contact Us",
      colorAnalysisTest: "Color analysis test",
      tailoredPalettes: "tailored color palettes",
      picsInColors: "AI photos",
      playgroundCredits: "playground credits",
      goldSilverTest: "Gold or Silver test",
      tailoredSolutions: "Tailored color analysis solutions",
      bulkPricing: "Bulk pricing options",
      customisedFeatures: "Customised features",
      popular: "Popular"
    },
    faqTitle: "Frequently Asked Questions",
    haveQuestion: "Have another question? Contact me on",
    faqs: [
      {
        question: "How does Palette Hunt work?",
        answer: "We use the most advanced AI vision algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences."
      },
      {
        question: "How long does it take to get results?",
        answer: "You will typically receive your results within 1h. If the results are taking more than 2h, please send us an email at hi@palettehunt.com"
      },
      {
        question: "Will the quality of the results be good?",
        answer: "Yes, we can assure you that the quality of the results will be the best you can get in the market. But you need to provide us with good quality photos to maximize the quality of the results."
      },
      {
        question: "Do I need to upload many pictures?",
        answer: "For optimal results, we recommend uploading 8-10 photos that showcase different angles, lighting conditions, and outfits. This will determine the quality of the results!"
      },
      {
        question: "Can I keep the generated images?",
        answer: "Absolutely! You own all the images generated by Palette Hunt. Both for the playground and for the color analysis."
      },
      {
        question: "Can I use Palette Hunt on mobile devices?",
        answer: "Yes, no problem! Palette Hunt works in either mobile or desktop. Feel free to use the device you are most comfortable with!"
      },
      {
        question: "How accurate are the color recommendations?",
        answer: "Our color recommendation process has been thoroughly tested to make sure our results are the best you can get. Our satisfaction rate is extremely high among our users."
      },
      {
        question: "Which type of photos will I get?",
        answer: "For each color palette you get, you will get 2 images of you in that color palette. One will have a white background, like in a studio, and one will have a background that matches the color theme."
      },
      {
        question: "What happens if I don't like the results?",
        answer: "Quality is our top priority. At this time, we cannot offer full refunds as every color analysis means a signficant cost per user. We are sure though, we will find a solution for you. We are always available at hi@palettehunt.com"
      },
      {
        question: "Will my photos be shared with anyone?",
        answer: "No. We just use the photos to train our AI that no one else has access to. Every uploaded picture you upload gets deleted after a week."
      }
    ],
    quiz: {
      previous: "Previous",
      next: "Next",
      getResults: "Get Your Results",
      joinCustomers: "Join thousands of satisfied customers!",
      stepProgress: "Step {current} of {total}",
      seeColorsLive: "See your perfect colors come to life with AI"
    },
    discover: "Color Analysis",
    yourPerfect: "With AI Try-On",
    colorPalette: "Photos of You",
    findYourShades: "Find your most flattering shades with our AI-powered color analysis. Upload your selfies and see yourself transformed in your perfect colors with stunning AI photos.",
    imagesCreated: "+15,000 images created",
  },
  es: {
    title: "Test de Colorimetría",
    subtitle: "Realiza nuestro breve test para obtener recomendaciones de color personalizadas que resaltarán tu belleza natural.",
    getStarted: "Analizar Mis Colores",
    loading: "Analizando tus colores perfectos...",
    selfiesTitle: "Solo Necesitas Unos Selfies",
    selfiesSubtitle: "Ve tus colores perfectos cobrar vida con nuestro análisis impulsado por IA",
    getWebAnalysis: "Obtener Análisis Completo",
    getApp: "Obtener Análisis en la App",
    testimonials: {
      title: "Lo Que Dicen Nuestros Usuarios",
      items: [
        {
          text: "Nunca pensé que los colores amarillos me quedarían bien, pero verme en ellos ha cambiado completamente mi opinión.",
          author: "Laura"
        },
        {
          text: "Las fotos generadas por IA me ayudaron a visualizar cómo me quedarían realmente los diferentes colores. ¡Es increíble!",
          author: "Olympia"
        }
      ]
    },
    whyChooseTitle: "¿Por qué Elegir Nuestro Análisis de Color?",
    features: [
      {
        title: "Análisis Profesional",
        description: "Obtén recomendaciones de color expertas impulsadas por IA avanzada"
      },
      {
        title: "Resultados Instantáneos",
        description: "Recibe tu paleta de colores personalizada en minutos"
      },
      {
        title: "Consejos Personalizados",
        description: "Obtén recomendaciones específicas basadas en tus características únicas"
      }
    ],
    palettes: {
      warmSpring: "Primavera Cálida 🌸",
      warmAutumn: "Otoño Cálido 🍁",
      softSummer: "Verano Suave ☀️",
      deepAutumn: "Otoño Profundo 🍂"
    },
    previewTitle: "Tu test de colorimetría",
    quizTitle: "Test de Colorimetría con IA",
    quizSubtitle: "Descubre tus colores perfectos con nuestro análisis impulsado por IA",
    getFreeQuiz: "Obtener Test Gratuito",
    getFullAnalysis: "Obtener Análisis Completo",
    contact: "Contacto",
    signIn: "Iniciar sesión",
    featuredOn: "Destacado en",
    transformTitle: "Transforma tu Look con IA",
    transformSubtitle: "Sube algunas fotos y obtén un análisis de color impulsado por IA en una hora",
    uploadPhotosTitle: "Sube tus Fotos",
    uploadPhotosDesc: "Sube tus mejores fotos para tu análisis de color. Nuestra IA procesará tus características únicas.",
    getColorsTitle: "Obtén tus Colores",
    getColorsDesc: "Recibe tu paleta de colores personalizada basada en tus características únicas, tono de piel y subtono.",
    seeResultsTitle: "Ve los Resultados",
    seeResultsDesc: "Mira tu transformación en tus colores perfectos con nuestra tecnología avanzada de IA. Experimenta la diferencia.",
    colorAnalysis: {
      title: "Finalmente Entiende Qué Colores Te Favorecen",
      subtitle: "Nuestro análisis de color impulsado por IA proporciona explicaciones detalladas y te muestra transformado en tus colores perfectos usando fotos generadas por IA.",
      features: [
        {
          title: "Análisis Personalizado",
          description: "Obtén un análisis detallado de tus características únicas y descubre tu paleta perfecta."
        },
        {
          title: "Transformaciones con IA",
          description: "Mira tu transformación con visualizaciones impulsadas por IA en tus colores ideales."
        },
        {
          title: "Recomendaciones Expertas",
          description: "Recibe la guía más avanzada y consejos prácticos para usar tu paleta de colores."
        }
      ]
    },
    playground: {
      title: "Tu Estilo, Reinventado",
      description1: "Entra en nuestro estudio virtual de pruebas donde la imaginación no tiene límites. Mírate con todos los looks y colores que quieras.",
      description2: "Crea imágenes de ti mismo en estilos que nunca pensaste que podrías usar."
    },
    pricing: {
      title: "Elige tu Plan de Análisis de Color",
      choosePlan: "Elige tu Plan",
      selectPlan: "Selecciona un plan que se adapte a tus necesidades y comienza a descubrir tu paleta de colores perfecta.",
      basicPlan: "Plan Básico",
      proPlan: "Plan Pro",
      enterprise: "Empresarial",
      custom: "Personalizado",
      oneOff: "Único pago",
      getStarted: "Empezar",
      getPro: "Obtener Pro",
      contactUs: "Contáctanos",
      colorAnalysisTest: "Prueba de análisis de color",
      tailoredPalettes: "paletas de colores personalizadas",
      picsInColors: "fotos de IA",
      playgroundCredits: "créditos para el playground",
      goldSilverTest: "Prueba Oro o Plata",
      tailoredSolutions: "Soluciones de análisis de color personalizadas",
      bulkPricing: "Opciones de precios por volumen",
      customisedFeatures: "Características personalizadas",
      popular: "Popular"
    },
    faqTitle: "Preguntas Frecuentes",
    haveQuestion: "¿Tienes otra pregunta? Contáctame en",
    faqs: [
      {
        question: "¿Cómo funciona Palette Hunt?",
        answer: "Utilizamos los algoritmos de visión por IA más avanzados para analizar tus fotos y generar paletas de colores personalizadas que complementan tus características únicas y preferencias de estilo."
      },
      {
        question: "¿Cuánto tiempo tarda en dar resultados?",
        answer: "Normalmente recibirás tus resultados en 1 hora. Si los resultados tardan más de 2 horas, envíanos un email a hi@palettehunt.com"
      },
      {
        question: "¿La calidad de los resultados será buena?",
        answer: "Sí, podemos asegurarte que la calidad de los resultados será la mejor que puedes obtener en el mercado. Pero necesitas proporcionarnos fotos de buena calidad para maximizar la calidad de los resultados."
      },
      {
        question: "¿Necesito subir muchas fotos?",
        answer: "Para obtener resultados óptimos, recomendamos subir 8-10 fotos que muestren diferentes ángulos, condiciones de iluminación y atuendos. ¡Esto determinará la calidad de los resultados!"
      },
      {
        question: "¿Puedo conservar las imágenes generadas?",
        answer: "¡Absolutamente! Eres dueño de todas las imágenes generadas por Palette Hunt. Tanto para el probador virtual como para el análisis de color."
      },
      {
        question: "¿Puedo usar Palette Hunt en dispositivos móviles?",
        answer: "¡Sí, sin problema! Palette Hunt funciona tanto en móvil como en escritorio. ¡Siéntete libre de usar el dispositivo con el que te sientas más cómodo!"
      },
      {
        question: "¿Qué tan precisas son las recomendaciones de color?",
        answer: "Nuestro proceso de recomendación de color ha sido probado exhaustivamente para asegurarnos de que nuestros resultados sean los mejores que puedas obtener. Nuestra tasa de satisfacción es extremadamente alta entre nuestros usuarios."
      },
      {
        question: "¿Qué tipo de fotos recibirás?",
        answer: "Por cada paleta de colores que recibas, obtendrás 2 imágenes tuyas en esa paleta de colores. Una tendrá un fondo blanco, como en un estudio, y otra tendrá un fondo que coincida con el tema de color."
      },
      {
        question: "¿Qué pasa si no me gustan los resultados?",
        answer: "La calidad es nuestra principal prioridad. En este momento, no podemos ofrecer reembolsos completos, ya que cada análisis de color significa un costo significativo por usuario. Sin embargo, estamos seguros de que encontraremos una solución para ti. Estamos siempre disponibles en hi@palettehunt.com"
      },
      {
        question: "��Mis fotos serán compartidas con alguien?",
        answer: "No. Solo usamos las fotos para entrenar nuestra IA, a la cual nadie más tiene acceso. Cada foto que envías es eliminada después de una semana."
      }
    ],
    quiz: {
      previous: "Anterior",
      next: "Siguiente",
      getResults: "Obtener Resultados",
      joinCustomers: "¡Únete a miles de clientes satisfechos!",
      stepProgress: "Paso {current} de {total}",
      seeColorsLive: "Ve tus colores perfectos cobrar vida con IA"
    },
    discover: "Descubre",
    yourPerfect: "Tu Perfecta",
    colorPalette: "Paleta de Colores",
    findYourShades: "Encuentra tus tonos más favorecedores con nuestro análisis de color impulsado por IA. Sube tus fotos para verte transformado en tus colores perfectos.",
    imagesCreated: "+15,000 imágenes creadas",
  },
  pt: {
    title: "Análise de Cores",
    subtitle: "Faça nosso teste rápido para obter recomendações de cores personalizadas que realçarão sua beleza natural.",
    getStarted: "Analisar Minhas Cores",
    loading: "Analisando suas cores perfeitas...",
    selfiesTitle: "Tudo o que Precisa são Selfies",
    selfiesSubtitle: "Veja suas cores perfeitas ganharem vida com nossa análise alimentada por IA",
    getWebAnalysis: "Obter Análise Completa",
    getApp: "Obter App de Análise",
    testimonials: {
      title: "O Que Nossos Usuários Dizem",
      items: [
        {
          text: "Nunca pensei que as cores amarelas me cairiam bem, mas me ver nelas mudou completamente minha opinião.",
          author: "Laura"
        },
        {
          text: "As fotos geradas por IA me ajudaram a visualizar como diferentes cores ficariam em mim. É incrível!",
          author: "Olympia"
        }
      ]
    },
    whyChooseTitle: "Por que Escolher Nossa Análise de Cores?",
    features: [
      {
        title: "Análise Profissional",
        description: "Obtenha recomendações de cores especializadas alimentadas por IA avançada"
      },
      {
        title: "Resultados Instantâneos",
        description: "Receba sua paleta de cores personalizada em minutos"
      },
      {
        title: "Conselhos Personalizados",
        description: "Obtenha recomendações específicas baseadas em suas características únicas"
      }
    ],
    palettes: {
      warmSpring: "Primavera Quente 🌸",
      warmAutumn: "Outono Quente 🍁",
      softSummer: "Verão Suave ☀️",
      deepAutumn: "Outono Profundo 🍂"
    },
    previewTitle: "O teste de colorimetría",
    quizTitle: "Teste de Análise de Cores com IA",
    quizSubtitle: "Descubra suas cores perfeitas com nossa análise impulsionada por IA",
    getFreeQuiz: "Obter Teste Gratuito",
    getFullAnalysis: "Obter Análise Completo",
    contact: "Contato",
    signIn: "Entrar",
    featuredOn: "Destaque em",
    transformTitle: "Transforme seu Look com IA",
    transformSubtitle: "Envie algumas fotos e obtenha análise de cores com IA em uma hora",
    uploadPhotosTitle: "Envie suas Fotos",
    uploadPhotosDesc: "Envie suas melhores fotos para sua análise de cores. Nossa IA processará suas características únicas.",
    getColorsTitle: "Obtenha suas Cores",
    getColorsDesc: "Receba sua paleta de cores personalizada com base em suas características únicas, tom de pele e subtom.",
    seeResultsTitle: "Veja os Resultados",
    seeResultsDesc: "Veja-se transformado em suas cores perfeitas com nossa tecnologia avançada de IA. Experimente a diferença.",
    colorAnalysis: {
      title: "Finalmente Entenda Quais Cores Combinam com Você",
      subtitle: "Nossa análise de cores com IA fornece explicações detalhadas e mostra você transformado em suas cores perfeitas usando fotos geradas por IA.",
      features: [
        {
          title: "Análise Personalizada",
          description: "Obtenha uma análise detalhada de suas características únicas e descubra sua paleta perfeita."
        },
        {
          title: "Transformações com IA",
          description: "Veja-se transformado com visualizações alimentadas por IA em suas cores ideais."
        },
        {
          title: "Recomendações Especializadas",
          description: "Receba a orientação mais avançada e dicas práticas para usar sua paleta de cores."
        }
      ]
    },
    playground: {
      title: "Seu Estilo, Reinventado",
      description1: "Entre em nosso estúdio virtual de provas onde a imaginação não tem limites. Veja-se em quantos looks e cores quiser.",
      description2: "Crie imagens suas em estilos que você nunca pensou que poderia usar."
    },
    pricing: {
      title: "Escolha Seu Plano de Análise de Cores",
      choosePlan: "Escolha Seu Plano",
      selectPlan: "Selecione um plano que atenda às suas necessidades e comece a descobrir sua paleta de cores perfeita.",
      basicPlan: "Plano Básico",
      proPlan: "Plano Pro",
      enterprise: "Empresarial",
      custom: "Personalizado",
      oneOff: "Pagamento único",
      getStarted: "Começar",
      getPro: "Obter Pro",
      contactUs: "Fale Conosco",
      colorAnalysisTest: "Teste de análise de cores",
      tailoredPalettes: "paletas de cores personalizadas",
      picsInColors: "fotos de IA",
      playgroundCredits: "créditos para o playground",
      goldSilverTest: "Teste Ouro ou Prata",
      tailoredSolutions: "Soluções de análise de cores personalizadas",
      bulkPricing: "Opções de preços em volume",
      customisedFeatures: "Recursos personalizados",
      popular: "Popular"
    },
    faqTitle: "Perguntas Frequentes",
    haveQuestion: "Tem outra pergunta? Fale comigo no",
    faqs: [
      {
        question: "Como funciona o Palette Hunt?",
        answer: "Utilizamos os algoritmos de visão por IA mais avançados para analisar suas fotos e gerar paletas de cores personalizadas que complementam suas características únicas e preferências de estilo."
      },
      {
        question: "Quanto tempo leva para dar resultados?",
        answer: "Normalmente você receberá seus resultados em 1 hora. Se os resultados demorarem mais de 2 horas, envie um email para hi@palettehunt.com"
      },
      {
        question: "A qualidade dos resultados será boa?",
        answer: "Sim, podemos garantir que a qualidade dos resultados será a melhor que você pode obter no mercado. Mas você precisa nos fornecer fotos de boa qualidade para maximizar a qualidade dos resultados."
      },
      {
        question: "Preciso enviar muitas fotos?",
        answer: "Para resultados ideais, recomendamos enviar 8-10 fotos que mostrem diferentes ângulos, condições de iluminação e roupas. Isso determinará a qualidade dos resultados!"
      },
      {
        question: "Posso manter as imagens geradas?",
        answer: "Absolutamente! Você é proprietário de todas as imagens geradas pelo Palette Hunt. Tanto para o playground quanto para a análise de cores."
      },
      {
        question: "Posso usar o Palette Hunt em dispositivos móveis?",
        answer: "Sim, sem problema! O Palette Hunt funciona tanto em dispositivos móveis quanto em desktop. Sinta-se à vontade para usar o dispositivo com o qual você se sente mais confortável!"
      },
      {
        question: "Qual a precisão das recomendações de cores?",
        answer: "Nosso processo de recomendação de cores foi testado exaustivamente para garantir que nossos resultados sejam os melhores que você pode obter. Nossa taxa de satisfação é extremamente alta entre nossos usuários."
      },
      {
        question: "Que tipo de fotos vou receber?",
        answer: "Para cada paleta de cores que você receber, você terá 2 imagens suas nessa paleta de cores. Uma terá um fundo branco, como em um estúdio, e outra terá um fundo que combina com o tema de cores."
      },
      {
        question: "O que acontece se eu não gostar dos resultados?",
        answer: "A qualidade é nossa principal prioridade. No momento, não podemos oferecer reembolsos completos, pois cada análise de cores significa um custo significativo por usuário. No entanto, temos certeza de que encontraremos uma solução para você. Estamos sempre disponíveis em hi@palettehunt.com"
      },
      {
        question: "Minhas fotos serão compartilhadas com alguém?",
        answer: "Não. Apenas usamos as fotos para treinar nossa IA, à qual ninguém mais tem acesso. Cada foto que você envia é excluída após uma semana."
      }
    ],
    quiz: {
      previous: "Anterior",
      next: "Próximo",
      getResults: "Ver Resultados",
      joinCustomers: "Junte-se a milhares de clientes satisfeitos!",
      stepProgress: "Passo {current} de {total}",
      seeColorsLive: "Veja suas cores perfeitas ganharem vida com IA"
    },
    discover: "Descubra",
    yourPerfect: "Sua Perfeita",
    colorPalette: "Paleta de Cores",
    findYourShades: "Encontre seus tons mais favoráveis com nossa análise de cores impulsionada por IA. Faça o upload de suas fotos para se ver transformado em suas cores perfeitas.",
    imagesCreated: "+15.000 imagens criadas",
  }
};

const questionTranslations = {
  en: [
    {
      id: 1,
      title: "Let's start with your skin tone",
      question: "How would you describe your natural skin tone?",
      options: [
        { id: 'fair', text: 'Fair - Burns easily, rarely tans' },
        { id: 'light', text: 'Light - Sometimes burns, gradually tans' },
        { id: 'medium', text: 'Medium - Rarely burns, easily tans' },
        { id: 'olive', text: 'Olive - Never burns, always tans' },
        { id: 'dark', text: 'Dark - Naturally dark skin' },
      ]
    },
    {
      id: 2,
      title: "Now, about your eyes",
      question: "What color are your eyes?",
      options: [
        { id: 'blue', text: 'Blue' },
        { id: 'green', text: 'Green' },
        { id: 'hazel', text: 'Hazel' },
        { id: 'brown', text: 'Brown' },
        { id: 'gray', text: 'Gray' },
      ]
    },
    {
      id: 3,
      title: "Let's talk about your hair",
      question: "What's your natural hair color?",
      options: [
        { id: 'blonde', text: 'Blonde' },
        { id: 'brown', text: 'Brown' },
        { id: 'black', text: 'Black' },
        { id: 'red', text: 'Red' },
        { id: 'gray', text: 'Gray/Silver' },
      ]
    },
    {
      id: 4,
      title: "Your veins' color matters",
      question: "Look at the veins on your wrist. What color do they appear?",
      options: [
        { id: 'green', text: 'Mostly green' },
        { id: 'blue', text: 'Mostly blue' },
        { id: 'purple', text: 'Purple or mix of blue and green' },
      ]
    },
    {
      id: 5,
      title: "Last question: Jewelry preference",
      question: "Which jewelry tone looks best on you?",
      options: [
        { id: 'gold', text: 'Gold looks more flattering' },
        { id: 'silver', text: 'Silver looks more flattering' },
        { id: 'both', text: 'Both look equally good' },
      ]
    }
  ],
  es: [
    {
      id: 1,
      title: "Empecemos con tu tono de piel",
      question: "¿Cómo describirías tu tono de piel natural?",
      options: [
        { id: 'fair', text: 'Clara - Se quema fácilmente, rara vez se broncea' },
        { id: 'light', text: 'Clara media - A veces se quema, se broncea gradualmente' },
        { id: 'medium', text: 'Media - Rara vez se quema, se broncea fácilmente' },
        { id: 'olive', text: 'Oliva - Nunca se quema, siempre se broncea' },
        { id: 'dark', text: 'Oscura - Piel naturalmente oscura' },
      ]
    },
    {
      id: 2,
      title: "Ahora, sobre tus ojos",
      question: "¿De qué color son tus ojos?",
      options: [
        { id: 'blue', text: 'Azules' },
        { id: 'green', text: 'Verdes' },
        { id: 'hazel', text: 'Avellana' },
        { id: 'brown', text: 'Marrones' },
        { id: 'gray', text: 'Grises' },
      ]
    },
    {
      id: 3,
      title: "Hablemos de tu cabello",
      question: "¿Cuál es tu color natural de cabello?",
      options: [
        { id: 'blonde', text: 'Rubio' },
        { id: 'brown', text: 'Castaño' },
        { id: 'black', text: 'Negro' },
        { id: 'red', text: 'Pelirrojo' },
        { id: 'gray', text: 'Gris/Plateado' },
      ]
    },
    {
      id: 4,
      title: "El color de tus venas es importante",
      question: "Mira las venas de tu muñeca. ¿De qué color parecen?",
      options: [
        { id: 'green', text: 'Mayormente verdes' },
        { id: 'blue', text: 'Mayormente azules' },
        { id: 'purple', text: 'Moradas o mezcla de azul y verde' },
      ]
    },
    {
      id: 5,
      title: "Última pregunta: Preferencia de joyería",
      question: "¿Qué tono de joyería te queda mejor?",
      options: [
        { id: 'gold', text: 'El dorado me favorece más' },
        { id: 'silver', text: 'El plateado me favorece más' },
        { id: 'both', text: 'Ambos me quedan igual de bien' },
      ]
    }
  ],
  pt: [
    {
      id: 1,
      title: "Vamos começar com seu tom de pele",
      question: "Como você descreveria seu tom de pele natural?",
      options: [
        { id: 'fair', text: 'Clara - Queima facilmente, raramente bronzeia' },
        { id: 'light', text: 'Clara média - Às vezes queima, bronzeia gradualmente' },
        { id: 'medium', text: 'Média - Raramente queima, bronzeia facilmente' },
        { id: 'olive', text: 'Oliva - Nunca queima, sempre bronzeia' },
        { id: 'dark', text: 'Escura - Pele naturalmente escura' },
      ]
    },
    {
      id: 2,
      title: "Agora, sobre seus olhos",
      question: "Qual é a cor dos seus olhos?",
      options: [
        { id: 'blue', text: 'Azuis' },
        { id: 'green', text: 'Verdes' },
        { id: 'hazel', text: 'Avelã' },
        { id: 'brown', text: 'Castanhos' },
        { id: 'gray', text: 'Cinzas' },
      ]
    },
    {
      id: 3,
      title: "Vamos falar do seu cabelo",
      question: "Qual é a sua cor natural de cabelo?",
      options: [
        { id: 'blonde', text: 'Loiro' },
        { id: 'brown', text: 'Castanho' },
        { id: 'black', text: 'Preto' },
        { id: 'red', text: 'Ruivo' },
        { id: 'gray', text: 'Grisalho/Prateado' },
      ]
    },
    {
      id: 4,
      title: "A cor das suas veias é importante",
      question: "Olhe para as veias do seu pulso. De que cor elas parecem?",
      options: [
        { id: 'green', text: 'Principalmente verdes' },
        { id: 'blue', text: 'Principalmente azuis' },
        { id: 'purple', text: 'Roxas ou mistura de azul e verde' },
      ]
    },
    {
      id: 5,
      title: "Última pergunta: Preferência de joias",
      question: "Qual tom de joias fica melhor em você?",
      options: [
        { id: 'gold', text: 'Dourado fica mais bonito' },
        { id: 'silver', text: 'Prateado fica mais bonito' },
        { id: 'both', text: 'Ambos ficam igualmente bem' },
      ]
    }
  ]
};

const ImageGrid = ({ t, language }) => (
  <section className="pt-24 pb-12 bg-white">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">
        {t.previewTitle}
      </h2>
      <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-8">
        {language === 'es' ? (
          "No solo leas sobre colores que podrían quedarte bien - vélos en acción en tu modelo personalizado de IA. Nuestro análisis de color completo te muestra exactamente cómo se ve tu paleta perfecta en ti. Todo lo que necesitas son algunas selfies."
        ) : language === 'pt' ? (
          "Não apenas leia sobre cores que poderiam ficar bem em você - veja-as em ação no seu modelo personalizado de IA. Nossa análise de cores completa mostra exatamente como sua paleta perfeita fica em você. Tudo que você precisa são algumas selfies."
        ) : (
          "Don't just read about colors that might suit you - see them in action on your personalized AI model. Our comprehensive color analysis shows you exactly how your perfect palette looks on you. All it takes is some selfies."
        )}
      </p>
      <div className="flex flex-col md:flex-row justify-between items-center">
        {/* Left Column */}
        <div className="w-full md:w-[48%] mb-8 md:mb-0">
          <div className="relative">
            {/* Top row of small images */}
            <div className="flex space-x-3 mb-2">
              <img 
                src={`/grid1.png`} 
                alt="Grid 1" 
                className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
              />
            </div>
            {/* Large images */}
            <div className="flex space-x-4">
              <div className="relative w-1/2">
                <img 
                  src="/laura-large-2.png" 
                  alt="Laurita" 
                  className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                />
                <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                <div className="mt-4 text-center">
                  <p className="text-gray-900 text-lg mb-3"><strong>{t.palettes.warmSpring}</strong></p>
                  <div className="flex justify-center space-x-1">
                    <div className="w-6 h-6 rounded-full bg-[#FF7F50]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#FA8072]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#228B22]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#2E8B57]"></div>
                  </div>
                </div>
              </div>
              <div className="relative w-1/2">
                <img 
                  src="/orange-2.jpg" 
                  alt="Sunny Yellow" 
                  className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                />
                <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                <div className="mt-4 text-center">
                  <p className="text-gray-900 text-lg mb-3"><strong>{t.palettes.warmAutumn}</strong></p>
                  <div className="flex justify-center space-x-1">
                    <div className="w-6 h-6 rounded-full bg-[#E57373]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#DDAA33]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#556B2F]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="w-full md:w-[48%]">
          <div className="relative">
            {/* Top row of small images */}
            <div className="flex space-x-3 mb-2 justify-end">
              <img 
                src="/grid2.png" 
                alt="Grid 2" 
                className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
              />
            </div>
            {/* Large images */}
            <div className="flex space-x-4">
              <div className="relative w-1/2">
                <img 
                  src="/olympia_large_21.png" 
                  alt="Olympia AI 2" 
                  className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                />
                <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                <div className="mt-4 text-center">
                  <p className="text-gray-900 text-lg mb-3"><strong>{t.palettes.softSummer}</strong></p>
                  <div className="flex justify-center space-x-1">
                    <div className="w-6 h-6 rounded-full bg-[#8B8589]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#778899]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#B8A6B3]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#9AA3A8]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#A7A69D]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#8C8C8C]"></div>
                  </div>
                </div>
              </div>
              <div className="relative w-1/2">
                <img 
                  src="/olympia_ai_3.png" 
                  alt="Olympia Transform 1" 
                  className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                />
                <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                <div className="mt-4 text-center">
                  <p className="text-gray-900 text-lg mb-3"><strong>{t.palettes.deepAutumn}</strong></p>
                  <div className="flex justify-center space-x-1">
                    <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#A0522D]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#D2691E]"></div>
                    <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const LogoCarousel = ({ t }) => {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-center text-gray-600 text-lg mb-4 font-bold">{t.featuredOn}</p>
        <div className="relative max-w-2xl mx-auto">
          {/* Sliding container */}
          <div className="overflow-hidden">
            <div 
              className="flex animate-scroll"
              style={{
                whiteSpace: 'nowrap',
                width: 'fit-content'
              }}
            >
              {/* Four copies of logos for smoother loop */}
              {[...Array(4)].map((_, index) => (
                <div 
                  key={index} 
                  className="flex items-center space-x-16 px-8"
                  style={{
                    minWidth: 'max-content'
                  }}
                >
                  <img src="/producthunt.png" alt="Product Hunt" className="h-20 object-contain" />
                  <img src="/toolify.webp" alt="Toolify" className="h-6 object-contain" />
                  <img src="/aipure.webp" alt="AI Pure" className="h-6 object-contain" />
                  <img src="/appstore.png" alt="App Store" className="h-6 object-contain" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animate-scroll {
          animation: scroll 40s linear infinite;
        }
        
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
      `}</style>
    </div>
  );
};

const ColorAnalysisSection = ({ t }) => {
  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 py-24">
            {t.colorAnalysis.title}
          </h2>
          <p className="text-gray-600 text-xl max-w-2xl mx-auto">
            {t.colorAnalysis.subtitle}
          </p>
        </div>

        {/* Image Carousel */}
        <div className="relative max-w-4xl mx-auto mb-16">
          {/* Gradient overlays for fade effect */}
          <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10"></div>
          
          <div className="overflow-hidden">
            <div className="flex animate-carousel">
              {[...Array(2)].map((_, setIndex) => (
                <div key={setIndex} className="flex space-x-8 flex-none">
                  {[...Array(9)].map((_, index) => (
                    <div key={`${setIndex}-${index}`} className="flex-none">
                      <img
                        src={`/carousel${index + 1}.jpeg`}
                        alt={`Color Analysis Example ${index + 1}`}
                        className="w-auto h-auto max-h-[300px] rounded-xl shadow-lg"
                      />
                    </div>
                  ))}
                  {/* Add spacing after each set */}
                  <div className="w-8 flex-none"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {t.colorAnalysis.features.map((feature, index) => (
            <div key={index} className="text-center">
              <div className="mb-4">
                <div className="w-16 h-16 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                  <svg className="w-8 h-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    {index === 0 && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    )}
                    {index === 1 && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                    )}
                    {index === 2 && (
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                    )}
                  </svg>
                </div>
              </div>
              <h3 className="text-2xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        @keyframes carousel {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        
        .animate-carousel {
          animation: carousel 60s linear infinite;
        }
      `}</style>
    </section>
  );
};

const PlaygroundSection = ({ t, isMobile, isTablet, language }) => {
  return (
    <section className={`${isMobile ? 'py-24' : 'py-48'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`flex flex-col ${isMobile ? 'items-center' : 'md:flex-row md:items-center md:space-x-12'}`}>
          <div className={`${isMobile ? 'w-full text-center' : 'md:w-1/2 text-left'} mb-12 md:mb-0`}>
            <h2 className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold mb-6 text-gray-900`}>{t.playground.title}</h2>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-4`}>
              {t.playground.description1}
            </p>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-6`}>
              {t.playground.description2}
            </p>
          </div>
          <div className={`${isMobile ? 'w-full' : 'md:w-1/2'}`}>
            <img 
              src={`/playground${language === 'en' ? '' : '_' + language}.png`}
              alt="Palette Hunt Try-on Studio" 
              className={`${isMobile ? 'w-3/4' : 'w-4/5'} mx-auto object-cover`} 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const TestimonialsSection = ({ isMobile, language }) => {
  const testimonials = translations[language].testimonials;
  
  return (
    <section className={`testimonials bg-white ${!isMobile ? 'mt-[-2rem] mb-4' : ''}`}>
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">{testimonials.title}</h2>
        <div className="grid md:grid-cols-2 gap-12">
          {testimonials.items.map((item, index) => (
            <div key={index} className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
              <p className="text-xl text-gray-600 italic mb-6 relative z-10">
                "{item.text}"
              </p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <h3 className="text-lg font-semibold text-gray-900 mr-2">{item.author}</h3>
                  <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                    ✓ Verified User
                  </span>
                </div>
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const PricingSection = ({ t, language }) => {
  // Add a helper function to get the correct login route
  const getLoginRoute = (language) => {
    switch (language) {
      case 'es':
        return '/es/login';
      case 'pt':
        return '/pt/login';
      default:
        return '/login';
    }
  };

  return (
    <section className="bg-white py-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h3 className="text-3xl font-bold text-gray-900 mb-4">{t.pricing.choosePlan}</h3>
          <p className="text-xl text-gray-600">{t.pricing.selectPlan}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Basic Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.pricing.basicPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$6<span className="text-3xl">.99</span></span>
                <span className="text-gray-600 ml-2">{t.pricing.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 {t.pricing.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.goldSilverTest}</span>
                </li>
              </ul>
            </div>
            <Link
              to={getLoginRoute(language)}
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.pricing.getStarted}
            </Link>
          </div>

          {/* Pro Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border-2 border-gray-900 p-8">
            <div className="absolute top-0 right-6 transform -translate-y-1/2">
              <span className="bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">{t.pricing.popular}</span>
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.pricing.proPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$17<span className="text-3xl">.99</span></span>
                <span className="text-gray-600 ml-2">{t.pricing.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 {t.pricing.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">12 {t.pricing.picsInColors}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">150 {t.pricing.playgroundCredits}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.goldSilverTest}</span>
                </li>
              </ul>
            </div>
            <Link
              to={getLoginRoute(language)}
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.pricing.getPro}
            </Link>
          </div>

          {/* Enterprise Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.pricing.enterprise}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">{t.pricing.custom}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.tailoredSolutions}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.bulkPricing}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.pricing.customisedFeatures}</span>
                </li>
              </ul>
            </div>
            <a
              href="mailto:hi@palettehunt.com"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.pricing.contactUs}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = ({ language }) => {
  const t = translations[language];
  
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-12 gap-x-16 lg:gap-x-24">
          {/* Left Column - Title */}
          <div className="md:col-span-5">
            <h2 className="text-6xl font-bold text-gray-900 top-8">
              {t.faqTitle}
            </h2>
            <p className="mt-8 text-gray-600">
              {t.haveQuestion}{' '}
              <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline">email</a>.
            </p>
          </div>

          {/* Right Column - Questions */}
          <div className="md:col-span-7">
            {t.faqs.map((item, index) => (
              <details key={index} className="group border-t border-gray-200 [&>summary::-webkit-details-marker]:hidden [&>summary::marker]:hidden">
                <summary className="flex justify-between items-center py-5 cursor-pointer text-xl font-semibold text-gray-900">
                  {item.question}
                  <span className="ml-6 flex-shrink-0 text-2xl group-open:rotate-45 transition-transform duration-200">
                    +
                  </span>
                </summary>
                <div className="pb-5 text-gray-600">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const ColorAnalysisQuiz = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;
  const questions = questionTranslations[language] || questionTranslations.en;
  const [currentStep, setCurrentStep] = useState(-1);
  const [answers, setAnswers] = useState({});
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  // Add this new state for quiz results
  const [showResults, setShowResults] = useState(false);

  const startQuiz = () => {
    // Update current step to show first question
    setCurrentStep(0);
    
    // Scroll to quiz section smoothly
    const quizSection = document.getElementById('quiz-section');
    if (quizSection) {
      quizSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getHomeRoute = (language) => {
    switch (language) {
      case 'es':
        return '/es';
      case 'pt':
        return '/pt';
      default:
        return '/';
    }
  };

  const handleAnswer = (questionId, answer) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answer
    }));
    // Remove the automatic advancement
    // if (currentStep < questions.length - 1) {
    //   setCurrentStep(currentStep + 1);
    // }
  };

  const handleSubmit = async () => {
    setIsAnalyzing(true);
    try {
      const currentQuestions = questionTranslations[language] || questionTranslations.en;
      const analysisPayload = {
        answers: {
          skin_tone: {
            type: answers[1],
            description: currentQuestions[0].options.find(opt => opt.id === answers[1])?.text || ''
          },
          eyes: {
            type: answers[2],
            description: currentQuestions[1].options.find(opt => opt.id === answers[2])?.text || ''
          },
          hair: {
            type: answers[3],
            description: currentQuestions[2].options.find(opt => opt.id === answers[3])?.text || ''
          },
          veins: {
            type: answers[4],
            description: currentQuestions[3].options.find(opt => opt.id === answers[4])?.text || ''
          },
          jewelry: {
            preference: answers[5],
            description: currentQuestions[4].options.find(opt => opt.id === answers[5])?.text || ''
          }
        },
        language
      };

      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/analyze-colors`, analysisPayload);
      
      // Parse the response data if it's a string
      const analysisData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
      
      // Extract the first color analysis result
      const colorAnalysis = analysisData.color_analysis[0];
      
      setResult({
        season: colorAnalysis.shades,
        description: colorAnalysis.explanation,
        colors: colorAnalysis.colors_and_codes.map(c => c.color_code),
        introduction: analysisData.introduction
      });
      
      setShowResults(true);
      
      // Scroll to results section
      const resultsSection = document.getElementById('results-section');
      if (resultsSection) {
        resultsSection.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error analyzing colors:', error);
      // Show error message to user
      alert('Sorry, there was an error analyzing your colors. Please try again.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const pageVariants = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 }
  };

  const drawArrow2 = (
    startEl,
    endEl,
    startXPercent,
    startYPercent,
    endXPercent,
    endYPercent,
    curveFactor = 0.5,
    color = '#FFA500',
    addText = false,
    textContent = '',
    textOffsetX = 0,
    textOffsetY = 0
  ) => {
    if (!startEl || !endEl) return;

    const startRect = startEl.getBoundingClientRect();
    const endRect = endEl.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    const startX = startRect.left + startRect.width * startXPercent - containerRect.left;
    const startY = startRect.top + startRect.height * startYPercent - containerRect.top;
    const endX = endRect.left + endRect.width * endXPercent - containerRect.left;
    const endY = endRect.top + endRect.height * endYPercent - containerRect.top;

    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;

    const dx = endX - startX;
    const dy = endY - startY;
    const normalX = -dy;
    const normalY = dx;
    const distance = Math.sqrt(dx * dx + dy * dy);

    const controlX = midX + (normalX / distance) * curveFactor * 100;
    const controlY = midY + (normalY / distance) * curveFactor * 100;

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', `M${startX},${startY} Q${controlX},${controlY} ${endX},${endY}`);
    path.setAttribute('fill', 'none');
    path.setAttribute('stroke', color);
    path.setAttribute('stroke-width', '2');
    svgRef.current.appendChild(path);

    // Add arrowhead
    const arrowSize = 10;
    const arrowWidth = 6;
    const arrowAngle = Math.atan2(endY - controlY, endX - controlX);
    const arrowhead = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    arrowhead.setAttribute(
      'd',
      `M${endX},${endY} 
      L${endX - arrowSize * Math.cos(arrowAngle - Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle - Math.PI / 6)}
      L${endX - (arrowSize - arrowWidth / 2) * Math.cos(arrowAngle)},${endY - (arrowSize - arrowWidth / 2) * Math.sin(arrowAngle)}
      L${endX - arrowSize * Math.cos(arrowAngle + Math.PI / 6)},${endY - arrowSize * Math.sin(arrowAngle + Math.PI / 6)}
      Z`
    );
    arrowhead.setAttribute('fill', color);
    svgRef.current.appendChild(arrowhead);
  };

  useEffect(() => {
    if (containerRef.current && svgRef.current) {
      svgRef.current.innerHTML = '';
      const photoGrid4 = containerRef.current.querySelector('[alt="Photo Grid 4"]');
      const sunnyYellow = containerRef.current.querySelector('[alt="Sunny Yellow"]');
      const olympiaSmall2 = containerRef.current.querySelector('[alt="Small 2"]');
      const olympiaLarge2 = containerRef.current.querySelector('[alt="AI 2"]');

      drawArrow2(
        photoGrid4,
        sunnyYellow,
        isMobile ? 1.3 : 1.15,
        isMobile ? 0.37 : 0.37,
        isMobile ? 0.45 : 0.35,
        isMobile ? -0.05 : -0.05,
        isMobile ? -0.45 : -0.55,
        '#000000',
        false
      );

      drawArrow2(
        olympiaSmall2,
        olympiaLarge2,
        isMobile ? -1.28 : -1.28,
        isMobile ? 0.3 : 0.3,
        isMobile ? 0.6 : 0.67,
        isMobile ? -0.05 : -0.05,
        isMobile ? 0.6 : 0.55,
        '#000000',
        false
      );

    }
  }, []);

  const getLoginRoute = (language) => {
    switch (language) {
      case 'es':
        return '/es/login';
      case 'pt':
        return '/pt/login';
      default:
        return '/login';
    }
  };

  // Add this new section right after the Hero Section and before the Logo Carousel
  const renderQuiz = () => {
    if (currentStep === -1) return null;

    return (
      <section id="quiz-section" className="py-16 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            {/* Progress indicator */}
            <div className="mb-8">
              <p className="text-sm text-gray-600 mb-2">
                {t.quiz.stepProgress.replace('{current}', currentStep + 1).replace('{total}', questions.length)}
              </p>
              <div className="h-2 bg-gray-200 rounded-full">
                <div 
                  className="h-full bg-gray-900 rounded-full transition-all duration-300"
                  style={{ width: `${((currentStep + 1) / questions.length) * 100}%` }}
                ></div>
              </div>
            </div>

            {/* Question */}
            <AnimatePresence mode='wait'>
              <motion.div
                key={currentStep}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageVariants}
              >
                <h3 className="text-2xl font-bold mb-2">{questions[currentStep].title}</h3>
                <p className="text-gray-600 mb-6">{questions[currentStep].question}</p>

                {/* Options */}
                <div className="space-y-4">
                  {questions[currentStep].options.map((option) => (
                    <button
                      key={option.id}
                      onClick={() => handleAnswer(questions[currentStep].id, option.id)}
                      className={`w-full p-4 text-left rounded-lg border transition-all duration-200 ${
                        answers[questions[currentStep].id] === option.id
                          ? 'border-gray-900 bg-gray-50'
                          : 'border-gray-200 hover:border-gray-300'
                      }`}
                    >
                      {option.text}
                    </button>
                  ))}
                </div>

                {/* Navigation buttons */}
                <div className="flex justify-between mt-8">
                  <button
                    onClick={() => setCurrentStep(currentStep - 1)}
                    className={`flex items-center ${
                      currentStep === 0 ? 'invisible' : ''
                    }`}
                  >
                    <FaArrowLeft className="mr-2" />
                    {t.quiz.previous}
                  </button>
                  
                  {currentStep < questions.length - 1 ? (
                    <button
                      onClick={() => setCurrentStep(currentStep + 1)}
                      className={`flex items-center ${
                        !answers[questions[currentStep].id] ? 'opacity-50 cursor-not-allowed' : ''}`}
                      disabled={!answers[questions[currentStep].id]}
                    >
                      {t.quiz.next}
                      <FaArrowRight className="ml-2" />
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      className="bg-gray-900 text-white px-6 py-2 rounded-full hover:bg-gray-800"
                      disabled={!answers[questions[currentStep].id]}
                    >
                      {t.quiz.getResults}
                    </button>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </section>
    );
  };

  // Add this new function to render results
  const renderResults = () => {
    if (!showResults) return null;

    return (
      <section id="results-section" className="py-16 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4">
          <div className="bg-white rounded-2xl shadow-lg p-8">
            <h2 className="text-3xl font-bold mb-6 text-center">Your Color Analysis Results</h2>
            
            {isAnalyzing ? (
              <div className="flex flex-col items-center justify-center py-12">
                <ClipLoader color="#000000" size={50} />
                <p className="mt-4 text-gray-600">{t.loading}</p>
              </div>
            ) : result ? (
              <>
                {result.introduction && (
                  <div className="mb-8">
                    <p className="text-gray-700">{result.introduction}</p>
                  </div>
                )}
                
                <div className="mb-8">
                  <h3 className="text-xl font-semibold mb-4">Your Color Season</h3>
                  <p className="text-gray-900 font-medium mb-4">{result.season}</p>
                  <p className="text-gray-700">{result.description}</p>
                </div>

                {result.colors && result.colors.length > 0 && (
                  <div className="mb-8">
                    <h3 className="text-xl font-semibold mb-4">Your Best Colors</h3>
                    <div className="grid grid-cols-6 gap-2">
                      {result.colors.map((color, index) => (
                        <div
                          key={index}
                          className="w-full aspect-square rounded-full shadow-md"
                          style={{ backgroundColor: color }}
                          title={`Color ${index + 1}`}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div className="text-center">
                  <Link
                    to={getLoginRoute(language)}
                    className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.getFullAnalysis}
                  </Link>
                  <p className="mt-4 text-sm text-gray-500">{t.quiz.seeColorsLive}</p>
                </div>
              </>
            ) : (
              <div className="text-center text-gray-600">
                <p>No results available. Please try again.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{t.quizTitle} | Palette Hunt</title>
        <meta name="description" content={t.quizSubtitle} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": t.quizTitle,
            "description": t.quizSubtitle,
            "applicationCategory": "Beauty & Fashion",
            "offers": {
              "@type": "AggregateOffer",
              "offers": [
                {
                  "@type": "Offer",
                  "name": t.pricing.basicPlan,
                  "price": "6.99",
                  "priceCurrency": "USD",
                  "description": `${t.pricing.colorAnalysisTest}, 3 ${t.pricing.tailoredPalettes}, 6 ${t.pricing.picsInColors}`,
                  "availability": "https://schema.org/InStock"
                },
                {
                  "@type": "Offer",
                  "name": t.pricing.proPlan,
                  "price": "17.99",
                  "priceCurrency": "USD",
                  "description": `${t.pricing.colorAnalysisTest}, 5 ${t.pricing.tailoredPalettes}, 12 ${t.pricing.picsInColors}, ${t.pricing.goldSilverTest}`,
                  "availability": "https://schema.org/InStock"
                }
              ]
            },
            "review": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "1250"
            },
            "provider": {
              "@type": "Organization",
              "name": "Palette Hunt",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.palettehunt.com/logo.png"
              }
            },
            "mainEntity": {
              "@type": "Quiz",
              "about": "Color Analysis",
              "educationalAlignment": {
                "@type": "AlignmentObject",
                "alignmentType": "teaches",
                "educationalFramework": "Personal Style",
                "targetName": "Color Analysis and Personal Color Theory"
              }
            },
            "hasPart": t.faqs.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          })}
        </script>
      </Helmet>
      
      {/* Header */}
      <header className="bg-white">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className={`flex justify-between items-center`}>
            <Link to="/" className="flex items-center">
              <img src="/logo.png" alt="Palette Hunt Logo" className="h-8 w-auto" />
              <span className={`ml-2 text-xl font-bold text-gray-900 ${isMobile ? 'hidden' : ''}`}>Palette Hunt</span>
            </Link>
            <div className="flex items-center">
              {isMobile ? (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/login"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.getStarted}
                  </Link>
                </div>
              ) : (
                <>
                  <div className="flex items-center space-x-8 mr-10">
                    <Link to="/contact" className="text-gray-700 hover:text-gray-900">
                      {t.contact}
                    </Link>
                    <div className="relative language-menu">
                      <button
                        onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
                        className="flex items-center text-gray-700 hover:text-gray-900"
                      >
                        <Globe className="w-5 h-5 mr-1" />
                        <span className="uppercase mr-1">{language}</span>
                        <ChevronDown className="w-4 h-4" />
                      </button>
                      {languageMenuOpen && (
                        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
                          <Link
                            to="/"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            English
                          </Link>
                          <Link
                            to="/es"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Español
                          </Link>
                          <Link
                            to="/pt"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Português
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <Link
                    to="/login"
                    className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.signIn}
                  </Link>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <div className="relative overflow-hidden">
        {/* Base white background with gradient blobs - only show on desktop */}
        {!isMobile && (
          <div className="absolute inset-0 bg-white">
            {/* Blue gradient blob */}
            <div className="absolute -left-1/4 top-1/4 h-[600px] w-[600px] animate-pulse rounded-full bg-blue-600/30 blur-[120px]" />
            
            {/* White gradient */}
            <div 
              className="absolute right-0 top-0 bottom-0 w-1/2"
              style={{
                background: 'linear-gradient(to right, transparent, white)'
              }}
            />
          </div>
        )}

        {/* Hero Section */}
        <section className={`relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobile ? 'pt-16' : 'pt-32'} pb-16`}>
          {isMobile ? (
            <div className="flex flex-col items-center">
              <div className="w-full text-center mb-8">
                <h1 className="text-4xl font-bold mb-4 text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  {t.yourPerfect}
                </h1>
                <h2 className="text-lg text-gray-600 mb-6">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
              </div>
              
              <div className="w-full mb-8">
                <img
                  src={`/hero${language === 'en' ? '' : '_' + language}.png`}
                  alt="Color Analysis Process"
                  className="w-full h-auto rounded-lg"
                />
              </div>

              <div className="w-full flex flex-col gap-4 px-4">
                <button
                  onClick={startQuiz}
                  className="w-full inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                >
                  {t.getFreeQuiz}
                </button>
                <a
                  href="https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full inline-flex items-center justify-center px-8 py-3 border border-gray-900 text-base font-bold rounded-full text-gray-900 bg-white hover:bg-gray-50"
                >
                  <svg className="h-5 w-5 mr-2" viewBox="0 0 384 512">
                    <path
                      fill="currentColor"
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                    />
                  </svg>
                  Download App
                </a>
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 text-left">
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-left text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  {t.yourPerfect}
                </h1>
                <h2 className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10 max-w-none">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
                <div className="flex flex-row gap-4 justify-start">
                  <button
                    onClick={startQuiz}
                    className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.getFreeQuiz}
                  </button>
                  <a
                    href="https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center px-8 py-3 border border-gray-900 text-base font-bold rounded-full text-gray-900 bg-white hover:bg-gray-50"
                  >
                    <svg className="h-5 w-5 mr-2" viewBox="0 0 384 512">
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                    Download App
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
                <div className="relative w-full max-w-lg mx-auto">
                  <img
                    src={`/hero${language === 'en' ? '' : '_' + language}.png`}
                    alt="Color Analysis Process"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>
            </div>
          )}
        </section>

        {/* Logo Carousel */}
        <div className="relative z-10">
          <LogoCarousel t={t} />
        </div>
      </div>

      {/* Quiz Section */}
      {renderQuiz()}
      
      {/* Results Section */}
      {renderResults()}

      {/* Transform Your Look Section */}
      <section className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
              {t.transformTitle}
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {t.transformSubtitle}
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">{t.uploadPhotosTitle}</h3>
              <p className="text-gray-600">{t.uploadPhotosDesc}</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">{t.getColorsTitle}</h3>
              <p className="text-gray-600">{t.getColorsDesc}</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-4">{t.seeResultsTitle}</h3>
              <p className="text-gray-600">{t.seeResultsDesc}</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Image Grid Section */}
      <div ref={containerRef} className="relative mb-16">
        <svg
          ref={svgRef}
          className="absolute inset-0 w-full h-full pointer-events-none"
          style={{ zIndex: 10 }}
        />
        <ImageGrid t={t} language={language} />
      </div>

      {/* Understanding Colors Section */}
      <ColorAnalysisSection t={t} />

      {/* Playground Section */}
      <PlaygroundSection t={t} isMobile={isMobile} isTablet={isMobile} language={language} />
      <TestimonialsSection isMobile={isMobile} language={language} />
      
      <PricingSection t={t} language={language} />
      <FAQSection language={language} />

      {/* Footer */}
      <footer className="bg-white py-8">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center mb-4 md:mb-0">
              <img src="/logo.png" alt="Palette Hunt Logo" className="h-12 w-auto mr-4" />
              <span className="text-2xl font-bold text-gray-800">Palette Hunt</span>
            </div>
            <div className="flex space-x-6">
              <Link to="/about" className="text-gray-600 hover:text-gray-800">About</Link>
              <Link to="/contact" className="text-gray-600 hover:text-gray-800">Contact</Link>
              <Link to="/privacy" className="text-gray-600 hover:text-gray-800">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ColorAnalysisQuiz; 