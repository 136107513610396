import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import { CheckCircle2, Globe, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LogoCarousel } from './LandingPage';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
};

const translations = {
  es: {
    title: "Palette Hunt - Análisis de Color con IA para Moda y Estilo",
    description: "Descubre tu paleta de colores perfecta con el análisis impulsado por IA de Palette Hunt. Transforma tu estilo, prueba looks virtuales y obtén recomendaciones personalizadas. ¡Desbloquea tu verdadero potencial de moda hoy!",
    keywords: "análisis de color, paleta, paleta de colores, palette hunt, palettehunt, estilismo con IA, paleta de colores personal, tecnología de moda, recomendaciones de estilo",
    ogTitle: "Palette Hunt - Análisis de Color Impulsado por IA",
    ogDescription: "Descubre tu colorimetría con análisis impulsado por IA.",
    getStarted: "Descubrir mis colores",
    contact: "Contacto",
    signIn: "Iniciar sesión",
    discover: "Colorimetría",
    yourPerfect: "Con Fotos de IA",
    colorPalette: "Paleta de Colores",
    findYourShades: "Encuentra tus tonos más favorecedores con nuestro análisis de colorimetría impulsado por IA. Sube tus fotos para verte transformado en tus colores perfectos.",
    imagesCreated: "+15,000 imágenes creadas",
    choosePlan: "Elige tu Plan de Análisis de Color",
    selectPlan: "Selecciona el plan que mejor se adapte a tus necesidades",
    featuredOn: "Visto en"
  }
};

const StepIndicator = ({ number, title }) => (
  <div className="flex items-center justify-center mb-6">
    <div className="flex items-center">
      <div className="w-8 h-8 rounded-full bg-gray-900 text-white flex items-center justify-center font-semibold text-sm">
        {number}
      </div>
      <div className="ml-3">
        <p className="text-gray-900 font-semibold">{title}</p>
      </div>
    </div>
  </div>
);

const ImageWithFallback = ({ src, alt, className, objectPosition, isMobile }) => {
  const [error, setError] = useState(false);

  const handleError = (e) => {
    console.error(`Failed to load image: ${src}`);
    setError(true);
  };

  return (
    <div className={`relative ${className} ${!isMobile ? 'transition-transform duration-300 ease-in-out transform hover:scale-110' : ''}`}>
      {!error ? (
        <img
          src={src}
          alt={alt}
          className="w-full h-full object-cover rounded-lg"
          style={{ objectPosition: objectPosition || 'center' }}
          onError={handleError}
        />
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-red-500 text-white text-xs p-2 text-center rounded-lg">
          Error al cargar la imagen: {src}
        </div>
      )}
    </div>
  );
};

const HeroSection = ({ t, isMobile, isTablet, containerRef, svgRef }) => {
  return (
    <>
      {/* Wrapper div that contains both Hero and LogoCarousel */}
      <div className="relative overflow-hidden">
        {/* Base white background with gradient blobs - only show on desktop */}
        {!isMobile && (
          <div className="absolute inset-0 bg-white">
            {/* Blue gradient blob */}
            <div className="absolute -left-1/4 top-1/4 h-[600px] w-[600px] animate-pulse rounded-full bg-blue-600/30 blur-[120px]" />
            
            {/* White gradient */}
            <div 
              className="absolute right-0 top-0 bottom-0 w-1/2"
              style={{
                background: 'linear-gradient(to right, transparent, white)'
              }}
            />
          </div>
        )}

        {/* Hero Content */}
        <div className={`relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobile ? 'pt-2' : 'pt-16'} pb-16`}>
          {isMobile ? (
            // Mobile Layout
            <div className="flex flex-col items-center">
              {/* Text */}
              <div className="w-full text-center mb-8">
                <h1 className="text-4xl font-bold mb-4 text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  {t.yourPerfect}
                </h1>
                <h2 className="text-base text-gray-600 mb-6">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
              </div>
              
              {/* Image */}
              <div className="w-full mb-8">
                <div className="relative w-full max-w-xs mx-auto">
                  <img
                    src="/hero_es.png"
                    alt="Proceso de Análisis de Color"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>

              {/* Buttons */}
              <div className="flex flex-col gap-4 w-full">
                <Link
                  to="/es/login"
                  className="bg-gray-900 text-white font-semibold py-3 px-6 text-lg rounded-full hover:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105 text-center"
                >
                  {t.getStarted}
                </Link>
                <a
                  href="https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-black text-white font-semibold py-3 px-6 text-lg rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
                >
                  <svg className="h-5 w-5 mr-2" viewBox="0 0 384 512">
                    <path
                      fill="currentColor"
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                    />
                  </svg>
                  Descargar App
                </a>
              </div>
            </div>
          ) : (
            // Desktop Layout
            <div className="flex flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 text-left">
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-left text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  {t.yourPerfect}
                </h1>
                <h2 className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10 max-w-none">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
                <div className="flex flex-row gap-4 justify-start">
                  <Link
                    to="/es/login"
                    className="bg-gray-900 text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105 text-base inline-block"
                  >
                    {t.getStarted}
                  </Link>
                  <a
                    href="https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-black text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full hover:bg-opacity-90 transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
                  >
                    <svg className="h-5 w-5 mr-2" viewBox="0 0 384 512">
                      <path
                        fill="currentColor"
                        d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                      />
                    </svg>
                    Descargar App
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
                <div className="relative w-full max-w-lg mx-auto">
                  <img
                    src="/hero_es.png"
                    alt="Proceso de Análisis de Color"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Logo Carousel */}
        <LogoCarousel title={t.featuredOn} />
      </div>
    </>
  );
};

const FeaturesSection = ({ isMobile }) => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Transforma Tu Look con IA
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Sube algunas fotos y obtén un análisis de color impulsado por IA en una hora
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Sube Tus Fotos</h3>
            <p className="text-gray-600">Sube tus mejores fotos para tu análisis de color. Nuestra IA procesará tus características únicas.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Obtén Tus Colores</h3>
            <p className="text-gray-600">Recibe tu paleta de colores personalizada basada en tus características únicas, tono de piel y subtonos.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Ve los Resultados</h3>
            <p className="text-gray-600">Visualízate transformado en tus colores perfectos con nuestra avanzada tecnología de IA. Experimenta la diferencia.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const PlaygroundSection = ({ isMobile, isTablet }) => {
  return (
    <section className={`${isMobile ? 'py-24' : 'py-48'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`flex flex-col ${isMobile ? 'items-center' : 'md:flex-row md:items-center md:space-x-12'}`}>
          <div className={`${isMobile ? 'w-full text-center' : 'md:w-1/2 text-left'} mb-12 md:mb-0`}>
            <h2 className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold mb-6 text-gray-900`}>Tu Estilo, Reinventado</h2>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-4`}>
              Entra en nuestro estudio virtual donde la imaginación no tiene límites. Mírate con todos los looks y colores que quieras.
            </p>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-6`}>
              Crea imágenes tuyas con estilos que nunca pensaste que podrías usar.
            </p>
          </div>
          <div className={`${isMobile ? 'w-full' : 'md:w-1/2'}`}>
            <img 
              src="/playground_es.png" 
              alt="Palette Hunt Try-on Studio" 
              className={`${isMobile ? 'w-3/4' : 'w-4/5'} mx-auto object-cover`} 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageGridSection = ({ isMobile, isTablet, containerRef2, svgRef2 }) => {
  return (
    <section className="pt-24 pb-12 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">
          Mírate en Tus Colores Perfectos
        </h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-8">
          No solo leas sobre colores que podrían quedarte bien - vélos en acción en tu modelo personalizado de IA. Nuestro análisis de color completo te muestra exactamente cómo se ve tu paleta perfecta en ti. <strong>Todo lo que necesitas son algunas selfies.</strong>
        </p>
        <div ref={containerRef2} className={`relative w-full ${isTablet ? 'md:h-[400px]' : 'md:h-[600px]'} mb-16 md:mb-0`}>
          <svg ref={svgRef2} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Left Column */}
            <div className="w-full md:w-[48%] mb-8 md:mb-0">
              <div className="relative">
                {/* Top row of small images */}
                <div className="flex space-x-3 mb-2">
                  <ImageWithFallback 
                    src="/grid1.png" 
                    alt="Grid 1" 
                    className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                  />
                </div>
                {/* Large images */}
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/laura-large-2.png" 
                      alt="Laurita" 
                      className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">Foto IA</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Primavera Cálida 🌸</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#FF7F50]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#FA8072]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#228B22]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#2E8B57]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/orange-2.jpg" 
                      alt="Sunny Yellow" 
                      className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">Foto IA</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Otoño Cálido 🍁</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#E57373]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DDAA33]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#556B2F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && <div className="mb-8"></div>}
            {/* Right Column */}
            <div className="w-full md:w-[48%] md:w-1/2">
              <div className="relative">
                {/* Top row of small images */}
                <div className="flex space-x-3 mb-2 justify-end">
                  <ImageWithFallback 
                    src="/grid2.png" 
                    alt="Grid 2" 
                    className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                  />
                </div>
                {/* Large images */}
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/olympia_large_21.png" 
                      alt="Olympia AI 2" 
                      className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">Foto IA</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Verano Suave ☀️</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#8B8589]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#778899]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8A6B3]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#9AA3A8]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#A7A69D]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#8C8C8C]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/olympia_ai_3.png" 
                      alt="Olympia Transform 1" 
                      className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">Foto IA</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Otoño Profundo 🍂</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#A0522D]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#D2691E]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ColorAnalysisSection = () => {
  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Entiende Qué Colores Te Favorecen
          </h2>
          <p className="text-gray-600 text-xl max-w-2xl mx-auto">
            Nuestro análisis de color impulsado por IA te ayuda a descubrir tu paleta de colores perfecta.
          </p>
        </div>

        {/* Image Carousel */}
        <div className="relative max-w-4xl mx-auto mb-16">
          {/* Gradient overlays for fade effect */}
          <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10"></div>
          
          <div className="overflow-hidden">
            <div className="flex animate-carousel">
              {[...Array(2)].map((_, setIndex) => (
                <div key={setIndex} className="flex space-x-8 flex-none">
                  {[...Array(9)].map((_, index) => (
                    <div key={`${setIndex}-${index}`} className="flex-none">
                      <img
                        src={`/carousel${index + 1}.jpeg`}
                        alt={`Color Analysis Example ${index + 1}`}
                        className="w-auto h-auto max-h-[300px] rounded-xl shadow-lg"
                      />
                    </div>
                  ))}
                  {/* Add spacing after each set */}
                  <div className="w-8 flex-none"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animate-carousel {
          animation: scroll 90s linear infinite;
        }
        
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-carousel:hover {
          animation-play-state: paused;
        }
      `}</style>
    </section>
  );
};

const TestimonialsSection = ({ isMobile }) => {
  return (
    <section className="bg-white py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Lo que dicen nuestros usuarios
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Descubre cómo Palette Hunt ha ayudado a otros a encontrar sus colores perfectos.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              "Nunca pensé que los colores amarillos me quedarían bien, pero verme en ellos ha cambiado mi opinión por completo."
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Laura</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                ✓ Usuario Verificado
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              "¡Las fotos generadas por IA me ayudaron a visualizar cómo diferentes colores se verían en mí. ¡Es increíble!"
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Olympia</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                  ✓ Usuario Verificado
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = () => {
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-12 gap-x-16 lg:gap-x-24">
          {/* Left Column - Title */}
          <div className="md:col-span-5">
            <h2 className="text-6xl font-bold text-gray-900 top-8">
              Preguntas Frecuentes
            </h2>
            <p className="mt-8 text-gray-600">
              ¿Tienes otra pregunta? Contáctame a través de {' '}
              <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline">email</a>.
            </p>
          </div>

          {/* Right Column - Questions */}
          <div className="md:col-span-7">
            {[
              {
                question: "¿Cómo funciona Palette Hunt?",
                answer: "Utilizamos los algoritmos de visión por IA más avanzados para analizar tus fotos y generar paletas de colores personalizadas que complementan tus características únicas y preferencias de estilo."
              },
              {
                question: "¿Cuánto tiempo tarda en dar resultados?",
                answer: <>Normalmente recibirás tus resultados en 1 hora. Si los resultados tardan más de 2 horas, envíanos un email a <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
              },
              {
                question: "¿La calidad de los resultados será buena?",
                answer: "Sí, podemos asegurarte que la calidad de los resultados será la mejor que puedes obtener en el mercado. Pero necesitas proporcionarnos fotos de buena calidad para maximizar la calidad de los resultados."
              },
              {
                question: "¿Necesito subir muchas fotos?",
                answer: "Para obtener resultados óptimos, recomendamos subir 8-10 fotos que muestren diferentes ángulos, condiciones de iluminación y atuendos. ¡Esto determinará la calidad de los resultados!"
              },
              {
                question: "¿Puedo conservar las imágenes generadas?",
                answer: "¡Absolutamente! Eres dueño de todas las imágenes generadas por Palette Hunt. Tanto para el probador virtual como para el análisis de color."
              },
              {
                question: "¿Puedo usar Palette Hunt en dispositivos móviles?",
                answer: "¡Sí, sin problema! Palette Hunt funciona tanto en móvil como en escritorio. ¡Siéntete libre de usar el dispositivo con el que te sientas más cómodo!"
              },
              {
                question: "¿Qué tan precisas son las recomendaciones de color?",
                answer: "Nuestro proceso de recomendación de color ha sido probado exhaustivamente para asegurarnos de que nuestros resultados sean los mejores que puedas obtener. Nuestra tasa de satisfacción es extremadamente alta entre nuestros usuarios."
              },
              {
                question: "¿Qué tipo de fotos recibiré?",
                answer: "Por cada paleta de colores que recibas, obtendrás 2 imágenes tuyas en esa paleta de colores. Una tendrá un fondo blanco, como en un estudio, y otra tendrá un fondo que coincida con el tema de color."
              },
              {
                question: "¿Qué pasa si no me gustan los resultados?",
                answer: <>La calidad es nuestra principal prioridad. En este momento, no podemos ofrecer reembolsos completos ya que cada análisis de color significa un costo significativo por usuario. Sin embargo, estamos seguros de que encontraremos una solución para ti. Siempre estamos disponibles en <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
              },
              {
                question: "¿Mis fotos serán compartidas con alguien?",
                answer: "No. Solo usamos las fotos para entrenar nuestra IA a la que nadie más tiene acceso. Cada foto que subes se elimina después de una semana."
              }
            ].map((item, index) => (
              <details key={index} className="group border-t border-gray-200 [&>summary::-webkit-details-marker]:hidden [&>summary::marker]:hidden">
                <summary className="flex justify-between items-center py-5 cursor-pointer text-xl font-semibold text-gray-900">
                  {item.question}
                  <span className="ml-6 flex-shrink-0 text-2xl group-open:rotate-45 transition-transform duration-200">
                    +
                  </span>
                </summary>
                <div className="pb-5 text-gray-600">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingSection = ({ t }) => {
  return (
    <section className="bg-white py-48">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        
        <div className="text-center mb-16">
          <h3 className="text-4xl font-bold text-gray-900 mb-4">{t.choosePlan}</h3>
          <p className="text-xl text-gray-600">{t.selectPlan}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Basic Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Plan Básico</h3>
              <div className="flex items-baseline mb-4">
              <span className="text-5xl font-bold text-gray-900">$6<span className="text-3xl">.99</span></span>
                <span className="text-gray-600 ml-2">{t.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Test de análisis de color</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">3 paletas de colores personalizadas</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">6 fotos tuyas en esos colores</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">50 créditos de probador virtual</span>
                </li>
              </ul>
            </div>
            <Link
              to="/es/login"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Obtener Plan Básico
            </Link>
          </div>

          {/* Pro Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border-2 border-gray-900 p-8">
            <div className="absolute top-0 right-6 transform -translate-y-1/2">
              <span className="bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">Popular</span>
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Plan Pro</h3>
              <div className="flex items-baseline mb-4">
              <span className="text-5xl font-bold text-gray-900">$17<span className="text-3xl">.99</span></span>
                <span className="text-gray-600 ml-2">{t.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Test de análisis de color</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 paletas de colores personalizadas</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">12 fotos de IA</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">150 créditos de probador virtual</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Test de oro o plata</span>
                </li>
              </ul>
            </div>
            <Link
              to="/es/login"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Obtener Pro
            </Link>
          </div>

          {/* Enterprise Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Empresas</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">Custom</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Soluciones de análisis de color personalizadas</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Opciones de precios por volumen</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Características personalizadas</span>
                </li>
              </ul>
            </div>
            <a
              href="mailto:hi@palettehunt.com"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Contáctanos
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const LandingPage = () => {
  const [language, setLanguage] = useState('es');
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const t = translations.es;
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const svgRef = useRef(null);
  const svgRef2 = useRef(null);
  const isMobile = useIsMobile();
  const isTablet = false;

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setLanguageMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.language-menu')) {
        setLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.palettehunt.com/es/#website",
        "url": "https://www.palettehunt.com/es",
        "name": "Palette Hunt",
        "description": "Análisis de color y prueba virtual impulsados por IA",
        "publisher": {
          "@id": "https://www.palettehunt.com/es/#organization"
        },
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.palettehunt.com/es/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "inLanguage": "es"
      },
      {
        "@type": "Organization",
        "@id": "https://www.palettehunt.com/es/#organization",
        "name": "Palette Hunt",
        "url": "https://www.palettehunt.com/es",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.palettehunt.com/logo.png",
          "width": "512",
          "height": "512"
        },
        "sameAs": [
          "https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
        ],
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "email": "hi@palettehunt.com",
            "contactType": "servicio al cliente",
            "availableLanguage": ["en", "es", "pt"]
          }
        ]
      },
      {
        "@type": "SoftwareApplication",
        "@id": "https://www.palettehunt.com/es/#software",
        "name": "Palette Hunt",
        "operatingSystem": "iOS",
        "applicationCategory": "LifestyleApplication",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "USD"
        }
      },
      {
        "@type": "Service",
        "@id": "https://www.palettehunt.com/es/#service",
        "name": "Análisis de Color con IA",
        "provider": {
          "@id": "https://www.palettehunt.com/es/#organization"
        },
        "description": "Descubre tu paleta de colores perfecta con análisis impulsado por IA. Transforma tu estilo con prueba virtual y obtén recomendaciones personalizadas.",
        "serviceType": "Análisis de Color",
        "areaServed": {
          "@type": "Country",
          "name": "Mundial"
        },
        "offers": [
          {
            "@type": "Offer",
            "name": "Plan Básico",
            "price": "6.99",
            "priceCurrency": "USD",
            "description": "Test de análisis de color, 5 paletas de colores personalizadas, Test de oro o plata",
            "availability": "https://schema.org/InStock"
          },
          {
            "@type": "Offer",
            "name": "Plan Pro",
            "price": "17.99",
            "priceCurrency": "USD",
            "description": "Test de análisis de color, 5 paletas de colores personalizadas, 12 fotos IA, 150 créditos de probador virtual, Test de oro o plata",
            "availability": "https://schema.org/InStock"
          }
        ]
      },
      {
        "@type": "Product",
        "@id": "https://www.palettehunt.com/es/#product",
        "name": "Análisis de Color Palette Hunt",
        "description": "Servicio de análisis de color y prueba virtual impulsado por IA",
        "brand": {
          "@type": "Brand",
          "name": "Palette Hunt",
          "logo": "https://www.palettehunt.com/logo.png"
        },
        "image": [
          "https://www.palettehunt.com/hero_es.png",
          "https://www.palettehunt.com/playground_es.png"
        ],
        "offers": {
          "@type": "AggregateOffer",
          "lowPrice": "6.99",
          "highPrice": "17.99",
          "priceCurrency": "USD",
          "offerCount": "2",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5",
          "reviewCount": "2",
          "bestRating": "5",
          "worstRating": "1"
        },
        "review": [
          {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "author": {
              "@type": "Person",
              "name": "Laura"
            },
            "reviewBody": "Nunca pensé que los colores amarillos me quedarían bien, pero verme en ellos ha cambiado mi opinión por completo."
          },
          {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "author": {
              "@type": "Person",
              "name": "Olympia"
            },
            "reviewBody": "¡Las fotos generadas por IA me ayudaron a visualizar cómo diferentes colores se verían en mí. ¡Es increíble!"
          }
        ],
        "additionalProperty": [
          {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Análisis de color impulsado por IA"
          },
          {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Prueba virtual"
          },
          {
            "@type": "PropertyValue",
            "name": "Características",
            "value": "Recomendaciones personalizadas"
          }
        ]
      },
      {
        "@type": "FAQPage",
        "@id": "https://www.palettehunt.com/es/#faq",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Cómo funciona Palette Hunt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Utilizamos los algoritmos de visión por IA más avanzados para analizar tus fotos y generar paletas de colores personalizadas que complementan tus características únicas y preferencias de estilo."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cuánto tiempo tarda en dar resultados?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Normalmente recibirás tus resultados en 1 hora. Si los resultados tardan más de 2 horas, envíanos un email a hi@palettehunt.com"
            }
          },
          {
            "@type": "Question",
            "name": "¿La calidad de los resultados será buena?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Sí, podemos asegurarte que la calidad de los resultados será la mejor que puedes obtener en el mercado. Pero necesitas proporcionarnos fotos de buena calidad para maximizar la calidad de los resultados."
            }
          }
        ]
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>{t.title}</title>
        <meta name="description" content={t.description} />
        <meta name="keywords" content={t.keywords} />
        <link rel="canonical" href="https://www.palettehunt.com/es" />
        <meta property="og:title" content={t.ogTitle} />
        <meta property="og:description" content={t.ogDescription} />
        <meta property="og:url" content="https://www.palettehunt.com/es" />
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <header className="bg-white">
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className={`flex justify-between items-center`}>
              <Link to="/es" className="flex items-center">
                <img src="/logo.png" alt="Palette Hunt Logo" className="h-8 w-auto" />
                {!isMobile && <span className="ml-2 text-xl font-bold text-gray-900">Palette Hunt</span>}
              </Link>
              <div className="flex items-center">
                {isMobile ? (
                  <div className="flex items-center space-x-4">
                    <Link
                      to="/es/login"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                    >
                      {t.getStarted}
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center space-x-8 mr-10">
                      <Link to="/es/contact" className="text-gray-700 hover:text-gray-900">
                        {t.contact}
                      </Link>
                      <div className="relative language-menu">
                        <button
                          onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
                          className="flex items-center text-gray-700 hover:text-gray-900"
                        >
                          <Globe className="w-5 h-5 mr-1" />
                          <span className="uppercase mr-1">{language}</span>
                          <ChevronDown className="w-4 h-4" />
                        </button>
                        {languageMenuOpen && (
                          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
                            <Link
                              to="/"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              English
                            </Link>
                            <Link
                              to="/es"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Español
                            </Link>
                            <Link
                              to="/pt"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Português
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <Link
                      to="/es/login"
                      className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-full text-white bg-gray-900 hover:bg-gray-800"
                    >
                      {t.signIn}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </nav>
        </header>

        <main className="flex-grow pt-16">
          <HeroSection t={t} isMobile={isMobile} isTablet={isTablet} containerRef={containerRef} svgRef={svgRef} />
          <FeaturesSection isMobile={isMobile} />
          <ImageGridSection isMobile={isMobile} isTablet={isTablet} containerRef2={containerRef2} svgRef2={svgRef2} />
          <ColorAnalysisSection />
          <PlaygroundSection isMobile={isMobile} isTablet={isTablet} />
          <TestimonialsSection isMobile={isMobile} />
          <PricingSection t={t} />
          <FAQSection />
        </main>

        <Footer />
      </div>
    </>
  );
};

export default LandingPage;